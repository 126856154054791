import React from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Input,
  Select
} from "antd";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import { CREATE_ROASTER_PLAN } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import DateFnsUtils from "@date-io/date-fns";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ReactGA from "react-ga4";


import "../login.css";
import { timezone } from "../timezone";

const { Text } = Typography;
const { Option } = Select;

export default class CreateRoasterPlan extends React.Component {
  state = {
    name: "",
    start_time:  Date.now(),
    start_time_view: moment(),
    end_time:  Date.now(),
    end_time_view: moment(),
    timezone: [],
    timezone_name: "",
    filteredTimezone: []
  };

  componentDidMount  () {
    this.setState({timezone:timezone});
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

  }

  handleSearch = value => {
    const result = this.state.timezone.filter(tz => {
      return tz.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filteredTimezone: result });
  };
  setTimezone = value => {
    this.setState({ timezone_name: value }, () => {
      console.log(this.state.timezone_name);
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  setUser = value => {
    this.setState({ user_name: value.label, user_id: value.key });
  };

  setStartTime = (value, dateString) => {
    this.setState(
      { start_time: moment(value).format("x"), start_time_view: value },
      () => {
        console.log("Selected Time: ", this.state.start_time);
        console.log("Selected value: ", this.state.start_time_view);
      }
    );
  };

  setEndTime = (value, dateString) => {
    this.setState(
      { end_time: moment(value).format("x"), end_time_view: value },
      () => {
        console.log("Selected Time: ", this.state.end_time);
        console.log("Selected value: ", this.state.end_time_view);
      }
    );
  };

  createRoasterPlan = () => {
    if (
      this.state.start_time === "" ||
      this.state.end_time === "" ||
      this.state.name === ""
    ) {
      swal.fire(
        "Info",
        "Please enter Start and End time with Roaster Name",
        "info"
      );
    } else {
      let data = {
        name: this.state.name,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        timezone_name: this.state.timezone_name
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_ROASTER_PLAN}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Info", response.data.message, "info");
            console.log("response from create Roaster plan", response);
          })
          .catch(error => {
            swal.fire("Error", error.message, "error");
            console.log("error from create Roaster plan", error);
          });
      });
    }
  };

  render() {
    const timezoneList=this.state.timezone.map(r => {
      return <Option key={r.name}>{r.name}</Option>;
    })

    const FliteredTimezoneList = this.state.filteredTimezone.map(r => {
      return <Option key={r.name}>{r.name}</Option>;
    })
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Create Roster Plan"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Search Timezone</Text>
            <Select
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.handleSearch}
              value={this.state.timezone_name}
              onChange={this.setTimezone}
              placeholder="Select Timezone"
            >
              {this.state.filteredTimezone.length === 0
                ? timezoneList
                : FliteredTimezoneList}
            </Select>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text> Shift Start Time</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={this.setStartTime}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text> Shift End Time</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={this.setEndTime}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createRoasterPlan}
            >
              Create Roster Plan
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
