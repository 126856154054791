import React from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Radio,
  Checkbox,
  Tag,
  Icon
} from "antd";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import {
  EDIT_ATTENDANCE,
  CREATE_CUSTOM_FIELD,
  ADD_CUSTOM_FIELDS_TO_TASK,
  MULTI_IMAGE_UPLOAD
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  isValidURL
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";


import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class ViewAttendance extends React.Component {
  state = {
    attendance: false,
    checkin_time: "",
    checkin_time_view: moment(),
    checkout_time: "",
    checkout_time_view: moment(),
    entity: "",
    // entity_key: null,
    entity_address: "",
    entity_loc: null,
    asset: "",
    asset_key: null,
    loc_lat: 23.685,
    loc_lon: 90.3563,
    entity_loc_name: "",
    checkout_entity_loc_name: "",
    loading: false,
    dataS: [],
    assetData: [],
    userData: [],
    user_id: "",
    user_name: "",
    attendance_id: "",
    created_by: "",
    created_for: "",
    checkin_loc_name: "",
    checkout_loc_name: "",
    custom_fields: [],
    custom_field_data: {},
    customArrayHandle: [],
    customArrayText: "",
    getCustomFieldData: {}
  };

  componentDidMount() {
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
    this.getAttendanceDetails();
    this.getCustomFields();
  }

  getCustomFields = () => {
    GET_ACCESS_TOKEN().then(token => {
      let url = `${CREATE_CUSTOM_FIELD}?field_for=attendance&access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({
            custom_fields: response.data.data
          });
          console.log("response from get custom fields", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from get custom fields", error);
        });
    });
  };

  getAttendanceDetails = () => {
    this.setState({ attendance_id: this.props.location.state.attendance_id });

    GET_ACCESS_TOKEN().then(token => {
      let attendance_id = this.props.location.state.attendance_id;
      let url = `${EDIT_ATTENDANCE}${attendance_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit Attendance", response.data.data);
        this.setState({
          checkin_time:
            response.data.data.checkin_time !== null
              ? response.data.data.checkin_time.$date
              : "",
          checkin_time_view:
            response.data.data.checkin_time !== null
              ? response.data.data.checkin_time.$date
              : moment(),
          checkout_time:
            response.data.data.checkout_time !== null
              ? response.data.data.checkout_time.$date
              : "",
          checkout_time_view:
            response.data.data.checkout_time !== null
              ? response.data.data.checkout_time.$date
              : moment(),
          asset_key:
            response.data.data.asset_confirm.asset !== null
              ? response.data.data.asset_confirm.asset
              : null,
          // entity_key:
          //   response.data.data.entity_confirm.entity !== null
          //     ? response.data.data.entity_confirm.entity
          //     : null,
          entity_loc:
            response.data.data.entity_confirm.checkin_loc !== null
              ? response.data.data.entity_confirm.checkin_loc.coordinates
              : null,
          attendance: response.data.data.attendance,
          created_by: response.data?.data?.created_by_name ?? "",
          created_for: response.data?.data?.created_for?.name ?? "",
          checkin_loc_name: response.data?.data?.checkin_loc_name ?? "",
          checkout_loc_name: response.data?.data?.checkout_loc_name ?? "",
          entity_loc_name: response.data?.data?.entity_confirm?.checkin_loc_name ?? "",
          checkout_entity_loc_name: response.data?.data?.entity_confirm?.checkout_loc_name ?? "",
          loc_lon: response.data.data.loc.coordinates[0],
          loc_lat: response.data.data.loc.coordinates[1],
          getCustomFieldData: response.data?.data?.custom_fields ?? {}
        });
      });
    });
  };

  updateCustomAttendanceData = checked => {
    if (Object.keys(this.state.custom_field_data).length === 0) {
      swal.fire("Info", "Please Enter Atleast One Value", "info");
    } else {
      const data = {
        field_for: "attendance",
        custom_fields: this.state.custom_field_data
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${ADD_CUSTOM_FIELDS_TO_TASK}${this.state.attendance_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Success", response.data.message, "success");
            if (response.data.message === "Data successfully updated!") {
              this.getAttendanceDetails();
            }
            console.log(
              "response from custom field attendance data update ",
              response
            );
          })
          .catch(error => {
            this.getAttendanceDetails();
            swal.fire("Error", error.message, "error");
            console.log("error from custom field attendance data update ", error);
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    const { custom_field_data } = this.state;
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title="View Attendance"
        />
        <Card
          style={{
            marginTop: 5,
            width: "100%",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}
        >
          <p >
            <Text style={{ fontWeight: "bold" }}>Created By</Text>:{" "}
            {this.state.created_by}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Created For</Text>:{" "}
            {this.state.created_for}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Checkin Time</Text>:{" "}
            {this.state.checkin_time?timeConverter(this.state.checkin_time):""}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Checkin Location</Text>:{" "}
            {this.state.checkin_loc_name}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Checkout Time</Text>:{" "}
            {this.state.checkout_time?timeConverter(this.state.checkout_time):""}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Checkout Location</Text>:{" "}
            {this.state.checkout_loc_name}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Checkin Place Address</Text>:{" "}
            {this.state.entity_loc_name}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Checkout Place Address</Text>:{" "}
            {this.state.checkout_entity_loc_name}
          </p>
        </Card>
        {Object.keys(this.state.getCustomFieldData).length !== 0 ? (
          <Card
            style={{
              marginTop: 5,
              width: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            }}
          >
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              title="Custom Field Data"
            />
            <div></div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {Object.keys(this.state.getCustomFieldData).map((el, index) => (
                <Text
                  className="task-view-text"
                  style={{ fontSize: 15 }}
                  key={index}
                >
                  <Icon type="book" style={{ margin: 5 }} />
                  {el} :{" "}
                  {Array.isArray(this.state.getCustomFieldData[el]) ? (
                    <div>
                      {this.state.getCustomFieldData[el].map((i, index) => {
                        if (isValidURL(i)) {
                          return (
                            <img
                              src={i}
                              alt=""
                              style={{ margin: 10, height: 200, width: 200 }}
                              key={index}
                            />
                          );
                        } else {
                          return (
                            <Text
                              className="task-view-text"
                              style={{ fontSize: 15 }}
                              key={index}
                            >
                              {`${i},`}
                            </Text>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <Text
                      className="task-view-text"
                      style={{ fontSize: 15 }}
                      key={index}
                    >
                      {this.state.getCustomFieldData[el]}
                    </Text>
                  )}
                </Text>
              ))}
            </div>
          </Card>
        ) : null}

        {this.state.custom_fields.length > 0 ? (
          <div style={{ display: "flex", flex: 1 }}>
            <Card
              style={{
                marginTop: 5,
                width: "100%",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
              }}
            >
              <PageHeader
                style={{
                  border: "1px solid rgb(235, 237, 240)"
                }}
                title="Update Custom Fields"
              />
              <Form>
                {this.state.custom_fields.map((f, index) => {
                  return (
                    <Form.Item key={f._id.$oid}>
                      <Text>{`${f.label} (Type : ${f._type}) : `}</Text>
                      {f._type === "dropdown" ? (
                        <Select
                          onChange={value => {
                            let obj = {
                              [f.key_name]: value
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                )
                              },
                              () => {
                                console.log(
                                  "from dropdown input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map(item => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : f._type === "radio" ? (
                        <Radio.Group
                          onChange={e => {
                            let obj = {
                              [f.key_name]: e.target.value
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                )
                              },
                              () => {
                                console.log(
                                  "from radio input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item, index) => {
                            return (
                              <Radio key={index} value={item}>
                                {item}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      ) : f._type === "checkbox" ? (
                        <Checkbox.Group
                          options={f.criteria.choices}
                          onChange={checkedValues => {
                            let obj = {
                              [f.key_name]: checkedValues
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                )
                              },
                              () => {
                                console.log(
                                  "from checkbox input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        />
                      ) : f._type === "multiselect" ? (
                        <Select
                          mode="multiple"
                          onChange={value => {
                            let obj = {
                              [f.key_name]: value
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                )
                              },
                              () => {
                                console.log(
                                  "from multiselect input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map(item => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : (
                        <Input
                          type={
                            f._type === "image"
                              ? "file"
                              : f._type === "int" ||
                                f._type === "float" ||
                                f._type === "decimal"
                              ? "number"
                              : "text"
                          }
                          multiple={f._type === "image" ? true : false}
                          name={f._type === "image" ? "file[]" : ""}
                          onChange={evt => {
                            if (f._type === "list") {
                              this.setState({
                                customArrayText: evt.target.value
                              });
                            } else if (f._type === "image") {
                              console.log(evt.target.files);
                              const { files } = evt.target;
                              GET_ACCESS_TOKEN().then(token => {
                                const img = new FormData();

                                for (let i = 0; i < files.length; i++) {
                                  img.append(`imgFile`, files[i]);
                                }

                                console.log("in upload", img);

                                axios({
                                  method: "put",
                                  url: `${MULTI_IMAGE_UPLOAD}task?access_token=${token}`,
                                  data: img,
                                  headers: {
                                    "Content-Type": "multipart/form-data"
                                  }
                                })
                                  .then(response => {
                                    swal.fire(
                                      "Info",
                                      response.data.message,
                                      "info"
                                    );
                                    if (
                                      response.data.message ===
                                      "Image successfully uploaded!"
                                    ) {
                                      let temp = [];
                                      response.data.data.map(res => {
                                        temp.push(
                                          `${process.env.REACT_APP_BASE_PATH}${res.upload_path}`
                                        );
                                        return null;
                                      });

                                      let customObj = {
                                        [f.key_name]: temp
                                      };
                                      this.setState(
                                        {
                                          custom_field_data: Object.assign(
                                            this.state.custom_field_data,
                                            customObj
                                          )
                                        },
                                        () => {
                                          console.log(
                                            "from image input",
                                            this.state.custom_field_data
                                          );
                                        }
                                      );
                                    }
                                    console.log(
                                      "response from image api",
                                      response
                                    );
                                  })
                                  .catch(error => {
                                    swal.fire("Error", error.message, "error");
                                    console.log("Error from upload", error);
                                  });
                              });
                            } else if (
                              f._type === "int" ||
                              f._type === "float" ||
                              f._type === "decimal"
                            ) {
                              let obj = {
                                [f.key_name]: Number(evt.target.value)
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  )
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            } else {
                              let obj = {
                                [f.key_name]: evt.target.value
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  )
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            }
                          }}
                        />
                      )}

                      {f._type === "list" ? (
                        <div>
                          <Button
                            style={{ backgroundColor: "teal", color: "white" }}
                            onClick={() => {
                              this.setState(
                                {
                                  customArrayHandle: [
                                    ...this.state.customArrayHandle,
                                    this.state.customArrayText
                                  ]
                                },
                                () => {
                                  let obj = {
                                    [f.key_name]: this.state.customArrayHandle
                                  };
                                  console.log("array object", obj);
                                  this.setState(
                                    {
                                      custom_field_data: Object.assign(
                                        this.state.custom_field_data,
                                        obj
                                      )
                                    },
                                    () => {
                                      console.log(
                                        "from list input",
                                        this.state.custom_field_data
                                      );
                                    }
                                  );
                                }
                              );
                            }}
                          >
                            Add
                          </Button>
                          {custom_field_data.hasOwnProperty(f.key_name) &&
                          this.state.customArrayHandle.length > 0 ? (
                            <Button
                              style={{
                                marginLeft: 10,
                                backgroundColor: "#1A3337",
                                color: "white"
                              }}
                              onClick={() => {
                                this.setState({
                                  customArrayText: "",
                                  customArrayHandle: []
                                });
                              }}
                            >
                              Adding Complete
                            </Button>
                          ) : null}
                        </div>
                      ) : null}
                      {f._type === "list" &&
                      custom_field_data.hasOwnProperty(f.key_name) ? (
                        <Card style={{ marginTop: 5 }}>
                          {custom_field_data[f.key_name].map((tag, index) => {
                            return (
                              <Tag color="blue" key={index}>
                                {tag}
                              </Tag>
                            );
                          })}
                        </Card>
                      ) : null}
                    </Form.Item>
                  );
                })}
                {this.state.custom_fields.length > 0 ? (
                  <Form.Item>
                    <Button
                      className="login-form-button"
                      htmlType="submit"
                      onClick={this.updateCustomAttendanceData}
                    >
                      Update Custom Fields
                    </Button>
                  </Form.Item>
                ) : null}
              </Form>
            </Card>
          </div>
        ) : null}
      </Card>
    );
  }
}
