import React, { Component } from "react";
import { Card,PageHeader,Layout,Typography,Descriptions  } from "antd";
// import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { SUCCESS_PAYMENT } from "../../api/constants";
import axios from "axios";
import swal from "sweetalert2";

import "../login.css";
import Logo from "../../assets/logo.png";
import { Spinner } from "../loader";

const { Footer } = Layout;
const { Text } = Typography;


export default class Successful extends Component {
  state = {
    name: "",
    address: "",
    city: "",
    country: "",
    connect_key: "",
    post_code: "",
    telephone: "",
    website: "",
    sub_orgs: "",
    credit_days: null,
    tax_amount: null,
    plans: [],
    created_on: "",
    discount_amount: null,
    active: false,
    sum_total: null,
    has_paid: false,
    balance: null,
    dues: null,
    payments: null,
    billed_amount: null,
    year: null,
    month: null,
    remarks: "",
    loading: false
  };
  componentDidMount() {
    console.log("bill id", this.props.match.params.mb_id);
    console.log("transaction id", this.props.match.params.tran_id);
    swal.fire("Success", "Payment successful", "success");
    this.getUserInfo();
  }

  getUserInfo = () => {
    this.setState({ loading: true });
    let url = `${SUCCESS_PAYMENT}${this.props.match.params.mb_id}/${this.props.match.params.tran_id}`;
    axios({
      method: "get",
      url: url
    })
      .then(response => {
        this.setState({ loading: false });
        console.log("response from monthly bill success", response);
        this.setState({
          name: response.data.data.org.name,
          address: response.data.data.org.address,
          city: response.data.data.org.city,
          country: response.data.data.org.country,
          connect_key: response.data.data.org.connect_key,
          post_code: response.data.data.org.post_code,
          telephone: response.data.data.org.telephone,
          website: response.data.data.org.website,
          sub_orgs: response.data.data.org.sub_orgs,
          credit_days: response.data.data.org.credit_days,
          tax_amount: response.data.data.org.tax_amount,
          plans: response.data.data.org.plans,
          created_on: response.data.data.generated_on,
          discount_amount: response.data.data.discount_amount,
          active: response.data.data.org.active,
          sum_total: response.data.data.sum_total,
          has_paid: response.data.data.has_paid,
          balance: response.data.data.balance,
          dues: response.data.data.dues,
          payments: response.data.data.payments,
          billed_amount: response.data.data.billed_amount,
          year: response.data.data.year,
          month: response.data.data.month,
          remarks: response.data.data.remarks
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        console.log("error from success payment", error);
      });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <div
       
      >
          <div
          style={{
            textAlign: "center",
            paddingBottom: 10,
            paddingTop: 15,
            backgroundColor: "#006479"
          }}
        >
          <img src={Logo} alt="" style={{ width: 300 }} />
        </div>
        <Card
          style={{ margin: 10, backgroundColor: "#F5F5F5" }}
        >
          <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              title="Payment Successful"
            />
        <Descriptions
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
        layout="vertical"
      >
        <Descriptions.Item label="Name">{this.state.name}</Descriptions.Item>
        <Descriptions.Item label="Address">{this.state.address}</Descriptions.Item>
        <Descriptions.Item label="City">{this.state.city}</Descriptions.Item>
        <Descriptions.Item label="Country"> {this.state.country}</Descriptions.Item>
        <Descriptions.Item label="Post Code">{this.state.post_code}</Descriptions.Item>
        <Descriptions.Item label="Telephone">{this.state.telephone}</Descriptions.Item>
        <Descriptions.Item label="Remarks">{this.state.remarks}</Descriptions.Item>
        <Descriptions.Item label="Credit Days">{this.state.credit_days}</Descriptions.Item>
        <Descriptions.Item label="Tax Amount">{this.state.tax_amount}</Descriptions.Item>
        <Descriptions.Item label=" Discount Amount">{this.state.discount_amount}</Descriptions.Item>
        <Descriptions.Item label=" Total Amount">{this.state.sum_total}</Descriptions.Item>
        <Descriptions.Item label=" Billed Amount">{this.state.billed_amount}</Descriptions.Item>
        <Descriptions.Item label=" Bill Date"> {this.state.month}/{this.state.year}</Descriptions.Item>
        <Descriptions.Item label="  Balance"> {this.state.balance}</Descriptions.Item>
        <Descriptions.Item label="  Dues"> {this.state.dues}</Descriptions.Item>
        <Descriptions.Item label="  Payments"> {this.state.payments}</Descriptions.Item>
        <Descriptions.Item label="  Paid"> {this.state.has_paid.toString()}</Descriptions.Item>
        <Descriptions.Item label="  Active"> {this.state.active.toString()}</Descriptions.Item>
      </Descriptions>
      </Card>
        {/* <Card
          style={{ margin: 10, backgroundColor: "#F5F5F5" }}
        >
          <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              title="Payment Successful"
            />
          <Card.Grid className="card-grid">Name : {this.state.name}</Card.Grid>
          <Card.Grid className="card-grid">
            Address : {this.state.address}
          </Card.Grid>
          <Card.Grid className="card-grid">City : {this.state.city}</Card.Grid>
          <Card.Grid className="card-grid">
            Country : {this.state.country}
          </Card.Grid>
          <Card.Grid className="card-grid">
            Post Code : {this.state.post_code}
          </Card.Grid>
          <Card.Grid className="card-grid">
            Telephone : {this.state.telephone}
          </Card.Grid>
          <Card.Grid className="card-grid">
            Remarks : {this.state.remarks}
          </Card.Grid>
          <Card.Grid className="card-grid">
            Credit Days : {this.state.credit_days}
          </Card.Grid>
          <Card.Grid className="card-grid">
            Tax Amount : {this.state.tax_amount}
          </Card.Grid>
          <Card.Grid className="card-grid">
            Discount Amount : {this.state.discount_amount}
          </Card.Grid>
          <Card.Grid className="card-grid">
            Total Amount : {this.state.sum_total}
          </Card.Grid>
          <Card.Grid className="card-grid">
            Billed Amount : {this.state.billed_amount}
          </Card.Grid>
          <Card.Grid className="card-grid">
            Bill Date : {this.state.month}/{this.state.year}
          </Card.Grid>
          <Card.Grid className="card-grid">
            Balance : {this.state.balance}
          </Card.Grid>
          <Card.Grid className="card-grid">Dues : {this.state.dues}</Card.Grid>
          <Card.Grid className="card-grid">
            Payments : {this.state.payments}
          </Card.Grid>
          <Card.Grid className="card-grid">
            Paid : {this.state.has_paid.toString()}
          </Card.Grid>
          <Card.Grid className="card-grid">
            Active : {this.state.active.toString()}
          </Card.Grid>
          {window.ReactNativeWebView ? null : (
             <Card.Grid className="card-grid">
             <Button
               className="login-form-button"
               style={{ width: 150 }}
               onClick={() => {
                 window.location.href = "http://dev.finder-lbs.com:5000";
               }}
             >
               Back to Home
             </Button>
           </Card.Grid>
          )}
         
        </Card> */}
        <Footer
            style={{
              textAlign: "center",
              backgroundColor: "#006479",
              width:"100%",
              position: "fixed",
              bottom:0
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                justifyItems: "center"
              }}
            >
              <a href="https://web.conveyance.app">
                <img
                  src={Logo}
                  alt=""
                  style={{ width: 160, height: 41, alignSelf: "center" }}
                />
              </a>
              <Text style={{ color: "white", fontSize: 15 }}>
                Monico Technologies LTD
              </Text>
              <Text style={{ color: "white", fontSize: 15 }}>
                26-27, Ringroad Adabor, Dhaka
              </Text>
              <Text style={{ color: "#b0bec5", fontSize: 15 }}>
                © All rights reserved by Monico Technologies LTD, 2020
              </Text>
            </div>
            </Footer>
      </div>
    );
  }
}
