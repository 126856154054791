import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  Input
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { MONTHLYBILL_PAYMENT, CREATE_DRIVER } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class StaffBillPayment extends Component {
  state = {
    monthly_bill_id: "",
    remarks: "",
    payments: null,
    target: "",
    loading: false,
    name: "",
    userData: []
  };

  componentDidMount() {
    this.setState({
      monthly_bill_id: this.props.location.state.monthlyBill_id
    });
    this.getUserList();
  }

  getUserList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch(error => {
          console.log("error from driver list api", error);
        });
    });
  };

  setTarget = value => {
    this.setState({ target: value.key }, () => {
      console.log(this.state.target);
    });
  };

  setRemarks = evt => {
    this.setState({ remarks: evt.target.value });
    console.log(this.state.remarks);
  };

  setPayment = evt => {
    this.setState({ payments: evt.target.value });
    console.log(this.state.payments);
  };

  payMonthlyBill=()=>{
    this.setState({ loading: true });
    const data = {
      monthly_bill_id: this.state.monthly_bill_id,
      remarks: this.state.remarks,
      payments: Number(this.state.payments),
      target: this.state.target
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${MONTHLYBILL_PAYMENT}${this.state.monthly_bill_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("success", response.data.message, "success");
          console.log("response from pay monthly bill admin", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("error", error, "error");
          console.log("error from pay monthly bill admin", error);
        });
    });
  }

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title="Pay Monthly Bill"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Select User</Text>
            <Select
              labelInValue
              value={{ key: this.state.target, label: this.state.name }}
              onChange={this.setTarget}
              placeholder="Select User"
            >
              {this.state.userData.map(r => {
                
                return <Option key={r.mobile===""?r.email:r.mobile}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Amount</Text>

            <Input
              type="number"
              placeholder="Amount"
              value={this.state.payments}
              onChange={this.setPayment}
            />
          </Form.Item>
          <Form.Item>
            <Text>Remarks</Text>

            <Input
              type="text"
              placeholder="Remarks"
              value={this.state.remarks}
              onChange={this.setRemarks}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.payMonthlyBill}
            >
              Pay Monthly Bill
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
