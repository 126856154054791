import React, { Component } from "react";
import {
  Card,
  Result
} from "antd";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { INITIATE_PAYMENT } from "../../api/constants";
import axios from "axios";

import Logo from "../../assets/logo.png";

export default class PaymentPage extends Component {
  state = {
    url: ""
  };
  componentDidMount() {
    console.log(this.props.match.params.mb_id);
    const data = {
      mb_id: this.props.match.params.mb_id,
      user_id: this.props.match.params.user_id
    };
    console.log("rdataent", data);
    GET_ACCESS_TOKEN().then(token => {
      let url=`${INITIATE_PAYMENT}?access_token=${token}`
      axios({
        method: "post",
        url:url ,
        data: data,
        headers: { 
          'content-type': 'application/x-www-form-urlencoded' 
        }
      })
        .then(response => {
          console.log("response from initiate payment", response);
          this.setState({
            url: response.data.response.GatewayPageURL,
            loading: false
          });
          window.location.href = this.state.url;
        })
        .catch(error => {
          console.log("error from initiate payment", error);
        });
    });
  }
  render() {
    return (
         <div>
      <div
       style={{
         textAlign: "center",
         paddingBottom: 10,
         paddingTop: 15,
         backgroundColor: "#006479"
       }}
     >
       <img src={Logo} alt="" style={{ width: 300 }} />
     </div>
     <Card style={{ margin: 10 }}>
         <Result
           title="Payment Processing ! Please Wait ...."
         />
       </Card>
   </div>
    );
  }
}
