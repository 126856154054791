import React, { Component } from "react";
import {
  Select,
  Card,
  Spin,
  Input,
  Tooltip,
  Icon,
  Typography,
  Button
} from "antd";
import { debounce } from "lodash";
import axios from "axios";
import swal from "sweetalert2";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import {
  // GEO_CODING_API,
  CREATE_ROUTE,
  // REVERSE_GEO,
  LOCATION_SEARCH_GOOGLE,
  REVERSE_LOCATION_SEARCH_GOOGLE
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

const { Option } = Select;
const { Text } = Typography;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40]
});
const red = new L.Icon({
  iconUrl: require("../.././assets/mymarkertwo.png"),
  iconSize: [30, 40]
});

export default class CreateRoute extends Component {
  state = {
    query: "",
    start_location: "",
    end_location: "",
    value: [],
    value1: [],
    data: [],
    fetch: false,
    start_location_lat: 23.685,
    start_location_lon: 90.3563,
    end_location_lat: 23.685,
    end_location_lon: 90.3563,
    name: "",
    colors: [],
    colorId: [],
    trip_duration: 0,
    loading: false,
    editStart: false,
    editEnd: false
  };

  colors = [
    {
      id: 1,
      color: "#f44336"
    },
    {
      id: 2,
      color: "#E91E63"
    },
    {
      id: 3,
      color: "#9C27B0"
    },
    {
      id: 4,
      color: "#673AB7"
    },
    {
      id: 5,
      color: "#3F51B5"
    },
    {
      id: 6,
      color: "#2196F3"
    },
    {
      id: 7,
      color: "#009688"
    },
    {
      id: 8,
      color: "#4CAF50"
    },
    {
      id: 9,
      color: "#FFEB3B"
    },
    {
      id: 10,
      color: "#FF9800"
    }
  ];

  setName = evt => {
    this.setState({ name: evt.target.value });
    console.log(this.state.name);
  };

  setTripDuration = evt => {
    this.setState({ trip_duration: evt.target.value });
    console.log(this.state.trip_duration);
  };

  handleStart = debounce(value => {
    this.setState({ query: value, data: [], fetching: true }, () => {
      console.log(this.state.query);
      if (this.state.query !== "") {
        GET_ACCESS_TOKEN().then(token => {
          axios
            .get(
              `${LOCATION_SEARCH_GOOGLE}?textsearch=${this.state.query}&access_token=${token}`
            )
            .then(response => {
              console.log("response from google search", response);
              const data = response.data.google_loc_datas.map(res => ({
                id: `${res._id.$oid}>${res.loc.coordinates[0]}>${res.loc.coordinates[1]}`,
                value: `${res.name}>${res.address}`
              }));
              this.setState({ data: data, fetch: false });
            });
        });
      }
    });
  }, 500);

  handleStartChange = value => {
    this.setState({ value, data: [], fetch: false });

    return this.setState({
      start_location: value.label.split(">")[0],
      start_location_lat: value.key.split(">")[1],
      start_location_lon: value.key.split(">")[2]
    });
  };
  updatePositionStart = evt => {
    console.log(evt.target.getLatLng());
    this.setState(
      {
        start_location_lat: evt.target.getLatLng().lat,
        start_location_lon: evt.target.getLatLng().lng
      },
      () => {
        GET_ACCESS_TOKEN().then(token => {
          axios
            .get(
              `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.start_location_lat}&lng=${this.state.start_location_lon}&access_token=${token}`
            )
            .then(response => {
              console.log("marker movement reponse", response);
              let name = response.data.google_loc_data[0].address;
              let id = response.data.google_loc_data[0]._id.$oid;
              this.setState({
                start_location: name,
                value: { key: id, label: name }
              });
            });
        });
      }
    );
  };

  updatePositionEnd = evt => {
    console.log(evt.target.getLatLng());
    this.setState(
      {
        end_location_lat: evt.target.getLatLng().lat,
        end_location_lon: evt.target.getLatLng().lng
      },
      () => {
        GET_ACCESS_TOKEN().then(token => {
          axios
            .get(
              `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.end_location_lat}&lng=${this.state.end_location_lon}&access_token=${token}`
            )
            .then(response => {
              console.log("marker movement reponse", response);
              let name = response.data.google_loc_data[0].address;
              let id = response.data.google_loc_data[0]._id.$oid;
              this.setState({
                end_location: name,
                value1: { key: id, label: name }
              });
            });
        });
      }
    );
  };

  handleEndChange = value => {
    this.setState({ value1: value, data: [], fetch: false });

    return this.setState(
      {
        end_location: value.label.split(">")[0],
        end_location_lat: value.key.split(">")[1],
        end_location_lon: value.key.split(">")[2]
      }
    );
  };

  createRoute = () => {
    if (this.state.start_location === "" || this.state.end_location === "") {
      swal.fire("info", " Please enter a valid start and end location", "info");
    } else {
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        start_loc: {
          coordinates: [
            this.state.start_location_lon,
            this.state.start_location_lat
          ]
        },
        start_loc_name: this.state.start_location,
        end_loc: {
          coordinates: [
            this.state.end_location_lon,
            this.state.end_location_lat
          ]
        },
        end_loc_name: this.state.end_location,
        colors: this.state.colors,
        trip_duration: this.state.trip_duration
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_ROUTE}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("success", response.data.message, "success");
            console.log("response from create route", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("error", error, "error");
            console.log("error from create route", error);
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                marginRight: 10,
                flexDirection: "column"
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginRight: 10,
                  flexDirection: "column"
                }}
              >
                <Text>Start Location</Text>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {this.state.editStart === true ? (
                    <Input
                      type="text"
                      placeholder="Start Location"
                      value={this.state.start_location}
                      onChange={evt =>
                        this.setState({ start_location: evt.target.value })
                      }
                      style={{ width: window.innerWidth / 2.8 }}
                    />
                  ) : (
                    <Select
                      showSearch={true}
                      labelInValue
                      value={this.state.value}
                      notFoundContent={fetch ? <Spin size="small" /> : null}
                      style={{ width: window.innerWidth / 2.8 }}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={false}
                      placeholder="Search Start Location"
                      onSearch={this.handleStart}
                      onChange={this.handleStartChange}
                      optionLabelProp="label"
                    >
                      {this.state.data.map(r => {
                        return (
                          <Option key={r.id} label={r.value}>
                            {
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <Text>{r.value.split(">")[0]}</Text>
                                <Text
                                  style={{ fontSize: 10, color: "#9CB4CC" }}
                                >
                                  {r.value.split(">")[1]}
                                </Text>
                              </div>
                            }
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  {this.state.start_location === "" ? null : (
                    <Tooltip
                      title={
                        this.state.editStart
                          ? "Search Start Location"
                          : "Edit Start Location"
                      }
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        style={{ marginLeft: 10 }}
                        onClick={() =>
                          this.setState({ editStart: !this.state.editStart })
                        }
                      >
                        <Icon type={this.state.editStart ? "search" : "edit"} />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>

              <div style={{ marginBottom: 5, marginRight: 10 }}>
                <Text>Name</Text>

                <Input
                  type="text"
                  placeholder="Name"
                  value={this.state.name}
                  onChange={this.setName}
                />
              </div>
            </div>
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text>End Location</Text>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {this.state.editEnd === true ? (
                    <Input
                      type="text"
                      placeholder="End Location"
                      value={this.state.end_location}
                      onChange={evt =>
                        this.setState({ end_location: evt.target.value })
                      }
                      style={{ width: window.innerWidth / 2.8 }}
                    />
                  ) : (
                    <Select
                      showSearch={true}
                      labelInValue
                      value={this.state.value1}
                      notFoundContent={fetch ? <Spin size="small" /> : null}
                      style={{ width: window.innerWidth / 2.8 }}
                      filterOption={false}
                      showArrow={false}
                      placeholder="Search End Location"
                      onSearch={this.handleStart}
                      onChange={this.handleEndChange}
                      optionLabelProp="label"
                    >
                      {this.state.data.map(r => {
                        return (
                          <Option key={r.id} label={r.value}>
                            {
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <Text>{r.value.split(">")[0]}</Text>
                                <Text
                                  style={{ fontSize: 10, color: "#9CB4CC" }}
                                >
                                  {r.value.split(">")[1]}
                                </Text>
                              </div>
                            }
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  {this.state.end_location === "" ? null : (
                    <Tooltip
                      title={
                        this.state.editEnd
                          ? "Search End Location"
                          : "Edit End Location"
                      }
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        style={{ marginLeft: 10 }}
                        onClick={() =>
                          this.setState({ editEnd: !this.state.editEnd })
                        }
                      >
                        <Icon type={this.state.editEnd ? "search" : "edit"} />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>

              <div style={{ marginBottom: 5 }}>
                <Text>Trip Duration</Text>

                <Input
                  type="number"
                  placeholder="Trip Duration"
                  value={this.state.trip_duration}
                  onChange={this.setTripDuration}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flex: 1 }}>
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <Map
                center={
                  this.state.end_location !== ""
                    ? [this.state.end_location_lat, this.state.end_location_lon]
                    : [
                        this.state.start_location_lat,
                        this.state.start_location_lon
                      ]
                }
                zoom={this.state.start_location === "" ? 10 : 12}
              >
                {/* <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                /> */}

                <ReactLeafletGoogleLayer
                  googleMapsLoaderConf={{
                    KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                    VERSION: "3.49.2"
                  }}
                  type={"roadmap"}
                />

                <Marker
                  position={[
                    this.state.start_location_lat,
                    this.state.start_location_lon
                  ]}
                  draggable
                  onDragend={this.updatePositionStart}
                  icon={green}
                >
                  <Popup>
                    <span>From :{this.state.start_location}</span>
                  </Popup>
                </Marker>

                {this.state.start_location !== "" ? (
                  <Marker
                    position={[
                      this.state.end_location_lat,
                      this.state.end_location_lon
                    ]}
                    draggable
                    onDragend={this.updatePositionEnd}
                    icon={red}
                  >
                    <Popup>
                      <span>To :{this.state.end_location}</span>
                    </Popup>
                  </Marker>
                ) : null}
              </Map>
              <div
                style={{
                  marginBottom: 5,
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <div style={{ display: "flex" }}>
                  <Text>
                    Select Colors :{" "}
                    {this.state.colors.length > 1
                      ? `${this.state.colors.length} Colors Selected`
                      : `${this.state.colors.length} Color Selected`}
                  </Text>
                  <button
                    className="borderless-button"
                    style={{ color: "red" }}
                    onClick={() => {
                      this.setState({ colors: [], colorId: "" }, () => {
                        console.log(this.state.colors);
                      });
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {this.colors.map(color => (
                    <div key={color.id}>
                      {this.state.colorId === color.id ? (
                        <Button
                          style={{
                            borderRadius: 50,
                            backgroundColor: color.color,
                            height: 40,
                            width: 40,
                            margin: 5,
                            borderColor: "black"
                          }}
                          onClick={() => {
                            this.state.colors.push(color.color);
                            this.setState({ colorId: color.id }, () => {
                              console.log(this.state.colorId);
                            });
                            console.log(this.state.colors);
                          }}
                        />
                      ) : (
                        <Button
                          style={{
                            borderRadius: 50,
                            backgroundColor: color.color,
                            height: 35,
                            width: 35,
                            margin: 5
                          }}
                          onClick={() => {
                            this.state.colors.push(color.color);
                            this.setState({ colorId: color.id }, () => {
                              console.log(this.state.colorId);
                            });
                            console.log(this.state.colors);
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          className="login-form-button"
          htmlType="submit"
          onClick={this.createRoute}
          style={{ marginTop: 24, marginBottom: 15 }}
        >
          Create Route
        </Button>
      </Card>
    );
  }
}
