import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
} from "antd";
import { debounce } from "lodash";
import {
  CREATE_LEAVE,
  LEAVE_TYPE_CHOICES,
  CREATE_DRIVER,
  WEB_FILE_UPLOAD,
  GET_ASSIGNED_USER_SUB_ORG_LIST,
} from "../../api/constants";
import { GET_ACCESS_TOKEN, GET_USER_LOCAL } from "../../utilities/utilities";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ReactGA from "react-ga4";

const { Text } = Typography;
const { Option } = Select;

export default class CreateLeave extends Component {
  state = {
    remarks: "",
    leave_type_choices: [],
    leave_type: "",
    leave_type_name: "",
    leave_date: Date.now(),
    leave_date_view: moment(),
    leave_end_date: Date.now(),
    leave_end_date_view: moment(),
    leave_joining_date: Date.now(),
    leave_joining_date_view: moment(),
    userData: [],
    name: GET_USER_LOCAL().name,
    id: GET_USER_LOCAL()._id.$oid,
    for_name: "attendance_leave",
    leave_file: null,
    leave_url: null,
    sub_org: "",
    sub_orgs: [],
  };
  componentDidMount() {
    this.getLeaveType();
    this.getUserList();
    this.getAssignedUserSubOrgs();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getAssignedUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${GET_ASSIGNED_USER_SUB_ORG_LIST}?assigned_user_id=${this.state.id}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            sub_orgs: response.data.data,
            sub_org:
              response.data.data.length > 0 ? response.data?.data[0]?.id : "",
          });

          console.log("response from assigned user sub org list", response);
        })
        .catch((error) => {
          console.log("error from assigned user sub org list api", error);
        });
    });
  };
  getLeaveType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${LEAVE_TYPE_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            leave_type_choices: response.data.leave_type_choices,
          });
          console.log("response from leave type choices", response);
        })
        .catch((error) => {
          console.log("error from leave type choices", error);
        });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from user list", response);
        })
        .catch((error) => {
          console.log("error from user list api", error);
        });
    });
  };

  setUser = (value) => {
    this.setState({ name: value.label, id: value.key, filtered: [] }, () => {
      this.getAssignedUserSubOrgs();
      console.log(this.state.name);
    });
  };

  setSubOrg = (value) => {
    this.setState({ sub_org: value }, () => {
      console.log(this.state.sub_org);
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_DRIVER}?limit=${limit}&name=${value}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from user list", response);
        })
        .catch((error) => {
          console.log("error from user list api", error);
        });
    });
  }, 500);

  setRemarks = (evt) => {
    this.setState({ remarks: evt.target.value });
    console.log(this.state.remarks);
  };

  setLeaveDate = (value, dateString) => {
    this.setState(
      { leave_date: moment(value).format("x"), leave_date_view: value },
      () => {
        console.log("Selected Time: ", this.state.leave_date);
        console.log("Selected value: ", this.state.leave_date_view);
      }
    );
  };
  setLeaveEndDate = (value, dateString) => {
    this.setState(
      { leave_end_date: moment(value).format("x"), leave_end_date_view: value },
      () => {
        console.log("Selected Time: ", this.state.leave_end_date);
        console.log("Selected value: ", this.state.leave_end_date_view);
      }
    );
  };
  setLeaveJoiningDate = (value, dateString) => {
    this.setState(
      {
        leave_joining_date: moment(value).format("x"),
        leave_joining_date_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.leave_joining_date);
        console.log("Selected value: ", this.state.leave_joining_date_view);
      }
    );
  };

  setLeaveType = (value) => {
    this.setState(
      { leave_type_name: value.label, leave_type: value.key },
      () => {
        console.log(this.state.leave_type_name);
      }
    );
  };

  uploadFile = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const file = new FormData();

      file.append("file", this.state.leave_file);

      console.log("in upload", file);

      axios({
        method: "put",
        url: `${WEB_FILE_UPLOAD}${this.state.for_name}?access_token=${token}`,
        data: file,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "File successfully uploaded!") {
            this.setState({
              leave_url: `${process.env.REACT_APP_BASE_PATH}${response.data.data.upload_path}`,
            });
          }
          console.log("response from file upload api", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };

  createLeaveApplication = () => {
    if (this.state.leave_type === "") {
      swal.fire("Info", "Please Select Leave Type", "info");
    } else {
      if (GET_USER_LOCAL().org_admin) {
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${CREATE_LEAVE}?access_token=${token}`;
          let data = {
            leave_start_date: this.state.leave_date,
            leave_end_date: this.state.leave_end_date,
            joining_date: this.state.leave_joining_date,
            leave_request_attachment: this.state.leave_url,
            leave_type: {
              name: this.state.leave_type_name,
              type: this.state.leave_type,
            },
            created_for: {
              name: this.state.name,
              id: this.state.id,
            },
            remarks: this.state.remarks,
            sub_org: this.state.sub_org,
          };
          console.log(data);
          axios({
            method: "post",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded",
            },
          })
            .then((response) => {
              swal.fire("Info", response.data.message, "info");
              console.log("response from create leave", response);
            })
            .catch((error) => {
              swal.fire("Error", error.message, "error");
              console.log("error from create leave", error);
            });
        });
      } else {
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${CREATE_LEAVE}?access_token=${token}`;
          let data = {
            leave_start_date: this.state.leave_date,
            leave_end_date: this.state.leave_end_date,
            joining_date: this.state.leave_joining_date,
            leave_request_attachment: this.state.leave_url,
            leave_type: {
              name: this.state.leave_type_name,
              type: this.state.leave_type,
            },
            created_for: {
              name: GET_USER_LOCAL().name,
              id: GET_USER_LOCAL()._id.$oid,
            },
            remarks: this.state.remarks,
            sub_org: this.state.sub_org,
          };
          console.log(data);
          axios({
            method: "post",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded",
            },
          })
            .then((response) => {
              swal.fire("Info", response.data.message, "info");
              console.log("response from create leave", response);
            })
            .catch((error) => {
              swal.fire("Error", error.message, "error");
              console.log("error from create leave", error);
            });
        });
      }
    }
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title="Create Leave Application(* Marked Fields Are Mandatory)"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          {GET_USER_LOCAL().org_admin ? (
            <Form.Item>
              <Text>Search User *</Text>
              <Select
                labelInValue
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                onSearch={this.handleSearch}
                style={{ width: "100%" }}
                value={{
                  key: this.state.id,
                  label: this.state.name,
                }}
                onChange={this.setUser}
                placeholder="Search User"
              >
                {this.state.userData.map((r) => {
                  return <Option key={r._id.$oid}>{`${r.name}`}</Option>;
                })}
              </Select>
            </Form.Item>
          ) : null}

          <Form.Item>
            <Text>Select Sub Org</Text>
            <Select
              value={this.state.sub_org}
              onChange={this.setSubOrg}
              disabled={this.state.sub_orgs.length < 1 ? true : false}
            >
              {this.state.sub_orgs.map((r) => {
                return <Option key={r.id}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Leave Type *</Text>
            <Select
              labelInValue
              style={{ width: "100%" }}
              value={{
                key: this.state.leave_type,
                label: this.state.leave_type_name,
              }}
              onChange={this.setLeaveType}
              placeholder="Leave Type"
            >
              {this.state.leave_type_choices.map((r) => {
                return <Option key={r.type}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Leave Start Date *</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  inputVariant="outlined"
                  value={this.state.leave_date_view}
                  onChange={this.setLeaveDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Leave End Date *</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  inputVariant="outlined"
                  value={this.state.leave_end_date_view}
                  onChange={this.setLeaveEndDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Joining Date *</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  inputVariant="outlined"
                  value={this.state.leave_joining_date_view}
                  onChange={this.setLeaveJoiningDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <Text>Remarks</Text>
            <Input
              placeholder="Remarks"
              type="text"
              value={this.state.remarks}
              onChange={this.setRemarks}
            />
          </Form.Item>
          <Form.Item>
            <Text>Upload Required Document</Text>

            <input
              type="file"
              onChange={(e) => {
                console.log("file inside", this.state.leave_url);
                this.setState(
                  {
                    leave_url: URL.createObjectURL(e.target.files[0]),
                    leave_file: e.target.files[0],
                  },
                  () => {
                    this.uploadFile();
                  }
                );
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createLeaveApplication}
            >
              Create Application
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
