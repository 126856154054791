import React, { Component } from "react";
import { Form, Icon, Input, Button, Card, Typography  } from "antd";
import { Redirect } from "react-router-dom";
import swal from "sweetalert2";
import axios from "axios";

import { RESET_CODE } from "../api/constants";
// import { LOCAL_USER_INFO, validateEmail } from "../utilities/utilities";
import { Spinner } from "./loader";
import "./login.css";
import Logo from "../assets/logoblue.png";

const { Text, Title } = Typography;

export default class ResetCodeMobile extends Component {
  state = {
    mobile: "",
    code: "",
    loading: false,
    toNpass: false,
    ccode:"",
    width: window.innerWidth,
    height: window.innerHeight
  };

  componentDidMount() {
    this.setState({ mobile: this.props?.location?.state?.mobile??"",ccode:this.props?.location?.state?.code??""  });
    const handleResize = () => {
      this.setState({ height: window.innerHeight, width: window.innerWidth });
    };
    window.addEventListener("resize", handleResize);
  }

  setCode = evt => {
    evt.preventDefault();
    this.setState({ code: evt.target.value });
    console.log(this.state.code);
  };

  verifyCode = () => {
    this.setState({ loading: true });
    console.log("clicked")
    const url = `${RESET_CODE}?mobile=${this.state.ccode+this.state.mobile}&code=${this.state.code}`;
    axios({
      method: "get",
      url: url
    })
      .then(response => {
        this.setState({ loading: false });
        console.log("response from code verify api", response);
        if (response.data.hasOwnProperty("status")) {
          swal.fire("Information", response.data.status, "info");
          if(response.data.status === "user verified"){
            this.setState({ toNpass: true });
          }
         
        }
      })
      .catch(error => {
        this.setState({ loading: false });
        console.log("error in code verify", error);
      });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    } else if (this.state.toNpass === true) {
      return (
        <Redirect
          to={{
            pathname: "/newPassword",
            state: { mobile: this.state.mobile,code:this.state.code,ccode:this.state.ccode }
          }}
        />
      );
    }
    return (
      <div className="container" style={{ width: this.state.width }}>
      <div style={{ display: "flex" }}>
        <Card
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 15,
            position: "relative",
            top: this.state.height / 20,
          }}
        >
          <div style={{ textAlign: "center", paddingBottom: 10 }}>
            <a href="https://www.conveyance.app/">
              <img
                src={Logo}
                alt=""
                style={{
                  width: 230,
                  position: "relative",
                  padding: 10,
                }}
              />
            </a>
          </div>
          <Title>Enter the 6-digit code</Title>
          <Text>We've sent an one-time passcode to {this.state.mobile}</Text>
          <Form className="login-form">
            <Form.Item>
              <Input
                type="number"
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Code"
                value={this.state.code}
                onChange={this.setCode}
              />
            </Form.Item>
            <div
                style={{
                  marginTop: -20,
                  textAlign: "center",
                  marginBottom: 15,
                }}
              >
                <Button type="link" onClick={this.resendCode}>
                  Didn't receive the code?
                </Button>
              </div>
            <Form.Item>
              <Button
                style={{ width: "100%" }}
                className="login-form-button"
                htmlType="submit"
                onClick={this.verifyCode}
              >
                Verify
              </Button>
            </Form.Item>
          </Form>
        </Card>
        </div>
      </div>
    );
  }
}
