import React, { Component } from 'react';
import {
    Card,
    Form,
    Button,
    Typography,
    PageHeader,
    Spin,
    Select
  } from "antd";
  import axios from "axios";
  import swal from "sweetalert2";
  import { SEND_ORG_INVITATION, EDIT_CONNECT_LIST} from "../../api/constants";
  import { GET_ACCESS_TOKEN, GET_USER_LOCAL} from "../../utilities/utilities";
  import { Spinner } from "../loader";
  
  import "../login.css";
  
  const { Text } = Typography;
  const { Option } = Select;

export default class CreateConnection extends Component {
    state = {
        organization:"",
        org_id:"",
        org:"",
        invite_rsp:"accept",
        loading: false,
        data: [],
        filtered:[],
        org_connect_id:""
      };
    
      componentDidMount() {
        this.setState({org_connect_id: this.props.location.state.org_connect_id,org:GET_USER_LOCAL().organization.$oid});
        this.getOrgList();
        this.getDetails();
      }

      getDetails=()=>{
        GET_ACCESS_TOKEN().then(token => {
            let org_connect_id = this.props.location.state.org_connect_id;
            let url = `${EDIT_CONNECT_LIST}${org_connect_id}?access_token=${token}`;
            axios({
              method: "get",
              url: url
            }).then(response => {
              console.log("response from edit connect list", response);
              if(response.data.message==="Information not found"){
                swal.fire("Error","User Not Found !","error")
                this.setState({redirect:true});
              } else {
                this.setState({
                 organization:response.data.data.sender_org_name,
                 org_id:response.data.data.sender_org.$oid,
                 invite_rsp:response.data.data.response,
                 org:response.data.data.org.$oid
                });
              }
              
            });
          });
      }
    
      getOrgList = () => {
        GET_ACCESS_TOKEN().then(token => {
          const url = `${SEND_ORG_INVITATION}?access_token=${token}`;

          axios({
            method: "get",
            url: url
          })
            .then(response => {
              this.setState({ data: response.data.data });
              console.log("data from org list api send org invite api", response);
            })
            .catch(error => {
              console.log("error from org list api", error);
            });
        });
      };
    
      
      setOrg = value => {
        this.setState({ organization: value.label,org_id:value.key }, () => {
          console.log(this.state.organization);
        });
      };
      handleSearch=(value)=>{
        const result = this.state.data.filter(org=> {
          return org.name.toLowerCase() === value.toLowerCase()
        })
        console.log(result)
        this.setState({filtered:result})

      }

      setResponse = value => {
        this.setState({ invite_rsp : value }, () => {
          console.log(this.state.invite_rsp );
        });
      };
      updateConnection = () => {
        if(this.state.org_id===""){
          swal.fire("info","Please Select Organization","info");
        } else {
          this.setState({ loading: true });
          let data = {
            org:this.state.org,
            sender_org:this.state.org_id,
            invite_rsp:this.state.invite_rsp
          }
          console.log(data);
          GET_ACCESS_TOKEN().then(token => {
            let url=`${EDIT_CONNECT_LIST}${this.state.org_connect_id}?access_token=${token}`

            axios({
              method: "put",
              url: url,
              data:data,
              headers: { 
                'content-type': 'application/x-www-form-urlencoded' 
              }
            })
              .then(response => {
                this.setState({ loading: false });
                swal.fire("Success", response.data.message, "success");
                console.log("response from update org connect list send", response);
              })
              .catch(error => {
                this.setState({ loading: false });
                swal.fire("Error", error.message, "error");
                console.log("error from update org connect list send", error);
              });
          });
        }
          
        
      };
      render() {
        if (this.state.loading === true) {
          return <Spinner />;
        }
        const UserList=this.state.data.map(r => {
          return <Option key={r._id.$oid}>{r.name}</Option>;
        })

        const FliteredList = this.state.filtered.map(r => {
          return <Option key={r._id.$oid}>{r.name}</Option>;
        })
        return (
          <Card style={{ margin: 10 }}>
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              onBack={() => this.props.history.goBack()}
              title="Update Partner Organization"
            />
            <Form
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column"
              }}
            >
             
              <Form.Item>
                <Text>Search Organization</Text>
                <Select
                  showSearch={true}
                  labelInValue
                  notFoundContent={fetch ? <Spin size="small" /> : null}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearch}
                  value={{ key: this.state.org_id, label: this.state.organization }}
                  onChange={this.setOrg}
                  placeholder="Search Organization"
                > 
              
                  {this.state.filtered.length===0?(
                    UserList
                  ):(
                    FliteredList
                  )}
                </Select>
              </Form.Item>

              <Form.Item>
            <Text>Invite Response</Text>
            <Select  value={this.state.invite_rsp.toString()} onChange={this.setResponse}>
              <Option key="accept">Accept</Option>
              <Option key="reject">Reject</Option>
              <Option key="ban">Ban</Option>
              <Option key="delete">Delete</Option>
            </Select>
          </Form.Item>
    
              <Form.Item>
                <Button className="login-form-button" htmlType="submit" onClick={this.updateConnection}>
                  Update Connection
                </Button>
              </Form.Item>
            </Form>
          </Card>
        );
      }
}
