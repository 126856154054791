import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  Table
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  ORGANIZATION_SETTINGS_LIST,
  GET_ORDER_SETTINGS,
  ORDER_STATUS_SETTINGS,
  GET_ACTIVE_ORDER_STATUS,
  ORDER_INVENTORY_DEDUCTION_STATUS_SETTINGS,
  ORDER_APPROVAL_STATUS_SETTINGS,
  ORDER_AVAILABLE_STOCK_OR_STOCK_QUANTITY_OPTIONS,
  ORDER_PRODUCT_STOCK_SETTINGS,
  AUTO_UPDATE_STATUS_NO_DISCOUNT_SETTINGS
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
    CHECK_BOOL,
  timeConverter
} from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

export default class OrderSettings extends Component {
  state = {
    org_setting_id: "",
    order_statuses: [],
    attendance_with_image: false,
    active_order_statuses: [],
    seq_no: "",
    name: "",
    order_approval_status:false,
    available_stock_or_stock_quantity_options:[],
    select_available_stock_or_stock_quantity:"",
    enable_order_for_availabl_stock_or_stock_quantity_zero:false,
    auto_update_status_for_no_discount:false,
  };

  componentDidMount() {
    this.getOrganizationSettingsList();
    this.getAvailableStockOrStockQuantityOptions();
  }
  getOrganizationSettingsList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${ORGANIZATION_SETTINGS_LIST}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState(
            {
              org_setting_id: response.data?.data[0]?._id.$oid ?? ""
            },
            () => {
              this.getOrderSettings();
              this.getActiveOrderStatus();
            }
          );
          console.log("response from organization settings list", response);
        })
        .catch(error => {
          console.log("error from organization settings list api", error);
        });
    });
  };

  getOrderSettings = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${GET_ORDER_SETTINGS}${this.state.org_setting_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({
            order_statuses: response.data?.data?.order_statuses??[],
            order_approval_status: response.data?.data?.order_approval_status??false,
            seq_no: response.data?.data?.inventory_deduction_status?.seq_no??"",
            name: response.data?.data?.inventory_deduction_status?.name??"",
            select_available_stock_or_stock_quantity: response.data?.data?.stock_settings?.select_available_stock_or_stock_quantity??"",
            enable_order_for_availabl_stock_or_stock_quantity_zero: response.data?.data?.stock_settings?.enable_order_for_availabl_stock_or_stock_quantity_zero??false,
            auto_update_status_for_no_discount: response.data?.data?.auto_update_status_for_no_discount??false,
          });
          console.log("response from order settings", response);
        })
        .catch(error => {
          console.log("error from order settings api", error);
        });
    });
  };

  getActiveOrderStatus = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${GET_ACTIVE_ORDER_STATUS}${this.state.org_setting_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({
            active_order_statuses: response?.data?.data??[]
          });
          console.log("response from active order statuses", response);
        })
        .catch(error => {
          console.log("error from active order statuses api", error);
        });
    });
  };
  getAvailableStockOrStockQuantityOptions = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${ORDER_AVAILABLE_STOCK_OR_STOCK_QUANTITY_OPTIONS}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({
            available_stock_or_stock_quantity_options: response.data.select_available_stock_or_stock_quantity
          });
          console.log("response from available stock or stock quantiy options", response);
        })
        .catch(error => {
          console.log("error from available stock or stock quantiy options api", error);
        });
    });
  };

  updateOrderStatusSettings = (seq_no, active) => {
    const data = {
      org_setting_id: this.state.org_setting_id,
      order_statuses: [
        {
          seq_no: Number(seq_no),
          active: !active
        }
      ]
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${ORDER_STATUS_SETTINGS}${this.state.org_setting_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from order status Settings", response);
          this.getOrganizationSettingsList();
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from order status Settings", error);
        });
    });
  };

  updateInventoryDeductionValue = () => {
    const data = {
      org_setting_id: this.state.org_setting_id,
      inventory_deduction_status: {
        seq_no: Number(this.state.seq_no),
        name: this.state.name
      }
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${ORDER_INVENTORY_DEDUCTION_STATUS_SETTINGS}${this.state.org_setting_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log(
            "response from inventory deduction status Settings",
            response
          );
          this.getOrganizationSettingsList();
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from inventory deduction status Settings", error);
        });
    });
  };
  updateOrderApprovalStatus = () => {
    const data = {
      org_setting_id: this.state.org_setting_id,
      order_approval_status:this.state.order_approval_status
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${ORDER_APPROVAL_STATUS_SETTINGS}${this.state.org_setting_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log(
            "response from order approval status Settings",
            response
          );
          this.getOrganizationSettingsList();
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from order approval status Settings", error);
        });
    });
  };
  updateAutoUpdateStatusNoPromotion = () => {
    const data = {
      org_setting_id: this.state.org_setting_id,
      auto_update_status_for_no_discount:this.state.auto_update_status_for_no_discount
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${AUTO_UPDATE_STATUS_NO_DISCOUNT_SETTINGS}${this.state.org_setting_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log(
            "response from auto approve order Settings",
            response
          );
          this.getOrganizationSettingsList();
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from auto approve order Settings", error);
        });
    });
  };
  updateProductStockSettings = () => {
    const data = {
      org_setting_id: this.state.org_setting_id,
      select_available_stock_or_stock_quantity: this.state.select_available_stock_or_stock_quantity,
      enable_order_for_availabl_stock_or_stock_quantity_zero:this.state.enable_order_for_availabl_stock_or_stock_quantity_zero
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${ORDER_PRODUCT_STOCK_SETTINGS}${this.state.org_setting_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log(
            "response from product stock Settings",
            response
          );
          this.getOrganizationSettingsList();
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from product stock Settings", error);
        });
    });
  };

  setAvailableStockOrStockQuantity = value => {
    this.setState({ select_available_stock_or_stock_quantity: value}, () => {
      console.log(this.state.select_available_stock_or_stock_quantity);
    });
  };
  setInventory = value => {
    this.setState({ seq_no: value.key, name: value.label }, () => {
      console.log(this.state.name);
    });
  };

  setZerotockSetting = value => {
    this.setState({ enable_order_for_availabl_stock_or_stock_quantity_zero: CHECK_BOOL(value) }, () => {
      console.log(this.state.enable_order_for_availabl_stock_or_stock_quantity_zero);
    });
  };
  setOrderApproval = value => {
    this.setState({ order_approval_status: CHECK_BOOL(value) }, () => {
      console.log(this.state.order_approval_status);
    });
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Order Active Status Update"
        />

        <Table
          dataSource={this.state.order_statuses}
          pagination={false}
          rowKey={record => record.seq_no}
          size="small"
          bordered
        >
          <Column title="Status Name" dataIndex="name" key="name" />
          <Column
            title="Active"
            key="Active"
            render={(text, record) => <span>{record.active.toString()}</span>}
          />
          <Column
            title="Active Field Update Date"
            key="Active Field Update Date"
            render={(text, record) => (
              <span>
                {timeConverter(record.active_field_upadte_date.$date)}
              </span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Button
                  type="link"
                  style={{ color: "green" }}
                  onClick={() => {
                    this.updateOrderStatusSettings(
                      record.seq_no,
                      record.active
                    );
                  }}
                >
                  Change Active Status
                </Button>
              </span>
            )}
          />
        </Table>
        <Card style={{ marginTop: 5 }}>
         
          <Form>
            <Form.Item>
              <Text>Select Inventory Deduction Status</Text>
              <Select
                value={{ key: this.state.seq_no, label: this.state.name }}
                labelInValue
                onChange={this.setInventory}
              >
                {this.state.active_order_statuses.map(r => {
                  return <Option key={r.seq_no}>{r.name}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateInventoryDeductionValue}
                style={{ marginTop: 24, marginBottom: 15 }}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Card style={{ marginTop: 5 }}>
          
          <Form>
            <Form.Item>
              <Text>Non promotional order approval settings</Text>
              <Select
                value={this.state.auto_update_status_for_no_discount.toString()}
                onChange={(value)=>{
                  this.setState({ auto_update_status_for_no_discount: CHECK_BOOL(value) }, () => {
                    console.log(this.state.auto_update_status_for_no_discount);
                  });
                }}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateAutoUpdateStatusNoPromotion}
                style={{ marginTop: 24, marginBottom: 15 }}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Card style={{ marginTop: 5 }}>
         
          <Form>
            <Form.Item>
              <Text>Order Approval Required</Text>
              <Select
                value={this.state.order_approval_status.toString()}
                onChange={this.setOrderApproval}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateOrderApprovalStatus}
                style={{ marginTop: 24, marginBottom: 15 }}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Card style={{ marginTop: 5 }}>
        
          <Form>
            <Form.Item>
              <Text>Select Available Stock or Stock Quantity</Text>
              <Select
                value={this.state.select_available_stock_or_stock_quantity}
                onChange={this.setAvailableStockOrStockQuantity}
              >
                {this.state.available_stock_or_stock_quantity_options.map(r => {
                  return <Option key={r}>{r}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Text>Enable Zero Quantity</Text>
              <Select
                value={this.state.enable_order_for_availabl_stock_or_stock_quantity_zero.toString()}
                onChange={this.setZerotockSetting}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateProductStockSettings}
                style={{ marginTop: 24, marginBottom: 15 }}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Card>
    );
  }
}
