import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Tag,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { debounce } from "lodash";
import {
  CREATE_SUB_ORG_TYPE,
  CREATE_SUB_ORG,
  CREATE_DRIVER,
  CREATE_ENTITY,
  EDIT_SUB_ORG,
  SUB_ORG_PARENT_LIST,
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class EditSubOrg extends Component {
  state = {
    name: "",
    sub_org_types: [],
    sub_orgs: [],
    parent_sub_org: "",
    users: [],
    assigned_users: [],
    places: [],
    assigned_places: [],
    filtered_places: [],
    sub_org_id: "",
    delete_assigned_users: [],
    delete_assigned_places: [],
    add_assigned_places: [],
    add_assigned_users: [],
    parent_sub_org_name: "",
    parent_sub_org_id: "",
    sub_org_type_id: "",
    sub_org_type_name: "",
    sub_org_type_seq_no: 0,
    sub_org_type_seq_no_constant: 0,
    places_rep: [],
    representative_user_key: "",
    representative_user_label: "",
    representative_place_as_inventory_key: "",
    representative_place_as_inventory_label: "",
    parent_sub_orgs: [],
  };

  componentDidMount() {
    this.setState({ sub_org_id: this.props.location.state.sub_org_id });
    this.getSubOrgTypeList();
    this.getSubOrgs();
    this.getUserList();
    this.getPlaceList();
    this.getSubOrgDetails();
    this.getPlaceListRep();
  }

  getSubOrgDetails = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let sub_org_id = this.props.location.state.sub_org_id;
      let url = `${EDIT_SUB_ORG}${sub_org_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro edit suborg", response);
        this.setState(
          {
            name: response.data.data.name,
            assigned_users: response.data.data.assigned_users,
            sub_org_type_id: response.data?.data?.sub_org_type_data?.id ?? "",
            sub_org_type_name:
              response.data?.data?.sub_org_type_data?.name ?? "",
            sub_org_type_seq_no:
              response.data?.data?.sub_org_type_data?.seq_no ?? "",
            sub_org_type_seq_no_constant:
              response.data?.data?.sub_org_type_data?.seq_no ?? "",
            assigned_places: response.data.data.assigned_places,
            parent_sub_org_name:
              response.data?.data?.parent_sub_org_data?.name ?? "",
            parent_sub_org_id:
              response.data?.data?.parent_sub_org_data?.id ?? "",
            representative_place_as_inventory_key:
              response.data?.data?.representative_place_as_inventory?.id ?? "",
            representative_place_as_inventory_label: `${
              response.data?.data?.representative_place_as_inventory?.name ?? ""
            }>${
              response.data?.data?.representative_place_as_inventory?.address ??
              ""
            }>${
              response.data?.data?.representative_place_as_inventory
                ?.entity_type ?? ""
            }>Inventory-${
              response.data?.data?.representative_place_as_inventory
                ?.is_inventory ?? false
            }`,
            representative_user_key:
              response.data?.data?.representative_user?.id ?? "",
            representative_user_label: `${
              response.data?.data?.representative_user?.name ?? ""
            }>${response.data?.data?.representative_user?.mobile ?? ""}>${
              response.data?.data?.representative_user?.designation ?? ""
            }`,
          },
          () => {
            this.getParentSubOrgs();
          }
        );
      });
    });
  };

  getSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_SUB_ORG}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from sub org list", response);
          this.setState({
            sub_orgs: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from sub org list api", error);
        });
    });
  };
  getSubOrgTypeList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_SUB_ORG_TYPE}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from sub org type list", response);
          this.setState({
            sub_org_types: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from sub org type list api", error);
        });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user list", response);
          this.setState({
            users: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from user list api", error);
        });
    });
  };

  getPlaceList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from place list", response);
          this.setState({
            places: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from place list api", error);
        });
    });
  };

  getPlaceListRep = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?is_inventory=true&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from place list", response);
          this.setState({
            places_rep: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from place list api", error);
        });
    });
  };

  getParentSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${SUB_ORG_PARENT_LIST}?sub_org_type_id=${this.state.sub_org_type_id}&limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from sub org list", response);
          this.setState({
            parent_sub_orgs: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from sub org list api", error);
        });
    });
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  setSubOrgType = (value) => {
    this.setState(
      {
        sub_org_type_id: value.key,
        sub_org_type_seq_no: value.label.split(".")[0],
        sub_org_type_name: value.label.split(".")[1],
        parent_sub_org_id: "",
        parent_sub_org_name: "",
      },
      () => {
        this.getParentSubOrgs();
        console.log(this.state.sub_org_type_id);
      }
    );
  };
  setParentSubOrg = (value) => {
    this.setState({ parent_sub_org_id: value }, () => {
      console.log(this.state.parent_sub_org_id);
    });
  };

  searchUser = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from search user", response);
          this.setState({
            users: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from search user api", error);
        });
    });
  }, 500);

  setAssigned = (value) => {
    this.setState(
      { assigned_to_label: value.label, assigned_to: value.key, filtered: [] },
      () => {
        console.log(this.state.assigned_to);
      }
    );
  };

  searchPlace = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from place list", response);
          this.setState({
            places: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from place list api", error);
        });
    });
  }, 500);

  searchPlaceRep = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?is_inventory=true&name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from place list", response);
          this.setState({
            places_rep: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from place list api", error);
        });
    });
  }, 500);

  editSubOrg = () => {
    if (
      this.state.sub_org_type_seq_no !== 1 &&
      this.state.parent_sub_org_id === ""
    ) {
      swal.fire("Info", "Please select a parent sub organization", "info");
    } else if (this.state.name === "") {
      swal.fire("Info", "Please enter name", "info");
    } else {
      const data = {
        name: this.state.name,
        add_assigned_users: this.state.add_assigned_users,
        add_assigned_places: this.state.add_assigned_places,
        delete_assigned_users: this.state.delete_assigned_users,
        delete_assigned_places: this.state.delete_assigned_places,
        sub_org_type: this.state.sub_org_type_id,
        parent_sub_org: this.state.parent_sub_org_id,
        representative_user: {
          key: this.state.representative_user_key,
          label: this.state.representative_user_label,
        },
        representative_place_as_inventory: {
          key: this.state.representative_place_as_inventory_key,
          label: this.state.representative_place_as_inventory_label,
        },
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_SUB_ORG}${this.state.sub_org_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            if (response.data.message === "Data successfully inserted!") {
              this.setState({
                add_assigned_places: [],
                add_assigned_users: [],
              });
              this.getSubOrgDetails();
            }
            console.log("response from edit sub org", response);
          })
          .catch((error) => {
            swal.fire("Error", error.message, "error");
            console.log("error from edit sub org", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          onBack={() => this.props.history.goBack()}
          title="Edit Sub Organization"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          {/* {this.state.parent_sub_org_name !== "" ? (
            <Card>
              <Text>{`Parent Sub Org : ${this.state.parent_sub_org_name}`}</Text>
            </Card>
          ) : null} */}
          <Form.Item>
            <Text>Sub Organization Type</Text>
            <Select
              labelInValue
              value={{
                key: this.state.sub_org_type_id,
                label: this.state.sub_org_type_name,
              }}
              onChange={this.setSubOrgType}
            >
              {this.state.sub_org_types
                .filter(
                  (f) =>
                    f.seq_no === this.state.sub_org_type_seq_no_constant - 1 ||
                    f.seq_no === this.state.sub_org_type_seq_no_constant
                )
                .map((r) => {
                  return (
                    <Option key={r._id.$oid}>{`${r.seq_no}.${r.name}`}</Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Parent Sub Organization</Text>
            <Select
              value={this.state.parent_sub_org_id}
              onChange={this.setParentSubOrg}
              disabled={this.state.parent_sub_orgs.length > 0 ? false : true}
            >
              {this.state.parent_sub_orgs.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Search Reprsentative Users</Text>
            <Button
              type="link"
              onClick={() =>
                this.setState({
                  representative_user_key: "",
                  representative_user_label: "",
                })
              }
            >
              Reset
            </Button>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              value={{
                key: this.state.representative_user_key,
                label: this.state.representative_user_label,
              }}
              onSearch={this.searchUser}
              onChange={(value) => {
                if (
                  this.state.assigned_users.some((u) => u.key === value.key)
                ) {
                  swal.fire(
                    "Info",
                    "User already added in assigned user",
                    "info"
                  );
                } else {
                  this.setState(
                    {
                      representative_user_key: value.key,
                      representative_user_label: value.label,
                    },
                    () => {
                      console.log(this.state.representative_user_key);
                    }
                  );
                }
              }}
              placeholder="Search Users"
            >
              {this.state.users.map((r) => {
                return (
                  <Option
                    key={r._id.$oid}
                  >{`${r.name}>${r.mobile}>${r.designation}`}</Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Search Assigned Users</Text>
            <Select
              labelInValue
              mode="multiple"
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              value={this.state.add_assigned_users}
              onSearch={this.searchUser}
              onChange={(value) => {
                if (
                  value.some(
                    (u) => u.key === this.state.representative_user_key
                  )
                ) {
                  swal.fire(
                    "Info",
                    "User already added as representative",
                    "info"
                  );
                } else {
                  this.setState({ add_assigned_users: value }, () => {
                    console.log(this.state.add_assigned_users);
                  });
                }
              }}
              placeholder="Search Users"
            >
              {this.state.users.map((r) => {
                return (
                  <Option key={r._id.$oid}>{`${r.name}>${r.mobile}`}</Option>
                );
              })}
            </Select>
            {this.state.assigned_users.length > 0 ? (
              <>
                <Text>Select Users You Want to Delete</Text>
                <Card>
                  {this.state.assigned_users.map((user, index) => (
                    <Tag
                      key={index}
                      color={
                        this.state.delete_assigned_users.some(
                          (u) => u.id === user.id
                        )
                          ? "red"
                          : "blue"
                      }
                      onClick={() => {
                        if (
                          this.state.delete_assigned_users.some(
                            (u) => u.id === user.id
                          )
                        ) {
                          this.setState(
                            {
                              delete_assigned_users:
                                this.state.delete_assigned_users.filter(
                                  (u) => u.id !== user.id
                                ),
                            },
                            () => {
                              console.log(
                                "delete user array if",
                                this.state.delete_assigned_users
                              );
                            }
                          );
                        } else {
                          let userObj = {
                            id: user.id,
                          };
                          let tempUser = [];
                          tempUser.push(userObj);
                          this.setState(
                            {
                              delete_assigned_users: [
                                ...this.state.delete_assigned_users,
                                ...tempUser,
                              ],
                            },
                            () => {
                              console.log(
                                "delete user array else",
                                this.state.delete_assigned_users
                              );
                            }
                          );
                        }
                      }}
                    >
                      {user.name}
                    </Tag>
                  ))}
                </Card>
              </>
            ) : null}
          </Form.Item>
          <Form.Item>
            <Text>Search Representative Places</Text>
            <Button
              type="link"
              onClick={() =>
                this.setState({
                  representative_place_as_inventory_key: "",
                  representative_place_as_inventory_label: "",
                })
              }
            >
              Reset
            </Button>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={true}
              value={{
                key: this.state.representative_place_as_inventory_key,
                label: this.state.representative_place_as_inventory_label,
              }}
              onSearch={this.searchPlaceRep}
              onChange={(value) => {
                if (
                  this.state.assigned_places.some((p) => p.key === value.key)
                ) {
                  swal.fire(
                    "Info",
                    "Place already added in assigned places",
                    "info"
                  );
                } else {
                  this.setState(
                    {
                      representative_place_as_inventory_key: value.key,
                      representative_place_as_inventory_label: value.label,
                    },
                    () => {
                      console.log(
                        this.state.representative_place_as_inventory_label
                      );
                    }
                  );
                }
              }}
              placeholder="Search Places"
            >
              {this.state.places_rep.map((r) => {
                return (
                  <Option
                    key={r._id.$oid}
                  >{`${r.name}>${r.address}>${r.entity_type}>Inventory-${r.is_inventory}`}</Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Search Assigned Places</Text>
            <Select
              labelInValue
              mode="multiple"
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              value={this.state.add_assigned_places}
              onSearch={this.searchPlace}
              onChange={(value) => {
                if (
                  value.some(
                    (p) =>
                      p.key === this.state.representative_place_as_inventory_key
                  )
                ) {
                  swal.fire(
                    "Info",
                    "place already added as representative place",
                    "info"
                  );
                } else {
                  this.setState({ add_assigned_places: value }, () => {
                    console.log(this.state.add_assigned_places);
                  });
                }
              }}
              placeholder="Search Places"
            >
              {this.state.places.map((r) => {
                return (
                  <Option
                    key={r._id.$oid}
                  >{`${r.name}>${r.address}>${r.entity_type}>Inventory-${r.is_inventory}`}</Option>
                );
              })}
            </Select>
            {this.state.assigned_places.length > 0 ? (
              <>
                <Text>Select Places You Want to Delete</Text>
                <Card>
                  {this.state.assigned_places.map((place, index) => (
                    <Tag
                      key={index}
                      color={
                        this.state.delete_assigned_places.some(
                          (p) => p.id === place.id
                        )
                          ? "red"
                          : "blue"
                      }
                      onClick={() => {
                        if (
                          this.state.delete_assigned_places.some(
                            (p) => p.id === place.id
                          )
                        ) {
                          this.setState(
                            {
                              delete_assigned_places:
                                this.state.delete_assigned_places.filter(
                                  (p) => p.id !== place.id
                                ),
                            },
                            () => {
                              console.log(
                                "delete place array if",
                                this.state.delete_assigned_places
                              );
                            }
                          );
                        } else {
                          let placeObj = {
                            id: place.id,
                          };
                          let tempPlace = [];
                          tempPlace.push(placeObj);
                          this.setState(
                            {
                              delete_assigned_places: [
                                ...this.state.delete_assigned_places,
                                ...tempPlace,
                              ],
                            },
                            () => {
                              console.log(
                                "delete place array else",
                                this.state.delete_assigned_places
                              );
                            }
                          );
                        }
                      }}
                    >
                      {`Name: ${place.name}, Address: ${place?.address ?? ""}`}
                    </Tag>
                  ))}
                </Card>
              </>
            ) : null}
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editSubOrg}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
