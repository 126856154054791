import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Tooltip } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";

import { TRACKING_PERMISSION } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Column } = Table;

export default class ViewTracking extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false
  };
  componentDidMount() {
    this.getTrackingList();
  }

  getTrackingList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${TRACKING_PERMISSION}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from tracking list", response);
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from tracking list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getTrackingList();
      }
    );
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <Link to="/createTracking">
          <Tooltip placement="top" title="Create Tracking Settings">
          <Button shape="circle" icon="plus" className="tooltip-button" />
          </Tooltip>
        </Link>
        <PageHeader
          title="Tracking Settings"
        />

        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title="Attendance Tracking"
            dataIndex="attendance_tracking_status"
            key="attendance_tracking_status"
          />
          <Column
            title="Task Tracking"
            dataIndex="task_tracking_status"
            key="task_tracking_status"
          />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
           <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Link
                  to={{
                    pathname: "/editTracking",
                    state: { tracking_id: record._id.$oid }
                  }}
                >
                  <button
                    className="borderless-button"
                    style={{ color: "green" }}
                  >
                    Edit
                  </button>
                </Link>
              </span>
            )}
          />
         
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}
