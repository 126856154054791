import React, { Component } from "react";
import {
  Card,
  Button,
  Result
} from "antd";

import Logo from "../../assets/logo.png";

export default class Failed extends Component {
  componentDidMount() {
    // setTimeout(
    //   () => (window.location.href = "https://web.conveyance.app"),
    //   2000
    // );
  }
  render() {
    return (
      <div>
      <div
       style={{
         textAlign: "center",
         paddingBottom: 10,
         paddingTop: 15,
         backgroundColor: "#006479"
       }}
     >
       <img src={Logo} alt="" style={{ width: 300 }} />
     </div>
     <Card style={{ margin: 10 }}>
         <Result
           status="error"
           title="Payment failed ! Please try Again."
           extra={
             <Button
               className="login-form-button"
               htmlType="submit"
               onClick={() =>
                 (window.location.href = `https://web.conveyance.app`)
               }
             >
               Home
             </Button>
           }
         />
       </Card>
   </div>
    );
  }
}
