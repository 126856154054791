import React from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Tag,
  Tooltip,
  Modal,
  Form,
  Typography,
  Select,
  Input,
  Divider,
  Pagination,
  Icon,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { saveUserListParams } from "../../redux/actions/userListParamsAction";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import FileSaver from "file-saver";

import {
  CREATE_DRIVER,
  EDIT_DRIVER,
  CREATE_ROLE,
  USER_ROLE_ADD_OR_CHANGE,
  MOBILE_EMAIL_VERIFY_SUPER_USER,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL,
  CHECK_BOOL,
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Text, Title } = Typography;
const { Option } = Select;

class DriverList extends React.Component {
  state = {
    data: [],
    searchData: [],
    page: 0,
    total: 0,
    limit: 10,
    datarange: "",
    showModal: false,
    from: Date.now(),
    from_view: moment(),
    to: Date.now(),
    to_view: moment(),
    user_name: "",
    user_id: "",
    loading: false,
    sort_by: "created_on",
    sort_type: "dsc",
    name: "",
    email: "",
    mobile: "",
    designation: "",
    org_admin: "",
    blood_grp: "",
    roles: [],
    role_id: "",
    role_name: "",
    showRoleModal: false,
    showVerifyUserModal: false,
    mobile_verified: false,
    email_verified: false,
    mobile_number: "",
    email_address: "",
    user_status: "all",
    start_time: "",
    start_time_view: moment(),
    end_time: "",
    end_time_view: moment(),
  };
  componentDidMount() {
    this.props.getUserData();
    console.log("user permission from redux local", this.props.userProfileData);
    console.log("redux", this.props.userListParams);
    if (Object.keys(this.props.userListParams).length !== 0) {
      console.log("inside if");
      this.setState(
        {
          name: this.props?.userListParams?.name ?? "",
          email: this.props?.userListParams?.email ?? "",
          mobile: this.props?.userListParams?.mobile ?? "",
          designation: this.props?.userListParams?.designation ?? "",
          org_admin: this.props?.userListParams?.org_admin ?? "",
          blood_grp: this.props?.userListParams?.blood_grp ?? "",
          limit: this.props?.userListParams?.limit ?? 10,
          page: this.props?.userListParams?.page ?? 0,
          sort_by: this.props?.userListParams?.sort_by ?? "created_by",
          user_status: this.props?.userListParams?.user_status ?? "all",
          sort_type: this.props?.userListParams?.sort_type ?? "dsc",
          start_time: this.props?.userListParams?.start_time ??"",
          start_time_view:
            this.props?.userListParams?.start_time_view ?? moment(),
          end_time: this.props?.userListParams?.end_time ?? "",
          end_time_view: this.props?.userListParams?.end_time_view ?? moment(),
        },
        () => {
          this.getDriverList();
        }
      );
    } else {
      this.getDriverList();
    }
    this.getRoleList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getRoleList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ROLE}?limit=100&page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from role list", response);
          this.setState({
            roles: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from role list api", error);
        });
    });
  };

  setFrom = (value, dateString) => {
    this.setState(
      {
        from: moment(value).valueOf(),
        from_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.from);
        console.log("Selected value: ", this.state.from_view);
      }
    );
  };
  setTo = (value, dateString) => {
    this.setState(
      {
        to: moment(value).valueOf(),
        to_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.to);
        console.log("Selected value: ", this.state.to_view);
      }
    );
  };

  getDriverList = debounce(() => {
    let reduxdata = {
      name: this.state.name,
      user_status: this.state.user_status,
      email: this.state.email,
      mobile: this.state.mobile,
      designation: this.state.designation,
      org_admin: this.state.org_admin,
      blood_grp: this.state.blood_grp,
      sort_by: this.state.sort_by,
      sort_type: this.state.sort_type,
      limit: this.state.limit,
      page: this.state.page,
      start_time: this.state.start_time,
      start_time_view: this.state.start_time_view,
      end_time: this.state.end_time,
      end_time_view: this.state.end_time_view,
    };
    this.props.saveUserListParams(reduxdata);
    console.log("reduxdata going in", reduxdata);
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?end_time=${this.state.end_time}&start_time=${this.state.start_time}&user_status=${this.state.user_status}&page=${this.state.page}&limit=${this.state.limit}&sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&name=${this.state.name}&email=${this.state.email}&mobile=${this.state.mobile}&designation=${this.state.designation}&org_admin=${this.state.org_admin}&blood_grp=${this.state.blood_grp}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
            datarange: response.data.show_data,
          });
        })
        .catch((error) => {
          console.log("error from user list api", error);
        });
    });
  }, 500);

  downloadUserReport = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?fmt=xls&user_status=${this.state.user_status}&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from user active deactive excel report", blob);
          FileSaver.saveAs(
            blob,
            `user_active_inactive_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from user active deactive excel report", error);
        });
    });
  };

  refresh = () => {
    this.setState(
      {
        email: "",
        user_status: "all",
        name: "",
        mobile: "",
        designation: "",
        org_admin: "",
        blood_grp: "",
        sort_by: "created_by",
        sort_type: "dsc",
        page: 0,
        limit: 10,
        start_time: "",
        start_time_view: moment(),
        end_time: "",
        end_time_view: moment(),
      },
      () => {
        this.getDriverList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getDriverList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getDriverList();
    });
  };
  setUserStatusChange = (value) => {
    this.setState({ user_status: value }, () => {
      console.log(this.state.user_status);
      this.getDriverList();
    });
  };

  deleteDriver = (id) => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${EDIT_DRIVER}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from del", error);
        });
    });
  };

  updateUserRole = () => {
    const data = {
      role_id: this.state.role_id,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${USER_ROLE_ADD_OR_CHANGE}${this.state.user_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from update user role", response);
          this.getDriverList();
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from update user role", error);
        });
    });
  };

  updateUserVerify = () => {
    const data = {
      mobile_verified: this.state.mobile_verified,
      email_verified: this.state.email_verified,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${MOBILE_EMAIL_VERIFY_SUPER_USER}${this.state.user_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            showVerifyUserModal: false,
            user_id: "",
            mobile_verified: false,
            email_verified: false,
          });
          this.getDriverList();
          swal.fire("Info", response.data.message, "info");
          console.log("response from create driver", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from create driver", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.user.user_create ? (
          <Link to="/createUser">
            <Tooltip placement="top" title="Create User">
            <Button
                shape="circle"
                icon="plus"
                className="tooltip-button"
              />
            </Tooltip>
          </Link>
        ) : null}

         <PageHeader
          title="Users"
        />
        <Card style={{ margin: 3 }}>
          <Title level={4}>
            Select date range to find active or inactive users
          </Title>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).format("x"),
                        start_time_view: value,
                      },
                      () => this.getDriverList()
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        end_time: moment(value).add(59, "seconds").format("x"),
                        end_time_view: value,
                      },
                      () => this.getDriverList()
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </Card>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
          <div style={{ margin: 10 }}>
            <Text>{`User Status : `}</Text>
            <Select
              style={{ minWidth: 100 }}
              value={this.state.user_status}
              onChange={this.setUserStatusChange}
            >
              <Option key={"all"}>All</Option>
              <Option key={"active"}>Active</Option>
              <Option key={"inactive"}>Inactive</Option>
            </Select>
          </div>
          <Button
            style={{
              backgroundColor: "#1A3337",
              color: "white",
              margin: 10,
            }}
            onClick={() => this.downloadUserReport()}
          >
            Download Report
          </Button>
        </div>
        <Table
          dataSource={
            this.state.searchData.length > 0
              ? this.state.searchData
              : this.state.data
          }
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Name</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "asc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "dsc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.name}
                  onChange={(evt) => {
                    this.setState({ name: evt.target.value, page: 0 }, () => {
                      this.getDriverList();
                    });
                  }}
                />
              </>
            }
            dataIndex="name"
            key="name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Designation</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "designation", sort_type: "asc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "designation", sort_type: "dsc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.designation}
                  onChange={(evt) => {
                    this.setState(
                      { designation: evt.target.value, page: 0 },
                      () => {
                        this.getDriverList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="designation"
            key="designation"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Mobile</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "mobile", sort_type: "asc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "mobile", sort_type: "dsc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.mobile}
                  onChange={(evt) => {
                    this.setState({ mobile: evt.target.value, page: 0 }, () => {
                      this.getDriverList();
                    });
                  }}
                />
              </>
            }
            dataIndex="mobile"
            key="mobile"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>E-mail</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "email", sort_type: "asc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "email", sort_type: "dsc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.email}
                  onChange={(evt) => {
                    this.setState({ email: evt.target.value, page: 0 }, () => {
                      this.getDriverList();
                    });
                  }}
                />
              </>
            }
            dataIndex="email"
            key="email"
          />
          {GET_USER_LOCAL().org_admin || GET_USER_LOCAL().is_staff ? (
            <Column
              title={
                <>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Text ellipsis={true}>PIN</Text>
                  <Input disabled />
                  </div>
                </>
              }
              key="pin"
              render={(text, record) => <span>{record.pin}</span>}
            />
          ) : null}
          {this.props.userProfileData.roles_and_permission
            .view_users_against_roles ? (
            <Column
              title={
                <>
                 <div style={{display: "flex", flexDirection: "column"}}>
                  <Text ellipsis={true}>Role</Text>
                  <Input disabled />
                  </div>
                </>
              }
              key="Role"
              render={(text, record) => <span>{record.role_name}</span>}
            />
          ) : null}
          <Column
            title={
              <>
                 <div style={{display: "flex", flexDirection: "column"}}>
                  <Text ellipsis={true}>Active</Text>
                  <Input disabled />
                  </div>
              </>
            }
            key="Active"
            render={(text, record) => (
              <span>{record?.is_active?.toString() ?? false}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Admin</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "org_admin", sort_type: "asc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "org_admin", sort_type: "dsc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.org_admin}
                  onChange={(evt) => {
                    this.setState(
                      { org_admin: evt.target.value, page: 0 },
                      () => {
                        this.getDriverList();
                      }
                    );
                  }}
                />
              </>
            }
            key="org_admin"
            render={(record) => <>{record?.org_admin?.toString()}</>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Blood</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "email", sort_type: "asc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "blood_grp", sort_type: "dsc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.blood_grp}
                  onChange={(evt) => {
                    this.setState(
                      { blood_grp: evt.target.value, page: 0 },
                      () => {
                        this.getDriverList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="blood_grp"
            key="blood_grp"
          />
          <Column
            title={
              <>
                 <div style={{display: "flex", flexDirection: "column"}}>
                  <Text ellipsis={true}>Sub Org Name</Text>
                  <Input disabled />
                  </div>
              </>
            }
            key="Sub Org Name"
            render={(text, record) => (
              <span>{record?.sub_org?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                 <div style={{display: "flex", flexDirection: "column"}}>
                  <Text ellipsis={true}>Sub Org Type</Text>
                  <Input disabled />
                  </div>
              </>
            }
            key="Sub Org Type"
            render={(text, record) => (
              <span>{record?.sub_org?.sub_org_type_data?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Text ellipsis={true}>Organizations</Text>
                  <Input disabled />
                  </div>
              </>
            }
            dataIndex="organizations"
            key="organizations"
            render={(organizations) => (
              <span>
                {organizations.map((org) => (
                  <Tag
                    color="blue"
                    key={Math.floor(Math.random() * Date.now() * 100)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2,
                    }}
                  >
                    {org.name}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Organization Stastus</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "organization_status", sort_type: "asc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "organization_status", sort_type: "dsc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled/>
              </>
            }
            dataIndex="organization_status"
            key="organization_status"
          />

          {/* <Column
            title="Subscribed On"
            key="Subscribed On"
            render={(text, record) => (
              <span>{timeConverter(record.subscription_date.$date)}</span>
            )}
          /> */}
          {/* <Column
            title="Expires On"
            key="Expires On"
            render={(text, record) => (
              <span>{record.hasOwnProperty("exp_date") ? timeConverter(record.exp_date.$date) : <div>N/A</div>}</span>
              
            )}
          /> */}
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Created On</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_on", sort_type: "asc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_on", sort_type: "dsc" },
                          () => {
                            this.getDriverList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled/>
              </>
            }
            key="created_on"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {GET_USER_LOCAL().is_staff || GET_USER_LOCAL().is_super ? (
                  <Button
                    type="link"
                    style={{ color: "#82954B" }}
                    onClick={() =>
                      this.setState({
                        showVerifyUserModal: true,
                        user_id: record._id.$oid,
                        mobile_verified: record.mobile_verified,
                        email_verified: record.email_verified,
                        mobile_number: record.mobile,
                        email_address: record.email,
                      })
                    }
                  >
                    Verify User
                  </Button>
                ) : null}
                {(GET_USER_LOCAL().org_admin ||
                  this.props.userProfileData.user.assigned_role_to_users) &&
                record.organization_status === "active" ? (
                  <Button
                    type="link"
                    style={{ color: "#82954B" }}
                    onClick={() =>
                      this.setState({
                        showRoleModal: true,
                        user_name: record.name,
                        user_id: record._id.$oid,
                        role_id: record.role_id,
                      })
                    }
                  >
                    Assign/Change Role
                  </Button>
                ) : null}
                {(GET_USER_LOCAL().org_admin ||
                  GET_USER_LOCAL()._id.$oid === record._id.$oid ||
                  this.props.userProfileData.user.view_user_route_history) &&
                record.organization_status === "active" ? (
                  <Button
                    type="link"
                    style={{ color: "#82954B" }}
                    onClick={() =>
                      this.setState({
                        showModal: true,
                        user_name: record.name,
                        user_id: record._id.$oid,
                      })
                    }
                  >
                    Route History
                  </Button>
                ) : null}
                {GET_USER_LOCAL().org_admin &&
                record.organization_status === "active" ? (
                  <Link
                    to={{
                      pathname: "/createSalesTarget",
                      state: {
                        assigned_user_name: record.name,
                        assigned_user: record._id.$oid,
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "#82954B" }}>
                      Assign Sales Target
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.user.user_detail ? (
                  <Link
                    to={{
                      pathname: "/viewUser",
                      state: { driver_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View
                    </Button>
                  </Link>
                ) : null}

                {(GET_USER_LOCAL().is_staff ||
                  GET_USER_LOCAL().org_admin ||
                  GET_USER_LOCAL()._id.$oid === record._id.$oid ||
                  this.props.userProfileData.user.user_update) &&
                record.organization_status === "active" ? (
                  <Link
                    to={{
                      pathname: "/editUser",
                      state: { driver_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}

                <div>
                  {(GET_USER_LOCAL().is_staff === true ||
                    GET_USER_LOCAL().org_admin ||
                    this.props.userProfileData.user.user_delete) &&
                  record.organization_status === "active" ? (
                    <div>
                      <Button
                        type="link"
                        style={{ color: "red" }}
                        onClick={() => {
                          if (window.confirm("Delete the item?")) {
                            this.deleteDriver(record._id.$oid);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  ) : null}
                </div>
              </span>
            )}
          />
        </Table>
        {/* <div style={{ marginRight: 10, marginTop: 15 }}>
          <Text>{`Displaying ${this.state.datarange} of ${this.state.total}`}</Text>
        </div> */}
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
        <Modal
          title="Select Date Range"
          visible={this.state.showModal}
          // onOk={() => {
          //   this.setState({ showModal: false });
          // }}
          onCancel={() => {
            this.setState({ showModal: false, user_name: "", user_id: "" });
          }}
          // width={window.innerWidth - 200}
          closable={true}
          destroyOnClose={true}
          footer={null}
        >
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>From</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.from_view}
                  onChange={this.setFrom}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>To</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.to_view}
                  onChange={this.setTo}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <Link
              to={{
                pathname: "/userRoute",
                state: {
                  search_user: this.state.user_id,
                  start_time: this.state.from,
                  end_time: this.state.to,
                  user_name: this.state.user_name,
                },
              }}
            >
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.createOrder}
              >
                View Route
              </Button>
            </Link>
          </Form.Item>
        </Modal>
        <Modal
          title="Asign or Change Role"
          visible={this.state.showRoleModal}
          // onOk={() => {
          //   this.setState({ showModal: false });
          // }}
          onCancel={() => {
            this.setState({
              showRoleModal: false,
              user_name: "",
              user_id: "",
              role_id: "",
            });
          }}
          // width={window.innerWidth - 200}
          closable={true}
          destroyOnClose={true}
          footer={null}
        >
          <Form>
            <Form.Item>
              <Text>Select Role</Text>
              <Select
                value={this.state.role_id}
                onChange={(value) => {
                  this.setState({ role_id: value }, () => {
                    console.log(this.state.role_id);
                  });
                }}
                placeholder="Select Role"
              >
                {this.state.roles.map((r) => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateUserRole}
              >
                Update Role
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Verify User"
          visible={this.state.showVerifyUserModal}
          onCancel={() => {
            this.setState({
              showVerifyUserModal: false,
              user_id: "",
              mobile_verified: false,
              email_verified: false,
              mobile_number: "",
              email_address: "",
            });
          }}
          closable={true}
          destroyOnClose={true}
          footer={null}
        >
          <Form>
            {this.state.mobile_number !== "" ? (
              <Form.Item>
                <Text>Mobile Verified</Text>
                <Select
                  value={this.state.mobile_verified.toString()}
                  onChange={(value) => {
                    this.setState(
                      { mobile_verified: CHECK_BOOL(value) },
                      () => {
                        console.log(this.state.mobile_verified);
                      }
                    );
                  }}
                >
                  <Option key={true}>True</Option>
                  <Option key={false}>False</Option>
                </Select>
              </Form.Item>
            ) : null}
            {this.state.email_address !== "" ? (
              <Form.Item>
                <Text>Email Verified</Text>
                <Select
                  value={this.state.email_verified.toString()}
                  onChange={(value) => {
                    this.setState({ email_verified: CHECK_BOOL(value) }, () => {
                      console.log(this.state.email_verified);
                    });
                  }}
                >
                  <Option key={true}>True</Option>
                  <Option key={false}>False</Option>
                </Select>
              </Form.Item>
            ) : null}

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateUserVerify}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userListParams: state.userListParams.userListParamsData,
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { saveUserListParams, getUserData })(
  DriverList
);
