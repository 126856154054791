import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Typography,
  Select,
  Tag,
  Pagination,
  Tooltip,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import FileSaver from "file-saver";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  CREATE_LEAVE,
  INDIVIDUAL_LEAVE_REPORT,
  ORGANIZATION_LEAVE_REPORT,
  CREATE_DRIVER,
  CURRENT_USER_SUB_ORG_LIST,
  GET_FIRST_CHILD_SUB_ORG_LIST,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL,
} from "../../utilities/utilities";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Text, Title } = Typography;
const { Option } = Select;

class LeaveList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    from_single: moment().startOf("day").format("x") / 1000,
    from_single_view: moment().startOf("day"),
    from: moment().startOf("day").format("x"),
    from_view: moment().startOf("day"),
    to_single: Date.now() / 1000,
    to_single_view: moment(),
    to: Date.now(),
    to_view: moment(),
    userData: [],
    user_id: "",
    org_id: "",
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    first_child_sub_orgs: [],
    first_child_sub_org_id: "",
    sub_org_id_leave_search: "",
    loading_org_report: false,
  };
  componentDidMount() {
    this.getCurrentUserSubOrgs();
    this.props.getUserData();
    this.setState({
      org_id: GET_USER_LOCAL().organization.$oid,
    });
    this.getUserList();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org_id_leave_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getLeaveList();
              if (this.state.current_user_sub_org_id !== "") {
                GET_ACCESS_TOKEN().then((token) => {
                  const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

                  axios({
                    method: "get",
                    url: url,
                  })
                    .then((response) => {
                      console.log(
                        "response from first child sub org list",
                        response
                      );

                      this.setState({
                        first_child_sub_orgs: response.data.data,
                      });
                    })
                    .catch((error) => {
                      console.log(
                        "error from first child sub org list api",
                        error
                      );
                    });
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  setAssignedUser = (value) => {
    this.setState({ user_id: value, filtered: [] }, () => {
      console.log(this.state.user_id);
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_DRIVER}?limit=${limit}&name=${value}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from user list", response);
        })
        .catch((error) => {
          console.log("error from user list api", error);
        });
    });
  }, 500);

  setToSingle = (value, dateString) => {
    this.setState(
      { to_single: moment(value).format("x") / 1000, to_single_view: value },
      () => {
        console.log("Selected Time: ", this.state.to_single);
      }
    );
  };
  setTo = (value, dateString) => {
    this.setState({ to: moment(value).format("x"), to_view: value }, () => {
      console.log("Selected Time: ", this.state.to);
    });
  };
  setFromSingle = (value, dateString) => {
    this.setState(
      {
        from_single: moment(value).format("x") / 1000,
        from_single_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.from_single);
      }
    );
  };
  setFrom = (value, dateString) => {
    this.setState(
      {
        from: moment(value).format("x"),
        from_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.from);
      }
    );
  };

  getLeaveList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_LEAVE}?sub_org=${this.state.sub_org_id_leave_search}&page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from leave list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from rate list api", error.message);
        });
    });
  };

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_leave_search: value },
      () => {
        this.getLeaveList();
        GET_ACCESS_TOKEN().then((token) => {
          const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

          axios({
            method: "get",
            url: url,
          })
            .then((response) => {
              console.log("response from first child sub org list", response);

              this.setState({
                first_child_sub_orgs: response.data.data,
              });
            })
            .catch((error) => {
              console.log("error from first child sub org list api", error);
            });
        });
      }
    );
  };
  setFirstChildSubOrg = (value) => {
    this.setState(
      { first_child_sub_org_id: value, sub_org_id_leave_search: value },
      () => {
        this.getLeaveList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getLeaveList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getLeaveList();
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from user list", response);
        })
        .catch((error) => {
          console.log("error from user list api", error);
        });
    });
  };

  downloadIndividualReport = () => {
    if (
      Number(this.state.from_single) > Date.now() / 1000 + 86400 ||
      Number(this.state.to_single) > Date.now() / 1000 + 86400
    ) {
      swal.fire("Info", "Future Date Not Allowed", "info");
    } else {
      GET_ACCESS_TOKEN().then((token) => {
        const url = `${INDIVIDUAL_LEAVE_REPORT}?from=${Number(
          this.state.from_single
        )}&to=${Number(this.state.to_single)}&user_id=${
          this.state.user_id
        }&access_token=${token}`;
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            console.log("response from leave report single", blob);

            FileSaver.saveAs(
              blob,
              `individual_leave_report_${moment().format(
                "DD-MMMM-YYYY_hh:mm A"
              )}.xlsx`
            );
          })
          .catch((error) => {
            console.log("error from leave report single", error);
          });
      });
    }
  };
  downloadOrganizationReport = () => {
    if (
      Number(this.state.from_single) > Date.now() / 1000 + 86400 ||
      Number(this.state.to_single) > Date.now() / 1000 + 86400
    ) {
      swal.fire("Info", "Future Date Not Allowed", "info");
    } else {
      this.setState({ loading_org_report: true });
      GET_ACCESS_TOKEN().then((token) => {
        const url = `${ORGANIZATION_LEAVE_REPORT}?from=${Number(
          this.state.from
        )}&to=${Number(this.state.to)}&org_id=${
          this.state.org_id
        }&access_token=${token}`;
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            console.log("response from leave report organization", blob);

            FileSaver.saveAs(
              blob,
              `organization_leave_report_${moment().format(
                "DD-MMMM-YYYY_hh:mm A"
              )}.xlsx`
            );
            this.setState({ loading_org_report: false });
          })
          .catch((error) => {
            this.setState({ loading_org_report: false });
            console.log("error from leave report organization", error);
          });
      });
    }
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.attendance.leave_application
          .leave_application_create ? (
          <Link to="/createLeave">
            <Tooltip placement="top" title="Create Leave">
              <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}
         <PageHeader
          title="Leave Applications"
        />
        {GET_USER_LOCAL().org_admin ? (
          <Card style={{ marginTop: 5 }}>
            <Text style={{ fontWeight: "bolder", fontSize: 20 }}>
              Individual Report Options{" "}
            </Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.from_single_view}
                    onChange={this.setFromSingle}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <Text style={{ paddingTop: 20 }}>---</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.to_single_view}
                    onChange={this.setToSingle}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 10,
                  width: 250,
                }}
              >
                <Text>Search User</Text>
                <Select
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearch}
                  style={{ width: "100%" }}
                  value={this.state.user_id}
                  onChange={this.setAssignedUser}
                  placeholder="Select User"
                >
                  {this.state.userData.map((r) => {
                    return (
                      <Option
                        key={r._id.$oid}
                      >{`${r.name}>${r.mobile}`}</Option>
                    );
                  })}
                </Select>
              </div>
              <Button
                onClick={this.downloadIndividualReport}
                style={{
                  minWidth: 200,
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 20,
                  marginLeft: 10,
                }}
              >
                Download Individual Report
              </Button>
            </div>
          </Card>
        ) : null}
        {GET_USER_LOCAL().org_admin ||
        this.props.userProfileData.report.attendance_report
          .leave_organizational_report ? (
          <Card style={{ marginTop: 5 }}>
            <Text style={{ fontWeight: "bolder", fontSize: 20 }}>
              Organization Report Options{" "}
            </Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.from_view}
                    onChange={this.setFrom}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <Text style={{ paddingTop: 20 }}>---</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.to_view}
                    onChange={this.setTo}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <Button
                loading={this.state.loading_org_report}
                disabled={this.state.loading_org_report}
                onClick={this.downloadOrganizationReport}
                style={{
                  minWidth: 200,
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 10,
                  marginLeft: 10,
                }}
              >
                Download Organization Report
              </Button>
            </div>
          </Card>
        ) : null}
        {this.props.userProfileData.data_level.access_suborg_data ||
        this.props.userProfileData.data_level.access_all_data ? (
          <Card>
            <Title level={4}>Filter Leaves With Sub Organization</Title>

            <div
              style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
            >
              <div style={{ margin: 10 }}>
                <Text> User Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.current_user_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.current_user_sub_org_id}
                  onChange={this.setCurrentUserSubOrg}
                >
                  {this.state.current_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
              <div style={{ margin: 10 }}>
                <Text> Child Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.first_child_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.first_child_sub_org_id}
                  onChange={this.setFirstChildSubOrg}
                >
                  {this.state.first_child_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
            </div>
          </Card>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title="Leave Type"
            dataIndex="leave_type"
            render={(text, record) => (
              <span>{record.leave_type?.name ?? ""}</span>
            )}
          />
          <Column
            title="Leave Start Date"
            dataIndex="leave_start"
            render={(text, record) => <span>{record.leave_start ?? ""}</span>}
          />
          <Column
            title="Leave End Date"
            dataIndex="leave_end"
            render={(text, record) => <span>{record.leave_end ?? ""}</span>}
          />
          <Column
            title="Joining Date"
            dataIndex="joining"
            render={(text, record) => <span>{record.joining ?? ""}</span>}
          />
          <Column title="Remarks" dataIndex="remarks" key="remarks" />
          <Column
            title="Created By"
            dataIndex="created_by_name"
            key="created_by_name"
          />
          <Column
            title="Created For"
            dataIndex="created_for"
            render={(text, record) => (
              <span>{record.created_for?.name ?? ""}</span>
            )}
          />
          <Column
            title="Sub Org Name"
            dataIndex="sub_org"
            render={(text, record) => <span>{record.sub_org?.name ?? ""}</span>}
          />
          <Column
            title="Sub Org Type"
            dataIndex="sub_org_type_data"
            render={(text, record) => (
              <span>{record.sub_org?.sub_org_type_data?.name ?? ""}</span>
            )}
          />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Recommended"
            key="recommended"
            render={(text, record) => (
              <Tag
                color={
                  record.recommended === "pending"
                    ? "blue"
                    : record.recommended === "approved"
                    ? "green"
                    : "red"
                }
              >
                {record.recommended}
              </Tag>
            )}
          />
          <Column
            title="Recommended By"
            dataIndex="recommender_name"
            key="recommender_name"
          />
          <Column
            title="Authorized"
            key="authorized"
            render={(text, record) => (
              <Tag
                color={
                  record.authorized === "pending"
                    ? "blue"
                    : record.authorized === "approved"
                    ? "green"
                    : "red"
                }
              >
                {record.authorized}
              </Tag>
            )}
          />
          <Column
            title="Authorized By"
            dataIndex="authorizer_name"
            key="authorizer_name"
          />
          <Column
            title="Approved"
            key="approved"
            render={(text, record) => (
              <Tag
                color={
                  record.approved === "pending"
                    ? "blue"
                    : record.approved === "approved"
                    ? "green"
                    : "red"
                }
              >
                {record.approved}
              </Tag>
            )}
          />
          <Column
            title="Approved By"
            dataIndex="approver_name"
            key="approver_name"
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {GET_USER_LOCAL().org_admin &&
                record.leave_request_attachment ? (
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() =>
                      window.open(`${record.leave_request_attachment}`)
                    }
                  >
                    Download Document
                  </Button>
                ) : null}

                {this.props.userProfileData.attendance.leave_application
                  .leave_application_detail ? (
                  <Link
                    to={{
                      pathname: "/viewLeave",
                      state: { leave_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View
                    </Button>
                  </Link>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(LeaveList);
