import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader, Select } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import FileSaver from "file-saver";
import {
  ORGANIZATION_PRODUCT_BULK_UPLOAD_SAMPLE,
  INVENTORY_PRODUCT_BULK_UPLOAD_SAMPLE,
  PARENT_PRODUCT_BULK_UPLOAD,
  INVENTORY_PRODUCT_BULK_UPLOAD,
  CREATE_PRODUCT_CATEGORY,
  WEB_FILE_UPLOAD,
  CREATE_INVENTORY
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class ProductBulkUpload extends Component {
  state = {
    productOrg: null,
    urlProductOrg: null,
    productInventory: null,
    urlProductInventory: null,
    product_category_id: "",
    product_categories: [],
    inventory_id: "",
    inventories: []
  };

  componentDidMount() {
    this.getProductCategory();
    this.getInventory();
  }

  getProductCategory = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_PRODUCT_CATEGORY}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({
            product_categories: response.data.data,
            product_category_id: response?.data?.data[0]?._id?.$oid ?? ""
          });
          console.log("data from product category list api", response);
        })
        .catch(error => {
          console.log("error from product category list api", error);
        });
    });
  };
  getInventory = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_INVENTORY}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({
            inventories: response.data.data,
            inventory_id: response?.data?.data[0]?._id?.$oid ?? ""
          });
          console.log("data from product inventory list api", response);
        })
        .catch(error => {
          console.log("error from product inventory list api", error);
        });
    });
  };

  setProductCategory = value => {
    this.setState({ product_category_id: value }, () => {
      console.log(this.state.product_category_id);
    });
  };
  setInventory = value => {
    this.setState({ inventory_id: value }, () => {
      console.log(this.state.inventory_id);
    });
  };

  downloadInventoryProductSample = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${INVENTORY_PRODUCT_BULK_UPLOAD_SAMPLE}?limit=1000&is_parent_product=true&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(res => res.blob())
        .then(blob => {
          console.log(
            "response from inventory product sample file donwload api fmt",
            blob
          );

          FileSaver.saveAs(blob, `upload_product_to_inventory.csv`);
        })
        .catch(error => {
          console.log("error from product sample file donwload api api", error);
        });
    });
  };

  updateOrgProduct = () => {
    let data = {
      parent_product_file_path: this.state.urlProductOrg,
      category_id: this.state.product_category_id
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${PARENT_PRODUCT_BULK_UPLOAD}?access_token=${token}`;

      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from org product bulk upload", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from org product bulk upload", error);
        });
    });
  };
  updateInventoryProduct = () => {
    let data = {
      inventory_product_file_path: this.state.urlProductInventory,
      inventory_id: this.state.inventory_id
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${INVENTORY_PRODUCT_BULK_UPLOAD}?access_token=${token}`;

      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from inventory product bulk upload", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from inventory product bulk upload", error);
        });
    });
  };
  uploadFileProductOrg = () => {
    GET_ACCESS_TOKEN().then(token => {
      const file = new FormData();

      file.append("file", this.state.productOrg);

      console.log("in upload", file);

      axios({
        method: "put",
        url: `${WEB_FILE_UPLOAD}bulk_parent_products?access_token=${token}`,
        data: file,
        headers: {
          "Content-Type": "multipart/form-data" 
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "File successfully uploaded!") {
            this.setState({
              urlProductOrg: `${process.env.REACT_APP_BASE_PATH}${response.data.data.upload_path}`,productOrg:null
            });
            document.getElementById("orgFile").value = "";
          }
          console.log("response from file upload api", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };
  uploadFileProductInventory = () => {
    GET_ACCESS_TOKEN().then(token => {
      const file = new FormData();

      file.append("file", this.state.productInventory);

      console.log("in upload", file);

      axios({
        method: "put",
        url: `${WEB_FILE_UPLOAD}bulk_inventory_products?access_token=${token}`,
        data: file,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "File successfully uploaded!") {
            this.setState({
              urlProductInventory: process.env.REACT_APP_BASE_PATH+response.data.data.upload_path,productInventory:null
            },()=>{
              console.log("inventory product url", this.state.urlProductInventory);
            });
            document.getElementById("invFile").value = "";
          }
          console.log("response from file upload api", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <>
        <Card style={{ margin: 10 }}>
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)"
            }}
            title="Organization Product Bulk Upload"
          />
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column"
            }}
          >
            <Form.Item>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text>
                  If you want to upload products to your organization then
                  download this file{" "}
                  <Button
                    type="link"
                    onClick={() =>
                      window.open(`${ORGANIZATION_PRODUCT_BULK_UPLOAD_SAMPLE}`)
                    }
                  >
                    Download Sample
                  </Button>
                </Text>
                <Text>
                  Populate the downloaded csv file with details and upload here{" "}
                  <Text style={{ color: "red" }}>
                    (If you have different categories then create separate
                    product upload csv file for each category)
                  </Text>
                </Text>
              </div>

              <input
                type="file"
                id="orgFile"
                onChange={e => {
                  console.log("excel file inside", this.state.users);
                  this.setState(
                    {
                      // urlProductOrg: URL.createObjectURL(e.target.files[0]),
                      productOrg: e.target.files[0]
                    },
                    () => {
                      this.uploadFileProductOrg();
                    }
                  );
                }}
              />
            </Form.Item>

            <Form.Item>
              <div style={{ width: 200 }}>
                <Text>Select Product Category</Text>
                <Select
                  value={this.state.product_category_id}
                  onChange={this.setProductCategory}
                >
                  {this.state.product_categories.map(r => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </div>
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateOrgProduct}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Card style={{ margin: 10 }}>
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)"
            }}
            title="Inventory Product Bulk Upload"
          />
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column"
            }}
          >
            <Form.Item>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text>
                  If you want to upload products to any of your inventory then
                  download this file{" "}
                  <Button
                    type="link"
                    onClick={this.downloadInventoryProductSample}
                  >
                    Download Sample
                  </Button>
                </Text>
                <Text>
                  Populate the downloaded csv file with details and upload here{" "}
                  <Text style={{ color: "red" }}>
                    (If you have different inventories then create separate
                    product upload csv file for each inventory)
                  </Text>
                </Text>
              </div>

              <input
                type="file"
                id="invFile"
                onChange={e => {
                  console.log("excel file inside", this.state.users);
                  this.setState(
                    {
                      // urlProductInventory: URL.createObjectURL(
                      //   e.target.files[0]
                      // ),
                      productInventory: e.target.files[0]
                    },
                    () => {
                      this.uploadFileProductInventory();
                    }
                  );
                }}
              />
            </Form.Item>
            <Form.Item>
              <div style={{ width: 200 }}>
                <Text>Select Inventory</Text>
                <Select
                  value={this.state.inventory_id}
                  onChange={this.setInventory}
                >
                  {this.state.inventories.map(r => {
                    return <Option key={r._id.$oid}>{r.entity_name}</Option>;
                  })}
                </Select>
              </div>
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateInventoryProduct}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </>
    );
  }
}
