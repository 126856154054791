import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import swal from "sweetalert2";
import axios from "axios";

import { RESET_LINK } from "../api/constants";

import { Spinner } from "./loader";


export default class EmailVerifyPassword extends Component {
    state={
        loading:false,
        toNewPassEmail:false,
        toLogin:false,
        email:"",
        access_id:""
    }

    componentDidMount(){
        console.log("reset pass id", this.props?.match?.params?.reset_password_id??"");
        console.log("reset pass id", this.props?.match?.params?.email??"");
        this.setState({ loading: true,email:this.props?.match?.params?.email??"",access_id:this.props?.match?.params?.reset_password_id??"" });
        const url = `${RESET_LINK}?id=${this.props.match.params.reset_password_id}`;
        axios({
          method: "get",
          url: url
        })
          .then(response => {
            this.setState({ loading: false });
            console.log("response from email verify api", response);
            if (response.data.hasOwnProperty("status")) {
              swal.fire("Information", response.data.status, "info");
              if(response.data.status === "user verified"){
                this.setState({ toNewPassEmail: true });
              }else{
                  this.setState({toLogin:true});
              }
             
            }
          })
          .catch(error => {
            this.setState({ loading: false });
            console.log("error in email verify", error);
          });
    }
    render() {
        if (this.state.toNewPassEmail === true) {
            return <Redirect to={{
                pathname: '/newPasswordEmail',
                state: { email: this.state.email,access_id:this.state.access_id }
            }}/>;
          } else if (this.state.loading === true) {
            return <Spinner />;
          }else if (this.state.toLogin===true){
              return <Redirect to="/"/>
          }

          return(
              <div></div>
          )
    }
}
