import React from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  EDIT_ORGANIZATION,
  CREATE_COUNTRY,
  EDIT_COUNTRY,
  ORG_IMAGE_UPLOAD
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  GET_USER_LOCAL,
  CHECK_BOOL
} from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";
import { timezone } from "../timezone";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

export default class EditOrganization extends React.Component {
  state = {
    name: "",
    address: "",
    city: "",
    country: "",
    connect_key: "",
    post_code: "",
    telephone: "",
    website: "",
    sub_orgs: "",
    cities: [],
    filtered: [],
    countries: [],
    city_id: "",
    country_id: "",
    loading: false,
    org_id: "",
    org_admin: false,
    allow_connect: true,
    timezone: [],
    timezone_name: "",
    filteredTimezone: [],
    for_name: "organization",
    imgFile: null,
    imgUrl: null
  };
  componentDidMount() {
    this.setState({ timezone: timezone });
    this.countryList();
    console.log(this.props.history);
    this.setState({
      org_id: this.props.location.state.org_id,
      org_admin: GET_USER_LOCAL().org_admin
    });

    GET_ACCESS_TOKEN().then(token => {
      let org_id = this.props.location.state.org_id;
      let url = `${EDIT_ORGANIZATION}${org_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit org", response);
        this.setState({
          name: response.data.data.name,
          address: response.data.data.address,
          city: response.data.data.city,
          country: response.data.data.country,
          connect_key: response.data.data.connect_key,
          post_code: response.data.data.post_code,
          telephone: response.data.data.telephone,
          website: response.data.data.website,
          sub_orgs: response.data.data.sub_orgs,
          allow_connect: response.data.data.allow_connect,
          timezone_name: response.data.data.timezone_name,
          imgUrl: response.data?.data?.org_image ?? null
        });
      });
    });
  }
  countryList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 200;
      const url = `${CREATE_COUNTRY}?limit=${limit}&d=${false}&sort_by="name"&show="no"&access_token=${token}`;
      axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          this.setState({ countries: response.data.data }, () => {
            console.log("dropdown", this.state.countries);
          });
        })
        .catch(error => {
          console.log("error from country list api", error);
        });
    });
  };
  handleSearch = value => {
    const result = this.state.timezone.filter(tz => {
      return tz.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filteredTimezone: result });
  };
  setTimezone = value => {
    this.setState({ timezone_name: value }, () => {
      console.log(this.state.timezone_name);
    });
  };
  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setAddress = evt => {
    evt.preventDefault();
    this.setState({ address: evt.target.value }, () => {
      console.log(this.state.address);
    });
  };
  handleSearchCity = value => {
    const result = this.state.cities.filter(city => {
      return city.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered: result });
  };
  setCity = value => {
    this.setState({ city: value.label, city_id: value.key }, () => {
      console.log(this.state.city);
    });
  };
  setCountry = value => {
    this.setState({ country: value.label, country_id: value.key }, () => {
      console.log(this.state.country);

      GET_ACCESS_TOKEN().then(token => {
        let url = `${EDIT_COUNTRY}${this.state.country_id}?access_token=${token}`;
        axios({
          method: "get",
          url: url
        }).then(response => {
          console.log("response fro edit country", response.data.data);
          this.setState({
            cities: response.data.data.cities
          });
        });
      });
    });
  };
  setConnectKey = evt => {
    evt.preventDefault();
    this.setState({ connect_key: evt.target.value }, () => {
      console.log(this.state.connect_key);
    });
  };
  setPostCode = evt => {
    evt.preventDefault();
    this.setState({ post_code: evt.target.value }, () => {
      console.log(this.state.post_code);
    });
  };
  setTelephone = evt => {
    evt.preventDefault();
    this.setState({ telephone: evt.target.value }, () => {
      console.log(this.state.telephone);
    });
  };
  setWebsite = evt => {
    evt.preventDefault();
    this.setState({ website: evt.target.value }, () => {
      console.log(this.state.website);
    });
  };
  setSubOrgs = evt => {
    evt.preventDefault();
    this.setState({ sub_orgs: evt.target.value }, () => {
      console.log(this.state.sub_orgs);
    });
  };

  setAllowConnection = value => {
    this.setState({ allow_connect: CHECK_BOOL(value) }, () => {
      console.log(this.state.allow_connect);
    });
  };

  uploadImage = () => {
    GET_ACCESS_TOKEN().then(token => {
      const img = new FormData();

      img.append("imgFile", this.state.imgFile);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}${this.state.for_name}?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              imgUrl: `${process.env.REACT_APP_BASE_PATH}${response.data.data.upload_path}`
            });
          }
          console.log("response from image api", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };

  updateOrganization = () => {
    this.setState({ loading: true });
    const data = {
      name: this.state.name,
      org_image: this.state.imgUrl,
      address: this.state.address,
      city: this.state.city,
      country: this.state.country,
      connect_key: this.state.connect_key,
      post_code: this.state.post_code,
      telephone: this.state.telephone,
      website: this.state.website,
      sub_orgs: this.state.sub_orgs,
      allow_connect: this.state.allow_connect,
      timezone_name: this.state.timezone_name,
      deleted: false
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_ORGANIZATION}${this.state.org_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Info", response.data.message, "info");
          console.log("response from edit org", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from edit org", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    const timezoneList = this.state.timezone.map(r => {
      return <Option key={r.name}>{r.name}</Option>;
    });

    const FliteredTimezoneList = this.state.filteredTimezone.map(r => {
      return <Option key={r.name}>{r.name}</Option>;
    });

    const citiesList = this.state.cities.map(r => {
      return <Option key={r.code}>{r.name}</Option>;
    });

    const FliteredList = this.state.filtered.map(r => {
      return <Option key={r.code}>{r.name}</Option>;
    });
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title="Edit Organization"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Address</Text>

            <TextArea
              rows={4}
              placeholder="Address"
              type="text"
              value={this.state.address}
              onChange={this.setAddress}
            />
          </Form.Item>
          <Form.Item>
            <Text>Country</Text>
            <Select
              labelInValue
              value={{ key: this.state.country_id, label: this.state.country }}
              onChange={this.setCountry}
              placeholder="Select Country"
            >
              {this.state.countries.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Search City *</Text>

            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.handleSearchCity}
              disabled={this.state.country === "" ? true : false}
              value={{ key: this.state.city_id, label: this.state.city }}
              onChange={this.setCity}
              placeholder="Select City"
            >
              {this.state.filtered.length === 0 ? citiesList : FliteredList}
            </Select>
          </Form.Item>
          {this.state.imgUrl === null ? null : (
            <img
              src={this.state.imgUrl}
              alt="avatar"
              style={{ width: 100, height: 100 }}
            />
          )}
          <Form.Item>
            <Text>Upload Organization Image (Image must be under 100 KB)</Text>

            <input
              type="file"
              onChange={e => {
                console.log("Image file inside", this.state.imgUrl);
                this.setState(
                  {
                    imgUrl: URL.createObjectURL(e.target.files[0]),
                    imgFile: e.target.files[0]
                  },
                  () => {
                    this.uploadImage();
                  }
                );
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Search Timezone</Text>
            <Select
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.handleSearch}
              value={this.state.timezone_name}
              onChange={this.setTimezone}
              placeholder="Select Timezone"
            >
              {this.state.filteredTimezone.length === 0
                ? timezoneList
                : FliteredTimezoneList}
            </Select>
          </Form.Item>
          <Form.Item></Form.Item>
          <Form.Item>
            <Text>Allow Connect</Text>
            <Select
              value={this.state.allow_connect.toString()}
              onChange={this.setAllowConnection}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Connect Key</Text>

            <Input
              placeholder="Connect Key"
              type="text"
              value={this.state.connect_key}
              onChange={this.setConnectKey}
            />
          </Form.Item>
          <Form.Item>
            <Text>Post Code</Text>

            <Input
              placeholder="Post Code"
              type="text"
              value={this.state.post_code}
              onChange={this.setPostCode}
            />
          </Form.Item>
          <Form.Item>
            <Text>Telephone</Text>

            <Input
              placeholder="Telephone"
              type="text"
              value={this.state.telephone}
              onChange={this.setTelephone}
            />
          </Form.Item>
          <Form.Item>
            <Text>Website</Text>

            <Input
              placeholder="Website"
              type="text"
              value={this.state.website}
              onChange={this.setWebsite}
            />
          </Form.Item>
          <Form.Item>
            <Text>Sub Organization</Text>

            <Input
              placeholder="Sub Organizations"
              type="text"
              value={this.state.sub_orgs}
              onChange={this.setSubOrgs}
            />
          </Form.Item>
          {this.state.org_admin === true ? (
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateOrganization}
              >
                Update Information
              </Button>
            </Form.Item>
          ) : null}
        </Form>
      </Card>
    );
  }
}
