import React, { Component } from 'react';
import {
    Card,
    Form,
    Button,
    Typography,
    PageHeader,
    Spin,
    Select,
    Input
  } from "antd";
  import axios from "axios";
  import swal from "sweetalert2";
  import { SEND_ORG_INVITATION } from "../../api/constants";
  import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
  import { Spinner } from "../loader";
  
  import "../login.css";
  
  const { Text } = Typography;
  const { Option } = Select;

export default class SendOrgInvitaion extends Component {
    state = {
        organization:"",
        org_id:"",
        loading: false,
        data: [],
        filtered:[],
        showLink:false,
        url:""
      };
    
      componentDidMount() {
        this.getOrgList();
      }
    
      getOrgList = () => {
        GET_ACCESS_TOKEN().then(token => {
          const url = `${SEND_ORG_INVITATION}?limit=10000&access_token=${token}`;

          axios({
            method: "get",
            url: url
          })
            .then(response => {
              this.setState({ data: response.data.data });
              console.log("data from org list api send org invite api", response);
            })
            .catch(error => {
              console.log("error from org list api", error);
            });
        });
      };
    
      
      setOrg = value => {
        this.setState({ organization: value.label,org_id:value.key }, () => {
          console.log(this.state.organization);
        });
      };

      handleSearch=(value)=>{
        const result = this.state.data.filter(org=> {
          return org.name.toLowerCase().includes(value.toLowerCase())
        })
        console.log(result)
        this.setState({filtered:result})

      }

      copyText = e => {
        this.Input.select();
        document.execCommand("copy");
        swal.fire(
          "Success",
          "Link Copied to Clipboard, , You Can now Send The Link Through Your Preferred Method",
          "success"
        );
      };
      
      sendOrgInvitation = () => {
        if(this.state.org_id===""){
          swal.fire("info","Please Select Organization","info");
        } else {
          this.setState({ loading: true });
          let data = {
            org:this.state.org_id
          }
          GET_ACCESS_TOKEN().then(token => {
            let url=`${SEND_ORG_INVITATION}?access_token=${token}`

            axios({
              method: "post",
              url: url,
              data:data,
              headers: { 
                'content-type': 'application/x-www-form-urlencoded' 
              }
            })
              .then(response => {
                this.setState({ loading: false });
                swal.fire("Info", response.data.message, "info");
                if (response.data.message==="Invitation sent successfully!"){
                  this.setState({url:response.data.url,showLink:true})
                }
                console.log("response from org invitation send", response);
              })
              .catch(error => {
                this.setState({ loading: false });
                swal.fire("Error", error.message, "error");
                console.log("error from org invitation send", error);
              });
          });
        }
          
        
      };
      render() {
        if (this.state.loading === true) {
          return <Spinner />;
        }
        const UserList=this.state.data.map(r => {
          return <Option key={r._id.$oid}>{r.name}</Option>;
        })

        const FliteredList = this.state.filtered.map(r => {
          return <Option key={r._id.$oid}>{r.name}</Option>;
        })
        return (
          <Card style={{ margin: 10 }}>
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              onBack={() => this.props.history.goBack()}
              title="Send Invitation to Join Your Organization"
            />
            <Form
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column"
              }}
            >
             
              <Form.Item>
                <Text>Search Organization</Text>
                <Select
                  showSearch={true}
                  labelInValue
                  notFoundContent={fetch ? <Spin size="small" /> : null}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearch}
                  value={{ key: this.state.org_id, label: this.state.organization }}
                  onChange={this.setOrg}
                  placeholder="Select Organization"
                > 
              
                  {this.state.filtered.length===0?(
                    UserList
                  ):(
                    FliteredList
                  )}
                </Select>
              </Form.Item>
              {this.state.showLink ? (
                 <Form.Item>
                    <Input
                      style={{ marginTop: 5 }}
                      type="text"
                      addonAfter={
                        <Button
                          style={{
                            border: "none",
                            backgroundColor: "#FAFAFA",
                            height: 30
                          }}
                          onClick={this.copyText}
                        >
                          Copy
                        </Button>
                      }
                      ref={Input => (this.Input = Input)}
                      value={this.state.url}
                    />
                    </Form.Item>
                  ) : null}
    
              <Form.Item>
                <Button className="login-form-button" htmlType="submit" onClick={this.sendOrgInvitation}>
                  Invite
                </Button>
              </Form.Item>
            </Form>
          </Card>
        );
      }
}
