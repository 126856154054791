import React, { Component } from "react";
import { Table, Divider, Card, PageHeader, Button,Tooltip } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";

import {
  CREATE_PLAN,
  EDIT_PLAN,
  REVERSE_GEO_COUNTRY
} from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";


import "../login.css";

const { Column } = Table;

export default class PlanList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false,
    country_code: "",
    filtered_country:[]
  };
  componentDidMount() {
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    this.getPlanList();
  }

  getCountry = () => {
    const success = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      axios.get(REVERSE_GEO_COUNTRY(latitude, longitude)).then(response => {
        this.setState({ loading: false });
        console.log("response from reverse geo", response);
        this.setState({ country_code: response.data.address.country_code },()=>{
          let filteredList=this.state.data.filter(plan=>plan.country.toLowerCase()===this.state.country_code)
          console.log("filtered list",filteredList);
          this.setState({filtered_country:filteredList})
        });
      });
    };
    navigator.geolocation.getCurrentPosition(success);
  };

  getPlanList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_PLAN}?page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from Plan list", response);
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          },()=>{
            this.getCountry();
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from plan list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getPlanList();
      }
    );
  };

  deletePlan = id => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${EDIT_PLAN}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <Link to="/createPlan">
          <Tooltip placement="top" title="Create New Plan">
            <Button
              shape="circle"
              icon="plus"
              style={{
                position: "fixed",
                zIndex: 100,
                bottom: 30,
                right: 20,
                backgroundColor: "#1A3337",
                height: 60,
                width: 60,
                fontSize: 30,
                paddingBottom: 5
              }}
            />
          </Tooltip>
        </Link>
        <PageHeader
          title="Plan List"
        />
        <Table
          dataSource={this.state.filtered_country}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Price" dataIndex="price" key="price" />
          <Column
            title="Description"
            dataIndex="description"
            key="description"
          />
          {/* <Column
            title="Services"
            dataIndex="service"
            key="service"
            render={service => (
              <span>
                {service.map(serv => (
                  <Tag
                    color="blue"
                    key={serv.created_on}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2
                    }}
                  >
                    {serv.name}
                  </Tag>
                ))}
              </span>
            )}
          /> */}
          <Column title="Currency" dataIndex="currency" key="currency" />
          <Column title="Country" dataIndex="country" key="country" />
          <Column title="Users" dataIndex="no_users" key="no_users" />
          <Column title="Days" dataIndex="days" key="days" />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Link
                  to={{
                    pathname: "/editPlan",
                    state: { plan_id: record._id.$oid }
                  }}
                >
                  <button
                    className="borderless-button"
                    style={{ color: "green" }}
                  >
                    Edit
                  </button>
                </Link>
                <Divider type="vertical" />
                <button
                  className="borderless-button"
                  style={{ color: "red" }}
                  onClick={() => {
                    if (window.confirm("Delete the item?")) {
                      this.deletePlan(record._id.$oid);
                    }
                  }}
                >
                  Delete
                </button>
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}
