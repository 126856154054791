import React, { Component } from "react";
import {
  Card,
  PageHeader,
  Table,
  Button,
  Input,
  Select,
  Pagination,
  Icon,
} from "antd";
// import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import {
  EDIT_INVENTORY,
  PRODUCT_STOCK_CHANGE,
  PRODUCT_ADD_REASON,
  PRODUCT_SUBTRACT_REASON,
  CREATE_PRODUCT,
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { debounce } from "lodash";

import "../login.css";

const { Column } = Table;
// const { Text } = Typography;
const { Option } = Select;

export default class ModifyInventory extends Component {
  state = {
    data: [],
    inventory_id: "",
    added_stock: 0,
    subtracted_stock: 0,
    cost_amount: 0,
    remarks: "",
    product_id: "",
    stock_update_type: "add",
    modify_inventory: false,
    add_reason_options: [],
    remove_reason_options: [],
    sku: "",
    product_name: "",
    category_name: "",
    page: 0,
    limit: 10,
    total: 0,
    sort_by: "created_on",
    sort_type: "dsc",
    disable_modify_inventory_button:false
  };

  componentDidMount() {
    this.setState(
      { inventory_id: this.props.location.state.inventory_id },
      () => {
        this.getProductList();
      }
    );
    this.getAddReasonOptions();
    this.getSubtractReasonOptions();
    this.getInventoryDetails();
  }

  getProductList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT}?sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&enable_order_for_availabl_stock_or_stock_quantity_zero=true&is_parent_product=false&sku=${this.state.sku}&name=${this.state.product_name}&category_name=${this.state.category_name}&inventory_id=${this.state.inventory_id}&page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from product list api", error);
        });
    });
  }, 500);

  getAddReasonOptions = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PRODUCT_ADD_REASON}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product add reason list", response);
          this.setState(
            {
              add_reason_options:
                response?.data?.add_reason_choices?.filter(
                  (reason) => reason.name !== "internal_transfer"
                ) ?? [],
            },
            () => {
              this.setState({ remarks: this.state.add_reason_options[0].name });
            }
          );
        })
        .catch((error) => {
          console.log("error from product add reason list api", error);
        });
    });
  };
  getSubtractReasonOptions = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PRODUCT_SUBTRACT_REASON}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product subtract reason list", response);
          this.setState({
            remove_reason_options:
              response?.data?.subtract_reason_choices?.filter(
                (reason) => reason.name !== "internal_transfer"
              ) ?? [],
          });
        })
        .catch((error) => {
          console.log("error from product subtract reason list api", error);
        });
    });
  };

  getInventoryDetails = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let inventory_id = this.props.location.state.inventory_id;
      let url = `${EDIT_INVENTORY}${inventory_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro view inventory", response);
        this.setState({
          data: response.data?.data?.inventory_items ?? [],
        });
      });
    });
  };

  setStockUpdateType = (value) => {
    this.setState({ stock_update_type: value }, () => {
      console.log(this.state.stock_update_type);
      if (this.state.stock_update_type === "add") {
        this.setState({
          subtracted_stock: 0,
          remarks: "",
        });
      } else {
        this.setState({
          added_stock: 0,
          cost_amount: 0,
          remarks: "",
        });
      }
    });
  };

  updateStock = () => {
    if (
      this.state.stock_update_type === "add" &&
      Number(this.state.added_stock) < 1
    ) {
      swal.fire("Info", "Added quantity can not be less than 1", "info");
    } else if (
      this.state.stock_update_type === "remove" &&
      Number(this.state.subtracted_stock) < 1
    ) {
      swal.fire("Info", "Removed quantity can not be less than 1", "info");
    } else if (this.state.remarks === "") {
      swal.fire("Info", "Please select remarks", "info");
    } else if (
      this.state.remarks === "wrong_added_stock_adjustment" &&
      Number(this.state.cost_amount) < 1
    ) {
      swal.fire("Info", "Please enter cost amount", "info");
    } else {
      this.setState({disable_modify_inventory_button:true});
      const data = {
        added_stock: Number(this.state.added_stock),
        subtracted_stock: Number(this.state.subtracted_stock),
        cost_amount: Number(this.state.cost_amount),
        add_or_subtract_reason: this.state.remarks,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${PRODUCT_STOCK_CHANGE}${this.state.product_id}?access_token=${token}`;

        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            this.getInventoryDetails();
            this.setState({
              added_stock: 0,
              subtracted_stock: 0,
              cost_amount: 0,
              remarks: "",
              modify_inventory: false,
              product_id: "",
              disable_modify_inventory_button:false
            });
            console.log("response from product status change", response);
          })
          .catch((error) => {
            this.setState({disable_modify_inventory_button:false});
            swal.fire("Error", error.message, "error");
            console.log("error from product status change", error);
          });
      });
    }
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getProductList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getProductList();
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          onBack={() => this.props.history.goBack()}
          title="Modify Inventory"
        />

        <Card>
          <Table
            dataSource={this.state.data}
            pagination={false}
            rowKey={(record) => record._id.$oid}
            size="small"
            scroll={{ x: 1300 }}
            bordered
          >
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Category Name</div>
                  </div>

                  <Input
                    value={this.state.category_name}
                    onChange={(evt) => {
                      this.setState(
                        { category_name: evt.target.value, page: 0 },
                        () => {
                          this.getProductList();
                        }
                      );
                    }}
                  />
                </>
              }
              dataIndex="category_name"
              key="category_name"
            />
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Product Name</div>
                  </div>

                  <Input
                    value={this.state.product_name}
                    onChange={(evt) => {
                      this.setState(
                        { product_name: evt.target.value, page: 0 },
                        () => {
                          this.getProductList();
                        }
                      );
                    }}
                  />
                </>
              }
              dataIndex="name"
              key="name"
            />
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>SKU</div>
                  </div>

                  <Input
                    value={this.state.sku}
                    onChange={(evt) => {
                      this.setState({ sku: evt.target.value, page: 0 }, () => {
                        this.getProductList();
                      });
                    }}
                  />
                </>
              }
              dataIndex="sku"
              key="sku"
            />
            <Column title="Unit" dataIndex="unit" key="unit" />

            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Unit Purchase Price</div>
                    <div>
                      <Icon
                        type="caret-up"
                        onClick={() => {
                          this.setState(
                            {
                              sort_by: "unit_purchase_price",
                              sort_type: "asc",
                            },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                      <Icon
                        type="caret-down"
                        onClick={() => {
                          this.setState(
                            {
                              sort_by: "unit_purchase_price",
                              sort_type: "dsc",
                            },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              }
              dataIndex="unit_purchase_price"
              key="unit_purchase_price"
            />
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Unit Selling Price</div>
                    <div>
                      <Icon
                        type="caret-up"
                        onClick={() => {
                          this.setState(
                            { sort_by: "unit_selling_price", sort_type: "asc" },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                      <Icon
                        type="caret-down"
                        onClick={() => {
                          this.setState(
                            { sort_by: "unit_selling_price", sort_type: "dsc" },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              }
              dataIndex="unit_selling_price"
              key="unit_selling_price"
            />
            <Column title="MRP" dataIndex="mrp" key="mrp" />

            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Stock Quantity</div>
                    <div>
                      <Icon
                        type="caret-up"
                        onClick={() => {
                          this.setState(
                            { sort_by: "stock_quantity", sort_type: "asc" },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                      <Icon
                        type="caret-down"
                        onClick={() => {
                          this.setState(
                            { sort_by: "stock_quantity", sort_type: "dsc" },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              }
              dataIndex="stock_quantity"
              key="stock_quantity"
            />



            <Column
              title="Update Type"
              key="Update Type"
              render={(text, record) => (
                <span>
                  {this.state.modify_inventory &&
                  this.state.product_id === record._id.$oid ? (
                    <Select
                      style={{ minWidth: "100%", maxWidth: "100%" }}
                      value={this.state.stock_update_type}
                      onChange={this.setStockUpdateType}
                    >
                      <Option key="add">Add</Option>
                      <Option key="remove">Remove</Option>
                    </Select>
                  ) : null}
                </span>
              )}
            />
            <Column
              title="Add/Remove Stock Quantity"
              key="Add/Remove Stock Quantity"
              render={(text, record) => (
                <span>
                  {this.state.stock_update_type === "add" &&
                  this.state.modify_inventory &&
                  this.state.product_id === record._id.$oid ? (
                    <Input
                      placeholder="Added Stock Amount"
                      type="text"
                      value={this.state.added_stock}
                      onChange={(evt) =>
                        this.setState({ added_stock: evt.target.value }, () => {
                          this.setState({
                            cost_amount:
                              Number(this.state.added_stock) *
                              Number(record.unit_purchase_price),
                          });
                        })
                      }
                    />
                  ) : this.state.stock_update_type === "remove" &&
                    this.state.modify_inventory &&
                    this.state.product_id === record._id.$oid ? (
                    <Input
                      placeholder="Removed Stock Amount"
                      type="text"
                      value={this.state.subtracted_stock}
                      onChange={(evt) =>
                        this.setState({ subtracted_stock: evt.target.value })
                      }
                    />
                  ) : null}
                </span>
              )}
            />
            <Column
              title="Total Purchase Price"
              key="Total Purchase Price"
              // width="15%"
              render={(text, record) => (
                <span>
                  {this.state.stock_update_type === "add" &&
                  this.state.modify_inventory &&
                  this.state.product_id === record._id.$oid ? (
                    <Input
                      placeholder="Total Purchase Price"
                      type="text"
                      value={this.state.cost_amount}
                      onChange={(evt) =>
                        this.setState({ cost_amount: evt.target.value })
                      }
                    />
                  ) : this.state.stock_update_type === "remove" &&
                    this.state.modify_inventory &&
                    this.state.product_id === record._id.$oid &&
                    this.state.remarks === "wrong_added_stock_adjustment" ? (
                    <Input
                      placeholder="Total Purchase Price"
                      type="text"
                      value={this.state.cost_amount}
                      onChange={(evt) =>
                        this.setState({ cost_amount: evt.target.value })
                      }
                    />
                  ) : null}
                </span>
              )}
            />
            <Column
              title="Remarks"
              key="Remarks"
              width="15%"
              render={(text, record) => (
                <span>
                  {this.state.stock_update_type === "add" &&
                  this.state.modify_inventory &&
                  this.state.product_id === record._id.$oid ? (
                    <Select
                      style={{ minWidth: "100%", maxWidth: "100%" }}
                      value={this.state.remarks}
                      onChange={(value) => this.setState({ remarks: value })}
                      placeholder="Remarks"
                    >
                      {this.state.add_reason_options.map((r) => {
                        return <Option key={r.name}>{r.label}</Option>;
                      })}
                    </Select>
                  ) : this.state.stock_update_type === "remove" &&
                    this.state.modify_inventory &&
                    this.state.product_id === record._id.$oid ? (
                    <Select
                      style={{ minWidth: "100%", maxWidth: "100%" }}
                      value={this.state.remarks}
                      onChange={(value) => this.setState({ remarks: value })}
                      placeholder="Remarks"
                    >
                      {this.state.remove_reason_options.map((r) => {
                        return <Option key={r.name}>{r.label}</Option>;
                      })}
                    </Select>
                  ) : null}
                </span>
              )}
            />
            {/* 
            <Column
              title="Update"
              key="Update"
              width="10%"
              render={(text, record) => (
                <span>
                  {this.state.modify_inventory &&
                  this.state.product_id === record._id.$oid ? (
                    <Button
                      type="link"
                      htmlType="submit"
                      onClick={this.updateStock}
                    >
                      Update
                    </Button>
                  ) : null}
                </span>
              )}
            /> */}
            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <span>
                  {this.state.modify_inventory &&
                  this.state.product_id === record._id.$oid ? (
                    <Button
                      disabled={this.state.disable_modify_inventory_button}
                      loading={this.state.disable_modify_inventory_button}
                      type="link"
                      htmlType="submit"
                      onClick={() => {
                        if (this.state.stock_update_type === "add") {
                          if (
                            Number(record.unit_purchase_price) !==
                            Number(this.state.cost_amount) /
                              Number(this.state.added_stock)
                          ) {
                            swal
                              .fire({
                                title: `Unit Purchase price will be updated from ${record.unit_purchase_price}. Do you want to proceed?`,
                                text: "Click Yes to confirm",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes",
                              })
                              .then((result) => {
                                if (result.value) {
                                  this.updateStock();
                                }
                              });
                          } else {
                            this.updateStock();
                          }
                        } else if (
                          this.state.remarks === "wrong_added_stock_adjustment"
                        ) {
                          if (
                            Number(record.unit_purchase_price) !==
                            Number(this.state.cost_amount) /
                              Number(this.state.subtracted_stock)
                          ) {
                            swal
                              .fire({
                                title: `Unit Purchase price will be updated from ${record.unit_purchase_price}. Do you want to proceed?`,
                                text: "Click Yes to confirm",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes",
                              })
                              .then((result) => {
                                if (result.value) {
                                  this.updateStock();
                                }
                              });
                          } else {
                            this.updateStock();
                          }
                        } else {
                          this.updateStock();
                        }
                      }}
                    >
                      Update
                    </Button>
                  ) : null}
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() =>
                      this.setState(
                        {
                          modify_inventory: !this.state.modify_inventory,
                          product_id: record._id.$oid,
                        },
                        () => {
                          if (!this.state.modify_inventory) {
                            this.setState({
                              added_stock: 0,
                              subtracted_stock: 0,
                              cost_amount: 0,
                              remarks: this.state.add_reason_options[0].name,
                            });
                          }
                        }
                      )
                    }
                  >
                    {this.state.modify_inventory &&
                    this.state.product_id === record._id.$oid
                      ? "Cancel"
                      : "Modify"}
                  </Button>
                </span>
              )}
            />
          </Table>
          {this.state.total > 10 ? (
            <Pagination
              pageSize={Number(this.state.limit)}
              current={this.state.page + 1}
              total={this.state.total}
              onChange={this.pageChange}
              style={{ marginTop: 10 }}
            />
          ) : null}
        </Card>
      </Card>
    );
  }
}
