import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Tooltip } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
// import swal from "sweetalert2";

import { CREATE_SUB_ORG_TYPE } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";

import "../login.css";

const { Column } = Table;

class SubOrgTypeList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
  };
  componentDidMount() {
    this.getSubOrgTypeList();
  }

  getSubOrgTypeList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_SUB_ORG_TYPE}?page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from sub org type list", response);
          // if (response.data.data.length === 0) {
          //   swal.fire("Info", "No Data Available", "info");
          // }
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from sub org type list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.getSubOrgTypeList();
      }
    );
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.sub_org.sub_org_type.sub_org_type_create ? (
          <Link to="/createSubOrgType">
            <Tooltip placement="top" title="Create Sub Org Type">
              <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}
         <PageHeader
          title="Sub Organization Types"
        />
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Sequence Number" dataIndex="seq_no" key="seq_no" />

          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />

          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.sub_org.sub_org_type
                  .sub_org_type_detail ? (
                  <Link
                    to={{
                      pathname: "/editSubOrgType",
                      state: { sub_org_type_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 && this.state.data.length < this.state.total ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(SubOrgTypeList);
