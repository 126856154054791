import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader } from "antd";
import { connect } from "react-redux";
import { saveTaskData } from "../../redux/actions/startTaskActions";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const { Text } = Typography;

class StartTime extends Component {
  state = {
    start_time: Date.now(),
    start_time_view: moment()
  };

  componentDidMount(){
    console.log("from starttask",this.props.startTask);
    if(Object.keys(this.props.startTask).length!==0){
        this.setState({start_time:this.props?.startTask?.start_time?? Date.now(),start_time_view:this.props?.startTask?.start_time_view??moment()});
    }
  }

  setTime = (value, dateString) => {
    this.setState(
      {
        start_time: moment(value).format("x"),
        start_time_view: value
      },
      () => {
        console.log("Selected Time: ", this.state.start_time);
        console.log("Selected value: ", this.state.start_time_view);
      }
    );
  };

  next = () => {
    let data = {
      name: this.props.startTask.name,
      task_type: this.props.startTask.task_type,
      task_code: this.props.startTask.task_code,
      colors: this.props.startTask.colors,
      start_time:this.state.start_time,
      start_time_view:this.state.start_time_view
    };
    console.log(data);

    this.props.saveTaskData(data);
    this.props.history.push("/startingFrom")
  };
  render() {
    return (
      <Card>
        <PageHeader
          title="When Are you Planning"
          onBack={() => this.props.history.goBack()}
        />
        <Form>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>Task Schedule</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={this.setTime}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.next}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps=state=>({
    startTask:state.startTask.startTaskData
})

export default connect(mapStateToProps, { saveTaskData })(StartTime);
