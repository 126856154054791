import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Typography,
  Select,
  Pagination,
  Tooltip
} from "antd";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import FileSaver from "file-saver";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  DateTimePicker,
  DatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  CREATE_ATTENDANCE,
  EDIT_ATTENDANCE,
  CREATE_DRIVER,
  ATTENDANCE_DETAILED_REPORT,
  ATTENDANCE_SINGLE_REPORT,
  ATTENDANCE_REPORT_WITH_CUSTOM_FIELDS,
  CURRENT_USER_SUB_ORG_LIST,
  GET_FIRST_CHILD_SUB_ORG_LIST,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL,
  CHECK_BOOL,
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Text, Title } = Typography;
const { Option } = Select;

class AttendanceList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    loading: false,
    search_start_time: true,
    search_end_time: true,
    startTimeStamp: moment().startOf("month").format("x"),
    endTimeStamp: moment().endOf("day").format("x"),
    userData: [],
    assigned_to_label: "",
    assigned_to: "",
    filtered: [],
    timezone_name: "",
    timezone_choice: "org_timezone",
    start_date_view: moment().startOf("month"),
    end_date_view: moment(),
    org_absent_users: [],
    attendanceChoice: "checkedin",
    from: Date.now() / 1000,
    //from_view: moment(),
    //from_single: Date.now() / 1000,
    //from_single_view: moment(),
    //to: Date.now() / 1000,
    //to_view: moment(),
    //to_single: Date.now() / 1000,
    //to_single_view: moment(),
    options: 1,
    //options_single: 1,
    timezone_option: 1,
    report_for_option: "report-for-all",
    custom_fields: false,
    //custom_fields_single: false,
    org_id: "",
    user_id: "",
    from_monthly_view: moment().startOf("day"),
    from_monthly: moment().startOf("day").format("x") / 1000,
    to_monthly: Date.now() / 1000,
    to_monthly_view: moment(),
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    first_child_sub_orgs: [],
    first_child_sub_org_id: "",
    sub_org_id_attendance_search: "",
    loading_detailed_report: false,
    loading_custom_report: false,
    loading_single_report: false,
  };
  componentDidMount() {
    console.log("time", moment().endOf("day").format("x"));
    this.props.getUserData();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({
      timezone_name: timezone,
      org_id: GET_USER_LOCAL().organization.$oid,
    });
    console.log("time zone", timezone);

    this.getCurrentUserSubOrgs();
    this.getUserList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0&&!this.props.userProfileData.data_level.access_all_data ? response?.data?.data[0].id : "",
              sub_org_id_attendance_search:
                response.data.data.length > 0&&!this.props.userProfileData.data_level.access_all_data ? response?.data?.data[0].id : "",
            },
            () => {
              this.getAttendanceList();
              if (this.state.current_user_sub_org_id !== "") {
                GET_ACCESS_TOKEN().then((token) => {
                  const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

                  axios({
                    method: "get",
                    url: url,
                  })
                    .then((response) => {
                      console.log(
                        "response from first child sub org list",
                        response
                      );

                      this.setState({
                        first_child_sub_orgs: response.data.data,
                      });
                    })
                    .catch((error) => {
                      console.log(
                        "error from first child sub org list api",
                        error
                      );
                    });
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_attendance_search: value },
      () => {
        this.getAttendanceList();
        GET_ACCESS_TOKEN().then((token) => {
          const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

          axios({
            method: "get",
            url: url,
          })
            .then((response) => {
              console.log("response from first child sub org list", response);

              this.setState({
                first_child_sub_orgs: response.data.data,
              });
            })
            .catch((error) => {
              console.log("error from first child sub org list api", error);
            });
        });
      }
    );
  };
  setFirstChildSubOrg = (value) => {
    this.setState(
      { first_child_sub_org_id: value, sub_org_id_attendance_search: value },
      () => {
        this.getAttendanceList();
      }
    );
  };

  // setTimezonetype = value => {
  //   this.setState({ timezone_choice: value }, () => {
  //     console.log(this.state.timezone_choice);
  //   });
  // };

  setOptions = (value) => {
    this.setState({ options: value }, () => {
      console.log(this.state.options);
    });
  };
  setReportForOptions = (value) => {
    this.setState({ report_for_option: value }, () => {
      console.log(this.state.report_for_option);
    });
  };
  setTimezoneOptions = (value) => {
    this.setState({ timezone_option: value }, () => {
      console.log(this.state.timezone_option);
    });
  };
  setAttendancetype = (value) => {
    this.setState({ attendanceChoice: value }, () => {
      console.log(this.state.attendanceChoice);
    });
  };
  getAttendanceList = () => {
    let date = new Date();
    let time = date.getTime();
    console.log(moment(time));
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ATTENDANCE}?page=${this.state.page}&limit=${this.state.limit}&search_user=${this.state.assigned_to}&search_end_time=${this.state.search_end_time}&search_start_time=${this.state.search_start_time}&start_time=${this.state.startTimeStamp}&end_time=${this.state.endTimeStamp}&sub_org=${this.state.sub_org_id_attendance_search}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from Attendance list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
            org_absent_users: response.data?.org_absent_users ?? [],
          });
        })
        .catch((error) => {
          console.log("error from attendance list api", error);
        });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from user list", response);
        })
        .catch((error) => {
          console.log("error from user list api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getAttendanceList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getAttendanceList();
    });
  };

  setAssigned = (value) => {
    this.setState(
      { assigned_to_label: value.label, assigned_to: value.key, filtered: [] },
      () => {
        console.log(this.state.assigned_to);
        this.getAttendanceList();
        // let date = new Date();
        // let time = date.getTime();
        // console.log(moment(time));
        // GET_ACCESS_TOKEN().then(token => {
        //   const url =
        //     this.state.search_start_time && this.state.search_end_time
        //       ? `${CREATE_ATTENDANCE}?page=${this.state.page}&limit=${this.state.limit}&ts=${time}&search_user=${this.state.assigned_to}&search_end_time=${this.state.search_end_time}&search_start_time=${this.state.search_start_time}&start_time=${this.state.startTimeStamp}&end_time=${this.state.endTimeStamp}&page=${this.state.page}&access_token=${token}`
        //       : `${CREATE_ATTENDANCE}?page=${this.state.page}&limit=${this.state.limit}&ts=${time}&search_user=${this.state.assigned_to}&page=${this.state.page}&access_token=${token}`;
        //   axios({
        //     method: "get",
        //     url: url
        //   })
        //     .then(response => {
        //       console.log("response from attendance list", response);
        //       this.setState({
        //         data:
        //           this.state.page === 0
        //             ? response.data.data
        //             : [...this.state.data, ...response.data.data],
        //         total: response.data.total
        //       });
        //     })
        //     .catch(error => {
        //       console.log("error from attendance list api", error);
        //     });
        // });
      }
    );
  };
  setAssignedUser = (value) => {
    this.setState({ user_id: value, filtered: [] }, () => {
      console.log(this.state.user_id);
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from search user", response);
          this.setState({
            userData: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from search user api", error);
        });
    });
  }, 500);

  setCustomFields = (value) => {
    this.setState({ custom_fields: CHECK_BOOL(value) }, () => {
      console.log(this.state.custom_fields);
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.getAttendanceList();
      }
    );
  };

  deleteAttendance = (id) => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${EDIT_ATTENDANCE}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from del", error);
        });
    });
  };

  // handleSaveFile = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     const url =
  //       this.state.timezone_choice === "org_timezone"
  //         ? `${CREATE_ATTENDANCE}?fmt=xls&timezone_choice=${this.state.timezone_choice}&page=${this.state.page}&access_token=${token}`
  //         : `${CREATE_ATTENDANCE}?fmt=xls&timezone_choice=${this.state.timezone_choice}&timezone_name=${this.state.timezone_name}&page=${this.state.page}&access_token=${token}`;
  //     fetch(url)
  //       .then(res => res.blob())
  //       .then(blob => {
  //         console.log("response from Attendance list fmt", blob);

  //         FileSaver.saveAs(
  //           blob,
  //           `attendance_report_${moment().format(
  //             "DD-MMMM-YYYY_hh:mm A"
  //           )}.xlsx`
  //         );
  //       })
  //       .catch(error => {
  //         console.log("error from Attendance list api", error);
  //       });
  //   });
  // };

  // handleSaveFileAbsent = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     const url =
  //       this.state.timezone_choice === "org_timezone"
  //         ? `${CREATE_ATTENDANCE}?fmt=xls&r_absent_users=r_absent_users&timezone_choice=${this.state.timezone_choice}&page=${this.state.page}&access_token=${token}`
  //         : `${CREATE_ATTENDANCE}?fmt=xls&r_absent_users=r_absent_users&timezone_choice=${this.state.timezone_choice}&timezone_name=${this.state.timezone_name}&page=${this.state.page}&access_token=${token}`;
  //     fetch(url)
  //       .then(res => res.blob())
  //       .then(blob => {
  //         console.log("response from Attendance list absent fmt", blob);

  //         FileSaver.saveAs(
  //           blob,
  //           `attendance_absent_report_${moment().format(
  //             "DD-MMMM-YYYY_hh:mm A"
  //           )}.xlsx`
  //         );
  //       })
  //       .catch(error => {
  //         console.log("error from Attendance list absent api", error);
  //       });
  //   });
  // };

  // downloadReport = () => {
  //   if (
  //     Number(this.state.from) > Date.now() / 1000 + 86400 ||
  //     Number(this.state.to) > Date.now() / 1000 + 86400
  //   ) {
  //     swal.fire("Info", "Future Date Not Allowed", "info");
  //   } else {
  //     GET_ACCESS_TOKEN().then(token => {
  //       const url = `${ATTENDANCE_DETAILED_REPORT}?org_id=${
  //         this.state.org_id
  //       }&from=${Number(this.state.from)}&to=${Number(
  //         this.state.to
  //       )}&options=${Number(this.state.options)}&custom_fields=${
  //         this.state.custom_fields
  //       }&timezone_option=${Number(
  //         this.state.timezone_option
  //       )}&access_token=${token}`;
  //       fetch(url)
  //         .then(res => res.blob())
  //         .then(blob => {
  //           console.log("response from Attendance list fmt", blob);

  //           FileSaver.saveAs(
  //             blob,
  //             `attendance_report_${moment().format(
  //               "DD-MMMM-YYYY_hh:mm A"
  //             )}.xlsx`
  //           );
  //         })
  //         .catch(error => {
  //           console.log("error from Attendance list api", error);
  //         });
  //     });
  //   }
  // };
  downloadReport = () => {
    if (
      moment(this.state.to_monthly_view).diff(
        this.state.from_monthly_view,
        "days"
      ) > 30
    ) {
      swal.fire("Info", "Date range cannot exceed 31 days", "info");
    } else {
      this.setState({ loading_detailed_report: true });
      GET_ACCESS_TOKEN().then((token) => {
        const url = `${ATTENDANCE_DETAILED_REPORT}?sub_org=${
          this.state.sub_org_id_attendance_search
        }&org_id=${this.state.org_id}&from=${this.state.from_monthly}&to=${
          this.state.to_monthly
        }&options=${Number(this.state.options)}&custom_fields=${
          this.state.custom_fields
        }&timezone_option=${Number(
          this.state.timezone_option
        )}&access_token=${token}`;
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            console.log("response from Attendance list fmt", blob);

            FileSaver.saveAs(
              blob,
              `attendance_report_${moment().format(
                "DD-MMMM-YYYY_hh:mm A"
              )}.xlsx`
            );
            this.setState({ loading_detailed_report: false });
          })
          .catch((error) => {
            this.setState({ loading_detailed_report: false });
            console.log("error from Attendance list api", error);
          });
      });
    }
  };

  downloadReportWithCustomFields = () => {
    if (
      moment(this.state.to_monthly_view).diff(
        this.state.from_monthly_view,
        "days"
      ) > 30
    ) {
      swal.fire("Info", "Date range can not exceed 31 days", "info");
    } else {
      this.setState({ loading_custom_report: true });
      GET_ACCESS_TOKEN().then((token) => {
        const url = `${ATTENDANCE_REPORT_WITH_CUSTOM_FIELDS}?sub_org=${
          this.state.sub_org_id_attendance_search
        }&org_id=${this.state.org_id}&from=${Number(
          this.state.from_monthly
        )}&to=${Number(this.state.to_monthly)}&timezone_option=${Number(
          this.state.timezone_option
        )}&access_token=${token}`;
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            console.log(
              "response from Attendance report with custom fields fmt",
              blob
            );

            FileSaver.saveAs(
              blob,
              `attendance_report_with_custom_fields${moment().format(
                "DD-MMMM-YYYY_hh:mm A"
              )}.xlsx`
            );
            this.setState({ loading_custom_report: false });
          })
          .catch((error) => {
            this.setState({ loading_custom_report: false });
            console.log(
              "error from Attendance report with custom fields api",
              error
            );
          });
      });
    }
  };
  downloadSingleReport = () => {
    if (
      moment(this.state.to_monthly_view).diff(
        this.state.from_monthly_view,
        "days"
      ) > 30
    ) {
      swal.fire("Info", "Date range can not exceed 31 days", "info");
    } else if (this.state.user_id === "") {
    /*
    if (
      Number(this.state.from_single) > Date.now() / 1000 + 86400 ||
      Number(this.state.to_single) > Date.now() / 1000 + 86400
    ) {
      swal.fire("Info", "Future Date Not Allowed", "info");
    }
    */
      swal.fire("Info", "Please select a user", "info");
    } else {
      this.setState({ loading_single_report: true });
      GET_ACCESS_TOKEN().then((token) => {
        const url = `${ATTENDANCE_SINGLE_REPORT}?sub_org=${
          this.state.sub_org_id_attendance_search
        }&org_id=${this.state.org_id}&from=${Number(
          this.state.from_monthly
        )}&to=${Number(this.state.to_monthly)}&options=${Number(
          this.state.options
        )}&user_id=${this.state.user_id}&access_token=${token}`;
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            console.log("response from Attendance Single list fmt", blob);

            FileSaver.saveAs(
              blob,
              `attendance_report_${moment().format(
                "DD-MMMM-YYYY_hh:mm A"
              )}.xlsx`
            );
            this.setState({ loading_single_report: false });
          })
          .catch((error) => {
            console.log("error from Attendance list api", error);
            this.setState({ loading_single_report: false });
          });
      });
    }
  };

  // handleStartTime = checked => {
  //   this.setState({ search_start_time: checked }, () => {
  //     console.log(this.state.search_start_time);
  //     // this.getAttendanceList();
  //   });
  // };

  // handleEndTime = checked => {
  //   this.setState({ search_end_time: checked }, () => {
  //     console.log(this.state.search_end_time);
  //     // this.getAttendanceList();
  //   });
  // };

  setFromMonthly = (value, dateString) => {
    this.setState(
      {
        from_monthly: moment(value).format("x") / 1000,
        from_monthly_view: value,
        // to_monthly:
        //   moment(value)
        //     .add(30, "d")
        //     .format("x") / 1000
      },
      () => {
        console.log("Selected Time: ", this.state.from_monthly);
      }
    );
  };

  setToMonthly = (value, dateString) => {
    this.setState(
      {
        to_monthly: moment(value).format("x") / 1000,
        to_monthly_view: value,
        // to_monthly:
        //   moment(value)
        //     .add(30, "d")
        //     .format("x") / 1000
      },
      () => {
        console.log("Selected Time: ", this.state.to_monthly);
      }
    );
  };

  setStartTime = (value, dateString) => {
    this.setState(
      { startTimeStamp: moment(value).format("x"), start_date_view: value },
      () => {
        console.log("Selected Time: ", this.state.startTimeStamp);
        this.getAttendanceList();
        // let date = new Date();
        // let time = date.getTime();
        // console.log(moment(time));
        // GET_ACCESS_TOKEN().then(token => {
        //   const url = `${CREATE_ATTENDANCE}?page=${this.state.page}&limit=${this.state.limit}&ts=${time}&search_end_time=${this.state.search_end_time}&search_start_time=${this.state.search_start_time}&start_time=${this.state.startTimeStamp}&end_time=${this.state.endTimeStamp}&page=${this.state.page}&access_token=${token}`;
        //   axios({
        //     method: "get",
        //     url: url
        //   })
        //     .then(response => {
        //       console.log("response from attendance list", response);
        //       this.setState({
        //         data:
        //           this.state.page === 0
        //             ? response.data.data
        //             : [...this.state.data, ...response.data.data],
        //         total: response.data.total
        //       });
        //     })
        //     .catch(error => {
        //       console.log("error from attendance list api", error);
        //     });
        // });
      }
    );
  };

  setEndTime = (value, dateString) => {
    this.setState(
      {
        endTimeStamp: moment(value).endOf("day").format("x"),
        end_date_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.endTimeStamp);
        this.getAttendanceList();
        // let date = new Date();
        // let time = date.getTime();
        // console.log(moment(time));
        // GET_ACCESS_TOKEN().then(token => {
        //   const url = `${CREATE_ATTENDANCE}?page=${this.state.page}&limit=${this.state.limit}&ts=${time}&search_end_time=${this.state.search_end_time}&search_start_time=${this.state.search_start_time}&start_time=${this.state.startTimeStamp}&end_time=${this.state.endTimeStamp}&page=${this.state.page}&access_token=${token}`;
        //   axios({
        //     method: "get",
        //     url: url
        //   })
        //     .then(response => {
        //       console.log("response from attendance list", response);
        //       this.setState({
        //         data:
        //           this.state.page === 0
        //             ? response.data.data
        //             : [...this.state.data, ...response.data.data],
        //         total: response.data.total
        //       });
        //     })
        //     .catch(error => {
        //       console.log("error from attendance list api", error);
        //     });
        // });
      }
    );
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.attendance.attendance
                    .attendance_create_for ? (
          <Link to="/createAttendance">
            <Tooltip placement="top" title="Create Attendance">
              <Button
                shape="circle"
                icon="plus"
                className="tooltip-button"
              />
            </Tooltip>
          </Link>
        ) : null}
         <PageHeader
          title="Attendance List"
        />

        {/* <Card style={{ marginTop: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}
          >
            <div style={{ margin: 10 }}>
              <Text> Timezone : {""}</Text>
              <Select
                value={this.state.timezone_choice}
                onChange={this.setTimezonetype}
                placeholder="View as"
              >
                <Option key="user_timezone">User</Option>
                <Option key="org_timezone">Organization</Option>
              </Select>
            </div>
            <div style={{ margin: 10 }}>
              {this.state.attendanceChoice === "absent" ? (
                <Button
                  onClick={this.handleSaveFileAbsent}
                  style={{
                    width: 200,
                    backgroundColor: "#1A3337",
                    color: "white"
                  }}
                >
                  Download Absent Report
                </Button>
              ) : (
                <Button
                  onClick={this.handleSaveFile}
                  style={{
                    width: 200,
                    backgroundColor: "#1A3337",
                    color: "white"
                  }}
                >
                  Download Report
                </Button>
              )}
            </div>
          </div>
        </Card> */}

        {this.props.userProfileData.report.attendance_report
          .attendance_organizational_report ||
        this.props.userProfileData.report.attendance_report
          .attendance_organizational_report_with_custom_fields ||
        GET_USER_LOCAL().org_admin ||
        this.props.userProfileData.report.attendance_report
          .single_person_attendance_report ? (
          <Card style={{ marginTop: 5 }}>
            <Text style={{ fontWeight: "bolder", fontSize: 20 }}>
              Report Options (Please select date range between any 31 days){" "}
            </Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  margin: 5,
                  display: "flex",
                  flexDirection: "column",
                  width: "220px",
                }}
              >
                <Text> Report For: {""}</Text>
                <Select
                  value={this.state.report_for_option.toString()}
                  onChange={this.setReportForOptions}
                  placeholder="Report For"
                >
                  <Option key={1} value="report-for-all">
                    Report for All
                  </Option>
                  <Option key={2} value="custom-field-report">
                    Report with Custom Fields
                  </Option>
                  <Option key={3} value="single-report">
                    Single Report
                  </Option>
                </Select>
              </div>

              {/*Options for any selected report*/}
              {(this.props.userProfileData.report.attendance_report
                .attendance_organizational_report &&
                this.state.report_for_option === "report-for-all") ||
              (this.props.userProfileData.report.attendance_report
                .attendance_organizational_report_with_custom_fields &&
                this.state.report_for_option === "custom-field-report") ||
              ((GET_USER_LOCAL().org_admin ||
                this.props.userProfileData.report.attendance_report
                  .single_person_attendance_report) &&
                this.state.report_for_option === "single-report") ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: 5,
                      minWidth: 200,
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.from_monthly_view}
                        onChange={this.setFromMonthly}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <Text style={{ paddingTop: 20 }}>---</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: 5,
                      minWidth: 200,
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.to_monthly_view}
                        onChange={this.setToMonthly}
                        disableFuture={true}
                        minDate={this.state.from_monthly_view}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div
                    style={{
                      margin: 5,
                      display: "flex",
                      flexDirection: "column",
                      width: "150px",
                    }}
                  >
                    <Text> Report Type: {""}</Text>
                    <Select
                      value={this.state.options.toString()}
                      onChange={this.setOptions}
                      placeholder="Report Type"
                    >
                      <Option key={1}>Detailed</Option>
                      <Option key={2}>Present/Absent</Option>
                    </Select>
                  </div>
                </>
              ) : null}

              {/* Show Timezone option for monthly and custom field report */}
              {((this.props.userProfileData.report.attendance_report
                .attendance_organizational_report &&
                this.state.report_for_option === "report-for-all") ||
                (this.props.userProfileData.report.attendance_report
                  .attendance_organizational_report_with_custom_fields &&
                  this.state.report_for_option === "custom-field-report")) && (
                <div
                  style={{
                    margin: 5,
                    display: "flex",
                    flexDirection: "column",
                    width: "150px",
                  }}
                >
                  <Text> Timezone: {""}</Text>
                  <Select
                    value={this.state.timezone_option.toString()}
                    onChange={this.setTimezoneOptions}
                    placeholder="Timezone"
                  >
                    <Option key={1}>Organization</Option>
                    <Option key={2}>User</Option>
                  </Select>
                </div>
              )}

              {/* Show Download Monthly Report button for monthy report */}
              {this.props.userProfileData.report.attendance_report
                .attendance_organizational_report &&
                this.state.report_for_option === "report-for-all" && (
                  <Button
                    onClick={this.downloadReport}
                    loading={this.state.loading_detailed_report}
                    style={{
                      width: "auto",
                      backgroundColor: "#1A3337",
                      color: "white",
                      marginTop: 25,
                    }}
                  >
                    Download Report for All
                  </Button>
                )}

              {/* Show Download Report with Custom Fields button for custom report */}
              {this.props.userProfileData.report.attendance_report
                .attendance_organizational_report_with_custom_fields &&
                this.state.report_for_option === "custom-field-report" && (
                  <Button
                    onClick={this.downloadReportWithCustomFields}
                    loading={this.state.loading_custom_report}
                    style={{
                      width: "auto",
                      backgroundColor: "#1A3337",
                      color: "white",
                      marginTop: 25,
                    }}
                  >
                    Download Report with Custom Fields
                  </Button>
                )}
              {/* Show Search User and Download single Report button for single report */}
              {(GET_USER_LOCAL().org_admin ||
                this.props.userProfileData.report.attendance_report
                  .single_person_attendance_report) &&
                this.state.report_for_option === "single-report" && (
                  <>
                    <div
                      style={{
                        margin: 5,
                        display: "flex",
                        flexDirection: "column",
                        width: "200px",
                      }}
                    >
                      <Text>Search User:</Text>
                      <Select
                        showSearch={true}
                        filterOption={false}
                        showArrow={false}
                        autoClearSearchValue={false}
                        onSearch={this.handleSearch}
                        style={{ width: "100%" }}
                        value={this.state.user_id}
                        onChange={this.setAssignedUser}
                        placeholder="Select User"
                        required
                      >
                        {this.state.userData.map((r) => {
                          return (
                            <Option
                              key={r._id.$oid}
                            >{`${r.name}>${r.mobile}(${r.organization_status})`}</Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Button
                        onClick={this.downloadSingleReport}
                        loading={this.state.loading_single_report}
                        style={{
                          width: "auto",
                          backgroundColor: "#1A3337",
                          color: "white",
                          marginTop: 25,
                        }}
                      >
                        Download Single Report
                      </Button>
                    </div>
                  </>
                )}
            </div>
          </Card>
        ) : null}

        {/*Filter Options*/}
        <Card style={{ marginTop: 5 }}>
          <Text style={{ fontWeight: "bolder", fontSize: 20 }}>
            Filter Options{" "}
          </Text>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            {/* <div style={{ margin: 5 }}>
              <Switch
                checkedChildren="Search Start Time"
                unCheckedChildren="Search Start Time"
                onChange={this.handleStartTime}
                defaultChecked={this.state.search_start_time}
              />
            </div> */}
            {this.state.search_start_time ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    value={this.state.start_date_view}
                    onChange={this.setStartTime}
                  />
                </MuiPickersUtilsProvider>
              </div>
            ) : null}
            {/* <div style={{ margin: 5 }}>
              <Switch
                checkedChildren="Search End Time"
                unCheckedChildren="Search End Time"
                onChange={this.handleEndTime}
                defaultChecked={this.state.search_end_time}
              />
            </div> */}
            <Text style={{ paddingTop: 20 }}>---</Text>
            {this.state.search_end_time ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    value={this.state.end_date_view}
                    onChange={this.setEndTime}
                  />
                </MuiPickersUtilsProvider>
              </div>
            ) : null}
            {GET_USER_LOCAL().org_admin ? (
              <div
                style={{ margin: 5, display: "flex", flexDirection: "column" }}
              >
                <Text> View Users : {""}</Text>
                <Select
                  value={this.state.attendanceChoice}
                  onChange={this.setAttendancetype}
                  placeholder="View Users "
                >
                  <Option key="checkedin">Checked In</Option>
                  <Option key="absent">Absent</Option>
                </Select>
              </div>
            ) : null}
            {GET_USER_LOCAL().org_admin ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: 5,
                  width: "200px",
                }}
              >
                <Text>Search User :</Text>
                <Select
                  labelInValue
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearch}
                  style={{ width: "100%" }}
                  value={{
                    key: this.state.assigned_to,
                    label: this.state.assigned_to_label,
                  }}
                  onChange={this.setAssigned}
                  placeholder="Select User"
                >
                  {this.state.userData.map((r) => {
                    return (
                      <Option
                        key={r._id.$oid}
                      >{`${r.name}>${r.mobile}(${r.organization_status})`}</Option>
                    );
                  })}
                </Select>
              </div>
            ) : null}
          </div>
        </Card>
        {this.props.userProfileData.data_level.access_suborg_data ||
        this.props.userProfileData.data_level.access_all_data ? (
          <Card style={{ marginTop: 5 }}>
            <Title level={4}>Filter Attendance With Sub Organization</Title>

            <div
              style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
            >
              <div
                style={{ margin: 10, display: "flex", flexDirection: "column" }}
              >
                <Text> User Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.current_user_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.current_user_sub_org_id}
                  onChange={this.setCurrentUserSubOrg}
                >
                  {this.state.current_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
              <div
                style={{ margin: 10, display: "flex", flexDirection: "column" }}
              >
                <Text> Child Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.first_child_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.first_child_sub_org_id}
                  onChange={this.setFirstChildSubOrg}
                >
                  {this.state.first_child_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
            </div>
          </Card>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
        </div>
        {this.state.attendanceChoice === "checkedin" ? (
          <Table
            style={{ marginTop: 5 }}
            dataSource={this.state.data}
            pagination={false}
            rowKey={(record) => record._id.$oid}
            size="small"
            bordered
          >
            {/* <Column
            title="Attendance"
            key="attendance"
            render={(text, record) => (
              <span>
                {record.attendance !== null ? record.attendance.toString() : ""}
              </span>
            )}
          /> */}

            <Column
              title={<><Text ellipsis={true}>Checkin Time</Text></>}
              key="checkin_time.$date"
              render={(text, record) => (
                <span>
                  {record.checkin_time !== null
                    ? timeConverter(record.checkin_time.$date)
                    : ""}
                </span>
              )}
            />
            <Column
              title={<><Text ellipsis={true}>Checkin Image</Text></>}
              key="checkin_image_path"
              render={(text, record) => (
                <span>
                  {record.checkin_image_path !== "" ? (
                    <img
                      alt=""
                      src={`${process.env.REACT_APP_BASE_PATH}${record.checkin_image_path}`}
                      width="300"
                      height="300"
                    />
                  ) : (
                    ""
                  )}
                </span>
              )}
            />
            <Column
              title={<><Text ellipsis={true}>Checkout Time</Text></>}
              key="checkout_time.$date"
              render={(text, record) => (
                <span>
                  {record.checkout_time !== null
                    ? timeConverter(record.checkout_time.$date)
                    : ""}
                </span>
              )}
            />

            <Column
              title={<><Text ellipsis={true}>Checkout Image</Text></>}
              key="checkout_image_path"
              render={(text, record) => (
                <span>
                  {record.checkout_image_path !== "" ? (
                    <img
                      alt=""
                      src={`${process.env.REACT_APP_BASE_PATH}${record.checkout_image_path}`}
                      width="300"
                      height="300"
                    />
                  ) : (
                    ""
                  )}
                </span>
              )}
            />

            <Column
              title={<><Text ellipsis={true}>Created By</Text></>}
              key="created_by_name"
              render={(text, record) => (
                <span>
                  {record.created_by_name !== null
                    ? record.created_by_name
                    : ""}
                </span>
              )}
            />
            <Column
              title={<><Text ellipsis={true}>Created For</Text></>}
              key="created_for"
              render={(text, record) => (
                <span>
                  {record.created_for.name !== null
                    ? record.created_for.name
                    : ""}
                </span>
              )}
            />

            <Column
              title={<><Text ellipsis={true}>Created On</Text></>}
              key="created_by"
              render={(text, record) => (
                <span>{timeConverter(record.created_on.$date)}</span>
              )}
            />

            {/* <Column
              title="Modified By"
              key="modified_by_name"
              render={(text, record) => (
                <span>
                  {record.modified_by_name !== null
                    ? record.modified_by_name
                    : ""}
                </span>
              )}
            />

            <Column
              title="Modified On"
              key="modified_by"
              render={(text, record) => (
                <span>{timeConverter(record.modified_on.$date)}</span>
              )}
            /> */}
            <Column
              title={<><Text ellipsis={true}>Checkin Location</Text></>}
              key="Checkin Location"
              render={(text, record) => <span>{record.checkin_loc_name}</span>}
            />
            <Column
              title={<><Text ellipsis={true}>Checkout Location</Text></>}
              key="Checkout Location"
              render={(text, record) => <span>{record.checkout_loc_name}</span>}
            />
            <Column
              title={<><Text ellipsis={true}>Checkin Place Address</Text></>}
              key="Checkin Place Address"
              render={(text, record) => (
                <span>{record.entity_confirm.checkin_loc_name}</span>
              )}
            />
            <Column
              title={<><Text ellipsis={true}>Checkout Place Address</Text></>}
              key="Checkout Place Address"
              render={(text, record) => (
                <span>{record.entity_confirm.checkout_loc_name}</span>
              )}
            />
            <Column
              title={<><Text ellipsis={true}>Parent Sub Org Name</Text></>}
              key="Parent Sub Org Name"
              render={(text, record) => (
                <span>{record.sub_org?.parent_sub_org?.name ?? ""}</span>
              )}
            />
            <Column
              title={<><Text ellipsis={true}>Sub Org Name</Text></>}
              key="Sub Org Name"
              render={(text, record) => (
                <span>{record.sub_org?.name ?? ""}</span>
              )}
            />
            <Column
              title={<><Text ellipsis={true}>Sub Org Type</Text></>}
              key="Sub Org Type"
              render={(text, record) => (
                <span>{record.sub_org?.sub_org_type_data?.name ?? ""}</span>
              )}
            />

            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <span style={{ display: "flex", flexDirection: "column" }}>
                  {GET_USER_LOCAL().org_admin ||
                  GET_USER_LOCAL()._id.$oid === record?.created_by ||
                  this.props.userProfileData.attendance.attendance
                    .attendance_view_route ? (
                    <Link
                      to={{
                        pathname: "/userRoute",
                        state: {
                          search_user:
                            record?.created_for?.id ?? record?.created_by,
                          start_time: record?.checkin_time?.$date,
                          end_time: record?.checkout_time?.$date,
                          user_name:
                            record?.created_for?.name ??
                            record?.created_by_name,
                        },
                      }}
                    >
                      <Button type="link" style={{ color: "blue" }}>
                        View Route
                      </Button>
                    </Link>
                  ) : null}
                  {/* <Divider type="vertical" /> */}
                  {this.props.userProfileData.attendance.attendance
                    .attendance_detail ? (
                    <Link
                      to={{
                        pathname: "/viewAttendance",
                        state: { attendance_id: record._id.$oid },
                      }}
                    >
                      <Button type="link" style={{ color: "green" }}>
                        View
                      </Button>
                    </Link>
                  ) : null}

                  {GET_USER_LOCAL().org_admin ||
                  GET_USER_LOCAL().is_operator ? (
                    <Link
                      to={{
                        pathname: "/editAttendance",
                        state: { attendance_id: record._id.$oid },
                      }}
                    >
                      <Button type="link" style={{ color: "green" }}>
                        Edit
                      </Button>
                    </Link>
                  ) : null}
                  {/* <Divider type="vertical" /> */}
                  {GET_USER_LOCAL().org_admin ||
                  this.props.userProfileData.attendance.attendance
                    .attendance_delete ? (
                    <Button
                      type="link"
                      style={{ color: "red" }}
                      onClick={() => {
                        if (window.confirm("Delete the item?")) {
                          this.deleteAttendance(record._id.$oid);
                        }
                      }}
                    >
                      Delete
                    </Button>
                  ) : null}
                </span>
              )}
            />
          </Table>
        ) : (
          <Table
            style={{ marginTop: 5 }}
            dataSource={this.state.org_absent_users}
            pagination={false}
            rowKey={(record) => record._id.$oid}
            size="small"
            bordered
          >
            {/* <Column
            title="Attendance"
            key="attendance"
            render={(text, record) => (
              <span>
                {record.attendance !== null ? record.attendance.toString() : ""}
              </span>
            )}
          /> */}

            <Column
              title="Name"
              key="name"
              render={(text, record) => <span>{record.name}</span>}
            />
            <Column
              title="Mobile"
              key="mobile"
              render={(text, record) => <span>{record.mobile}</span>}
            />
          </Table>
        )}

        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(AttendanceList);
