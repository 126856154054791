import React, { Component } from "react";
import { Card, Typography, PageHeader, Button } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { EDIT_PARCEL, PARCEL_TAG_PRINT } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";


import "../login.css";

const { Text } = Typography;

export default class ParcelDetails extends Component {
  state = {
    name: "",
    orders: [],
    order_id: "",
    items: [],
    loc_lat: 23.685,
    loc_lon: 90.3563,
    pl: null,
    pw: null,
    ph: null,
    unit_dim: null,
    unit_weight: null,
    parcel_weight: null,
    max_weight: null,
    fragile: false,
    sensitive: false,
    fast: false,
    temperature: null,
    containers: [],
    container: "",
    device_id: "",
    connect_key: "",
    parcel_id: "",
    created_on: "",
    loading: false,
    delivered: false,
    pin: "",
    org_id: ""
  };

  componentDidMount() {
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    this.setState({ org_id: GET_USER_LOCAL().organization.$oid });
    this.getParcelDetails();
  }

  getParcelDetails = () => {
    this.setState({ parcel_id: this.props.location.state.parcel_id });

    GET_ACCESS_TOKEN().then(token => {
      let parcel_id = this.props.location.state.parcel_id;
      let url = `${EDIT_PARCEL}${parcel_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro parcel view", response.data.data);
        this.setState({
          name: response.data.data.name,
          order_id: response.data.data.order_id,
          items: response.data.data.items,
          pl: response.data.data.pl,
          pw: response.data.data.pw,
          ph: response.data.data.ph,
          unit_dim: response.data.data.unit_dim,
          unit_weight: response.data.data.unit_weight,
          parcel_weight: response.data.data.parcel_weight,
          max_weight: response.data.data.max_weight,
          fragile: response.data.data.fragile,
          sensitive: response.data.data.temperature.sensitive,
          fast: response.data.data.temperature.fast,
          temperature: response.data.data.temperature.temperature,
          container: response.data.data.container,
          created_on: response.data.data.created_on.$date,
          delivered: response.data.data.delivered,
          pin: response.data.data.pin
        });
      });
    });
  };

  parcelTagprint = (item_id, item) => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${PARCEL_TAG_PRINT}?parcel_id=${this.state.parcel_id}&org_id=${this.state.org_id}&access_token=${token}`;
      axios
        .get(url)
        .then(response => {
          console.log("response from parcel print list fmt", response);
          if (response.data.status === "success") {
            window
              .open(
                `${process.env.REACT_APP_BASE_PATH}${response.data.data.parcel_tag_pdf_file_path}`,
                "_blank"
              )
              .focus();
          } else {
            swal.fire("Info", response.data.message, "info");
          }
        })
        .catch(error => {
          console.log("error from parcel print list fmt", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title="View Parcel"
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Card className="view">
            <p >
              <Text style={{ fontWeight: "bold" }}>Parcel Name</Text>:{" "}
              {this.state.name}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Order ID</Text>:{" "}
              {this.state.order_id}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Parcel Width</Text>:{" "}
              {this.state.pw} Feet
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Parcel Height</Text>:{" "}
              {this.state.ph} Feet
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Parcel Length</Text>:{" "}
              {this.state.pl} Feet
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Parcel Weight</Text>:{" "}
              {this.state.parcel_weight} KG
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Max Weight</Text>:{" "}
              {this.state.max_weight} KG
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Parcel PIN</Text>:{" "}
              {this.state.pin}
            </p>
          </Card>
          <Card className="view">
            <p >
              <Text style={{ fontWeight: "bold" }}>Unit Dimension</Text>:{" "}
              {this.state.unit_dim}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Unit Weight</Text>:{" "}
              {this.state.unit_weight}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Fragile</Text>:{" "}
              {this.state.fragile.toString()}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Sensitive</Text>:{" "}
              {this.state.sensitive.toString()}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Fast</Text>:{" "}
              {this.state.fast.toString()}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Delivered</Text>:{" "}
              {this.state.delivered.toString()}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Container</Text>:{" "}
              {this.state.container}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Created On</Text>:{" "}
              {timeConverter(this.state.created_on)}
            </p>
          </Card>
        </div>
        <Button
          onClick={this.parcelTagprint}
          className="login-form-button"
          style={{ marginTop: 10 }}
        >
          Print Tag
        </Button>
      </Card>
    );
  }
}
