import "date-fns";
import React, { Component } from "react";
// import io from "socket.io-client";
import { debounce } from "lodash";
import {
  Select,
  Card,
  Spin,
  Input,
  Typography,
  Button,
  Tooltip,
  Icon,
  Switch,
  PageHeader,
  Divider,
  Avatar,
  Modal,
  // Form,
  // Table,
  List,
} from "antd";
import { Link } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Redirect } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import FileSaver from "file-saver";

import {
  // GEO_CODING_API,
  // REVERSE_GEO,
  CREATE_DRIVER,
  ASSET_SEARCH,
  EDIT_TASK,
  TASK_ROUTE,
  ASSIGNED_USER_LOC,
  // POST_TASK_COMMENTS,
  TRANSPORT_BILL,
  TRANSPORT_TYPE,
  CREATE_ROUTE,
  CREATE_ENTITY,
  CREATE_TASK_TYPE,
  GET_ASSIGNED_USER_SUB_ORG_LIST,
  LOCATION_SEARCH_GOOGLE,
  REVERSE_LOCATION_SEARCH_GOOGLE,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  LOCAL_USER_INFO,
  // timeConverter,
  GET_USER_LOCAL,
} from "../../utilities/utilities";
import { Spinner } from "../loader";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;
// const { Column } = Table;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40],
});
const red = new L.Icon({
  iconUrl: require("../.././assets/mymarkertwo.png"),
  iconSize: [30, 40],
});
const user = new L.Icon({
  iconUrl: require("../.././assets/user.png"),
  iconSize: [25, 40],
});
let socket;
export default class EditTask extends Component {
  state = {
    query: "",
    start_location: "",
    end_location: "",
    value: [],
    value1: [],
    data: [],
    fetch: false,
    center_lat: 23.685,
    center_lon: 90.3563,
    start_location_lat: 23.685,
    start_location_lon: 90.3563,
    end_location_lat: 23.685,
    end_location_lon: 90.3563,
    name: "",
    details: "",
    start_time: "",
    start_time_view: moment(),
    end_time: "",
    end_time_view: moment(),
    task_code: "",
    showContactDropdown: true,
    contact_id: "",
    contact_no: "",
    contact_name: "",
    contact_info: "",
    showDropcDropdown: true,
    drop_contact_id: "",
    drop_contact_no: "",
    drop_contact_name: "",
    drop_contact_info: "",
    price: "",
    assigned_to: "",
    assigned_to_label: "",
    userData: [],
    assigned_asset: "",
    assigned_asset_label: "",
    assetData: [],
    delay_mins: null,
    colors: [],
    colorId: [],
    trip_duration: 0,
    accepted: false,
    started: false,
    completed: false,
    cancelled: false,
    is_public: false,
    loading: false,
    isChainTask: false,
    delivery_time: "",
    delivery_time_view: moment(),
    delivery_time_by_customer: "",
    delivery_time_by_customer_view: moment(),
    delay_reason: "",
    secondaryUsers: [],

    prev_task_id: "",
    m_task_id: "",
    start_dist: null,
    end_dist: null,
    comments: "",
    task_id: "",
    showInputLocStart: false,
    showInputLocEnd: false,
    disable: false,
    disableState: false,
    currentUserid: "",
    editStart: false,
    editEnd: false,
    checkAssign: "",
    checkPublic: false,
    checkStarted: false,
    checkAccepted: false,
    checkCompleted: false,
    polyData: [],
    assignedUserLat: "",
    assignedUserLon: "",
    userLocLon: 0,
    userLocLat: 0,
    commentsArray: [],
    task_link: "",
    zoom: 8,
    pickLink: false,
    dropLink: false,
    chainRedirect: false,
    commentSocket: [],
    typingName: "",
    filtered: [],
    task_status: "",
    transport_bill_modal: false,
    transport_type: "",
    transport_name: "",
    transport_fare: null,
    transport_bill_data: [],
    total_transport_bill: null,
    transport_options: [],
    transport_bill_edit: false,
    transport_bill_id: "",
    savedRoutes: [],
    saveStartModal: false,
    timezone_name: "",
    timezone_choice: "org_timezone",
    entityList: [],
    startEntityModal: false,
    endEntityModal: false,
    taskTypes: [],
    task_type: "",
    assigned_user_sub_orgs: [],
    sub_org_id: "",
    sub_org_name: "",
    vehicle_load: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ timezone_name: timezone });
    console.log("time zone", timezone);

    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState({
        userLocLat: latitude,
        userLocLon: longitude,
      });
    };
    navigator.geolocation.getCurrentPosition(success);

    this.setState(
      {
        loading: true,
        task_id: this.props.location.state.task_id,
        checkAssign: this.props.location.state.assigned_to,
        checkPublic: this.props.location.state.is_public,
        checkStarted: this.props.location.state.is_started,
        checkAccepted: this.props.location.state.is_accepted,
        checkCompleted: this.props.location.state.is_completed,
        currentUserid: LOCAL_USER_INFO().user._id,
      },
      () => {
        if (this.state.checkStarted === true) {
          this.getTaskRoute();
          this.getTransportBill();
          this.getTransportType();
        } else if (this.state.checkCompleted === true) {
          swal.fire("Task Status", "COMPLETED", "info");
          this.getTaskRoute();
          this.getTransportBill();
          this.getTransportType();
        } else if (this.state.checkAccepted) {
          this.getTransportBill();
        }
      }
    );
    if (localStorage.getItem("pastTaskId")) {
      this.setState(
        { prev_task_id: localStorage.getItem("pastTaskId") },
        () => {
          console.log(this.state.prev_task_id);
        }
      );
    }

    // this.getComments();
    this.getTask();
    this.getUserList();
    this.getAssetList();
    // this.socketConfig();
    this.getRouteList();
    this.getEntityList();
    this.getTaskType();
  }

  getTaskType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_TASK_TYPE}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from task type list", response);

          this.setState({
            taskTypes: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from task type list api", error);
        });
    });
  };

  getEntityList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?limit=1000&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from entity list", response);
          this.setState({
            entityList: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  };

  getRouteList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ROUTE}?limit=1000&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from route list", response);
          this.setState({
            savedRoutes: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from route list api", error);
        });
    });
  };

  getTransportBill = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${TRANSPORT_BILL}${this.state.task_id}?access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            transport_bill_data: response.data.data.bills.transport_bills,
            total_transport_bill: response.data.data.bills.total_bill,
          });
          console.log("response from get trasnport bills", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from get trasnport bills", error);
        });
    });
  };

  getTransportType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${TRANSPORT_TYPE}?access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            transport_options: response.data.bill_type_choices,
          });
          console.log("response from get trasnport type", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from get trasnport type", error);
        });
    });
  };

  // socketConfig = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     let token_type = "Bearer";
  //     let access_token = token;
  //     let expires_in = 3600;
  //     let refresh_token = LOCAL_USER_INFO().refresh_token;
  //     let url = `https://api.conveyance.app/task-comments?token_type=${token_type}&access_token=${access_token}&expires_in=${expires_in}&refresh_token=${refresh_token}`;
  //     // let url = `http://dsdev.findergpstracking.com:5000/`;
  //     socket = io(url);
  //     socket.emit("subscribe", { task_id: this.props.location.state.task_id });
  //     socket.on("commented", data => {
  //       console.log("comment data", data);
  //       this.setState({
  //         commentsArray: [...this.state.commentsArray, ...data]
  //       });
  //     });
  //     socket.on("typed", data => {
  //       this.setState({ typingName: data.name }, () => {
  //         setTimeout(() => {
  //           this.setState({ typingName: "" });
  //         }, 1000);
  //       });
  //     });
  //   });
  // };

  // getComments = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     let url = `${POST_TASK_COMMENTS}${this.state.task_id}?access_token=${token}`;
  //     axios({
  //       method: "GET",
  //       url: url,
  //       headers: {
  //         "content-type": "application/x-www-form-urlencoded"
  //       }
  //     })
  //       .then(response => {
  //         this.setState({ commentsArray: response.data.data.comments });
  //         console.log("response from get comments", response);
  //       })
  //       .catch(error => {
  //         this.setState({ loading: false });
  //         swal.fire("Error", error.message, "error");
  //         console.log("error from get comments", error);
  //       });
  //   });
  // };
  // postComment = (name, comment) => {
  //   if (this.state.comments !== "") {
  //     const data = {
  //       task_id: this.state.task_id,
  //       comment: this.state.comments,
  //       comment_loc: {
  //         coordinates: [this.state.userLocLon, this.state.userLocLat]
  //       }
  //     };
  //     let comentData = [
  //       {
  //         task_id: this.state.task_id,
  //         commented_by_name: name,
  //         comment: comment,
  //         commented_on: {
  //           $date: Date.now()
  //         },
  //         _id: {
  //           $oid: Math.floor(Math.random() * 578649)
  //         }
  //       }
  //     ];

  //     socket.emit("comment", comentData);
  //     GET_ACCESS_TOKEN().then(token => {
  //       let url = `${POST_TASK_COMMENTS}${this.state.task_id}?access_token=${token}`;
  //       axios({
  //         method: "put",
  //         url: url,
  //         data: data,
  //         headers: {
  //           "content-type": "application/x-www-form-urlencoded"
  //         }
  //       })
  //         .then(response => {
  //           this.getComments();
  //           this.setState({ comments: "" });
  //           console.log("response from post comments", response);
  //         })
  //         .catch(error => {
  //           this.setState({ loading: false });
  //           swal.fire("Error", error.message, "error");
  //           console.log("error from post comments", error);
  //         });
  //     });
  //   }
  // };

  getTaskRoute = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${TASK_ROUTE}${this.state.task_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
        body: { is_public: this.state.checkPublic },
      }).then((response) => {
        console.log("response from task route", response.data.route_data);
        this.setState({ polyData: response.data?.route_data ?? [] });
      });
    });
  };

  getTask = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let task_id = this.props.location.state.task_id;
      let url = `${EDIT_TASK}${task_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response from edit task", response);
        this.setState({ loading: false });

        if (response.data.message === "Task not found") {
          swal.fire("Info", "Task Not Found", "info");
          this.props.history.goBack();
        } else {
          if (response.data.data.created_by !== this.state.currentUserid) {
            this.setState({ disable: true }, () => {
              console.log("disable value", this.state.disable);
            });
          }
          this.setState(
            {
              name: response?.data?.data?.name ?? "",
              task_type: response?.data?.data?.task_type ?? "",
              details: response?.data?.data?.details ?? "",
              delay_reason: response?.data?.data?.delay_reason ?? "",
              start_location: response?.data?.data?.start_loc_name || "",
              end_location: response?.data?.data?.end_loc_name || "",
              start_location_lat: response.data.data.start_loc.coordinates
                ? response.data.data.start_loc.coordinates[1]
                : 23.685,
              start_location_lon: response.data.data.start_loc.coordinates
                ? response.data.data.start_loc.coordinates[0]
                : 90.3563,
              center_lat: response.data.data.start_loc.coordinates
                ? response.data.data.start_loc.coordinates[1]
                : 23.685,
              center_lon: response.data.data.start_loc.coordinates
                ? response.data.data.start_loc.coordinates[0]
                : 90.3563,
              end_location_lat: response.data.data.end_loc.coordinates
                ? response.data.data.end_loc.coordinates[1]
                : 23.685,
              end_location_lon: response.data.data.end_loc.coordinates
                ? response.data.data.end_loc.coordinates[0]
                : 90.3563,
              start_time: response?.data?.data?.start_time?.$date ?? Date.now(),
              start_time_view:
                response?.data?.data?.start_time?.$date ?? moment(),
              end_time_view: response?.data?.data?.end_time?.$date ?? moment(),
              end_time: response?.data?.data?.end_time?.$date ?? Date.now(),
              delivery_time_view: response.data.data.delivery_time
                ? response.data.data.delivery_time.$date
                : moment(),
              delivery_time: response.data.data.delivery_time
                ? response.data.data.delivery_time.$date
                : "",
              delivery_time_by_customer_view: response.data.data
                .delivery_time_by_customer
                ? response.data.data.delivery_time_by_customer.$date
                : moment(),
              delivery_time_by_customer: response.data.data
                .delivery_time_by_customer
                ? response.data.data.delivery_time_by_customer.$date
                : "",
              task_code: response?.data?.data?.task_code ?? "",
              contact_id: response?.data?.data?.contact_id ?? "",
              contact_no: response?.data?.data?.contact_no ?? "",
              contact_name: response?.data?.data?.contact_name ?? "",
              drop_contact_id: response?.data?.data?.drop_contact_id ?? "",
              drop_contact_no: response?.data?.data?.drop_contact_no ?? "",
              drop_contact_name: response?.data?.data?.drop_contact_name ?? "",
              price: response?.data?.data?.price ?? "",
              assigned_to: response?.data?.data?.assigned_to ?? "",
              assigned_to_label: response?.data?.data?.assigned_to_name ?? "",
              sub_org_id: response?.data?.data?.sub_org?.id ?? "",
              sub_org_name: response?.data?.data?.sub_org?.name ?? "",
              assigned_asset: response?.data?.data?.assigned_asset ?? "",
              delay_mins: response?.data?.data?.delay_mins ?? "",
              colors: response?.data?.data?.colors ?? [],
              trip_duration: response?.data?.data?.trip_duration ?? "",
              is_public: response?.data?.data?.is_public ?? false,

              prev_task_id: response?.data?.data?.prev_task_id ?? "",
              m_task_id: response?.data?.data?.m_task_id ?? "",
              start_dist: response?.data?.data?.start_dist ?? "",
              end_dist: response?.data?.data?.end_dist ?? "",
              task_link: response?.data?.data?.task_link ?? "",
              task_status: response?.data?.data?.task_status,
              accepted: response?.data?.data?.accepted ?? false,
              started: response?.data?.data?.started ?? false,
              completed: response?.data?.data?.completed ?? false,
              cancelled: response?.data?.data?.cancelled ?? false,
              secondaryUsers: response.data?.data?.assign_connected_users ?? [],
              vehicle_load: response.data?.data?.vehicle_load ?? "",
            },
            () => {
              if (this.state.checkAssign !== "") {
                GET_ACCESS_TOKEN().then((token) => {
                  let url = `${ASSIGNED_USER_LOC}${this.state.assigned_to}?access_token=${token}`;
                  axios({
                    method: "get",
                    url: url,
                  }).then((response) => {
                    console.log("response from assigned user loc", response);
                    if (response.data.message === "Location not allowed") {
                      swal.fire(
                        "Warning",
                        "No location data found for assigned user !",
                        "warning"
                      );
                    } else {
                      this.setState({
                        assignedUserLat: response.data.data.latitude,
                        assignedUserLon: response.data.data.longitude,
                      });
                    }
                  });
                });
              }

              if (this.state.assigned_to !== LOCAL_USER_INFO().user._id) {
                this.setState({ disableState: true });
              }
            }
          );
        }
      });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 10;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  };

  getAssetList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${ASSET_SEARCH}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ assetData: response.data.data });
          console.log("response from asset list", response);
        })
        .catch((error) => {
          console.log("error from asset list api", error);
        });
    });
  };

  colors = [
    {
      id: 1,
      color: "#f44336",
    },
    {
      id: 2,
      color: "#E91E63",
    },
    {
      id: 3,
      color: "#9C27B0",
    },
    {
      id: 4,
      color: "#673AB7",
    },
    {
      id: 5,
      color: "#3F51B5",
    },
    {
      id: 6,
      color: "#2196F3",
    },
    {
      id: 7,
      color: "#009688",
    },
    {
      id: 8,
      color: "#4CAF50",
    },
    {
      id: 9,
      color: "#FFEB3B",
    },
    {
      id: 10,
      color: "#FF9800",
    },
  ];

  setTimezonetype = (value) => {
    this.setState({ timezone_choice: value }, () => {
      console.log(this.state.timezone_choice);
    });
  };

  setName = (evt) => {
    this.setState({ name: evt.target.value });
    console.log(this.state.name);
  };

  setTaskType = (value) => {
    this.setState({ task_type: value }, () => {
      console.log(this.state.task_type);
    });
  };

  setDetails = (evt) => {
    this.setState({ details: evt.target.value });
    console.log(this.state.details);
  };

  setDelayReason = (evt) => {
    this.setState({ delay_reason: evt.target.value });
    console.log(this.state.delay_reason);
  };

  setTaskCode = (evt) => {
    this.setState({ task_code: evt.target.value });
    console.log(this.state.task_code);
  };

  setContact = (value) => {
    let string = value.label.split(">");
    this.setState(
      {
        contact_id: value.key,
        contact_name: string[0],
        contact_no: string[1],
        filtered: [],
      },
      () => {
        console.log(this.state.contact_name);
        console.log(this.state.contact_no);
        console.log(this.state.contact_id);
      }
    );
  };

  setContactNumber = (evt) => {
    this.setState({ contact_no: evt.target.value });
    console.log(this.state.contact_no);
  };

  setContactName = (evt) => {
    this.setState({ contact_name: evt.target.value });
    console.log(this.state.contact_name);
  };

  setDropContact = (value) => {
    let string = value.label.split(">");
    this.setState(
      {
        drop_contact_id: value.key,
        drop_contact_name: string[0],
        drop_contact_no: string[1],
        filtered: [],
      },
      () => {
        console.log(this.state.drop_contact_name);
        console.log(this.state.drop_contact_no);
        console.log(this.state.drop_contact_id);
      }
    );
  };

  setDropContactNumber = (evt) => {
    this.setState({ drop_contact_no: evt.target.value });
    console.log(this.state.drop_contact_no);
  };

  setDropContactName = (evt) => {
    this.setState({ drop_contact_name: evt.target.value });
    console.log(this.state.drop_contact_name);
  };

  setPrice = (evt) => {
    this.setState({ price: evt.target.value });
    console.log(this.state.price);
  };

  setTripDuration = (evt) => {
    this.setState({ trip_duration: evt.target.value });
    console.log(this.state.trip_duration);
  };

  setDelayMinutes = (evt) => {
    this.setState({ delay_mins: evt.target.value });
    console.log(this.state.delay_mins);
  };

  setComments = (evt) => {
    socket.emit("typing", {
      name: LOCAL_USER_INFO().user.name,
      task_id: this.state.task_id,
    });
    this.setState({ comments: evt.target.value });
    console.log(this.state.comments);
  };

  setAssigned = (value) => {
    this.setState(
      { assigned_to_label: value.label, assigned_to: value.key, filtered: [] },
      () => {
        console.log(this.state.assigned_to);
        GET_ACCESS_TOKEN().then((token) => {
          const url = `${GET_ASSIGNED_USER_SUB_ORG_LIST}?assigned_user_id=${this.state.assigned_to}&access_token=${token}`;
          axios({
            method: "get",
            url: url,
          })
            .then((response) => {
              if (response.data.data.length === 1) {
                this.setState({
                  assigned_user_sub_orgs: response.data.data,
                  sub_org_id: response.data.data[0].id,
                  sub_org_name: response.data.data[0].name,
                });
              } else {
                this.setState({ assigned_user_sub_orgs: response.data.data });
              }
              console.log("response from assigned user sub org list", response);
            })
            .catch((error) => {
              console.log("error from assigned user sub org list api", error);
            });
        });
      }
    );
  };

  setSubOrg = (value) => {
    this.setState({ sub_org_id: value.key, sub_org_name: value.label }, () => {
      console.log("sub org value", this.state.sub_org_id);
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  }, 500);

  setAssignedAsset = (value) => {
    this.setState(
      { assigned_asset_label: value.label, assigned_asset: value.key },
      () => {
        console.log(this.state.assigned_asset_label);
      }
    );
  };

  handlePchange = (checked) => {
    this.setState({ is_public: checked }, () => {
      console.log(this.state.is_public);
    });
  };

  setChainTask = (checked) => {
    this.setState({ isChainTask: checked }, () => {
      console.log(this.state.isChainTask);
    });
  };

  setStartTime = (value, dateString) => {
    this.setState(
      {
        start_time: moment(value).format("x"),
        start_time_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.start_time);
        console.log("Selected value: ", this.state.start_time_view);
      }
    );
  };
  setEndTime = (value, dateString) => {
    this.setState(
      {
        end_time: moment(value).format("x"),
        end_time_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.end_time);
        console.log("Selected Value: ", this.state.end_time_view);
      }
    );
  };

  setDeliveryTime = (value, dateString) => {
    this.setState(
      { delivery_time: moment(value).format("x"), delivery_time_view: value },
      () => {
        console.log("Selected Time: ", this.state.delivery_time);
        console.log("Selected Time: ", this.state.delivery_time_view);
      }
    );
  };
  setDeliveryTimeCustomer = (value, dateString) => {
    this.setState(
      {
        delivery_time_by_customer: moment(value).format("x"),
        delivery_time_by_customer_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.delivery_time_by_customer);
        console.log(
          "Selected Time: ",
          this.state.delivery_time_by_customer_view
        );
      }
    );
  };

  setTransportType = (value) => {
    this.setState({ transport_type: value }, () => {
      console.log(this.state.transport_type);
    });
  };
  setTransportName = (evt) => {
    evt.preventDefault();
    this.setState({ transport_name: evt.target.value }, () => {
      console.log(this.state.transport_name);
    });
  };
  setTransportFare = (evt) => {
    evt.preventDefault();
    this.setState({ transport_fare: evt.target.value }, () => {
      console.log(this.state.transport_fare);
    });
  };

  handleStart = debounce((value) => {
    this.setState({ query: value, data: [], fetching: true }, () => {
      console.log(this.state.query);
      if (this.state.query !== "") {
        GET_ACCESS_TOKEN().then((token) => {
          axios
            .get(
              `${LOCATION_SEARCH_GOOGLE}?textsearch=${this.state.query}&access_token=${token}`
            )
            .then((response) => {
              console.log(response);
              const data = response.data.google_loc_datas.map((res) => ({
                id: `${res._id.$oid}>${res.loc.coordinates[0]}>${res.loc.coordinates[1]}`,
                value: `${res.name}>${res.address}`,
              }));
              this.setState({ data: data, fetch: false });
              console.log(response.data.google_loc_datas);
            });
        });
      }
    });
  }, 500);
  updatePositionStart = (evt) => {
    console.log(evt.target.getLatLng());
    this.setState(
      {
        start_location_lat: evt.target.getLatLng().lat,
        start_location_lon: evt.target.getLatLng().lng,
        center_lon: evt.target.getLatLng().lng,
        center_lat: evt.target.getLatLng().lat,
      },
      () => {
        GET_ACCESS_TOKEN().then((token) => {
          axios
            .get(
              `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.start_location_lat}&lng=${this.state.start_location_lon}&access_token=${token}`
            )
            .then((response) => {
              let name = response.data.google_loc_data[0].address;
              let id = response.data.google_loc_data[0]._id.$oid;
              this.setState({
                start_location: name,
                value: { key: id, label: name },
              });
            });
        });
      }
    );
  };

  updatePositionEnd = (evt) => {
    console.log(evt.target.getLatLng());
    this.setState(
      {
        end_location_lat: evt.target.getLatLng().lat,
        end_location_lon: evt.target.getLatLng().lng,
        center_lon: evt.target.getLatLng().lng,
        center_lat: evt.target.getLatLng().lat,
      },
      () => {
        GET_ACCESS_TOKEN().then((token) => {
          axios
            .get(
              `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.end_location_lat}&lng=${this.state.end_location_lon}&access_token=${token}`
            )
            .then((response) => {
              let name = response.data.google_loc_data[0].address;
              let id = response.data.google_loc_data[0]._id.$oid;
              this.setState({
                end_location: name,
                value1: { key: id, label: name },
              });
            });
        });
      }
    );
  };
  handleStartChange = (value) => {
    this.setState({ value, data: [], fetch: false });

    return this.setState({
      start_location: value.label.split(">")[0],
      start_location_lon: value.key.split(">")[1],
      center_lon: value.key.split(">")[1],
      start_location_lat: value.key.split(">")[2],
      center_lat: value.key.split(">")[2],
    });
  };

  handleEndChange = (value) => {
    this.setState({ value1: value, data: [], fetch: false });

    return this.setState({
      end_location: value.label.split(">")[0],
      end_location_lon: value.key.split(">")[1],
      center_lon: value.key.split(">")[1],
      end_location_lat: value.key.split(">")[2],
      center_lat: value.key.split(">")[2],
    });
  };

  copyText = (e) => {
    this.Input.select();
    document.execCommand("copy");
    swal.fire(
      "Success",
      "Link Copied to Clipboard, , You Can now Send The Link Through Your Preferred Method",
      "success"
    );
  };

  editTask = () => {
    if (this.state.is_public === true && this.state.price === "0") {
      swal.fire("Info", "Please enter a valid price for public task", "info");
    } else if (this.state.is_public === true && this.state.price === "") {
      swal.fire("Info", "Please enter a valid price for public task", "info");
    } else if (
      this.state.start_location === "" ||
      this.state.end_location === ""
    ) {
      swal.fire("Info", " Please enter a valid start and end location", "info");
    } else if (this.state.start_time === "" || this.state.end_time === "") {
      swal.fire("Info", "Please enter a valid start and end time", "info");
    } else if (
      Date.now() > this.state.start_time ||
      Date.now() > this.state.end_time
    ) {
      swal.fire("Info", "Please enter a Future start and end time", "info");
    } else if (
      this.state.contact_no !== "" &&
      !this.state.contact_no.includes("+")
    ) {
      swal.fire(
        "Info",
        "Enter Country Code For Pickup Contact.Example: +8801711123456",
        "info"
      );
    } else if (
      this.state.drop_contact_no !== "" &&
      !this.state.drop_contact_no.includes("+")
    ) {
      swal.fire(
        "Info",
        "Enter Country Code For Dropoff Contact.Example: +8801711123456",
        "info"
      );
    } else {
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        task_type: this.state.task_type,
        details: this.state.details,
        task_code: this.state.task_code,
        start_loc: {
          coordinates: [
            this.state.start_location_lon,
            this.state.start_location_lat,
          ],
        },
        start_time: this.state.start_time,
        start_loc_name: this.state.start_location,
        start_dist: this.state.start_dist,
        end_loc: {
          coordinates: [
            this.state.end_location_lon,
            this.state.end_location_lat,
          ],
        },
        end_time: this.state.end_time,
        end_loc_name: this.state.end_location,
        end_dist: this.state.end_dist,
        contact_no: this.state.contact_no,
        contact_name: this.state.contact_name,
        contact_id: this.state.contact_id,
        drop_contact_no: this.state.drop_contact_no,
        drop_contact_name: this.state.drop_contact_name,
        drop_contact_id: this.state.drop_contact_id,
        price: Number(this.state.price),
        assigned_to:
          this.state.is_public === true ? "" : this.state.assigned_to,
        sub_org: this.state.sub_org_id,

        assigned_to_name: this.state.is_public
          ? ""
          : this.state.assigned_to_label.split(">")[0],
        assigned_asset:
          this.state.is_public === true ? "" : this.state.assigned_asset,
        assigned_asset_name: this.state.is_public
          ? ""
          : this.state.assigned_asset_label,
        prev_task_id: this.state.prev_task_id,
        m_task_id: this.state.m_task_id,
        delay_mins: this.state.delay_mins,
        colors: this.state.colors,
        trip_duration: this.state.trip_duration,
        comments: [],
        is_public: this.state.is_public,
        delivery_time: this.state.delivery_time,
        delivery_time_by_customer: this.state.delivery_time_by_customer,
        delay_reason: this.state.delay_reason,
        assign_connected_users: this.state.secondaryUsers,
        vehicle_load: this.state.vehicle_load,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_TASK}${this.state.task_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({ loading: false });
            if (this.state.isChainTask === true) {
              localStorage.setItem(
                "pastTaskId",
                JSON.stringify(response.data.data._id.$oid)
              );
              localStorage.setItem(
                "pastInfo",
                JSON.stringify({
                  end_location: this.state.end_location,
                  end_time: this.state.end_time,
                  end_location_lon: this.state.end_location_lon,
                  end_location_lat: this.state.end_location_lat,
                })
              );
              this.setState({ chainRedirect: true });
            }
            swal.fire("Info", response.data.message, "info");
            console.log("response from edit task", response);
          })
          .catch((error) => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from edit task", error);
          });
      });
    }
  };

  createTransportBill = () => {
    if (this.state.transport_type === "" || this.state.fare === null) {
      swal.fire("Info", "Please enter transport type and fare!", "info");
    } else {
      this.setState({ transport_bill_modal: false });
      const data = {
        transport_bills: {
          name: this.state.transport_name,
          type: this.state.transport_type,
          fare: Number(this.state.transport_fare),
          start_loc: {
            coordinates: [this.state.userLocLon, this.state.userLocLat],
          },
        },
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${TRANSPORT_BILL}${this.state.task_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Success", response.data.message, "success");
            console.log("response from generate trasnport bill", response);
            this.getTransportBill();
          })
          .catch((error) => {
            swal.fire("Error", error.message, "error");
            console.log("error from generate trasnport bill", error);
          });
      });
    }
  };

  updateTransportBill = (bill_id, name, fare, type) => {
    this.setState({ transport_bill_edit: false });
    const data = {
      transport_bills: {
        _id: bill_id,
        fare: Number(fare),
      },
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${TRANSPORT_BILL}${this.state.task_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Success", response.data.message, "success");
          console.log("response from edit trasnport bill", response);
          this.setState({
            transport_name: "",
            transport_fare: null,
            transport_type: "",
            transport_bill_id: "",
          });
          this.getTransportBill();
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          this.setState({
            transport_name: "",
            transport_fare: null,
            transport_type: "",
            transport_bill_id: "",
          });
          console.log("error from edit trasnport bill", error);
        });
    });
  };

  handleSaveFile = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url =
        this.state.timezone_choice === "org_timezone"
          ? `${TRANSPORT_BILL}${this.state.task_id}?fmt=xls&timezone_choice=${this.state.timezone_choice}&access_token=${token}`
          : `${TRANSPORT_BILL}${this.state.task_id}?fmt=xls&timezone_choice=${this.state.timezone_choice}&timezone_name=${this.state.timezone_name}&access_token=${token}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from transport bill list fmt", blob);

          FileSaver.saveAs(
            blob,
            `transport_bill_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from transport bill list api", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    if (this.state.chainRedirect === true) {
      return <Redirect to="/createTask" />;
    }

    // const UserList = this.state.userData.map(r => {
    //   return <Option key={r._id.$oid}>{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>;
    // });

    // const FliteredList = this.state.filtered.map(r => {
    //   return <Option key={r._id.$oid}>{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>;
    // });

    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title="Edit Task"
          onBack={() => this.props.history.goBack()}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                marginRight: 10,
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Text style={{ fontSize: "large", marginLeft: 5 }}>
                  Start Location
                </Text>
                {this.state.disable === true ? null : (
                  <button
                    onClick={() =>
                      this.setState({
                        showInputLocStart: !this.state.showInputLocStart,
                      })
                    }
                    style={{
                      color: "white",
                      borderRadius: 25,
                      backgroundColor: "teal",
                      border: "none",
                      margin: 3,
                    }}
                  >
                    {this.state.showInputLocStart === false ? (
                      <div>Edit</div>
                    ) : (
                      <div>Cancel</div>
                    )}
                  </button>
                )}
              </div>
              {this.state.showInputLocStart === false ? (
                <Card>{this.state.start_location}</Card>
              ) : (
                <div style={{ display: "flex" }}>
                  {this.state.editStart === true ? (
                    <Input
                      type="text"
                      placeholder="Start Location"
                      value={this.state.start_location}
                      onChange={(evt) =>
                        this.setState({ start_location: evt.target.value })
                      }
                      style={{ width: window.innerWidth / 2.8 }}
                    />
                  ) : (
                    <Select
                      showSearch={true}
                      labelInValue
                      value={this.state.value}
                      notFoundContent={fetch ? <Spin size="small" /> : null}
                      style={{ width: window.innerWidth / 2.8 }}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={false}
                      placeholder="Search Start Location"
                      onSearch={this.handleStart}
                      onChange={this.handleStartChange}
                      optionLabelProp="label"
                    >
                      {this.state.data.map((r) => {
                        return (
                          <Option key={r.id} label={r.value}>
                            {
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Text>{r.value.split(">")[0]}</Text>
                                <Text
                                  style={{ fontSize: 10, color: "#9CB4CC" }}
                                >
                                  {r.value.split(">")[1]}
                                </Text>
                              </div>
                            }
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  {this.state.start_location === "" ? null : (
                    <Tooltip
                      title={
                        this.state.editStart
                          ? "Search Start Location"
                          : "Edit Start Location"
                      }
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        style={{ marginLeft: 10 }}
                        onClick={() =>
                          this.setState({ editStart: !this.state.editStart })
                        }
                      >
                        <Icon type={this.state.editStart ? "search" : "edit"} />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Text style={{ fontSize: "large", marginLeft: 5 }}>
                  End Location
                </Text>
                {this.state.disable === true ? null : (
                  <button
                    onClick={() =>
                      this.setState({
                        showInputLocEnd: !this.state.showInputLocEnd,
                      })
                    }
                    style={{
                      color: "white",
                      borderRadius: 25,
                      backgroundColor: "teal",
                      border: "none",
                      margin: 3,
                    }}
                  >
                    {this.state.showInputLocEnd === false ? (
                      <div>Edit</div>
                    ) : (
                      <div>Cancel</div>
                    )}
                  </button>
                )}
              </div>
              {this.state.showInputLocEnd === false ? (
                <Card>{this.state.end_location}</Card>
              ) : (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {this.state.editEnd === true ? (
                    <Input
                      type="text"
                      placeholder="End Location"
                      value={this.state.end_location}
                      onChange={(evt) =>
                        this.setState({ end_location: evt.target.value })
                      }
                      style={{ width: window.innerWidth / 2.8 }}
                    />
                  ) : (
                    <Select
                      showSearch={true}
                      labelInValue
                      value={this.state.value1}
                      notFoundContent={fetch ? <Spin size="small" /> : null}
                      style={{ width: window.innerWidth / 2.8 }}
                      filterOption={false}
                      showArrow={false}
                      placeholder="Search End Location"
                      onSearch={this.handleStart}
                      onChange={this.handleEndChange}
                      optionLabelProp="label"
                    >
                      {this.state.data.map((r) => {
                        return (
                          <Option key={r.id} label={r.value}>
                            {
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Text>{r.value.split(">")[0]}</Text>
                                <Text
                                  style={{ fontSize: 10, color: "#9CB4CC" }}
                                >
                                  {r.value.split(">")[1]}
                                </Text>
                              </div>
                            }
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  {this.state.end_location === "" ? null : (
                    <Tooltip
                      title={
                        this.state.editEnd
                          ? "Search End Location"
                          : "Edit End Location"
                      }
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        style={{ marginLeft: 10 }}
                        onClick={() =>
                          this.setState({ editEnd: !this.state.editEnd })
                        }
                      >
                        <Icon type={this.state.editEnd ? "search" : "edit"} />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="primary"
              onClick={() => this.setState({ saveStartModal: true })}
              style={{ margin: 5 }}
            >
              Select Location From Routes
            </Button>
            <Modal
              title="Select Route"
              visible={this.state.saveStartModal}
              onOk={() => this.setState({ saveStartModal: false })}
              onCancel={() => this.setState({ saveStartModal: false })}
            >
              <List
                size="small"
                bordered
                dataSource={this.state.savedRoutes}
                renderItem={(item) => (
                  <List.Item
                    key={item._id.$oid}
                    onClick={() => {
                      this.setState({
                        start_location: item.start_loc_name,
                        end_location: item.end_loc_name,
                        start_location_lon: item.start_loc.coordinates[0],
                        start_location_lat: item.start_loc.coordinates[1],
                        end_location_lon: item.end_loc.coordinates[0],
                        end_location_lat: item.end_loc.coordinates[1],
                        center_lon: item.end_loc.coordinates[0],
                        center_lat: item.end_loc.coordinates[1],
                        saveStartModal: false,
                        editStart: true,
                        editEnd: true,
                      });
                    }}
                  >{`From : ${item.start_loc_name} - To : ${item.end_loc_name}`}</List.Item>
                )}
              />
            </Modal>
            <Button
              type="primary"
              onClick={() => this.setState({ startEntityModal: true })}
              style={{ margin: 5 }}
            >
              Select Start Location From Place
            </Button>
            <Modal
              title="Select Start Location"
              visible={this.state.startEntityModal}
              onOk={() => this.setState({ startEntityModal: false })}
              onCancel={() => this.setState({ startEntityModal: false })}
            >
              <List
                size="small"
                bordered
                dataSource={this.state.entityList}
                renderItem={(item) => (
                  <List.Item
                    key={item._id.$oid}
                    onClick={() => {
                      this.setState({
                        start_location: item.address,
                        start_location_lon: item.loc.coordinates[0],
                        start_location_lat: item.loc.coordinates[1],
                        center_lon: item.loc.coordinates[0],
                        center_lat: item.loc.coordinates[1],
                        startEntityModal: false,
                        editStart: true,
                      });
                      if (item.hasOwnProperty("contact")) {
                        this.setState({
                          contact_name: item.contact?.name ?? "",
                          contact_no: item.contact?.mobile ?? "",
                        });
                      }
                    }}
                  >{`${item.name} - ${item.address}`}</List.Item>
                )}
              />
            </Modal>
            <Button
              type="primary"
              onClick={() => this.setState({ endEntityModal: true })}
              style={{ margin: 5 }}
            >
              Select End Location From Place
            </Button>
            <Modal
              title="Select End Location"
              visible={this.state.endEntityModal}
              onOk={() => this.setState({ endEntityModal: false })}
              onCancel={() => this.setState({ endEntityModal: false })}
            >
              <List
                size="small"
                bordered
                dataSource={this.state.entityList}
                renderItem={(item) => (
                  <List.Item
                    key={item._id.$oid}
                    onClick={() => {
                      this.setState({
                        end_location: item.address,
                        end_location_lon: item.loc.coordinates[0],
                        end_location_lat: item.loc.coordinates[1],
                        center_lon: item.loc.coordinates[0],
                        center_lat: item.loc.coordinates[1],
                        endEntityModal: false,
                        editEnd: true,
                      });
                      if (item.hasOwnProperty("contact")) {
                        this.setState({
                          drop_contact_name: item.contact?.name ?? "",
                          drop_contact_no: item.contact?.mobile ?? "",
                        });
                      }
                    }}
                  >{`${item.name} - ${item.address}`}</List.Item>
                )}
              />
            </Modal>
          </div>
          <Map
            center={[this.state.center_lat, this.state.center_lon]}
            zoom={this.state.zoom}
            style={{ height: 500 }}
            onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
          >
            {/* <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            /> */}
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{
                KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                VERSION: "3.49.2",
              }}
              type={"roadmap"}
            />

            {this.state.checkStarted || this.state.checkCompleted ? (
              <Polyline
                color="purple"
                positions={[
                  this.state.polyData.map((m) => [
                    m.loc.coordinates[1],
                    m.loc.coordinates[0],
                  ]),
                ]}
              />
            ) : null}
            <Marker
              position={[
                this.state.start_location_lat,
                this.state.start_location_lon,
              ]}
              draggable
              onDragend={this.updatePositionStart}
              icon={green}
            >
              <Popup>
                <span>From :{this.state.start_location}</span>
              </Popup>
            </Marker>
            {this.state.started === true && this.state.completed === false ? (
              <Marker
                position={[
                  this.state.assignedUserLat,
                  this.state.assignedUserLon,
                ]}
                icon={user}
              >
                <Popup>
                  <span>Assigned User Location</span>
                </Popup>
              </Marker>
            ) : null}

            {this.state.start_location !== "" ? (
              <Marker
                position={[
                  this.state.end_location_lat,
                  this.state.end_location_lon,
                ]}
                draggable
                onDragend={this.updatePositionEnd}
                icon={red}
              >
                <Popup>
                  <span>To :{this.state.end_location}</span>
                </Popup>
              </Marker>
            ) : null}
          </Map>
          <div style={{ display: "flex", flex: 1 }}>
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <div
                style={{
                  marginBottom: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Text>
                    Select Colors :{" "}
                    {this.state.colors.length > 1
                      ? `${this.state.colors.length} Colors Selected`
                      : `${this.state.colors.length} Color Selected`}
                  </Text>
                  <button
                    className="borderless-button"
                    style={{ color: "red" }}
                    onClick={() => {
                      this.setState({ colors: [], colorId: "" }, () => {
                        console.log(this.state.colors);
                      });
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {this.colors.map((color) => (
                    <div key={color.id}>
                      {this.state.colorId === color.id ? (
                        <Button
                          style={{
                            borderRadius: 50,
                            backgroundColor: color.color,
                            height: 40,
                            width: 40,
                            margin: 5,
                            borderColor: "black",
                          }}
                          onClick={() => {
                            this.state.colors.push(color.color);
                            this.setState({ colorId: color.id }, () => {
                              console.log(this.state.colorId);
                            });
                            console.log(this.state.colors);
                          }}
                        />
                      ) : (
                        <Button
                          style={{
                            borderRadius: 50,
                            backgroundColor: color.color,
                            height: 35,
                            width: 35,
                            margin: 5,
                          }}
                          onClick={() => {
                            this.state.colors.push(color.color);
                            this.setState({ colorId: color.id }, () => {
                              console.log(this.state.colorId);
                            });
                            console.log(this.state.colors);
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    marginRight: 5,
                  }}
                >
                  <Switch
                    checkedChildren="Public"
                    unCheckedChildren="Public"
                    defaultChecked={this.state.is_public}
                    onChange={this.handlePchange}
                  />
                </div>
                <div
                  style={{
                    marginRight: 5,
                  }}
                >
                  <Switch
                    checkedChildren="Chain task"
                    unCheckedChildren="Chain task"
                    defaultChecked={this.state.isChainTask}
                    onChange={this.setChainTask}
                  />
                </div>
              </div>
              <div style={{ marginBottom: 5 }}>
                <Text>Trip Duration</Text>

                <Input
                  type="number"
                  placeholder="Trip Duration"
                  value={this.state.trip_duration}
                  onChange={this.setTripDuration}
                />
              </div>

              <div style={{ marginBottom: 5 }}>
                <Text>Price</Text>

                <Input
                  type="number"
                  placeholder="Price"
                  value={this.state.price}
                  onChange={this.setPrice}
                />
              </div>
              <div style={{ marginBottom: 5 }}>
                <Text>Delay in Minutes</Text>

                <Input
                  type="number"
                  placeholder="Delay in Minutes"
                  value={this.state.delay_mins}
                  onChange={this.setDelayMinutes}
                />
              </div>

              <Card style={{ marginBottom: 5 }}>
                {/* <Text>Post Comment</Text> */}

                {this.state.commentsArray.length !== 0 ? (
                  <div
                    id="chat"
                    style={{
                      height: 400,
                      overflowY: "auto",
                      display: "flex",
                      flexDirection: "column-reverse",
                      backgroundColor: "#ECEFF1",
                      borderRadius: 5,
                    }}
                  >
                    {this.state.commentsArray
                      .slice(0)
                      .reverse()
                      .map((comment, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              margin: 10,
                              justifyContent:
                                LOCAL_USER_INFO().user.name !==
                                comment.commented_by_name
                                  ? "flex-end"
                                  : null,
                            }}
                            key={comment._id.$oid}
                          >
                            <Avatar style={{ marginRight: 5, marginTop: 20 }}>
                              {comment.commented_by_name[0]}
                            </Avatar>
                            <Card
                              style={{
                                borderRadius: 25,
                                display: "flex",
                                flexDirection: "column",
                                minWidth: 200,
                                minHeight: 20,
                                backgroundColor:
                                  LOCAL_USER_INFO().user.name ===
                                  comment.commented_by_name
                                    ? "#2196F3"
                                    : null,
                                color:
                                  LOCAL_USER_INFO().user.name ===
                                  comment.commented_by_name
                                    ? "white"
                                    : null,
                              }}
                            >
                              <div style={{ fontWeight: 1000 }}>
                                {comment.commented_by_name}
                              </div>
                              <div>{comment.comment}</div>
                            </Card>
                            {/* <div style={{paddingTop:30}}>{timeConverter(comment.commented_on.$date)}</div> */}
                          </div>
                        );
                      })}
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 15,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder="Type Your Message and Press Enter"
                      value={this.state.comments}
                      onChange={this.setComments}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.postComment(
                            LOCAL_USER_INFO().user.name,
                            this.state.comments
                          );
                        }
                      }}
                    />
                    {this.state.typingName !== "" ? (
                      <Text
                        type="secondary"
                        style={{ fontSize: 10, paddingLeft: 10 }}
                      >{`${this.state.typingName} is typing`}</Text>
                    ) : null}
                  </div>
                </div>
              </Card>
              {!this.state.disableState &&
              (this.state.started ||
                this.state.completed ||
                this.state.accepted) ? (
                <Card>
                  <div style={{ textAlign: "center" }}>
                    <Tooltip placement="top" title="Add Transport Bill">
                      <Link
                        to={{
                          pathname: "/createBill",
                          state: {
                            task_id: this.state.task_id,
                          },
                        }}
                      >
                        <Button
                          onClick={() =>
                            this.setState({ transport_bill_modal: true })
                          }
                          shape="circle"
                          icon="plus"
                          style={{
                            backgroundColor: "#1A3337",
                            height: 40,
                            width: 40,
                            fontSize: 18,
                          }}
                        />
                      </Link>
                    </Tooltip>
                  </div>
                  {/* <Modal
                    title="Create Transport Bill"
                    visible={this.state.transport_bill_modal}
                    onOk={this.createTransportBill}
                    onCancel={() =>
                      this.setState({ transport_bill_modal: false })
                    }
                  >
                    <Form
                      style={{
                        display: "flex",
                        alignSelf: "center",
                        flexDirection: "column"
                      }}
                    >
                      <Form.Item>
                        <Text>Transport Name</Text>
                        <Input
                          placeholder="Transport Name"
                          type="text"
                          value={this.state.transport_name}
                          onChange={this.setTransportName}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Text>Transport fare</Text>
                        <Input
                          placeholder="Transport fare"
                          type="number"
                          value={this.state.transport_fare}
                          onChange={this.setTransportFare}
                        />
                      </Form.Item>

                      <Form.Item>
                        <Text>Transport Type</Text>
                        <Select
                          value={this.state.transport_type}
                          onChange={this.setTransportType}
                        >
                          {this.state.transport_options.map(item => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                    </Form>
                  </Modal> */}
                </Card>
              ) : null}
              {/* {!this.state.disableState &&
              (this.state.started || this.state.completed||this.state.accepted) ? (
                <Card style={{ marginTop: 5 }}>
                  <div style={{ margin: 10 }}>
                    <Text> Timezone : {""}</Text>
                    <Select
                      value={this.state.timezone_choice}
                      onChange={this.setTimezonetype}
                      placeholder="View as"
                    >
                      <Option key="user_timezone">User</Option>
                      <Option key="org_timezone">Organization</Option>
                    </Select>
                  </div>
                  <Button
                    className="login-form-button"
                    onClick={this.handleSaveFile}
                    style={{ marginBottom: 5, maxWidth: 200 }}
                  >
                    Save File
                  </Button>
                  <Table
                    dataSource={this.state.transport_bill_data}
                    pagination={false}
                    size="small"
                    title={() => "Transport Bills"}
                    rowKey={record => record._id.$oid}
                  >
                    <Column title="Type" dataIndex="type" key="type" />
                    <Column title="Name" dataIndex="name" key="name" />
                    <Column title="Fare" dataIndex="fare" key="fare" />
                    <Column
                      title="Billed by"
                      dataIndex="billed_by_name"
                      key="billed_by_name"
                    />
                    <Column
                      title="Billed on"
                      dataIndex="billed_on"
                      render={(text, record) => (
                        <span>{timeConverter(record.billed_on.$date)}</span>
                      )}
                    />
                    <Column
                      title="Action"
                      dataIndex="action"
                      render={(text, record) => (
                        <button
                          className="borderless-button"
                          style={{ color: "green" }}
                          onClick={() =>
                            this.setState({
                              transport_bill_edit: true,
                              transport_name: record.name,
                              transport_fare: record.fare,
                              transport_type: record.type,
                              transport_bill_id: record._id.$oid
                            })
                          }
                        >
                          Edit
                        </button>
                      )}
                    />
                  </Table>
                  <div style={{ textAlign: "center" }}>
                    <Text>
                      Total Transport Bill : {this.state.total_transport_bill}
                    </Text>
                  </div>
                  <Modal
                    title="Update Transport Bill"
                    visible={this.state.transport_bill_edit}
                    onOk={() =>
                      this.updateTransportBill(
                        this.state.transport_bill_id,
                        this.state.transport_name,
                        this.state.transport_fare,
                        this.state.transport_type
                      )
                    }
                    onCancel={() =>
                      this.setState({
                        transport_bill_edit: false,
                        transport_name: "",
                        transport_fare: null,
                        transport_type: "",
                        transport_bill_id: ""
                      })
                    }
                  >
                    <Form
                      style={{
                        display: "flex",
                        alignSelf: "center",
                        flexDirection: "column"
                      }}
                    >
                      <Form.Item>
                        <Text>Transport Name</Text>
                        <Input
                          placeholder="Transport Name"
                          disabled
                          type="text"
                          value={this.state.transport_name}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Text>Transport fare</Text>
                        <Input
                          placeholder="Transport fare"
                          type="number"
                          value={this.state.transport_fare}
                          onChange={this.setTransportFare}
                        />
                      </Form.Item>

                      <Form.Item>
                        <Text>Transport Type</Text>
                        <Select disabled value={this.state.transport_type}>
                          {this.state.transport_options.map(item => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                    </Form>
                  </Modal>
                </Card>
              ) : null} */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginLeft: 10,
              }}
            >
              <div style={{ marginBottom: 5 }}>
                <Text>Shipment Number</Text>

                <Input
                  type="text"
                  placeholder="Type Shipment Number"
                  value={this.state.task_code}
                  onChange={this.setTaskCode}
                />
              </div>
              <div style={{ marginBottom: 5 }}>
                <Text>Task Status</Text>

                <Input
                  type="text"
                  placeholder="Task Status"
                  value={this.state.task_status}
                  disabled
                />
              </div>
              <Card
                style={{
                  margin: 3,
                }}
              >
                <div
                  style={{
                    marginBottom: 5,
                  }}
                >
                  <Text>Search Pickup Contact From List</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Select
                      labelInValue
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={false}
                      onSearch={this.handleSearch}
                      style={{ width: "100%" }}
                      value={{
                        key: this.state.contact_id,
                        label: this.state.contact_info,
                      }}
                      onChange={this.setContact}
                      placeholder="Select Pickup Contact"
                    >
                      {this.state.userData.map((r) => {
                        return (
                          <Option
                            key={r._id.$oid}
                          >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                        );
                      })}
                    </Select>
                    {/* <Button
                      style={{ marginLeft: 5 }}
                      onClick={() =>
                        this.setState({ pickLink: !this.state.pickLink })
                      }
                    >
                      Share Link
                    </Button> */}
                  </div>
                  {/* {this.state.pickLink ? (
                    <Input
                      style={{ marginTop: 5 }}
                      type="text"
                      addonAfter={
                        <Button
                          style={{
                            border: "none",
                            backgroundColor: "#FAFAFA",
                            height: 30
                          }}
                          onClick={this.copyText}
                        >
                          Copy
                        </Button>
                      }
                      ref={Input => (this.Input = Input)}
                      value={`https://web.conveyance.app/task-link/${this.state.task_link}`}
                    />
                  ) : null} */}
                </div>
                <Divider>Or Enter Manually</Divider>
                <div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Pickup Contact Number</Text>

                    <Input
                      type="text"
                      placeholder="Example:+8801711123456"
                      value={this.state.contact_no}
                      onChange={this.setContactNumber}
                    />
                  </div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Pickup Contact Name</Text>

                    <Input
                      type="text"
                      placeholder="Pickup Contact Name"
                      value={this.state.contact_name}
                      onChange={this.setContactName}
                    />
                  </div>
                </div>
              </Card>

              <Card
                style={{
                  margin: 3,
                }}
              >
                <div style={{ marginBottom: 5 }}>
                  <Text>Search Drop-off Contact From List</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Select
                      labelInValue
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={false}
                      onSearch={this.handleSearch}
                      style={{ width: "100%" }}
                      value={{
                        key: this.state.drop_contact_id,
                        label: this.state.drop_contact_name,
                      }}
                      onChange={this.setDropContact}
                      placeholder="Select Drop-off Contact"
                    >
                      {this.state.userData.map((r) => {
                        return (
                          <Option
                            key={r._id.$oid}
                          >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                        );
                      })}
                    </Select>
                    {/* <Button
                      style={{ marginLeft: 5 }}
                      onClick={() =>
                        this.setState({ dropLink: !this.state.dropLink })
                      }
                    >
                      Share Link
                    </Button> */}
                  </div>
                  {/* {this.state.dropLink ? (
                    <Input
                      style={{ marginTop: 5 }}
                      type="text"
                      addonAfter={
                        <Button
                          style={{
                            border: "none",
                            backgroundColor: "#FAFAFA",
                            height: 30
                          }}
                          onClick={this.copyText}
                        >
                          Copy
                        </Button>
                      }
                      ref={Input => (this.Input = Input)}
                      value={`https://web.conveyance.app/task-link/${this.state.task_link}`}
                    />
                  ) : null} */}
                </div>
                <Divider>Or Enter Manually</Divider>
                <div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Drop-off Contact Number</Text>

                    <Input
                      type="text"
                      placeholder="Example:+8801711123456"
                      value={this.state.drop_contact_no}
                      onChange={this.setDropContactNumber}
                    />
                  </div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Drop Contact Name</Text>

                    <Input
                      type="text"
                      placeholder="Drop-off Contact Name"
                      value={this.state.drop_contact_name}
                      onChange={this.setDropContactName}
                    />
                  </div>
                </div>
              </Card>
              <Card
                style={{
                  margin: 3,
                }}
              >
                <Text>Share Task Link</Text>
                <Input
                  style={{ marginTop: 5 }}
                  type="text"
                  addonAfter={
                    <Button
                      style={{
                        border: "none",
                        backgroundColor: "#FAFAFA",
                        height: 30,
                      }}
                      onClick={this.copyText}
                    >
                      Copy
                    </Button>
                  }
                  ref={(Input) => (this.Input = Input)}
                  value={`https://web.conveyance.app/task-link/${this.state.task_link}`}
                />
              </Card>
              <Card
                style={{
                  margin: 3,
                }}
              >
                <div
                  style={{
                    marginBottom: 5,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text>Start Date & Time</Text>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={moment(this.state.start_time_view)}
                      onChange={this.setStartTime}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Card>
              <Card
                style={{
                  margin: 3,
                }}
              >
                <div
                  style={{
                    marginBottom: 5,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text>End Date & Time</Text>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={moment(this.state.end_time_view)}
                      onChange={this.setEndTime}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Card>
              <Card
                style={{
                  margin: 3,
                }}
              >
                <div
                  style={{
                    marginBottom: 5,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text>Delivery Date & Time</Text>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={this.state.delivery_time_view}
                      onChange={this.setDeliveryTime}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Card>
              <Card
                style={{
                  margin: 3,
                }}
              >
                <div
                  style={{
                    marginBottom: 5,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text>Delivery Date & Time by Customer</Text>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={this.state.delivery_time_by_customer_view}
                      onChange={this.setDeliveryTimeCustomer}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Card>
              {this.state.is_public === true ? null : (
                <div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Search Assigned User</Text>
                    <Select
                      labelInValue
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={false}
                      onSearch={this.handleSearch}
                      style={{ width: "100%" }}
                      value={{
                        key: this.state.assigned_to,
                        label: this.state.assigned_to_label,
                      }}
                      onChange={this.setAssigned}
                      placeholder="Select User"
                    >
                      {this.state.userData.map((r) => {
                        return (
                          <Option
                            key={r._id.$oid}
                          >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Select Sub Organization</Text>
                    <Select
                      style={{ width: "100%" }}
                      labelInValue
                      value={{
                        key: this.state.sub_org_id,
                        label: this.state.sub_org_name,
                      }}
                      disabled={
                        this.state.assigned_user_sub_orgs.length < 2
                          ? true
                          : false
                      }
                      onChange={this.setSubOrg}
                      placeholder="Select Sub Organization"
                    >
                      {this.state.assigned_user_sub_orgs.map((r) => {
                        return <Option key={r.id}>{`${r.name}`}</Option>;
                      })}
                    </Select>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>Search Secondary Users</Text>
                    <Select
                      mode="multiple"
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={false}
                      value={this.state.secondaryUsers}
                      onSearch={this.handleSearch}
                      onChange={(value) => {
                        this.setState({ secondaryUsers: value }, () => {
                          console.log(this.state.secondaryUsers);
                        });
                      }}
                      placeholder="Search Secondary Users"
                    >
                      {this.state.userData.map((r) => {
                        return (
                          <Option
                            key={r._id.$oid}
                          >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Assign to (Asset)</Text>
                    <Select
                      labelInValue
                      style={{ width: "100%" }}
                      value={{
                        key: this.state.assigned_asset,
                        label: this.state.assigned_asset_label,
                      }}
                      onChange={this.setAssignedAsset}
                      placeholder="Select Asset"
                    >
                      {this.state.assetData.map((r) => {
                        return <Option key={r._id.$oid}>{r.name}</Option>;
                      })}
                    </Select>
                  </div>
                </div>
              )}

              {this.state.assigned_asset ? (
                <div style={{ marginBottom: 5 }}>
                  <Text>Vehicle Load</Text>

                  <Input
                    type="number"
                    placeholder="Vehicle Load"
                    value={this.state.vehicle_load}
                    onChange={(evt) =>
                      this.setState({ vehicle_load: evt.target.value })
                    }
                  />
                </div>
              ) : null}

              <div style={{ marginBottom: 5 }}>
                <Text>Task Name</Text>

                <Input
                  type="text"
                  placeholder="Task Name"
                  value={this.state.name}
                  onChange={this.setName}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text>Task Type</Text>
                <Select
                  value={this.state.task_type}
                  onChange={this.setTaskType}
                  placeholder="Task Type"
                >
                  {this.state.taskTypes.map((r) => {
                    return <Option key={r.name}>{r.name}</Option>;
                  })}
                </Select>
              </div>
              <div style={{ marginBottom: 5 }}>
                <Text>Task Details</Text>

                <TextArea
                  rows={4}
                  placeholder="Task Details"
                  value={this.state.details}
                  onChange={this.setDetails}
                />
              </div>
              <div style={{ marginBottom: 5 }}>
                <Text>Delay Reason</Text>

                <TextArea
                  rows={4}
                  placeholder="Delay Reason"
                  value={this.state.delay_reason}
                  onChange={this.setDelayReason}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {this.state.disable === false ||
          GET_USER_LOCAL().org_admin === true ? (
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editTask}
              style={{ marginTop: 24, marginBottom: 15 }}
            >
              Update Task
            </Button>
          ) : null}
        </div>
      </Card>
    );
  }
}
