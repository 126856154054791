import { SAVE_SIDEBAR_SCROLL_POSITION } from "../actions/types";

const initialState = {
  defaultSideBarScrollPosition: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SAVE_SIDEBAR_SCROLL_POSITION:
      return {
        defaultSideBarScrollPosition: action.payload
      };
    default:
      return state;
  }
}
