import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Tooltip, Tag } from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";

import { CREATE_TERRITORY } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";


import "../login.css";

const { Column } = Table;

class TerritoryList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false
  };
  componentDidMount() {
    this.props.getUserData();
    this.getTerrytoryList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

  }

  getTerrytoryList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_TERRITORY}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from territory list", response);
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          console.log("error from territory list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getTerrytoryList();
      }
    );
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.order_and_delivery.territory.territory_create?(
          <Link to="/createTerritory">
          <Tooltip placement="top" title="Create Territory">
          <Button shape="circle" icon="plus" className="tooltip-button" />
          </Tooltip>
        </Link>
        ):null}
        
        <PageHeader
          title="Territory List"
        />

        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title="Name"
            dataIndex="name"
            key="name"
          />
          
          <Column
            title="Assigned Users"
            dataIndex="assigned_users"
            key="assigned_users"
            render={assigned_users => (
              <span>
                {assigned_users.map(item => (
                  <Tag
                    color="purple"
                    key={item.id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2
                    }}
                  >
                    {`${item.name}>${item.mobile}`}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
           <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.order_and_delivery.territory.territory_detail?(
                  <Link
                  to={{
                    pathname: "/viewTerritory",
                    state: { territory_id: record._id.$oid }
                  }}
                >
                  <Button
                    type="link"
                    style={{ color: "green" }}
                  >
                    View
                  </Button>
                </Link>
                ):null}
                {this.props.userProfileData.order_and_delivery.territory.territory_update?(
                  <Link
                  to={{
                    pathname: "/editTerritory",
                    state: { territory_id: record._id.$oid }
                  }}
                >
                  <Button
                    type="link"
                    style={{ color: "green" }}
                  >
                    Edit
                  </Button>
                </Link>
                ):null}
                
              </span>
            )}
          />
         
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}
const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(TerritoryList);