import React, { Component } from "react";
import {
  Card,
  PageHeader,
  Typography,
  Input,
  Form,
  Button,
  Modal,
  List,
  Select
} from "antd";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { saveTaskData } from "../../redux/actions/startTaskActions";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { Redirect } from "react-router-dom";

import { countryCode } from "../countryCode";
import axios from "axios";
import swal from "sweetalert2";
import {
  // LOCATION_SEARCH,
  LOCATION_SEARCH_GOOGLE,
  REVERSE_LOCATION_SEARCH_GOOGLE,
  CREATE_QUICK_TASK,
  SAVE_FREQUENT_LOCATION,
  CREATE_ENTITY
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
// import { Redirect } from "react-router-dom";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40]
});

class Destination extends Component {
  state = {
    center_lat: 23.685,
    center_lon: 90.3563,
    end_location_lat: 23.685,
    end_location_lon: 90.3563,
    end_location_name: "",
    selected_value: [],
    selected_value_google: [],
    location_input: "",
    show_google_search: false,
    end_search_data: [],
    end_search_data_google: [],
    zoom: 15,
    countryCode: [],
    code: "+880",
    contact_no: "",
    contact_name: "",
    entityList: [],
    endEntityModal: false,
    redirect: false
  };
  componentDidMount() {
    console.log("from starttask", this.props.startTask);
    const success = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState({
        center_lat: latitude,
        end_location_lat: latitude,
        center_lon: longitude,
        end_location_lon: longitude
      });
    };

    if (Object.keys(this.props.startTask).length !== 0) {
      this.setState(
        {
          contact_name: this.props?.startTask?.drop_contact_name ?? "",
          contact_no: this.props?.startTask?.drop_contact_no?.slice(4) ?? "",
          end_location_name: this.props?.startTask?.end_loc_name ?? "",
          end_location_lon:
            this.props?.startTask?.end_loc?.coordinates[0] ?? 90.3563,
          end_location_lat:
            this.props?.startTask?.end_loc?.coordinates[1] ?? 23.685,
          center_lon: this.props?.startTask?.end_loc?.coordinates[0] ?? 90.3563,
          center_lat: this.props?.startTask?.end_loc?.coordinates[1] ?? 23.685
        },
        () => {
          if (this.state.end_location_name === "") {
            navigator.geolocation.getCurrentPosition(success);
          }
        }
      );
    this.getEntityList();
    } else {
      this.getEntityList();
      navigator.geolocation.getCurrentPosition(success);
    }

    this.setState({
      countryCode: countryCode
    });
  }

  getEntityList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ENTITY}?sub_org=${this.props?.startTask?.sub_org_id ?? ""}&limit=1000&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from entity list", response);
          this.setState({
            entityList: response.data.data
          });
        })
        .catch(error => {
          console.log("error from entity list api", error);
        });
    });
  };

  setCode = value => {
    this.setState({ code: value }, () => {
      console.log(this.state.code);
    });
  };

  setContactNumber = evt => {
    this.setState({ contact_no: evt.target.value });
    console.log(this.state.contact_no);
  };

  setContactName = evt => {
    this.setState({ contact_name: evt.target.value });
    console.log(this.state.contact_name);
  };

  // getEndLocation = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     const url = `${LOCATION_SEARCH}?loc_name=${this.state.location_input}&access_token=${token}`;
  //     axios({
  //       method: "get",
  //       url: url
  //     })
  //       .then(response => {
  //         const data = response.data.loc_data.map(res => ({
  //           id: res._id.$oid,
  //           value: res.address
  //         }));
  //         this.setState({ end_search_data: data });
  //         console.log("response from location search", response);
  //       })
  //       .catch(error => {
  //         console.log("error from location search api", error);
  //       });
  //   });
  // };

  // handleChange = value => {
  //   this.setState({
  //     selected_value: value,
  //     selected_value_google: [],
  //     end_search_data: []
  //   });
  //   return this.setState({ end_location_name: value.label }, () => {
  //     GET_ACCESS_TOKEN().then(token => {
  //       const url = `${LOCATION_SEARCH}?loc_name=${this.state.end_location_name}&access_token=${token}`;
  //       axios({
  //         method: "get",
  //         url: url
  //       }).then(response => {
  //         console.log("response after selecting normal location", response);
  //         this.setState({
  //           end_location_lat:
  //             response.data?.loc_data[0]?.loc?.coordinates[1] ??
  //             this.state.center_lat,
  //           end_location_lon:
  //             response.data?.loc_data[0]?.loc?.coordinates[0] ??
  //             this.state.center_lon,
  //           center_lon:
  //             response.data?.loc_data[0]?.loc?.coordinates[0] ??
  //             this.state.center_lon,
  //           center_lat:
  //             response.data?.loc_data[0]?.loc?.coordinates[1] ??
  //             this.state.center_lat
  //         });
  //       });
  //     });
  //   });
  // };

  searchGooglemaps = debounce(() => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${LOCATION_SEARCH_GOOGLE}?textsearch=${this.state.location_input}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          const data = response.data.google_loc_datas.map(res => ({
            id: `${res._id.$oid}>${res.loc.coordinates[0]}>${res.loc.coordinates[1]}`,
            value: `${res.name}>${res.address}`
          }));
          this.setState({ end_search_data_google: data });
          console.log("response from location search google", response);
        })
        .catch(error => {
          console.log("error from location search api google", error);
        });
    });
  }, 500);

  handleChangeGoogle = value => {
    this.setState({
      selected_value_google: value,
      selected_value: [],
      end_search_data_google: []
    });
    return this.setState({
      end_location_name: value.label.split(">")[0],
      end_location_lon: value.key.split(">")[1],
      center_lon: value.key.split(">")[1],
      end_location_lat: value.key.split(">")[2],
      center_lat: value.key.split(">")[2]
    });
  };

  updatePosition = evt => {
    console.log(evt.target.getLatLng());
    this.setState(
      {
        end_location_lat: evt.target.getLatLng().lat,
        end_location_lon: evt.target.getLatLng().lng,
        center_lon: evt.target.getLatLng().lng,
        center_lat: evt.target.getLatLng().lat
      },
      () => {
        GET_ACCESS_TOKEN().then(token => {
          const url = `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.end_location_lat}&lng=${this.state.end_location_lon}&access_token=${token}`;
          axios({
            method: "get",
            url: url
          }).then(response => {
            console.log("reverse api response", response);
            let name = response.data.google_loc_data[0].address;
            let id = response.data.google_loc_data[0]._id.$oid;
            this.setState({
              end_location_name: name,
              selected_value_google: { key: id, label: name },
              selected_value: { key: id, label: name }
            });
          });
        });
      }
    );
  };

  chooseAsset = () => {
    if (this.state.end_location_name === "") {
      swal.fire("Info", "Please Enter Destination", "info");
    } else {
      let data = {
        name: this?.props?.startTask?.name ?? "",
        task_type: this?.props?.startTask?.task_type ?? "",
        task_code: this?.props?.startTask?.task_code ?? "",
        colors: this?.props?.startTask?.colors ?? [],
        start_time: this?.props?.startTask?.start_time ?? Date.now(),
        started: this.props?.startTask?.started ?? false,
        assigned_to: this?.props?.startTask?.assigned_to ?? "",
        assigned_to_label: this?.props?.startTask?.assigned_to_label ?? "",
        sub_org_id: this.props?.startTask?.sub_org_id ?? "",
        sub_org_name: this.props?.startTask?.sub_org_name ?? "",
        start_loc_name: this?.props?.startTask?.start_loc_name ?? "",
        start_loc: {
          coordinates: [
            this?.props?.startTask?.start_location_lon ?? 90.3563,
            this?.props?.startTask?.start_location_lat ?? 23.685
          ]
        },
        end_loc_name: this.state.end_location_name,
        end_loc: {
          coordinates: [
            this.state.end_location_lon,
            this.state.end_location_lat
          ]
        },
        drop_contact_no:
          this.state.contact_no !== ""
            ? this.state.code + this.state.contact_no
            : "",
        drop_contact_name: this.state.contact_name
      };
      console.log(data);

      this.props.saveTaskData(data);
      this.props.history.push("/chooseAsset");
    }
  };

  createQuickTask = () => {
    if (this.state.end_location_name === "") {
      swal.fire("Info", "Please Enter Destination", "info");
    } else {
      let data = {
        name: this?.props?.startTask?.name ?? "",
        task_type: this?.props?.startTask?.task_type ?? "",
        task_code: this?.props?.startTask?.task_code ?? "",
        colors: this?.props?.startTask?.colors ?? [],
        start_time: this?.props?.startTask?.start_time ?? Date.now(),
        started: this.props?.startTask?.started ?? false,
        assigned_to: this?.props?.startTask?.assigned_to ?? "",
        assigned_to_name: this?.props?.startTask?.assigned_to_label ?? "",
        sub_org: this.props?.startTask?.sub_org_id ?? "",
        start_loc_name: this?.props?.startTask?.start_loc_name ?? "",
        start_loc: {
          coordinates: [
            this?.props?.startTask?.start_location_lon ?? 90.3563,
            this?.props?.startTask?.start_location_lat ?? 23.685
          ]
        },
        end_loc_name: this.state.end_location_name,
        end_loc: {
          coordinates: [
            this.state.end_location_lon,
            this.state.end_location_lat
          ]
        },
        drop_contact_no:
          this.state.contact_no !== ""
            ? this.state.code + this.state.contact_no
            : "",
        drop_contact_name: this.state.contact_name
      };
      console.log("data from detination", data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_QUICK_TASK}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Info", response.data.message, "info");
            this.props.saveTaskData({});
            if (response.data.message === "Data successfully inserted!") {
              this.setState({ redirect: true });
            }
            console.log("response from create quick task", response);
          })
          .catch(error => {
            swal.fire("Error", error.message, "error");
            console.log("error from create quick task", error);
          });
      });
    }
  };

  saveLocation = () => {
    let data = {
      name: this.state?.end_location_name.substring(0, 14) ?? "",
      address: this.state.end_location_name,
      loc: {
        coordinates: [this.state.end_location_lon, this.state.end_location_lat]
      }
    };
    GET_ACCESS_TOKEN().then(token => {
      let url = `${SAVE_FREQUENT_LOCATION}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Success", response.data.message, "success");
          console.log("response from save location", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from save location", error);
        });
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/startTask" />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Destination"
          onBack={() => this.props.history.goBack()}
        />
        <Form>
          <Form.Item>
            <Text>
              Search End Location
              {/* <Button
                type="link"
                onClick={() =>
                  this.setState({
                    show_google_search: !this.state.show_google_search
                  })
                }
              >
                {this.state.show_google_search
                  ? "Go Back"
                  : "Need More Results ?"}
              </Button> */}
              <Button
                type="link"
                onClick={() => this.setState({ endEntityModal: true })}
                style={{ margin: 5 }}
              >
                Select End Location From Place
              </Button>
              <Button
                type="link"
                onClick={() => this.props.history.push("/createEntity")}
                style={{ margin: 5 }}
              >
                Create Place
              </Button>
            </Text>
            {/* {this.state.show_google_search ? ( */}
            <Select
              showSearch={true}
              labelInValue
              value={this.state.selected_value_google}
              filterOption={false}
              showArrow={false}
              placeholder="Search Google Maps"
              onSearch={value => {
                this.setState({ location_input: value }, () => {
                  console.log(
                    "input location value",
                    this.state.location_input
                  );
                  this.searchGooglemaps();
                });
              }}
              onChange={this.handleChangeGoogle}
              optionLabelProp="label"
            >
              {this.state.end_search_data_google.map(r => {
                return (
                  <Option key={r.id} label={r.value}>
                    {
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column"
                        }}
                      >
                        <Text>{r.value.split(">")[0]}</Text>
                        <Text style={{ fontSize: 10, color: "#9CB4CC" }}>
                          {r.value.split(">")[1]}
                        </Text>
                      </div>
                    }
                  </Option>
                );
              })}
            </Select>
            {/* ) : ( */}
            {/* <Select
                showSearch={true}
                labelInValue
                value={this.state.selected_value}
                filterOption={false}
                showArrow={false}
                placeholder="Search End Location"
                onSearch={value => {
                  this.setState({ location_input: value }, () => {
                    console.log(
                      "input location value",
                      this.state.location_input
                    );
                    this.getEndLocation();
                  });
                }}
                onChange={this.handleChange}
              >
                {this.state.end_search_data.map(r => {
                  return <Option key={r.id}>{r.value}</Option>;
                })}
              </Select> */}
            {/* )} */}
            <Modal
              title="Select End Location"
              visible={this.state.endEntityModal}
              onOk={() => this.setState({ endEntityModal: false })}
              onCancel={() => this.setState({ endEntityModal: false })}
            >
              <List
                size="small"
                bordered
                dataSource={this.state.entityList}
                renderItem={item => (
                  <List.Item
                    key={item._id.$oid}
                    onClick={() => {
                      this.setState({
                        end_location_name: item.address,
                        selected_value_google: {
                          key: item._id.$oid,
                          label: item.address
                        },
                        selected_value: {
                          key: item._id.$oid,
                          label: item.address
                        },
                        end_location_lon: item.loc.coordinates[0],
                        end_location_lat: item.loc.coordinates[1],
                        center_lon: item.loc.coordinates[0],
                        center_lat: item.loc.coordinates[1],
                        endEntityModal: false
                      });
                      if (item.hasOwnProperty("contact")) {
                        this.setState({
                          contact_name: item.contact?.name ?? "",
                          contact_no: item.contact?.mobile?.slice(4) ?? ""
                        });
                      }
                    }}
                  >{`${item.name} - ${item.address}`}</List.Item>
                )}
              />
            </Modal>
          </Form.Item>
          <Form.Item>
            <Text>Selected Location</Text>
            <Card>{this.state.end_location_name}</Card>
          </Form.Item>
          <Form.Item>
            <Map
              center={[this.state.center_lat, this.state.center_lon]}
              zoom={this.state.zoom}
              style={{ height: 500 }}
              onZoomEnd={e => this.setState({ zoom: e.target._zoom })}
            >
              {/* <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
              /> */}
              <ReactLeafletGoogleLayer
                googleMapsLoaderConf={{
                  KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                  VERSION: "3.49.2"
                }}
                type={"roadmap"}
              />

              <Marker
                position={[
                  this.state.end_location_lat,
                  this.state.end_location_lon
                ]}
                draggable
                onDragend={this.updatePosition}
                icon={green}
              >
                <Popup>
                  <span>Destination :{this.state.end_location_name}</span>
                </Popup>
              </Marker>
            </Map>
          </Form.Item>
          <Form.Item>
            <Text>Visiting Frequently ?</Text>

            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.saveLocation}
            >
              Save Destination
            </Button>
          </Form.Item>
          <Form.Item>
            <Text>Contact Number</Text>
            <Select
              value={this.state.code}
              onChange={this.setCode}
              placeholder="Select Coode"
            >
              {this.state.countryCode.map(r => {
                return (
                  <Option key={r.dial_code}>
                    {r.name} {r.dial_code}
                  </Option>
                );
              })}
            </Select>
            <Input
              type="text"
              placeholder="Example:1711123456"
              value={this.state.contact_no}
              onChange={this.setContactNumber}
            />
          </Form.Item>
          <Form.Item>
            <Text>Contact Name</Text>

            <Input
              type="text"
              placeholder="Contact Name"
              value={this.state.contact_name}
              onChange={this.setContactName}
            />
          </Form.Item>
          <Form.Item>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.chooseAsset}
              >
                Choose a Ride
              </Button>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.createQuickTask}
              >
                Just Save Right Away
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
const mapStateToProps = state => ({
  startTask: state.startTask.startTaskData
});
export default connect(mapStateToProps, { saveTaskData })(Destination);
