import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Tooltip,
  Pagination,
  Select,
  Typography,
  Modal,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";

import {
  CREATE_MATERIAL,
  EDIT_MATERIAL,
  QUICKBOOKS_EXPENSE_ACCOUNTS,
  SYNC_QUICKBOOKS_ITEM,
  XERO_EXPENSE_ACCOUNTS,
  XERO_REVENUE_ACCOUNTS,
  SYNC_XERO_ITEM
} from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
// import { Spinner } from "../loader";
import ReactGA from "react-ga4";
import { debounce } from "lodash";

import "../login.css";

const { Column } = Table;
const { Option } = Select;
const { Text } = Typography;

class MaterialList extends Component {
  state = {
    data: [],
    page: 0,
    limit: 10,
    total: 0,
    quickbooks_expense_accounts: [],
    expense_account_id: "",
    item_id: "",
    show_quickbooks_modal: false,
    loading_sync_quickbooks:false,
    xero_expense_accounts: [],
    xero_expense_account_id: "",
    xero_revenue_accounts: [],
    xero_revenue_account_id: "",
    show_xero_modal: false,
    loading_sync_xero:false
  };
  componentDidMount() {
    this.props.getUserData();
    this.getMaterialList();
    this.getQuickbooksExpenseAccounts();
    this.getXeroExpenseAccounts();
    this.getXeroRevenueAccounts();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getQuickbooksExpenseAccounts = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${QUICKBOOKS_EXPENSE_ACCOUNTS}?limit=50&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from quickbooks expense accounts", response);
          this.setState({
            quickbooks_expense_accounts: response.data?.data??[],
          });
        })
        .catch((error) => {
          console.log("error from quickbooks expense accounts", error);
        });
    });
  };

  getXeroExpenseAccounts = debounce((value="") => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${XERO_EXPENSE_ACCOUNTS}?account_name=${value}&limit=50&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from xero expense accounts", response);
          this.setState({
            xero_expense_accounts: response.data?.data??[],
          });
        })
        .catch((error) => {
          console.log("error from xero expense accounts", error);
        });
    });
  },500)

  getXeroRevenueAccounts = debounce((value="") => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${XERO_REVENUE_ACCOUNTS}?account_name=${value}&limit=50&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from xero revenue accounts", response);
          this.setState({
            xero_revenue_accounts: response.data?.data??[],
          });
        })
        .catch((error) => {
          console.log("error from xero revenue accounts", error);
        });
    });
  },500)

  searchAccountsQuickbooks = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${QUICKBOOKS_EXPENSE_ACCOUNTS}?account_name=${value}&limit=50&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("from quickbooks expense accounts search", response);
          this.setState({
            quickbooks_expense_accounts: response.data?.data??[],
          });
        })
        .catch((error) => {
          console.log("error from quickbooks expense accounts search", error);
        });
    });
  }, 500);

  setAccountsQuickbooks = (value) => {
    this.setState({ expense_account_id: value }, () => {
      console.log(this.state.expense_account_id);
    });
  };
  getMaterialList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_MATERIAL}?page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from material list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from material list api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getMaterialList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getMaterialList();
    });
  };

  deleteMaterial = (id) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${EDIT_MATERIAL}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from del", error);
        });
    });
  };

  syncItemQuickbooks=()=>{
    this.setState({loading_sync_quickbooks:true});
    let data = {
      expense_account_id: this.state.expense_account_id,
      item_id: this.state.item_id,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${SYNC_QUICKBOOKS_ITEM}${this.state.item_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({loading_sync_quickbooks:false});
          swal.fire("Info", response.data.message, "info");
          this.setState({
            show_quickbooks_modal: false,
            item_id: "",
            expense_account_id:""
          });
          console.log("response from quickbooks sync item", response);
        })
        .catch((error) => {
          this.setState({loading_sync_quickbooks:false});
          swal.fire("Error", error.message, "error");
          console.log("error from quickbooks sync item", error);
        });
    });
  }

  syncItemXero=()=>{
    this.setState({loading_sync_xero:true});
    let data = {
      expense_account_id: this.state.xero_expense_account_id,
      sales_account_id: this.state.xero_revenue_account_id,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${SYNC_XERO_ITEM}${this.state.item_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({loading_sync_xero:false});
          swal.fire("Info", response.data.message, "info");
          this.setState({
            show_xero_modal: false,
            item_id: "",
            xero_expense_account_id:"",
            xero_revenue_account_id:"",
          });
          console.log("response from xero sync item", response);
        })
        .catch((error) => {
          this.setState({loading_sync_xero:false});
          swal.fire("Error", error.message, "error");
          console.log("error from xero sync item", error);
        });
    });
  }

  render() {
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.bill.item.item_create ? (
          <Link to="/createMaterial">
            <Tooltip placement="top" title="Create Item">
            <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}

         <PageHeader
          title="Items"
        />
        <div style={{ margin: 10 }}>
          <Text>{`Displaying : `}</Text>
          <Select value={this.state.limit} onChange={this.setLimit}>
            <Option key={10}>10</Option>
            <Option key={25}>25</Option>
            <Option key={50}>50</Option>
            <Option key={100}>100</Option>
          </Select>
          <Text>{`  Entries `}</Text>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Price" dataIndex="price" key="price" />
          <Column title="Unit Price" dataIndex="unit_price" key="unit_price" />
          <Column
            title="Item Type"
            key="mtype"
            render={(text, record) => <span>{record.mtype.name}</span>}
          />
          <Column title="Unit" dataIndex="unit" key="unit" />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.state.quickbooks_expense_accounts.length>0?(
                  <Button
                  onClick={() =>
                    this.setState({
                      item_id: record._id.$oid,
                      show_quickbooks_modal: true,
                    })
                  }
                  type="link"
                  style={{ color: "green" }}
                >
                  Sync With Quickbooks
                </Button>
                ):null}
                {this.state.xero_expense_accounts.length>0&&this.state.xero_revenue_accounts.length>0?(
                  <Button
                  onClick={() =>
                    this.setState({
                      item_id: record._id.$oid,
                      show_xero_modal: true,
                    })
                  }
                  type="link"
                  style={{ color: "green" }}
                >
                  Sync With Xero
                </Button>
                ):null}
                

                {this.props.userProfileData.bill.item.item_update ? (
                  <Link
                    to={{
                      pathname: "/editMaterial",
                      state: { material_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.bill.item.item_delete ? (
                  <Button
                    type="link"
                    style={{ color: "red" }}
                    onClick={() => {
                      if (window.confirm("Delete the item?")) {
                        this.deleteMaterial(record._id.$oid);
                      }
                    }}
                  >
                    Delete
                  </Button>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
        <Modal
          destroyOnClose={true}
          footer={null}
          visible={this.state.show_quickbooks_modal}
          onCancel={() => {
            this.setState({
              show_quickbooks_modal: false,
              item_id: "",
            });
          }}
          closable={true}
        >
          <div style={{ display: "flex", flexDirection: "column",marginBottom:5 }}>
            <Text>Select Expense Account</Text>
            <Select
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue
              value={this.state.expense_account_id}
              onSearch={this.searchAccountsQuickbooks}
              onChange={this.setAccountsQuickbooks}
            >
              {this.state.quickbooks_expense_accounts.map((r) => {
                return <Option key={r.Id}>{r.Name}</Option>;
              })}
            </Select>
          </div>
          <Button
              loading={this.state.loading_sync_quickbooks}
              className="login-form-button"
              htmlType="submit"
              onClick={() =>this.syncItemQuickbooks()}
            >
              Sync
            </Button>
        </Modal>
        <Modal
          destroyOnClose={true}
          footer={null}
          visible={this.state.show_xero_modal}
          onCancel={() => {
            this.setState({
              show_xero_modal: false,
              item_id: "",
            });
          }}
          closable={true}
        >
          <div style={{ display: "flex", flexDirection: "column",marginBottom:5 }}>
            <Text>Select Expense Account</Text>
            <Select
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue
              value={this.state.xero_expense_account_id}
              onSearch={(value)=>this.getXeroExpenseAccounts(value)}
              onChange={(value) => {
                this.setState({ xero_expense_account_id: value }, () => {
                  console.log(this.state.xero_expense_account_id);
                });
              }}
            >
              {this.state.xero_expense_accounts.map((r) => {
                return <Option key={r.Code}>{r.Name}</Option>;
              })}
            </Select>
          </div>
          <div style={{ display: "flex", flexDirection: "column",marginBottom:5 }}>
            <Text>Select Revenue Account</Text>
            <Select
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue
              value={this.state.xero_revenue_account_id}
              onSearch={(value)=>this.getXeroRevenueAccounts(value)}
              onChange={(value) => {
                this.setState({ xero_revenue_account_id: value }, () => {
                  console.log(this.state.xero_revenue_account_id);
                });
              }}
            >
              {this.state.xero_revenue_accounts.map((r) => {
                return <Option key={r.Code}>{r.Name}</Option>;
              })}
            </Select>
          </div>
          <Button
              loading={this.state.loading_sync_xero}
              className="login-form-button"
              htmlType="submit"
              onClick={() =>this.syncItemXero()}
            >
              Sync
            </Button>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(MaterialList);
