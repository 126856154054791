import React, { Component } from "react";
import {
  Card,
  Typography,
  PageHeader,
  Modal,
  Form,
  // Table,
  Tooltip,
  Button,
  Input,
  Select,
  Switch,
  Icon,
  Tag,
  Radio,
  Checkbox,
  Divider,
  Slider,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import axios from "axios";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

// import FileSaver from "file-saver";
import moment from "moment";

import {
  EDIT_TASK,
  TASK_ROUTE,
  ASSIGNED_USER_LOC,
  // TRANSPORT_BILL,
  // TRANSPORT_TYPE,
  // LOAD_PARCEL,
  // UNLOAD_PARCEL,
  // CHECK_PARCEL,
  CREATE_CUSTOM_FIELD,
  ADD_CUSTOM_FIELDS_TO_TASK,
  MULTI_IMAGE_UPLOAD,
  CREATE_CUSTOM_FORM,
  SUBMIT_CUSTOM_FORM,
  EDIT_CUSTOM_FORM_SUBMITTED_DATA,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  LOCAL_USER_INFO,
  timeConverter,
  GET_USER_LOCAL,
  isValidURL,
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

const { Text, Title } = Typography;
// const { Column } = Table;
const { Option } = Select;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40],
});
const red = new L.Icon({
  iconUrl: require("../.././assets/mymarkertwo.png"),
  iconSize: [30, 40],
});
const user = new L.Icon({
  iconUrl: require("../.././assets/user.png"),
  iconSize: [25, 40],
});

class ViewTask extends Component {
  state = {
    start_location: "",
    end_location: "",
    data: [],
    fetch: false,
    center_lat: 23.685,
    center_lon: 90.3563,
    start_location_lat: 23.685,
    start_location_lon: 90.3563,
    end_location_lat: 23.685,
    end_location_lon: 90.3563,
    name: "",
    details: "",
    start_time: "",
    start_time_view: "",
    end_time: "",
    end_time_view: "",
    task_code: "",
    contact_id: "",
    contact_no: "",
    contact_name: "",
    contact_info: "",
    drop_contact_id: "",
    drop_contact_no: "",
    drop_contact_name: "",
    drop_contact_info: "",
    price: "",
    assigned_to: "",
    assigned_to_label: "",
    userData: [],
    assigned_asset: "",
    assigned_asset_label: "",
    delay_mins: null,
    trip_duration: 0,
    started: false,
    accepted: false,
    completed: false,
    cancelled: false,
    is_public: false,
    loading: false,
    isChainTask: false,
    delivery_time: "",
    delivery_time_view: moment(),
    delivery_time_by_customer: "",
    delivery_time_by_customer_view: moment(),
    delay_reason: "",

    prev_task_id: "",
    m_task_id: "",
    start_dist: null,
    end_dist: null,
    task_id: "",
    disable: false,
    disableState: false,
    currentUserid: "",
    checkAssign: "",
    checkPublic: false,
    checkStarted: false,
    checkAccepted: false,
    checkCompleted: false,
    polyData: [],
    assignedUserLat: "",
    assignedUserLon: "",
    userLocLon: 0,
    userLocLat: 0,
    task_link: "",
    zoom: 8,
    task_status: "",
    created_by_name: "",
    created_by_mobile: "",
    created_by_email: "",
    created_on: "",
    transport_bill_modal: false,
    transport_type: "",
    transport_name: "",
    transport_fare: null,
    transport_bill_data: [],
    total_transport_bill: null,
    transport_options: [],
    transport_bill_edit: false,
    transport_bill_id: "",
    taskData: {},
    disableLoad: false,
    timezone_name: "",
    timezone_choice: "org_timezone",
    custom_fields: [],
    custom_field_data: {},
    customArrayHandle: [],
    customArrayText: "",
    getCustomFieldData: {},
    assigned_user_name: "",
    assigned_user_no: "",
    assigned_asset_name: "",
    task_type: "",
    sub_org_id: "",
    sub_org_name: "",
    sub_org_type: "",
    customFormData: [],
    custom_form_id: "",
    custom_form_fields: [],
    show_custom_form_modal: false,
    custom_form_field_post_object: {},
    custom_form_submitted_data: [],
    document_id: "",
    view_submitted_data_modal: false,
  };

  componentDidMount() {
    this.props.getUserData();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    window.scrollTo(0, 0);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ timezone_name: timezone });
    console.log("time zone", timezone);

    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState({
        userLocLat: latitude,
        userLocLon: longitude,
      });
    };
    navigator.geolocation.getCurrentPosition(success);

    this.setState(
      {
        loading: true,
        task_id: this.props.location.state.task_id,
        checkAssign: this.props.location.state.assigned_to,
        checkPublic: this.props.location.state.is_public,
        checkStarted: this.props.location.state.is_started,
        checkAccepted: this.props.location.state.is_accepted,
        checkCompleted: this.props.location.state.is_completed,
        currentUserid: LOCAL_USER_INFO().user._id,
      },
      () => {
        if (this.state.checkStarted === true) {
          this.getTaskRoute();
          // this.getTransportBill();
          // this.getTransportType();
        } else if (this.state.checkCompleted === true) {
          swal.fire("Task Status", "COMPLETED", "info");
          this.getTaskRoute();
          // this.getTransportBill();
          // this.getTransportType();
        }
      }
    );
    this.getTask();
    // this.getTransportType();
    // this.getCustomFields();
  }

  getCustomFields = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_CUSTOM_FIELD}?field_for=task&task_type=${this.state.task_type}&sort_type=asc&sort_by=created_on&access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            custom_fields: response.data.data,
          });
          console.log("response from get custom fields", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from get custom fields", error);
        });
    });
  };
  getCustomFormSubmissionData = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${SUBMIT_CUSTOM_FORM}?associated_with=${this.state.task_id}&form_id=${this.state.custom_form_id}&access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            custom_form_submitted_data: response.data.data,
          });
          console.log(
            "response from get custom form submission data ",
            response
          );
        })
        .catch((error) => {
          // swal.fire("Error", error.message, "error");
          console.log("error from get custom form submission ", error);
        });
    });
  };

  // getTransportBill = () => {
  //   GET_ACCESS_TOKEN().then((token) => {
  //     let url = `${TRANSPORT_BILL}${this.state.task_id}?access_token=${token}`;
  //     axios({
  //       method: "GET",
  //       url: url,
  //       headers: {
  //         "content-type": "application/x-www-form-urlencoded",
  //       },
  //     })
  //       .then((response) => {
  //         this.setState({
  //           transport_bill_data: response.data.data.bills.transport_bills,
  //           total_transport_bill: response.data.data.bills.total_bill,
  //         });
  //         console.log("response from get trasnport bills", response);
  //       })
  //       .catch((error) => {
  //         this.setState({ loading: false });
  //         swal.fire("Error", error.message, "error");
  //         console.log("error from get trasnport bills", error);
  //       });
  //   });
  // };

  // getTransportType = () => {
  //   GET_ACCESS_TOKEN().then((token) => {
  //     let url = `${TRANSPORT_TYPE}?access_token=${token}`;
  //     axios({
  //       method: "GET",
  //       url: url,
  //       headers: {
  //         "content-type": "application/x-www-form-urlencoded",
  //       },
  //     })
  //       .then((response) => {
  //         this.setState({
  //           transport_options: response.data.bill_type_choices,
  //         });
  //         console.log("response from get trasnport type", response);
  //       })
  //       .catch((error) => {
  //         this.setState({ loading: false });
  //         swal.fire("Error", error.message, "error");
  //         console.log("error from get trasnport type", error);
  //       });
  //   });
  // };

  getTaskRoute = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${TASK_ROUTE}${this.state.task_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
        body: { is_public: this.state.checkPublic },
      }).then((response) => {
        console.log("response from task route", response.data.route_data);
        this.setState({ polyData: response.data?.route_data ?? [] });
      });
    });
  };

  // checkParcel = (parcel) => {
  //   let data = {
  //     container: this.state.taskData.container_id,
  //     parcels: this.state.taskData.order_parcel,
  //   };
  //   console.log(data);
  //   GET_ACCESS_TOKEN().then((token) => {
  //     let url = `${CHECK_PARCEL}?access_token=${token}`;
  //     axios({
  //       method: "post",
  //       url: url,
  //       data: data,
  //       headers: {
  //         "content-type": "application/x-www-form-urlencoded",
  //       },
  //     })
  //       .then((response) => {
  //         console.log("response from check status parcel", response);
  //         if (response.data.data[0].available === true) {
  //           this.setState({ disableLoad: true });
  //         } else if (response.data.data[0].available === false) {
  //           this.setState({ disableLoad: false });
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("error from check status parcel", error);
  //       });
  //   });
  // };

  // loadParcel = (parcel) => {
  //   let data = {
  //     container: this.state.taskData.container_id,
  //     parcels: this.state.taskData.order_parcel,
  //   };
  //   console.log(data);
  //   GET_ACCESS_TOKEN().then((token) => {
  //     let url = `${LOAD_PARCEL}?access_token=${token}`;
  //     axios({
  //       method: "post",
  //       url: url,
  //       data: data,
  //       headers: {
  //         "content-type": "application/x-www-form-urlencoded",
  //       },
  //     })
  //       .then((response) => {
  //         swal.fire("Success", response.data.message, "success");
  //         this.checkParcel();
  //         console.log("response from load parcel", response);
  //       })
  //       .catch((error) => {
  //         swal.fire("Error", error.message, "error");
  //         console.log("error from load parcel", error);
  //       });
  //   });
  // };

  // unloadparcel = () => {
  //   let data = {
  //     container: this.state.taskData.container_id,
  //     parcels: this.state.taskData.order_parcel,
  //   };
  //   console.log(data);
  //   GET_ACCESS_TOKEN().then((token) => {
  //     let url = `${UNLOAD_PARCEL}?access_token=${token}`;
  //     axios({
  //       method: "post",
  //       url: url,
  //       data: data,
  //       headers: {
  //         "content-type": "application/x-www-form-urlencoded",
  //       },
  //     })
  //       .then((response) => {
  //         swal.fire("Success", response.data.message, "success");
  //         this.checkParcel();
  //         console.log("response from unload parcel", response);
  //       })
  //       .catch((error) => {
  //         swal.fire("Error", error.message, "error");
  //         console.log("error from unload parcel", error);
  //       });
  //   });
  // };

  getCustomFormData = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_CUSTOM_FORM}?associated_with=tasks&task_type=${this.state.task_type}&_id=${this.state.custom_form_id}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from custom form lists", response);
          this.setState(
            {
              customFormData: response.data?.data ?? [],
            },
            () => {
              this.getCustomFormSubmissionData();
            }
          );
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from custom form lists", error);
        });
    });
  };
  getCustomFormDataWithId = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_CUSTOM_FORM}?associated_with=tasks&task_type=${this.state.task_type}&_id=${this.state.custom_form_id}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from custom form lists", response);
          this.setState(
            {
              custom_form_id:
                response.data.data.length === 1
                  ? response.data.data[0]._id.$oid
                  : "",
              custom_form_fields:
                response.data.data.length === 1
                  ? response.data.data[0].fields
                  : [],
            },
            () => {
              this.getCustomFormSubmissionData();
            }
          );
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from custom form lists", error);
        });
    });
  };

  getTask = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let task_id = this.props.location.state.task_id;
      let url = `${EDIT_TASK}${task_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response from edit task", response);
        this.setState({ loading: false });

        if (response.data.message === "Task not found") {
          swal.fire("Info", "Task Not Found", "info");
          this.props.history.goBack();
        } else {
          if (response.data.data.created_by !== this.state.currentUserid) {
            this.setState({ disable: true }, () => {
              console.log("disable value", this.state.disable);
            });
          }
          this.setState(
            {
              taskData: response.data.data,
              name: response.data.data.name,
              task_type: response?.data?.data?.task_type ?? "",
              details: response.data.data.details,
              delay_reason: response.data.data.delay_reason,
              start_location: response.data.data.start_loc_name || "",
              end_location: response.data.data.end_loc_name || "",
              start_location_lat: response.data.data.start_loc.coordinates
                ? response.data.data.start_loc.coordinates[1]
                : 23.685,
              start_location_lon: response.data.data.start_loc.coordinates
                ? response.data.data.start_loc.coordinates[0]
                : 90.3563,
              center_lat: response.data.data.start_loc.coordinates
                ? response.data.data.start_loc.coordinates[1]
                : 23.685,
              center_lon: response.data.data.start_loc.coordinates
                ? response.data.data.start_loc.coordinates[0]
                : 90.3563,
              end_location_lat: response.data.data.end_loc.coordinates
                ? response.data.data.end_loc.coordinates[1]
                : 23.685,
              end_location_lon: response.data.data.end_loc.coordinates
                ? response.data.data.end_loc.coordinates[0]
                : 90.3563,
              start_time: response.data.data.start_time.$date,
              start_time_view: response.data.data.start_time.$date,
              end_time_view: response.data.data.end_time.$date,
              end_time: response.data.data.end_time.$date,
              delivery_time_view: response.data.data.delivery_time
                ? response.data.data.delivery_time.$date
                : "",
              delivery_time: response.data.data.delivery_time
                ? response.data.data.delivery_time.$date
                : "",
              delivery_time_by_customer_view: response.data.data
                .delivery_time_by_customer
                ? response.data.data.delivery_time_by_customer.$date
                : "",
              delivery_time_by_customer: response.data.data
                .delivery_time_by_customer
                ? response.data.data.delivery_time_by_customer.$date
                : "",
              task_code: response.data.data.task_code,
              contact_id: response.data.data.contact_id,
              contact_no: response.data.data.contact_no,
              contact_name: response.data.data.contact_name,
              drop_contact_id: response.data.data.drop_contact_id,
              drop_contact_no: response.data.data.drop_contact_no,
              drop_contact_name: response.data.data.drop_contact_name,
              price: response.data.data.price,
              assigned_user_name: response.data.data.assigned_user_name,
              assigned_user_no: response.data.data.assigned_user_no,
              assigned_to: response.data.data.assigned_to,
              sub_org_id: response?.data?.data?.sub_org?.id ?? "",
              sub_org_name: response?.data?.data?.sub_org?.name ?? "",
              sub_org_type:
                response?.data?.data?.sub_org?.sub_org_type_data?.name ?? "",
              assigned_asset: response.data.data.assigned_asset,
              assigned_asset_name:
                response.data?.data?.assigned_asset_name ?? "",
              delay_mins: response.data.data.delay_mins,
              colors: response.data.data.colors,
              trip_duration: response.data.data.trip_duration,
              is_public: response.data.data.is_public,

              prev_task_id: response.data.data.prev_task_id,
              m_task_id: response.data.data.m_task_id,
              start_dist: response.data.data.start_dist,
              end_dist: response.data.data.end_dist,
              task_link: response.data.data.task_link,
              task_status: response.data.data.task_status,
              created_by_name: response.data.data.created_by_details.name,
              created_by_mobile: response.data.data.created_by_details.mobile,
              created_by_email: response.data.data.created_by_details.email,
              created_on: response.data.data.created_on.$date,
              accepted: response.data.data.accepted,
              started: response.data.data.started,
              completed: response.data.data.completed,
              cancelled: response.data.data.cancelled,
              getCustomFieldData: response.data?.data?.custom_fields ?? {},
            },
            () => {
              console.log("taskdata", this.state.taskData);
              this.getCustomFormData();
              this.getCustomFields();
              if (this.state.checkAssign !== "") {
                GET_ACCESS_TOKEN().then((token) => {
                  let url = `${ASSIGNED_USER_LOC}${this.state.assigned_to}?access_token=${token}`;
                  axios({
                    method: "get",
                    url: url,
                  }).then((response) => {
                    console.log("response from assigned user loc", response);
                    if (response.data.message === "Location not allowed") {
                      swal.fire(
                        "Warning",
                        "No location data found for assigned user !",
                        "warning"
                      );
                    } else {
                      this.setState({
                        assignedUserLat: response.data.data.latitude,
                        assignedUserLon: response.data.data.longitude,
                      });
                    }
                  });
                });
              }

              if (this.state.assigned_to !== LOCAL_USER_INFO().user._id) {
                this.setState({ disableState: true });
              }

              if (this.state.taskData.hasOwnProperty("container_id")) {
                this.checkParcel();
              }
            }
          );
        }
      });
    });
  };

  // createTransportBill = () => {
  //   if (this.state.transport_type === "" || this.state.fare === null) {
  //     swal.fire("Info", "Please enter transport type and fare!", "info");
  //   } else {
  //     this.setState({ transport_bill_modal: false });
  //     const data = {
  //       transport_bills: {
  //         name: this.state.transport_name,
  //         type: this.state.transport_type,
  //         fare: Number(this.state.transport_fare),
  //         start_loc: {
  //           coordinates: [this.state.userLocLon, this.state.userLocLat],
  //         },
  //       },
  //     };
  //     console.log(data);
  //     GET_ACCESS_TOKEN().then((token) => {
  //       let url = `${TRANSPORT_BILL}${this.state.task_id}?access_token=${token}`;
  //       axios({
  //         method: "put",
  //         url: url,
  //         data: data,
  //         headers: {
  //           "content-type": "application/x-www-form-urlencoded",
  //         },
  //       })
  //         .then((response) => {
  //           swal.fire("Success", response.data.message, "success");
  //           console.log("response from generate trasnport bill", response);
  //           this.getTransportBill();
  //         })
  //         .catch((error) => {
  //           swal.fire("Error", error.message, "error");
  //           console.log("error from generate trasnport bill", error);
  //         });
  //     });
  //   }
  // };

  // updateTransportBill = (bill_id, name, fare, type) => {
  //   this.setState({ transport_bill_edit: false });
  //   const data = {
  //     transport_bills: {
  //       _id: bill_id,
  //       fare: Number(fare),
  //     },
  //   };
  //   console.log(data);
  //   GET_ACCESS_TOKEN().then((token) => {
  //     let url = `${TRANSPORT_BILL}${this.state.task_id}?access_token=${token}`;
  //     axios({
  //       method: "put",
  //       url: url,
  //       data: data,
  //       headers: {
  //         "content-type": "application/x-www-form-urlencoded",
  //       },
  //     })
  //       .then((response) => {
  //         swal.fire("Success", response.data.message, "success");
  //         console.log("response from edit trasnport bill", response);
  //         this.setState({
  //           transport_name: "",
  //           transport_fare: null,
  //           transport_type: "",
  //           transport_bill_id: "",
  //         });
  //         this.getTransportBill();
  //       })
  //       .catch((error) => {
  //         swal.fire("Error", error.message, "error");
  //         this.setState({
  //           transport_name: "",
  //           transport_fare: null,
  //           transport_type: "",
  //           transport_bill_id: "",
  //         });
  //         console.log("error from edit trasnport bill", error);
  //       });
  //   });
  // };

  setTimezonetype = (value) => {
    this.setState({ timezone_choice: value }, () => {
      console.log(this.state.timezone_choice);
    });
  };

  setTransportType = (value) => {
    this.setState({ transport_type: value }, () => {
      console.log(this.state.transport_type);
    });
  };
  setTransportName = (evt) => {
    evt.preventDefault();
    this.setState({ transport_name: evt.target.value }, () => {
      console.log(this.state.transport_name);
    });
  };
  setTransportFare = (evt) => {
    evt.preventDefault();
    this.setState({ transport_fare: evt.target.value }, () => {
      console.log(this.state.transport_fare);
    });
  };

  handleAchange = (checked) => {
    this.setState({ accepted: checked }, () => {
      console.log(this.state.accepted);
      if (this.state.accepted) {
        const data = {
          accepted: this.state.accepted,
          accepted_loc: {
            coordinates: [this.state.userLocLon, this.state.userLocLat],
          },
          accepted_loc_name: "",
        };
        console.log(data);
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_TASK}${this.state.task_id}?access_token=${token}`;
          axios({
            method: "put",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded",
            },
          })
            .then((response) => {
              swal.fire("Info", response.data.message, "info");
              if (response.data.message === "Data successfully updated!") {
                this.getTask();
              }
              this.getTask();
              console.log("response from accepted task", response);
            })
            .catch((error) => {
              this.getTask();
              swal.fire("Error", error.message, "error");
              console.log("error from edit task", error);
            });
        });
      }
    });
  };
  handleSchange = (checked) => {
    this.setState({ started: checked }, () => {
      console.log(this.state.started);
      if (this.state.started) {
        const data = {
          started: this.state.started,
          started_loc: {
            coordinates: [this.state.userLocLon, this.state.userLocLat],
          },
          started_loc_name: "",
        };
        console.log(data);
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_TASK}${this.state.task_id}?access_token=${token}`;
          axios({
            method: "put",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded",
            },
          })
            .then((response) => {
              swal.fire("Info", response.data.message, "info");
              if (response.data.message === "Data successfully updated!") {
                this.getTask();
                this.setState({ checkStarted: true });
              }
              this.getTask();

              console.log("response from started task", response);
            })
            .catch((error) => {
              this.getTask();
              swal.fire("Error", error.message, "error");
              console.log("error from edit task", error);
            });
        });
      }
    });
  };
  handleCochange = (checked) => {
    this.setState({ completed: checked }, () => {
      console.log(this.state.completed);
      if (this.state.completed) {
        const data = {
          completed: this.state.completed,
          completed_loc: {
            coordinates: [this.state.userLocLon, this.state.userLocLat],
          },
          completed_loc_name: "",
        };
        console.log(data);
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_TASK}${this.state.task_id}?access_token=${token}`;
          axios({
            method: "put",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded",
            },
          })
            .then((response) => {
              swal.fire("Info", response.data.message, "info");
              if (response.data.message === "Data successfully updated!") {
                this.getTask();
                this.setState({ checkCompleted: true });
              }
              this.getTask();
              console.log("response from completed task", response);
            })
            .catch((error) => {
              this.getTask();
              swal.fire("Error", error.message, "error");
              console.log("error from edit task", error);
            });
        });
      }
    });
  };
  handleCachange = (checked) => {
    this.setState({ cancelled: checked }, () => {
      console.log(this.state.cancelled);
      if (this.state.cancelled) {
        const data = {
          cancelled: this.state.cancelled,
        };
        console.log(data);
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_TASK}${this.state.task_id}?access_token=${token}`;
          axios({
            method: "put",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded",
            },
          })
            .then((response) => {
              swal.fire("Info", response.data.message, "info");
              if (response.data.message === "Data successfully updated!") {
                this.getTask();
              }
              this.getTask();
              console.log("response from cancelled task", response);
            })
            .catch((error) => {
              this.getTask();
              swal.fire("Error", error.message, "error");
              console.log("error from edit task", error);
            });
        });
      }
    });
  };

  // handleSaveFile = () => {
  //   GET_ACCESS_TOKEN().then((token) => {
  //     const url =
  //       this.state.timezone_choice === "org_timezone"
  //         ? `${TRANSPORT_BILL}${this.state.task_id}?fmt=xls&timezone_choice=${this.state.timezone_choice}&access_token=${token}`
  //         : `${TRANSPORT_BILL}${this.state.task_id}?fmt=xls&timezone_choice=${this.state.timezone_choice}&timezone_name=${this.state.timezone_name}&access_token=${token}`;
  //     fetch(url)
  //       .then((res) => res.blob())
  //       .then((blob) => {
  //         console.log("response from transport bill list fmt", blob);

  //         FileSaver.saveAs(
  //           blob,
  //           `transport_bill_report_${moment().format(
  //             "DD-MMMM-YYYY_hh:mm A"
  //           )}.xlsx`
  //         );
  //       })
  //       .catch((error) => {
  //         console.log("error from transport bill list api", error);
  //       });
  //   });
  // };

  updateCustomTaskData = (checked) => {
    if (Object.keys(this.state.custom_field_data).length === 0) {
      swal.fire("Info", "Please Enter Atleast One Value", "info");
    } else {
      const data = {
        field_for: "task",
        task_type: this.state.task_type,
        custom_fields: this.state.custom_field_data,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${ADD_CUSTOM_FIELDS_TO_TASK}${this.state.task_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            if (response.data.message === "Data successfully updated!") {
              this.getTask();
            }
            console.log(
              "response from custom field task data update ",
              response
            );
          })
          .catch((error) => {
            this.getTask();
            swal.fire("Error", error.message, "error");
            console.log("error from custom field task data update ", error);
          });
      });
    }
  };

  updateCustomFormData = () => {
    if (Object.keys(this.state.custom_form_field_post_object).length === 0) {
      swal.fire("Info", "Please Enter Atleast One Value", "info");
    } else {
      const data = {
        form_id: this.state.custom_form_id,
        submission_data: this.state.custom_form_field_post_object,
        associated_with: this.state.task_id,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${SUBMIT_CUSTOM_FORM}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            if (
              response.data.message === "Form submission created successfully"
            ) {
              this.getCustomFormSubmissionData();
              this.setState({ custom_form_field_post_object: {} });
            }
            console.log("response from custom form task data post ", response);
          })
          .catch((error) => {
            swal.fire("Error", error.message, "error");
            console.log("error from custom form task data post ", error);
          });
      });
    }
  };
  editCustomFormSubmittedData = () => {
    if (Object.keys(this.state.custom_form_field_post_object).length === 0) {
      swal.fire("Info", "Please Enter Atleast One Value", "info");
    } else {
      const data = {
        form_id: this.state.custom_form_id,
        submission_data: this.state.custom_form_field_post_object,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_CUSTOM_FORM_SUBMITTED_DATA}${this.state.document_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            if (
              response.data.message === "Form submission updated successfully"
            ) {
              this.getCustomFormSubmissionData();
              this.setState({ custom_form_field_post_object: {} });
            }
            console.log(
              "response from custom form task submission data put ",
              response
            );
          })
          .catch((error) => {
            swal.fire("Error", error.message, "error");
            console.log(
              "error from custom form task submission data put ",
              error
            );
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    const { custom_field_data } = this.state;
    const { custom_form_field_post_object } = this.state;

    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title="View Task"
          onBack={() => this.props.history.goBack()}
        />
        <Map
          center={[this.state.center_lat, this.state.center_lon]}
          zoom={this.state.zoom}
          style={{ height: 500 }}
          onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
        >
          {/* <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          /> */}

          <ReactLeafletGoogleLayer
            googleMapsLoaderConf={{
              KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
              VERSION: "3.49.2",
            }}
            type={"roadmap"}
          />

          {this.state.checkStarted || this.state.checkCompleted ? (
            <Polyline
              color="purple"
              positions={[
                this.state.polyData.map((m) => [
                  m.loc.coordinates[1],
                  m.loc.coordinates[0],
                ]),
              ]}
            />
          ) : null}
          <Marker
            position={[
              this.state.start_location_lat,
              this.state.start_location_lon,
            ]}
            icon={green}
          >
            <Popup>
              <span>From :{this.state.start_location}</span>
            </Popup>
          </Marker>
          {this.state.started === true && this.state.completed === false ? (
            <Marker
              position={[
                this.state.assignedUserLat,
                this.state.assignedUserLon,
              ]}
              icon={user}
            >
              <Popup>
                <span>Assigned User Location</span>
              </Popup>
            </Marker>
          ) : null}

          {this.state.start_location !== "" ? (
            <Marker
              position={[
                this.state.end_location_lat,
                this.state.end_location_lon,
              ]}
              icon={red}
            >
              <Popup>
                <span>To :{this.state.end_location}</span>
              </Popup>
            </Marker>
          ) : null}
        </Map>
        {this.state.completed && GET_USER_LOCAL().org_admin ? (
          <Card
            style={{
              marginTop: 10,
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <div
              style={{
                marginRight: 5,
              }}
            >
              <Link
                to={{
                  pathname: "/userRoute",
                  state: {
                    search_user: this.state.assigned_to,
                    start_time: this.state.start_time,
                    end_time: this.state.end_time,
                    user_name: "",
                  },
                }}
              >
                <Button className="login-form-button">
                  View Route History
                </Button>
              </Link>
            </div>
          </Card>
        ) : null}

        {this.state.disableState ? null : (
          <Card
            style={{
              marginTop: 10,
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  marginRight: 5,
                }}
              >
                <Switch
                  checkedChildren="Accepted"
                  unCheckedChildren="Accepted"
                  checked={this.state.accepted}
                  onChange={this.handleAchange}
                  disabled={this.state.accepted}
                />
              </div>
              <div
                style={{
                  marginRight: 5,
                }}
              >
                <Switch
                  checkedChildren="Started"
                  unCheckedChildren="Started"
                  checked={this.state.started}
                  onChange={this.handleSchange}
                  disabled={this.state.started}
                />
              </div>
              <div
                style={{
                  marginRight: 5,
                }}
              >
                <Switch
                  checkedChildren="Completed"
                  unCheckedChildren="Completed"
                  checked={this.state.completed}
                  onChange={this.handleCochange}
                  disabled={this.state.completed}
                />
              </div>
              <div
                style={{
                  marginRight: 5,
                }}
              >
                <Switch
                  checkedChildren="Cancelled"
                  unCheckedChildren="Cancelled"
                  checked={this.state.cancelled}
                  onChange={this.handleCachange}
                  disabled={this.state.cancelled}
                />
              </div>
            </div>
          </Card>
        )}
        {this.state.taskData.hasOwnProperty("container_id") ? (
          <Card
            style={{
              marginTop: 10,
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                textAlign: "center",
              }}
            >
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.loadParcel}
                disabled={this.state.disableLoad}
              >
                Load Parcel
              </Button>

              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.unloadparcel}
                disabled={!this.state.disableLoad}
              >
                Unload Parcel
              </Button>
            </div>
          </Card>
        ) : null}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Card
            style={{
              marginRight: 10,
              marginTop: 10,
              width: "60%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text className="task-view-text">
                <Icon type="environment" style={{ margin: 5 }} />
                Start Location : {""}
                <Text>{this.state.start_location}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="environment" style={{ margin: 5 }} />
                End Location : {""}
                <Text>{this.state.end_location}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="clock-circle" style={{ margin: 5 }} />
                Start Time : {""}
                <Text>
                  {this.state.start_time
                    ? timeConverter(this.state.start_time)
                    : ""}
                </Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="clock-circle" style={{ margin: 5 }} />
                End Time : {""}
                <Text>
                  {this.state.end_time
                    ? timeConverter(this.state.end_time)
                    : ""}
                </Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="clock-circle" style={{ margin: 5 }} />
                Delivery Time : {""}
                <Text>
                  {this.state.delivery_time
                    ? timeConverter(this.state.delivery_time)
                    : ""}
                </Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="clock-circle" style={{ margin: 5 }} />
                Delivery Time by Customer : {""}
                <Text>
                  {this.state.delivery_time_by_customer
                    ? timeConverter(this.state.delivery_time_by_customer)
                    : ""}
                </Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="book" style={{ margin: 5 }} />
                Task Name: {""}
                <Text>{this.state.name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="book" style={{ margin: 5 }} />
                Task Type: {""}
                <Text>{this.state.task_type}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="book" style={{ margin: 5 }} />
                Task Code: {""}
                <Text>{this.state.task_code}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="book" style={{ margin: 5 }} />
                Task Status: {""}
                <Text>{this.state.task_status}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="book" style={{ margin: 5 }} />
                Task Details: {""}
                <Text>{this.state.details}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="book" style={{ margin: 5 }} />
                Delay Reason: {""}
                <Text>{this.state.delay_reason}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="link" style={{ margin: 5 }} />
                Task Link: {""}
                <Text>
                  <a
                    href={`https://web.conveyance.app/task-link/${this.state.task_link}`}
                  >
                    {" "}
                    {`https://web.conveyance.app/task-link/${this.state.task_link}`}
                  </a>
                </Text>
              </Text>
            </div>
          </Card>
          <Card
            style={{
              marginTop: 10,
              width: "40%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text className="task-view-text">
                <Icon type="contacts" style={{ margin: 5 }} />
                Pickup Contact Name: {""}
                <Text>{this.state.contact_name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="contacts" style={{ margin: 5 }} />
                Pickup Contact Number: {""}
                <Text>{this.state.contact_no}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="contacts" style={{ margin: 5 }} />
                Drop Contact Name: {""}
                <Text>{this.state.drop_contact_name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="contacts" style={{ margin: 5 }} />
                Drop Contact Number: {""}
                <Text>{this.state.drop_contact_no}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="file" style={{ margin: 5 }} />
                Public Task: {""}
                <Text>{this.state?.is_public?.toString() ?? false}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Assigned User Name: {""}
                <Text>{this.state.assigned_user_name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Assigned User Mobile: {""}
                <Text>{this.state.assigned_user_no}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Sub Organization Name: {""}
                <Text>{this.state.sub_org_name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Sub Organization Type: {""}
                <Text>{this.state.sub_org_type}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Assigned Asset: {""}
                <Text>{this.state.assigned_asset_name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Email: {""}
                <Text>{this.state.created_by_email}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Created By: {""}
                <Text>{this.state.created_by_name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="dollar" style={{ margin: 5 }} />
                Price: {""}
                <Text>{this.state.price}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="clock-circle" style={{ margin: 5 }} />
                Trip Duration: {""}
                <Text>{this.state.trip_duration}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="clock-circle" style={{ margin: 5 }} />
                Created On: {""}
                <Text>{timeConverter(this.state.created_on)}</Text>
              </Text>
            </div>
          </Card>
        </div>

        <Card
          style={{
            marginTop: 10,
            width: "100%",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text>Select Custom Form</Text>
              <Select
                style={{ width: 200 }}
                value={this.state.custom_form_id}
                onChange={(value) =>
                  this.setState({ custom_form_id: value }, () =>
                    this.getCustomFormDataWithId()
                  )
                }
              >
                {this.state.customFormData.map((r) => {
                  return <Option key={r._id.$oid}>{r.title}</Option>;
                })}
              </Select>
            </div>
            {this.state.custom_form_fields.length > 0 ? (
              <Button
                style={{ marginLeft: 10, marginTop: 21 }}
                type="primary"
                onClick={() => this.setState({ show_custom_form_modal: true })}
              >
                View Form
              </Button>
            ) : null}
          </div>
        </Card>
        {this.state.custom_form_submitted_data.length > 0 &&
        this.props.userProfileData.custom_form.custom_form_data ? (
          <Card
            style={{
              marginTop: 10,
              width: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Title style={{ marginLeft: 1 }} level={4}>
              Custom Form Submitted Data
            </Title>
            <>
              <>
                <table
                  style={{
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                    paddingTop: 15,
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                >
                  <thead>
                    <tr>
                      {Object.keys(
                        this.state.custom_form_submitted_data[0].submitted_data
                      ).map((data, index) => (
                        <th
                          style={{
                            border: "1px solid grey",
                            borderCollapse: "collapse",
                            paddingTop: 15,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                          key={index}
                        >
                          {data}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.custom_form_submitted_data.map((el, index) => (
                      <tr key={index}>
                        {Object.keys(el.submitted_data).map((el2, index) => (
                          <td
                            style={{
                              border: "1px solid grey",
                              borderCollapse: "collapse",
                              paddingTop: 15,
                              paddingLeft: 15,
                              paddingRight: 15,
                            }}
                            key={index}
                          >
                            {Array.isArray(el.submitted_data[el2]) ? (
                              <div>
                                {el.submitted_data[el2].map((i, index) => {
                                  if (isValidURL(i)) {
                                    return (
                                      <img
                                        src={i}
                                        alt=""
                                        style={{
                                          margin: 10,
                                          height: 200,
                                          width: 200,
                                        }}
                                        key={index}
                                      />
                                    );
                                  } else {
                                    return (
                                      <Text
                                        className="task-view-text"
                                        style={{ fontSize: 15 }}
                                        key={index}
                                      >
                                        {`${i},`}
                                      </Text>
                                    );
                                  }
                                })}
                              </div>
                            ) : (
                              <Text
                                className="task-view-text"
                                style={{ fontSize: 15 }}
                                key={index}
                              >
                                {el.submitted_data[el2]}
                              </Text>
                            )}
                          </td>
                        ))}
                        <td
                          style={{
                            border: "1px solid grey",
                            borderCollapse: "collapse",
                            paddingTop: 15,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                          key={index}
                        >
                          <Button
                            type="link"
                            onClick={() =>
                              this.setState({
                                document_id: el._id.$oid,
                                view_submitted_data_modal: true,
                              })
                            }
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            </>
          </Card>
        ) : null}
        {Object.keys(this.state.getCustomFieldData).length !== 0 &&
        this.props.userProfileData.task.task.task_detail
          .view_custom_field_data_related_to_task ? (
          <Card
            style={{
              marginTop: 10,
              width: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)",
              }}
              title="Custom Field Data"
            />
            <div></div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {Object.keys(this.state.getCustomFieldData).map((el, index) => (
                <Text
                  className="task-view-text"
                  style={{ fontSize: 15 }}
                  key={index}
                >
                  <Icon type="book" style={{ margin: 5 }} />
                  {el} :{" "}
                  {Array.isArray(this.state.getCustomFieldData[el]) ? (
                    <div>
                      {this.state.getCustomFieldData[el].map((i, index) => {
                        if (isValidURL(i)) {
                          return (
                            <img
                              src={i}
                              alt=""
                              style={{ margin: 10, height: 200, width: 200 }}
                              key={index}
                            />
                          );
                        } else {
                          return (
                            <Text
                              className="task-view-text"
                              style={{ fontSize: 15 }}
                              key={index}
                            >
                              {`${i},`}
                            </Text>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <Text
                      className="task-view-text"
                      style={{ fontSize: 15 }}
                      key={index}
                    >
                      {this.state.getCustomFieldData[el]}
                    </Text>
                  )}
                </Text>
              ))}
            </div>
          </Card>
        ) : null}

        {this.state.custom_fields.length > 0 &&
        this.props.userProfileData.task.task.task_detail
          .update_custom_field_data_related_to_task ? (
          <div style={{ display: "flex", flex: 1 }}>
            <Card
              style={{
                marginTop: 10,
                width: "100%",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <PageHeader
                style={{
                  border: "1px solid rgb(235, 237, 240)",
                }}
                title="Update Custom Fields"
              />
              <Form>
                {this.state.custom_fields.map((f, index) => {
                  return (
                    <Form.Item key={f._id.$oid}>
                      <Text>
                        {f._type === "linebreak"
                          ? null
                          : `${f.label} (Type : ${f._type}) : `}
                      </Text>
                      {f._type === "dropdown" ? (
                        <Select
                          onChange={(value) => {
                            let obj = {
                              [f.key_name]: value,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from dropdown input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item) => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : f._type === "radio" ? (
                        <Radio.Group
                          onChange={(e) => {
                            let obj = {
                              [f.key_name]: e.target.value,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from radio input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item, index) => {
                            return (
                              <Radio key={index} value={item}>
                                {item}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      ) : f._type === "checkbox" ? (
                        <Checkbox.Group
                          options={f.criteria.choices}
                          onChange={(checkedValues) => {
                            let obj = {
                              [f.key_name]: checkedValues,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from checkbox input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        />
                      ) : f._type === "multiselect" ? (
                        <Select
                          mode="multiple"
                          onChange={(value) => {
                            let obj = {
                              [f.key_name]: value,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from multiselect input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item) => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : f._type === "linebreak" ? (
                        <Divider>{f.label}</Divider>
                      ) : (
                        <Input
                          type={
                            f._type === "image"
                              ? "file"
                              : f._type === "int" ||
                                f._type === "float" ||
                                f._type === "decimal"
                              ? "number"
                              : "text"
                          }
                          multiple={f._type === "image" ? true : false}
                          name={f._type === "image" ? "file[]" : ""}
                          onChange={(evt) => {
                            if (f._type === "list") {
                              this.setState({
                                customArrayText: evt.target.value,
                              });
                            } else if (f._type === "image") {
                              console.log(evt.target.files);
                              const { files } = evt.target;
                              GET_ACCESS_TOKEN().then((token) => {
                                const img = new FormData();

                                for (let i = 0; i < files.length; i++) {
                                  img.append(`imgFile`, files[i]);
                                }

                                console.log("in upload", img);

                                axios({
                                  method: "put",
                                  url: `${MULTI_IMAGE_UPLOAD}task?access_token=${token}`,
                                  data: img,
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                })
                                  .then((response) => {
                                    swal.fire(
                                      "Info",
                                      response.data.message,
                                      "info"
                                    );
                                    if (
                                      response.data.message ===
                                      "Image successfully uploaded!"
                                    ) {
                                      let temp = [];
                                      response.data.data.map((res) => {
                                        temp.push(
                                          `${process.env.REACT_APP_BASE_PATH}${res.upload_path}`
                                        );
                                        return null;
                                      });

                                      let customObj = {
                                        [f.key_name]: temp,
                                      };
                                      this.setState(
                                        {
                                          custom_field_data: Object.assign(
                                            this.state.custom_field_data,
                                            customObj
                                          ),
                                        },
                                        () => {
                                          console.log(
                                            "from image input",
                                            this.state.custom_field_data
                                          );
                                        }
                                      );
                                    }
                                    console.log(
                                      "response from image api",
                                      response
                                    );
                                  })
                                  .catch((error) => {
                                    swal.fire("Error", error.message, "error");
                                    console.log("Error from upload", error);
                                  });
                              });
                            } else if (
                              f._type === "int" ||
                              f._type === "float" ||
                              f._type === "decimal"
                            ) {
                              let obj = {
                                [f.key_name]: Number(evt.target.value),
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  ),
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            } else {
                              let obj = {
                                [f.key_name]: evt.target.value,
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  ),
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            }
                          }}
                        />
                      )}

                      {f._type === "list" ? (
                        <div>
                          <Button
                            style={{ backgroundColor: "teal", color: "white" }}
                            onClick={() => {
                              this.setState(
                                {
                                  customArrayHandle: [
                                    ...this.state.customArrayHandle,
                                    this.state.customArrayText,
                                  ],
                                },
                                () => {
                                  let obj = {
                                    [f.key_name]: this.state.customArrayHandle,
                                  };
                                  console.log("array object", obj);
                                  this.setState(
                                    {
                                      custom_field_data: Object.assign(
                                        this.state.custom_field_data,
                                        obj
                                      ),
                                    },
                                    () => {
                                      console.log(
                                        "from list input",
                                        this.state.custom_field_data
                                      );
                                    }
                                  );
                                }
                              );
                            }}
                          >
                            Add
                          </Button>
                          {custom_field_data.hasOwnProperty(f.key_name) &&
                          this.state.customArrayHandle.length > 0 ? (
                            <Button
                              style={{
                                marginLeft: 10,
                                backgroundColor: "#1A3337",
                                color: "white",
                              }}
                              onClick={() => {
                                this.setState({
                                  customArrayText: "",
                                  customArrayHandle: [],
                                });
                              }}
                            >
                              Adding Complete
                            </Button>
                          ) : null}
                        </div>
                      ) : null}
                      {f._type === "list" &&
                      custom_field_data.hasOwnProperty(f.key_name) ? (
                        <Card style={{ marginTop: 5 }}>
                          {custom_field_data[f.key_name].map((tag, index) => {
                            return (
                              <Tag color="blue" key={index}>
                                {tag}
                              </Tag>
                            );
                          })}
                        </Card>
                      ) : null}
                    </Form.Item>
                  );
                })}
                {this.state.custom_fields.length > 0 ? (
                  <Form.Item>
                    <Button
                      className="login-form-button"
                      htmlType="submit"
                      onClick={this.updateCustomTaskData}
                    >
                      Update Custom Fields
                    </Button>
                  </Form.Item>
                ) : null}
              </Form>
            </Card>
          </div>
        ) : null}

        <div style={{ display: "flex", flexDirection: "column" }}>
          {!this.state.disableState &&
          (this.state.started ||
            this.state.completed ||
            this.state.accepted) ? (
            <Card
              style={{
                marginTop: 10,
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Tooltip placement="top" title="Add Transport Bill">
                  <Link
                    to={{
                      pathname: "/createBill",
                      state: {
                        task_id: this.state.task_id,
                      },
                    }}
                  >
                    <Button
                      onClick={() =>
                        this.setState({ transport_bill_modal: true })
                      }
                      shape="circle"
                      icon="plus"
                      style={{
                        backgroundColor: "#1A3337",
                        height: 60,
                        width: 60,
                        fontSize: 32,
                        paddingBottom: 10,
                      }}
                    />
                  </Link>
                </Tooltip>
              </div>
            </Card>
          ) : null}
        </div>
        <Modal
          title="Task Custom Form"
          visible={this.state.show_custom_form_modal}
          onCancel={() => this.setState({ show_custom_form_modal: false })}
          footer={null}
        >
          <Form>
            {this.state.custom_form_fields.map((f, index) => (
              <Form.Item key={f.name}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text>{`${f.name} : `}</Text>
                  {f.field_type === "text" ? (
                    <Input
                      type={f.input_type}
                      onChange={(evt) => {
                        if (f.input_type === "text") {
                          let obj = {
                            [f.name]: evt.target.value,
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form data",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        } else {
                          let obj = {
                            [f.name]: Number(evt.target.value),
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form text field",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        }
                      }}
                    />
                  ) : f.field_type === "dropdown" ? (
                    <Select
                      onChange={(value) => {
                        let obj = {
                          [f.name]: value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from dropdown input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    >
                      {f.options.map((item) => {
                        return <Option key={item}>{item}</Option>;
                      })}
                    </Select>
                  ) : f.field_type === "checkbox" ? (
                    <Checkbox.Group
                      options={f.options}
                      onChange={(checkedValues) => {
                        let obj = {
                          [f.name]: checkedValues,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from checkbox input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    />
                  ) : f.field_type === "radio" ? (
                    <Radio.Group
                      onChange={(e) => {
                        let obj = {
                          [f.name]: e.target.value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from radio input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    >
                      {f.options.map((item, index) => {
                        return (
                          <Radio key={index} value={item}>
                            {item}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  ) : f.field_type === "image_upload" ? (
                    <Input
                      type="file"
                      multiple={true}
                      name={"file[]"}
                      onChange={(evt) => {
                        console.log(evt.target.files);
                        const { files } = evt.target;
                        GET_ACCESS_TOKEN().then((token) => {
                          const img = new FormData();

                          for (let i = 0; i < files.length; i++) {
                            img.append(`imgFile`, files[i]);
                          }

                          console.log("in upload", img);

                          axios({
                            method: "put",
                            url: `${MULTI_IMAGE_UPLOAD}task?access_token=${token}`,
                            data: img,
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          })
                            .then((response) => {
                              swal.fire("Info", response.data.message, "info");
                              if (
                                response.data.message ===
                                "Image successfully uploaded!"
                              ) {
                                let temp = [];
                                response.data.data.map((res) => {
                                  temp.push(
                                    `${process.env.REACT_APP_BASE_PATH}${res.upload_path}`
                                  );
                                  return null;
                                });

                                let customObj = {
                                  [f.name]: temp,
                                };
                                this.setState(
                                  {
                                    custom_form_field_post_object:
                                      Object.assign(
                                        this.state
                                          .custom_form_field_post_object,
                                        customObj
                                      ),
                                  },
                                  () => {
                                    console.log(
                                      "from image input",
                                      this.state.custom_form_field_post_object
                                    );
                                  }
                                );
                              }
                              console.log("response from image api", response);
                            })
                            .catch((error) => {
                              swal.fire("Error", error.message, "error");
                              console.log("Error from upload", error);
                            });
                        });
                      }}
                    />
                  ) : f.field_type === "numeric_slider" ? (
                    <Slider
                      min={Number(f.min_value)}
                      max={Number(f.max_value)}
                      defaultValue={0}
                      onChange={(value) => {
                        let obj = {
                          [f.name]: value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from radio input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    />
                  ) : f.field_type === "conditional" ? (
                    <Input
                      disabled={
                        custom_form_field_post_object.hasOwnProperty(
                          f.condition_field
                        ) &&
                        custom_form_field_post_object[f.condition_field] ===
                          f.condition_value
                          ? false
                          : true
                      }
                      onChange={(evt) => {
                        if (
                          custom_form_field_post_object[f.condition_field] ===
                          f.condition_value
                        ) {
                          let obj = {
                            [f.name]: evt.target.value,
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form data for cnditional field",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        } else {
                          return;
                        }
                      }}
                    />
                  ) : null}
                </div>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateCustomFormData}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Edit Custom Form Data"
          visible={this.state.view_submitted_data_modal}
          onCancel={() => this.setState({ view_submitted_data_modal: false })}
          footer={null}
        >
          <Form>
            {this.state.custom_form_fields.map((f, index) => (
              <Form.Item key={f.name}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text>{`${f.name} : `}</Text>
                  {f.field_type === "text" ? (
                    <Input
                      type={f.input_type}
                      onChange={(evt) => {
                        if (f.input_type === "text") {
                          let obj = {
                            [f.name]: evt.target.value,
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form data",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        } else {
                          let obj = {
                            [f.name]: Number(evt.target.value),
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form text field",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        }
                      }}
                    />
                  ) : f.field_type === "dropdown" ? (
                    <Select
                      onChange={(value) => {
                        let obj = {
                          [f.name]: value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from dropdown input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    >
                      {f.options.map((item) => {
                        return <Option key={item}>{item}</Option>;
                      })}
                    </Select>
                  ) : f.field_type === "checkbox" ? (
                    <Checkbox.Group
                      options={f.options}
                      onChange={(checkedValues) => {
                        let obj = {
                          [f.name]: checkedValues,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from checkbox input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    />
                  ) : f.field_type === "radio" ? (
                    <Radio.Group
                      onChange={(e) => {
                        let obj = {
                          [f.name]: e.target.value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from radio input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    >
                      {f.options.map((item, index) => {
                        return (
                          <Radio key={index} value={item}>
                            {item}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  ) : f.field_type === "image_upload" ? (
                    <Input
                      type="file"
                      multiple={true}
                      name={"file[]"}
                      onChange={(evt) => {
                        console.log(evt.target.files);
                        const { files } = evt.target;
                        GET_ACCESS_TOKEN().then((token) => {
                          const img = new FormData();

                          for (let i = 0; i < files.length; i++) {
                            img.append(`imgFile`, files[i]);
                          }

                          console.log("in upload", img);

                          axios({
                            method: "put",
                            url: `${MULTI_IMAGE_UPLOAD}task?access_token=${token}`,
                            data: img,
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          })
                            .then((response) => {
                              swal.fire("Info", response.data.message, "info");
                              if (
                                response.data.message ===
                                "Image successfully uploaded!"
                              ) {
                                let temp = [];
                                response.data.data.map((res) => {
                                  temp.push(
                                    `${process.env.REACT_APP_BASE_PATH}${res.upload_path}`
                                  );
                                  return null;
                                });

                                let customObj = {
                                  [f.name]: temp,
                                };
                                this.setState(
                                  {
                                    custom_form_field_post_object:
                                      Object.assign(
                                        this.state
                                          .custom_form_field_post_object,
                                        customObj
                                      ),
                                  },
                                  () => {
                                    console.log(
                                      "from image input",
                                      this.state.custom_form_field_post_object
                                    );
                                  }
                                );
                              }
                              console.log("response from image api", response);
                            })
                            .catch((error) => {
                              swal.fire("Error", error.message, "error");
                              console.log("Error from upload", error);
                            });
                        });
                      }}
                    />
                  ) : f.field_type === "numeric_slider" ? (
                    <Slider
                      min={Number(f.min_value)}
                      max={Number(f.max_value)}
                      defaultValue={0}
                      onChange={(value) => {
                        let obj = {
                          [f.name]: value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from radio input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    />
                  ) : f.field_type === "conditional" ? (
                    <Input
                      disabled={
                        custom_form_field_post_object.hasOwnProperty(
                          f.condition_field
                        ) &&
                        custom_form_field_post_object[f.condition_field] ===
                          f.condition_value
                          ? false
                          : true
                      }
                      onChange={(evt) => {
                        if (
                          custom_form_field_post_object[f.condition_field] ===
                          f.condition_value
                        ) {
                          let obj = {
                            [f.name]: evt.target.value,
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form data for cnditional field",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        } else {
                          return;
                        }
                      }}
                    />
                  ) : null}
                </div>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.editCustomFormSubmittedData}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(ViewTask);
