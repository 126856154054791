import React, { Component } from "react";
import { Card, Tabs } from "antd";

import "../login.css";

import CreateNormalBill from "./createNormalBill";
import CreateAutomatedBill from "./createAutomatedBill";
import ReactGA from "react-ga4";

const { TabPane } = Tabs;

export default class CreateBill extends Component {
  state = {
    activeKeyBills: "1",
  };

  componentDidMount() {
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    let key = JSON.parse(localStorage.getItem("activeKeyBills"));
    if (key) {
      this.setState({ activeKeyBills: key });
    }
  }

  onChange = (activeKeyBills) => {
    console.log(`onChange ${activeKeyBills}`);
    this.setState(
      {
        activeKeyBills,
      },
      () => {
        localStorage.setItem(
          "activeKeyBills",
          JSON.stringify(this.state.activeKeyBills)
        );
      }
    );
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <Tabs
          destroyInactiveTabPane={true}
          activeKey={this.state.activeKeyBills}
          onChange={this.onChange}
        >
          <TabPane tab="Create Bill" key="1">
            <CreateNormalBill
              values={{
                task_id: this.props?.location?.state?.task_id ?? "",
                incident_id: this.props?.location?.state?.incident_id ?? "",
                incident_name: this.props?.location?.state?.incident_name ?? "",
              }}
            />
          </TabPane>
          <TabPane tab="Create Bulk Bill" key="2">
            <CreateAutomatedBill />
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}
