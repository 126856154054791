import React, { Component } from "react";
import {
  Card,
  Typography,
  Button,
  Table,
  Pagination,
  Select,
  Skeleton,
  Tag,
  Progress,
  Modal,
} from "antd";
import axios from "axios";
import CardMaterial from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";
import swal from "sweetalert2";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "leaflet/dist/leaflet.css";
import { Map, Marker } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import {
  GET_ACCESS_TOKEN,
  // timeConverter,
  GET_USER_LOCAL,
} from "../../utilities/utilities";
import {
  ANALYTICS_API,
  USER_PROFILE,
  CURRENT_USER_SUB_ORG_LIST,
  ORDER_TYPE_CHOICES,
  CREATE_DRIVER,
  ENTITY_TYPE,
} from "../../api/constants";
import { debounce } from "lodash";

import "../login.css";

const { Text, Title } = Typography;
const { Column } = Table;
const { Option } = Select;
const { CheckableTag } = Tag;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
);

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const userIcon = new L.Icon({
  iconUrl: require("../../assets/store.svg"),
  iconSize: [35, 50],
});
const inventoryIcon = new L.Icon({
  iconUrl: require("../../assets/inventory.svg"),
  iconSize: [45, 60],
});

class Order extends Component {
  state = {
    start_time: moment().startOf("day").format("x"),
    start_time_view: moment().startOf("day"),
    end_time: moment().format("x"),
    end_time_view: moment(),
    date_to_use: "created_on",
    total_received_amount: 0,
    total_due_amount: 0,
    total_discount_amount: 0,
    total_costing: 0,
    total_profit: 0,
    order_count: 0,
    due_amount: 0,
    total_discount: 0,
    total_price: 0,
    total_received_amount_map: 0,
    total_order_placed: 0,
    total_order_approved: 0,
    total_order_in_process: 0,
    name: "",
    entity_type: "",
    show_card: false,
    show_inventory_card: false,
    task_data: [],
    user_org_id: "",
    page: 1,
    page_size: 10,
    total: 0,
    loading: false,
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    sub_org_id_task_search: "",
    map_data: [],
    order_types: [],
    top_products: [],
    low_products: [],
    top_profit_products: [],
    least_profit_products: [],
    order_type: "",
    entityLocationLat: 23.685,
    entityLocationLon: 90.3563,
    zoom: 10,
    seven_days: false,
    thirty_days: false,
    last_month: false,
    this_month: false,
    target_achievement_page: 1,
    target_achievement_page_size: 10,
    target_achievement_total: 0,
    target_achievement_data: [],
    individual_users: [],
    individual_user_id: "",
    duration_type: "WEEKLY",
    forecast_type: "Monthly",
    top_ten_users: [],
    forecasted_amount: [],
    received_amount: [],
    forecasted_product: [],
    normal_product: [],
    upper_bound_product_amount: [],
    lower_bound_product_amount: [],
    inventory_map_data: [],
    inventory_id: "",
    entity_name: "",
    entity_address: "",
    entity_contact_person_name: "",
    entity_contact_person_mobile: "",
    total_stock: 0,
    total_stock_value: 0,
    inventory_turnover_ratio: -0,
    stockout_rate: 0,
    average_days_to_sale: 0,
    available_to_promise: 0,
    entity_choice: "shop",
    entity_choices: [],
    upper_bound_received_amount: [],
    lower_bound_received_amount: [],
    necessary_stock: [],
    show_necessary_stock_modal: false,
  };
  componentDidMount() {
    this.getOrderTypes();
    this.getCurrentUserSubOrgs();
    this.getUserList();
    this.getEntityChoices();
  }

  getEntityChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ENTITY_TYPE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("dropdown entity", response);
          this.setState({ entity_choices: response.data.entity_choices });
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user list", response);
          this.setState({
            individual_users: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  };

  handleSearchUser = debounce((value) => {
    console.log("search text", value);
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from search user", response);
          this.setState({
            individual_users: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from search user api", error);
        });
    });
  }, 500);

  targetPageChange = (current, pageSize) => {
    this.setState({ target_achievement_page: current }, () => {
      console.log("page number", this.state.target_achievement_page);
      this.getTargetAchievementTable();
    });
  };

  setTargetLimit = (value) => {
    this.setState({ target_achievement_page_size: value }, () => {
      console.log(this.state.target_achievement_page_size);
      this.getTargetAchievementTable();
    });
  };

  getOrderTypes = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_TYPE_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from order_types list", response);

          this.setState({
            order_types: response.data.order_type_choices,
          });
        })
        .catch((error) => {
          console.log("error from order_types list", error);
        });
    });
  };

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org_id_task_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getUserdata();
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_task_search: value },
      () => {
        this.getUserdata();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current }, () => {
      console.log("page number", this.state.page);
      this.getTasksTable();
    });
  };

  setLimit = (value) => {
    this.setState({ page_size: value }, () => {
      console.log(this.state.page_size);
      this.getTasksTable();
    });
  };

  getUserdata = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${USER_PROFILE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user profile", response);

          this.setState(
            {
              user_org_id: response.data.organization.$oid,
            },
            () => {
              this.getTotalOrders();
              this.getOrderMapData();
              this.getOrderInventoryMapData();
              this.getProductStocks();
              this.getTopProfitProduct();
              this.getTargetAchievementTable();
              this.getTopTenSellsRep();
              this.getOrderForecast();
              this.getProductDemandForecast();
            }
          );
        })
        .catch((error) => {
          console.log("error from user profile", error);
        });
    });
  };

  getTotalOrders = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}order_calculation_sub_org/${
        this.state.user_org_id
      }?user_id=${GET_USER_LOCAL()._id.$oid}&sub_organization=${
        this.state.sub_org_id_task_search
      }&start_date=${this.state.start_time}&end_date=${
        this.state.end_time
      }&date_to_use=${this.state.date_to_use}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from total order chart api", response);
          this.setState({
            total_received_amount: response.data.total_received_amount ?? 0,
            total_due_amount: response.data.total_due_amount ?? 0,
            total_discount_amount: response.data.total_discount_amount ?? 0,
            total_costing: response.data.total_costing ?? 0,
            total_profit: response.data.total_profit ?? 0,
            total_order_placed: response.data.total_order_placed ?? 0,
            total_order_approved: response.data.total_order_approved ?? 0,
            total_order_in_process: response.data.total_order_in_process ?? 0,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from total order chart api", error);
        });
    });
  };
  getOrderForecast = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}order_total_value_forecasting/${
        this.state.user_org_id
      }?user_id=${GET_USER_LOCAL()._id.$oid}&date_to_use=${
        this.state.date_to_use
      }&forecast_type=${this.state.forecast_type}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from order forecasting api", response);
          this.setState({
            forecasted_amount: response.data?.forecasted_received_amount ?? [],
            received_amount: response.data?.original_data ?? [],
            upper_bound_received_amount: response.data?.upper_bound ?? [],
            lower_bound_received_amount: response.data?.lower_bound ?? [],
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from order forecasting api", error);
        });
    });
  };
  getProductDemandForecast = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}product_demand_value_forecasting/${
        this.state.user_org_id
      }?user_id=${GET_USER_LOCAL()._id.$oid}&date_to_use=${
        this.state.date_to_use
      }&forecast_type=${this.state.forecast_type}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from product demand forecasting api", response);
          this.setState({
            forecasted_product: response.data?.forecasted_received_amount ?? [],
            normal_product: response.data?.original_data ?? [],
            upper_bound_product_amount: response.data?.upper_bound ?? [],
            lower_bound_product_amount: response.data?.lower_bound ?? [],
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from product demand forecasting api", error);
        });
    });
  };
  getTopTenSellsRep = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}order_top_sales_person/${this.state.user_org_id}?start_date=${this.state.start_time}&end_date=${this.state.end_time}&date_to_use=${this.state.date_to_use}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from top ten sells rep api", response);
          this.setState({
            top_ten_users: response.data?.top_sales_people ?? [],
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from top ten sells rep api", error);
        });
    });
  };
  getOrderMapData = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}order_map_data/${
        this.state.user_org_id
      }?entity_choice=${this.state.entity_choice}&user_id=${
        GET_USER_LOCAL()._id.$oid
      }&sub_organization=${this.state.sub_org_id_task_search}&date_to_use=${
        this.state.date_to_use
      }&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from order map data api", response);
          this.setState(
            {
              map_data: response.data ?? [],
            },
            () => {
              this.setState({
                entityLocationLat:
                  this.state.map_data.length > 0
                    ? this.state.map_data[0].entity_details.location
                        .coordinates[1]
                    : 23.685,
                entityLocationLon:
                  this.state.map_data.length > 0
                    ? this.state.map_data[0].entity_details.location
                        .coordinates[0]
                    : 90.3563,
              });
            }
          );
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from order map data api", error);
        });
    });
  };
  getOrderInventoryMapData = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}order_inventory_list/${
        this.state.user_org_id
      }?user_id=${GET_USER_LOCAL()._id.$oid}&sub_organization=${
        this.state.sub_org_id_task_search
      }&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from order inventory map data api", response);
          this.setState({
            inventory_map_data: response.data.inventory_list ?? [],
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from order inventory map data api", error);
        });
    });
  };
  getOrderInventoryDetailsData = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}order_inventory_map_data/${
        this.state.user_org_id
      }?user_id=${GET_USER_LOCAL()._id.$oid}&inventory_id=${
        this.state.inventory_id
      }&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from order inventory details api", response);
          this.setState({
            show_inventory_card: true,
            show_card: false,
            entity_name:
              response?.data?.specific_inventory_data?.entity_name ?? "",
            entity_address:
              response?.data?.specific_inventory_data?.entity_address ?? "",
            entity_contact_person_name:
              response?.data?.specific_inventory_data
                ?.entity_contact_person_name ?? "",
            total_stock:
              response?.data?.specific_inventory_data?.total_stock ?? 0,
            total_stock_value:
              response?.data?.specific_inventory_data?.total_stock_value ?? 0,
            inventory_turnover_ratio:
              response?.data?.specific_inventory_data
                ?.inventory_turnover_ratio ?? -0,
            stockout_rate:
              response?.data?.specific_inventory_data?.stockout_rate ?? 0,
            average_days_to_sale:
              response?.data?.specific_inventory_data?.average_days_to_sale ??
              0,
            available_to_promise:
              response?.data?.specific_inventory_data?.available_to_promise ??
              0,
            necessary_stock: response?.data?.necessary_stock ?? [],
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from order inventory details api", error);
        });
    });
  };
  getProductStocks = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}order_top_product/${
        this.state.user_org_id
      }?user_id=${GET_USER_LOCAL()._id.$oid}&sub_organization=${
        this.state.sub_org_id_task_search
      }&start_date=${this.state.start_time}&end_date=${
        this.state.end_time
      }&date_to_use=${this.state.date_to_use}&order_type=${
        this.state.order_type
      }&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from order stock api", response);
          this.setState({
            top_products: response.data.top_products ?? [],
            low_products: response.data.lowest_products ?? [],
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from order stock api", error);
        });
    });
  };
  getTopProfitProduct = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}order_top_product_profit/${
        this.state.user_org_id
      }?user_id=${GET_USER_LOCAL()._id.$oid}&sub_organization=${
        this.state.sub_org_id_task_search
      }&start_date=${this.state.start_time}&end_date=${
        this.state.end_time
      }&date_to_use=${this.state.date_to_use}&order_type=${
        this.state.order_type
      }&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from order products by profit api", response);
          this.setState({
            top_profit_products: response.data.top_products ?? [],
            least_profit_products: response.data.lowest_products ?? [],
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from order products by profit api", error);
        });
    });
  };

  getTargetAchievementTable = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}target_achivement/${
        this.state.user_org_id
      }?page_size=${this.state.target_achievement_page_size}&page=${
        this.state.target_achievement_page
      }&sub_organization=${this.state.sub_org_id_task_search}&user_id=${
        GET_USER_LOCAL()._id.$oid
      }&individual_user=${this.state.individual_user_id}&start_date=${
        this.state.start_time
      }&end_date=${this.state.end_time}&duration_type=${
        this.state.duration_type
      }&achivement_for=Order&date_to_use=${
        this.state.date_to_use
      }&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log(
            "response from target achievement table chart api",
            response
          );
          this.setState({
            target_achievement_data: response.data.data,
            target_achievement_total: response.data.total_count,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from target achievement table chart api", error);
        });
    });
  };

  setUser = (value) => {
    this.setState({ individual_user_id: value }, () => {
      this.getTargetAchievementTable();
      console.log(this.state.individual_user_id);
    });
  };
  changeOrderType = (value) => {
    this.setState({ order_type: value }, () => {
      this.getProductStocks();
      this.getTopProfitProduct();
      this.getOrderForecast();
      this.getProductDemandForecast();
    });
  };

  findFirstZeroIndexFromEnd = (arr, keyName) => {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i][keyName] === 0) {
        return i;
      }
    }
    return -1; // Return -1 if no zero is found
  };
  render() {
    if (this.state.loading === true) {
      return (
        <Skeleton
          paragraph={{
            rows: 20,
          }}
          active
        />
      );
    }
    return (
      <div style={{ marginTop: 10 }}>
        <Card>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  // minDate={moment().subtract(30, "days")}
                  style={{ marginTop: 15 }}
                  inputProps={{
                    style: {
                      padding: 10,
                      height: 14,
                      width: 130,
                    },
                  }}
                  disableFuture
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).startOf("day").format("x"),
                        start_time_view: value,
                        end_time: moment(value)
                          .add(30, "days")
                          .endOf("day")
                          .format("x"),
                        end_time_view: moment(value)
                          .add(30, "days")
                          .endOf("day"),
                      },
                      () => {
                        this.getTotalOrders();
                        this.getProductStocks();
                        this.getTopProfitProduct();
                        this.getTargetAchievementTable();
                        this.getTopTenSellsRep();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  // minDate={moment().subtract(30, "days")}
                  style={{ marginTop: 15 }}
                  inputProps={{
                    style: {
                      padding: 10,
                      height: 14,
                      width: 130,
                    },
                  }}
                  disableFuture
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    if (
                      (value - this.state.start_time_view) /
                        (1000 * 3600 * 24) >
                      30
                    ) {
                      swal.fire(
                        "Info",
                        "Date range must be within 30 days",
                        "info"
                      );
                    } else {
                      this.setState(
                        {
                          end_time: moment(value)
                            .endOf("day")
                            .add(59, "seconds")
                            .format("x"),
                          end_time_view: value,
                        },
                        () => {
                          this.getTotalOrders();
                          this.getProductStocks();
                          this.getTopProfitProduct();
                          this.getTargetAchievementTable();
                          this.getTopTenSellsRep();
                        }
                      );
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 5,
                marginLeft: 4,
              }}
            >
              <Text>Filter Time By : </Text>
              <Select
                value={this.state.date_to_use}
                onChange={(value) => {
                  this.setState({ date_to_use: value }, () => {
                    this.getTotalOrders();
                    this.getOrderMapData();
                    this.getProductStocks();
                    this.getTopProfitProduct();
                    this.getTargetAchievementTable();
                    this.getTopTenSellsRep();
                    this.getOrderForecast();
                    this.getProductDemandForecast();
                    console.log(this.state.date_to_use);
                  });
                }}
                style={{ minWidth: 100 }}
              >
                <Option key="created_on">Created On</Option>
                <Option key="completed_on">Completed On</Option>
              </Select>
            </div>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight:5
              }}
            >
              <Text>Task Type : </Text>
              <Select
                value={this.state.task_type}
                onChange={this.setTaskType}
                placeholder="Task Type"
                style={{ minWidth: 200 }}
              >
                {this.state.task_types.map((r) => {
                  return <Option key={r.name}>{r.name}</Option>;
                })}
              </Select>
            </div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text> User Sub Organization : {""}</Text>
              <Select
                style={{ minWidth: 200 }}
                disabled={
                  this.state.current_user_sub_orgs.length < 1 ? true : false
                }
                value={this.state.current_user_sub_org_id}
                onChange={this.setCurrentUserSubOrg}
              >
                {this.state.current_user_sub_orgs.map((r) => {
                  return <Option key={r.id}>{`${r.name}`}</Option>;
                })}
              </Select>
            </div>
            {this.props.userProfileData.data_level.access_all_data ? (
              <Button
                type="link"
                style={{ marginTop: 20, color: "#1A3337" }}
                onClick={() => {
                  this.setState(
                    { current_user_sub_org_id: "", sub_org_id_task_search: "" },
                    () => {
                      this.getUserdata();
                    }
                  );
                }}
              >
                All Sub Orgs
              </Button>
            ) : null}
          </div>
          <div style={{ marginLeft: 5 }}>
            <CheckableTag
              style={{
                fontSize: 15,
                padding: 5,
                border: "1px solid grey",
                backgroundColor: this.state.seven_days ? "#1A3337" : "",
              }}
              checked={this.state.seven_days}
              onChange={(checked) => {
                console.log(checked);
                this.setState({
                  seven_days: checked,
                  thirty_days: false,
                  last_month: false,
                  this_month: false,
                });

                if (checked) {
                  this.setState(
                    {
                      start_time: moment().subtract(7, "days").format("x"),
                      start_time_view: moment().subtract(7, "days"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalOrders();
                      this.getProductStocks();
                      this.getTopProfitProduct();
                      this.getTargetAchievementTable();
                      this.getTopTenSellsRep();
                    }
                  );
                } else {
                  this.setState(
                    {
                      start_time: moment().startOf("day").format("x"),
                      start_time_view: moment().startOf("day"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalOrders();
                      this.getProductStocks();
                      this.getTopProfitProduct();
                      this.getTargetAchievementTable();
                      this.getTopTenSellsRep();
                    }
                  );
                }
              }}
            >
              Last 7 Days
            </CheckableTag>
            <CheckableTag
              style={{
                fontSize: 15,
                padding: 5,
                border: "1px solid grey",
                backgroundColor: this.state.thirty_days ? "#1A3337" : "",
              }}
              checked={this.state.thirty_days}
              onChange={(checked) => {
                console.log(checked);
                this.setState({
                  seven_days: false,
                  thirty_days: checked,
                  last_month: false,
                  this_month: false,
                });
                if (checked) {
                  this.setState(
                    {
                      start_time: moment().subtract(30, "days").format("x"),
                      start_time_view: moment().subtract(30, "days"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalOrders();
                      this.getProductStocks();
                      this.getTopProfitProduct();
                      this.getTargetAchievementTable();
                      this.getTopTenSellsRep();
                    }
                  );
                } else {
                  this.setState(
                    {
                      start_time: moment().startOf("day").format("x"),
                      start_time_view: moment().startOf("day"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalOrders();
                      this.getProductStocks();
                      this.getTopProfitProduct();
                      this.getTargetAchievementTable();
                      this.getTopTenSellsRep();
                    }
                  );
                }
              }}
            >
              Last 30 Days
            </CheckableTag>
            <CheckableTag
              style={{
                fontSize: 15,
                padding: 5,
                border: "1px solid grey",
                backgroundColor: this.state.this_month ? "#1A3337" : "",
              }}
              checked={this.state.this_month}
              onChange={(checked) => {
                console.log(checked);
                this.setState({
                  seven_days: false,
                  thirty_days: false,
                  last_month: false,
                  this_month: checked,
                });
                if (checked) {
                  this.setState(
                    {
                      start_time: moment().startOf("month").format("x"),
                      start_time_view: moment().startOf("month"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalOrders();
                      this.getProductStocks();
                      this.getTopProfitProduct();
                      this.getTargetAchievementTable();
                      this.getTopTenSellsRep();
                    }
                  );
                } else {
                  this.setState(
                    {
                      start_time: moment().startOf("day").format("x"),
                      start_time_view: moment().startOf("day"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalOrders();
                      this.getProductStocks();
                      this.getTopProfitProduct();
                      this.getTargetAchievementTable();
                      this.getTopTenSellsRep();
                    }
                  );
                }
              }}
            >
              Current Month
            </CheckableTag>
            <CheckableTag
              style={{
                fontSize: 15,
                padding: 5,
                border: "1px solid grey",
                backgroundColor: this.state.last_month ? "#1A3337" : "",
              }}
              checked={this.state.last_month}
              onChange={(checked) => {
                console.log(checked);
                this.setState({
                  seven_days: false,
                  thirty_days: false,
                  last_month: checked,
                  this_month: false,
                });
                if (checked) {
                  this.setState(
                    {
                      start_time: moment()
                        .subtract(1, "month")
                        .startOf("month")
                        .format("x"),
                      start_time_view: moment()
                        .subtract(1, "month")
                        .startOf("month"),
                      end_time: moment()
                        .subtract(1, "month")
                        .endOf("month")
                        .format("x"),
                      end_time_view: moment()
                        .subtract(1, "month")
                        .endOf("month"),
                    },
                    () => {
                      this.getTotalOrders();
                      this.getProductStocks();
                      this.getTopProfitProduct();
                      this.getTargetAchievementTable();
                      this.getTopTenSellsRep();
                    }
                  );
                } else {
                  this.setState(
                    {
                      start_time: moment().startOf("day").format("x"),
                      start_time_view: moment().startOf("day"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalOrders();
                      this.getProductStocks();
                      this.getTopProfitProduct();
                      this.getTargetAchievementTable();
                      this.getTopTenSellsRep();
                    }
                  );
                }
              }}
            >
              Last Month
            </CheckableTag>
          </div>
        </Card>
        <Card style={{ marginTop: 5 }}>
          <div
            className="cardscontainer"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: "5px solid #AA5486",
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Profit
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.total_profit}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: "5px solid #803D3B",
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Received
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.total_received_amount}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: "5px solid #E4003A",
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Costing
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.total_costing}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: "5px solid #921A40",
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Due
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.total_due_amount}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: "5px solid #C7253E",
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Discount
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.total_discount_amount}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: "5px solid #E85C0D",
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Placed
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.total_order_placed}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: "5px solid #D8A25E",
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Approved
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.total_order_approved}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: "5px solid #A04747",
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Processing
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.total_order_in_process}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
          </div>
        </Card>
        {/* badges end here */}
        <Card style={{ marginTop: 5, width: "100%" }}>
          <Title level={3}>Inventories & Places</Title>
          <div
            style={{
              marginBottom: 10,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text> Select Place Type : {""}</Text>
            <Select
              style={{ maxWidth: 200 }}
              value={this.state.entity_choice}
              onChange={(value) => {
                this.setState({ entity_choice: value }, () => {
                  console.log(this.state.entity_choice);
                  this.getOrderMapData();
                });
              }}
            >
              {this.state.entity_choices.map((entity) => {
                return <Option key={entity}>{entity}</Option>;
              })}
            </Select>
          </div>
          <Map
            center={[
              this.state.entityLocationLat,
              this.state.entityLocationLon,
            ]}
            zoom={this.state.zoom}
            onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
          >
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{
                KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                VERSION: "3.53",
              }}
              type={"roadmap"}
            />

            {this.state.map_data.map((map) => {
              return (
                <Marker
                  onClick={() => {
                    this.setState({
                      order_count: map.order_count ?? 0,
                      due_amount: map.due_amount ?? 0,
                      total_discount: map.total_discount ?? 0,
                      total_price: map.total_price ?? 0,
                      total_received_amount_map: map.total_received_amount ?? 0,
                      name: map.entity_details.name ?? "",
                      entity_type: map.entity_details.entity_type ?? "",
                      show_card: true,
                      show_inventory_card: false,
                      entityLocationLat:
                        map.entity_details.location.coordinates[1],
                      entityLocationLon:
                        map.entity_details.location.coordinates[0],
                      // zoom:12,
                    });
                  }}
                  key={map.id}
                  position={[
                    map.entity_details.location.coordinates[1],
                    map.entity_details.location.coordinates[0],
                  ]}
                  icon={userIcon}
                ></Marker>
              );
            })}
            {this.state.inventory_map_data.map((map) => {
              return (
                <Marker
                  onClick={() => {
                    this.setState(
                      {
                        inventory_id: map._id,
                        entityLocationLat:
                          map.entity_loc?.coordinates[1] ?? 23.685,
                        entityLocationLon:
                          map.entity_loc?.coordinates[0] ?? 90.3563,
                        // zoom:12,
                      },
                      () => {
                        this.getOrderInventoryDetailsData();
                      }
                    );
                  }}
                  key={map._id}
                  position={[
                    map.entity_loc?.coordinates[1] ?? 23.685,
                    map.entity_loc?.coordinates[0] ?? 90.3563,
                  ]}
                  icon={inventoryIcon}
                ></Marker>
              );
            })}
            {this.state.show_card ? (
              <Card
                size="small"
                title={this.state.name}
                extra={
                  <Button
                    type="link"
                    onClick={() => this.setState({ show_card: false })}
                  >
                    Close
                  </Button>
                }
                style={{
                  position: "absolute",
                  right: 20,
                  bottom: 40,
                  zIndex: 400,
                  paddingTop: 7,
                  paddingRight: 1,
                  width: 300,
                  borderRadius: 25,
                }}
              >
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #FBFBFB",
                    borderRadius: 5,
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>Place Type :</Text>{" "}
                  <Text style={{ fontWeight: "bold" }}>
                    {this.state.entity_type}
                  </Text>
                </div>
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #FBFBFB",
                    borderRadius: 5,
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>Order Count :</Text>{" "}
                  <Text style={{ fontWeight: "bold" }}>
                    {this.state.order_count}
                  </Text>
                </div>
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #FBFBFB",
                    borderRadius: 5,
                    borderLeft: "5px solid #3795BD",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>Total Price :</Text>{" "}
                  <Text
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.total_price}
                  </Text>
                </div>
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #FBFBFB",
                    borderRadius: 5,
                    borderLeft: "5px solid #F4CE14",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>Discount :</Text>{" "}
                  <Text
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.total_discount}
                  </Text>
                </div>
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #FBFBFB",
                    borderRadius: 5,
                    borderLeft: "5px solid #379777",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>Received Amount :</Text>{" "}
                  <Text
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.total_received_amount_map}
                  </Text>
                </div>
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #FBFBFB",
                    borderRadius: 5,
                    borderLeft: "5px solid #FF0000",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>Due :</Text>{" "}
                  <Text
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.due_amount}
                  </Text>
                </div>
              </Card>
            ) : null}
            {this.state.show_inventory_card ? (
              <Card
                size="small"
                title={this.state.entity_name}
                extra={
                  <Button
                    type="link"
                    onClick={() =>
                      this.setState({ show_inventory_card: false })
                    }
                  >
                    Close
                  </Button>
                }
                style={{
                  position: "absolute",
                  right: 20,
                  bottom: 40,
                  zIndex: 400,
                  paddingTop: 7,
                  paddingRight: 1,
                  width: 300,
                  borderRadius: 25,
                }}
              >
                {/* <div>
                  <Text style={{ fontWeight: "bold" }}>Inventory Address</Text>:{" "}
                  {this.state.entity_address}
                </div>
                <div>
                  <Text style={{ fontWeight: "bold" }}>Contact</Text>:{" "}
                  {`${this.state.entity_contact_person_name}`}
                </div> */}
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #FBFBFB",
                    borderRadius: 5,
                    borderLeft: "5px solid #3795BD",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>Total Stock :</Text>{" "}
                  <Text
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.total_stock}
                  </Text>
                </div>
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #FBFBFB",
                    borderRadius: 5,
                    borderLeft: "5px solid #379777",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    Total Stock Value :{" "}
                  </Text>{" "}
                  <Text
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.total_stock_value}
                  </Text>
                </div>
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #FBFBFB",
                    borderRadius: 5,
                    borderLeft: "5px solid #F4CE14",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>Turnover Ratio :</Text>{" "}
                  <Text
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.inventory_turnover_ratio}
                  </Text>
                </div>
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #FBFBFB",
                    borderRadius: 5,
                    borderLeft: "5px solid #FF0000",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>Stockout Rate :</Text>{" "}
                  <Text
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.stockout_rate}
                  </Text>
                </div>
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #FBFBFB",
                    borderRadius: 5,
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    Average Days to Sell :
                  </Text>
                  <Text style={{ fontWeight: "bold" }}>
                    {this.state.average_days_to_sale}
                  </Text>
                </div>
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #FBFBFB",
                    borderRadius: 5,
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    Available to Promise :
                  </Text>
                  <Text style={{ fontWeight: "bold" }}>
                    {this.state.available_to_promise}
                  </Text>
                </div>
                {this.state.necessary_stock.length > 0 ? (
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="link"
                      onClick={() =>
                        this.setState({ show_necessary_stock_modal: true })
                      }
                    >
                      View Necessary Stock
                    </Button>
                  </div>
                ) : null}
              </Card>
            ) : null}
          </Map>
        </Card>
        <Card style={{ marginTop: 5 }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text> Select Order Type : {""}</Text>
              <Select
                style={{ maxWidth: 200 }}
                value={this.state.order_type}
                onChange={this.changeOrderType}
              >
                {this.state.order_types.map((r) => {
                  return <Option key={r}>{`${r}`}</Option>;
                })}
              </Select>
            </div>
            <div
              style={{ margin: 10, display: "flex", flexDirection: "column" }}
            >
              <Text> Forecast Type : {""}</Text>
              <Select
                style={{ maxWidth: 200 }}
                value={this.state.forecast_type}
                onChange={(value) => {
                  this.setState({ forecast_type: value }, () => {
                    this.getOrderForecast();
                    this.getProductDemandForecast();
                  });
                }}
              >
                <Option key="Weekly">Weekly</Option>
                <Option key="Monthly">Monthly</Option>
              </Select>
            </div>
          </div>
          <Title level={3}>Received Amount Forecasting</Title>
          <Line
            style={{ marginBottom: 20 }}
            data={{
              labels: this.state.forecasted_amount.map((r) => r.date),
              datasets: [
                {
                  label: "Received Amount",
                  data: this.state.received_amount.map((r, index) =>
                    r.received_amount === 0 ? null : r.received_amount
                  ),
                  borderColor: "#F05454",
                  pointBackgroundColor: "#F05454",
                },
                {
                  label: "Forecasted Amount",
                  data: this.state.forecasted_amount.map((r, index) =>
                    r.forecasted_amount === 0 &&
                    index !==
                      this.findFirstZeroIndexFromEnd(
                        this.state.forecasted_amount,
                        "forecasted_amount"
                      )
                      ? null
                      : r.forecasted_amount
                  ),
                  borderColor: "#006769",
                  pointBackgroundColor: "#006769",
                  borderDash: [5, 5],

                  backgroundColor: "rgba(199, 255, 216, 0.4)",
                  fill: true,
                },
                {
                  label: "Upper Bound",
                  data: this.state.upper_bound_received_amount.map((r, index) =>
                    r.upper_bound === 0 &&
                    index !==
                      this.findFirstZeroIndexFromEnd(
                        this.state.upper_bound_received_amount,
                        "upper_bound"
                      )
                      ? null
                      : r.upper_bound
                  ),
                  borderColor: "#B958A5",
                  pointBackgroundColor: "#B958A5",
                  borderDash: [5, 5],
                  fill: true,
                  backgroundColor: "rgba(199, 255, 216, 0.4)",
                },
                {
                  label: "Lower Bound",
                  data: this.state.lower_bound_received_amount.map((r, index) =>
                    r.lower_bound === 0 &&
                    index !==
                      this.findFirstZeroIndexFromEnd(
                        this.state.lower_bound_received_amount,
                        "lower_bound"
                      )
                      ? null
                      : r.lower_bound
                  ),
                  borderColor: "#F8E559",
                  pointBackgroundColor: "#F8E559",
                  borderDash: [5, 5],
                  fill: true,
                  backgroundColor: "rgba(199, 255, 216, 0.4)",
                },
              ],
            }}
            options={{
              responsive: true,
              aspectRatio: 4,
              spanGaps: true,
            }}
          />
          <Title level={3}>Product Quantity Forecasting</Title>
          <Line
            style={{ marginBottom: 20 }}
            data={{
              labels: this.state.forecasted_product.map((r) => r.date),
              datasets: [
                {
                  label: "Product Quantity",
                  data: this.state.normal_product.map((r, index) =>
                    r.qty === 0 ? null : r.qty
                  ),
                  borderColor: "#597445",
                  pointBackgroundColor: "#597445",
                },
                {
                  label: "Forecasted Quantity",
                  data: this.state.forecasted_product.map((r, index) =>
                    r.forecasted_total === 0 &&
                    index !==
                      this.findFirstZeroIndexFromEnd(
                        this.state.forecasted_product,
                        "forecasted_total"
                      )
                      ? null
                      : r.forecasted_total
                  ),
                  borderColor: "#C96868",
                  pointBackgroundColor: "#C96868",
                  borderDash: [5, 5],

                  backgroundColor: "rgba(199, 255, 216, 0.4)",
                  fill: true,
                },
                {
                  label: "Upper Bound",
                  data: this.state.upper_bound_product_amount.map((r, index) =>
                    r.upper_bound === 0 &&
                    index !==
                      this.findFirstZeroIndexFromEnd(
                        this.state.upper_bound_product_amount,
                        "upper_bound"
                      )
                      ? null
                      : r.upper_bound
                  ),
                  borderColor: "#624E88",
                  pointBackgroundColor: "#624E88",
                  borderDash: [5, 5],
                  fill: true,
                  backgroundColor: "rgba(199, 255, 216, 0.4)",
                },
                {
                  label: "Lower Bound",
                  data: this.state.lower_bound_product_amount.map((r, index) =>
                    r.lower_bound === 0 &&
                    index !==
                      this.findFirstZeroIndexFromEnd(
                        this.state.lower_bound_product_amount,
                        "lower_bound"
                      )
                      ? null
                      : r.lower_bound
                  ),
                  borderColor: "#CB80AB",
                  pointBackgroundColor: "#CB80AB",
                  borderDash: [5, 5],
                  fill: true,
                  backgroundColor: "rgba(199, 255, 216, 0.4)",
                },
              ],
            }}
            options={{
              responsive: true,
              aspectRatio: 4,
              spanGaps: true,
            }}
          />

          <div style={{ display: "flex", marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "50%",
              }}
            >
              <Title style={{ marginLeft: 15 }} level={3}>
                Top Profitable Products
              </Title>
              <Table
                dataSource={this.state.top_profit_products}
                pagination={false}
                rowKey={(record) => record.product_id}
                size="small"
              >
                <Column
                  title="Name"
                  key="Name"
                  render={(text, record) => (
                    <span>{`${record?.name ?? ""}`}</span>
                  )}
                />
                <Column
                  title="SKU"
                  key="sku"
                  render={(text, record) => (
                    <span>{`${record?.sku ?? ""}`}</span>
                  )}
                />
                <Column
                  title="Quantity"
                  key="quantity"
                  render={(text, record) => (
                    <span>{`${record?.quantity ?? 0}`}</span>
                  )}
                />
              </Table>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "50%",
                marginLeft: 5,
              }}
            >
              <Title style={{ marginLeft: 15 }} level={3}>
                Least Profitable Products
              </Title>
              <Table
                dataSource={this.state.least_profit_products}
                pagination={false}
                rowKey={(record) => record.product_id}
                size="small"
              >
                <Column
                  title="Name"
                  key="Name"
                  render={(text, record) => (
                    <span>{`${record?.name ?? ""}`}</span>
                  )}
                />
                <Column
                  title="SKU"
                  key="sku"
                  render={(text, record) => (
                    <span>{`${record?.sku ?? ""}`}</span>
                  )}
                />
                <Column
                  title="Quantity"
                  key="quantity"
                  render={(text, record) => (
                    <span>{`${record?.quantity ?? 0}`}</span>
                  )}
                />
              </Table>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "50%",
              }}
            >
              <Title style={{ marginLeft: 15 }} level={3}>
                Top Selling Products
              </Title>
              <Table
                dataSource={this.state.top_products}
                pagination={false}
                rowKey={(record) => record.product_id}
                size="small"
              >
                <Column
                  title="Name"
                  key="Name"
                  render={(text, record) => (
                    <span>{`${record?.name ?? ""}`}</span>
                  )}
                />
                <Column
                  title="SKU"
                  key="sku"
                  render={(text, record) => (
                    <span>{`${record?.sku ?? ""}`}</span>
                  )}
                />
                <Column
                  title="Quantity"
                  key="quantity"
                  render={(text, record) => (
                    <span>{`${record?.quantity ?? 0}`}</span>
                  )}
                />
              </Table>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "50%",
                marginLeft: 5,
              }}
            >
              <Title style={{ marginLeft: 15 }} level={3}>
                Least Selling Products
              </Title>
              <Table
                dataSource={this.state.low_products}
                pagination={false}
                rowKey={(record) => record.product_id}
                size="small"
              >
                <Column
                  title="Name"
                  key="Name"
                  render={(text, record) => (
                    <span>{`${record?.name ?? ""}`}</span>
                  )}
                />
                <Column
                  title="SKU"
                  key="sku"
                  render={(text, record) => (
                    <span>{`${record?.sku ?? ""}`}</span>
                  )}
                />
                <Column
                  title="Quantity"
                  key="quantity"
                  render={(text, record) => (
                    <span>{`${record?.quantity ?? 0}`}</span>
                  )}
                />
              </Table>
            </div>
          </div>
        </Card>
        <div style={{ display: "flex" }}>
          <Card style={{ marginTop: 5, marginRight: 5, width: "100%" }}>
            <Title style={{ marginLeft: 15 }} level={3}>
              Top 10 Sells Rep
            </Title>
            {this.state.top_ten_users.map((user) => (
              <div key={user.created_by}>
                <Text
                  style={{
                    fontSize: 20,
                  }}
                >
                  {user.created_by_name}
                </Text>
                <Progress
                  style={{
                    fontSize: 20,
                  }}
                  percent={user.task_count * 10}
                  format={() => user.task_count}
                  strokeColor="#00712D"
                  status="active"
                />
              </div>
            ))}
          </Card>
          <Card style={{ marginTop: 5, width: "100%" }}>
            <Title style={{ marginLeft: 15 }} level={3}>
              Recent Achievements
            </Title>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ margin: 10 }}>
                <Text>{`Displaying : `}</Text>
                <Select
                  value={this.state.target_achievement_page_size}
                  onChange={this.setTargetLimit}
                >
                  <Option key={10}>10</Option>
                  <Option key={25}>25</Option>
                  <Option key={50}>50</Option>
                  <Option key={100}>100</Option>
                </Select>
                <Text>{`  Entries `}</Text>
              </div>
              <div style={{ margin: 10 }}>
                <Text>{`Search User : `}</Text>
                <Select
                  style={{ width: 200 }}
                  value={this.state.individual_user_id}
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearchUser}
                  onChange={this.setUser}
                  placeholder="Search User"
                >
                  {this.state.individual_users.map((r) => {
                    return (
                      <Option
                        key={r._id.$oid}
                      >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                    );
                  })}
                </Select>
              </div>
              <div style={{ margin: 10 }}>
                <Text>Duration Type : </Text>
                <Select
                  value={this.state.duration_type}
                  onChange={(value) => {
                    this.setState({ duration_type: value }, () => {
                      this.getTargetAchievementTable();
                      console.log(this.state.duration_type);
                    });
                  }}
                  style={{ minWidth: 100 }}
                >
                  <Option key="WEEKLY">WEEKLY</Option>
                  <Option key="MONTHLY">MONTHLY</Option>
                </Select>
              </div>
            </div>
            <Table
              dataSource={this.state.target_achievement_data}
              pagination={false}
              rowKey={(record) => record.user_id}
              size="small"
            >
              <Column
                title="Name"
                key="name"
                render={(text, record) => (
                  <span>{`${record?.name ?? ""}`}</span>
                )}
              />
              <Column
                title="Month"
                key="month"
                render={(text, record) => (
                  <span>{`${record?.month ?? ""}`}</span>
                )}
              />
              <Column
                title="Target Value"
                key="target_value"
                render={(text, record) => (
                  <span>{`${record?.target_value ?? 0}`}</span>
                )}
              />
              <Column
                title="Achievement Percentage"
                key="achievement_percentage"
                render={(text, record) => (
                  <span>{`${record?.achievement_percentage ?? 0}`}</span>
                )}
              />
            </Table>
            {this.state.target_achievement_total > 10 ? (
              <Pagination
                pageSize={Number(this.state.target_achievement_page_size)}
                current={this.state.target_achievement_page}
                total={this.state.target_achievement_total}
                onChange={this.targetPageChangepageChange}
                style={{ marginTop: 10 }}
              />
            ) : null}
          </Card>
        </div>
        <Modal
          title="Necessary Stocks"
          visible={this.state.show_necessary_stock_modal}
          footer={null}
          onCancel={() => {
            this.setState({ show_necessary_stock_modal: false });
          }}
          width={window.innerWidth - 200}
          closable={true}
        >
          <Table
            dataSource={this.state.necessary_stock}
            pagination={false}
            rowKey={(record) => record.item_name}
            size="small"
          >
            <Column
              title="Name"
              key="Name"
              render={(text, record) => (
                <span>{`${record?.item_name ?? ""}`}</span>
              )}
            />
            <Column
              title="Needed Stock"
              key="Needed Stock"
              render={(text, record) => (
                <span style={{ color: "#F5004F" }}>{`${
                  record?.needed_stock ?? ""
                }`}</span>
              )}
            />
          </Table>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {
  getUserData,
})(Order);
