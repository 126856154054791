import {SAVE_START_TASK} from "../actions/types";

const initialState={
    startTaskData:{}
}


export default function(state=initialState,action){
    switch(action.type){
        case SAVE_START_TASK:
            return {
                ...state,
                startTaskData:action.payload
            }
        default:
            return state;
    }
}

