import React, { Component } from "react";
import {
  Table,
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Icon,
  Tag,
} from "antd";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
// import DateFnsUtils from "@date-io/date-fns";
// import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import swal from "sweetalert2";
import { debounce } from "lodash";
import {
  // PLACE_LIST_FOR_PURCHASE_ORDER,
  // PLACE_DETAILS_FOR_PURCHASE_ORDER,
  // INVENTORY_BY_PLACE,
  CREATE_PRODUCT,
  CREATE_PRODUCT_CATEGORY,
  CREATE_ORDER,
  ORDER_NUMBER,
  CURRENT_USER_SUB_ORG_LIST,
  CREATE_INVENTORY,
  CREATE_ENTITY,
  EDIT_ENTITY,
  REVERSE_LOCATION_SEARCH_GOOGLE,
  ORDER_DISCOUNT_TYPE_CHOICES,
  ORDER_PAYMENT_METHOD_CHOICES,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  GET_USER_LOCAL,
  CHECK_BOOL,
} from "../../utilities/utilities";

import "../order.css";

const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

class CreatePosOrder extends Component {
  state = {
    endObj: {},
    end_loc: {
      coordinates: [90.3563, 23.685],
    },
    end_loc_name: "",
    assigned_org: "",
    assigned_org_name: "",
    drop_contact_no: "",
    drop_contact_name: "",
    drop_contact_email: "",
    product_categories: [],
    category_name: "",
    data: [],
    items: [],
    selected_items: [],
    delivery_date_time: Date.now(),
    delivery_date_time_view: moment(),
    order_number: "",
    total_price: 0,
    delivery_charge: 0,
    other_charges: 0,
    bill_amount: 0,
    total_price_before_discount: 0,
    total_discount: 0,
    organization: "",
    assigned_user_sub_orgs: [],
    sub_org_id: "",
    sub_org_name: "",
    inventory_data: [],
    inventory_id: "",
    inventory_name: "",
    dataS: [],
    end_entity_name: "",
    end_entity_key: "",
    showDropDowns: false,
    current_loc_lat: 23.685,
    current_loc_lon: 90.3563,
    current_loc_name: "",
    barcode_search: false,
    barcode_search_text: "",
    discount_type_choices: [],
    payment_method_choices: [],
    delivery_charge_included: false,
    other_discount_type: "amount",
    other_discount: 0,
    received_method: "cash",
    received_amount: 0,
    due_amount: 0,
  };
  componentDidMount() {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState(
        {
          current_loc_lat: latitude,
          current_loc_lon: longitude,
          end_loc: {
            coordinates: [longitude, latitude],
          },
        },
        () => {
          GET_ACCESS_TOKEN().then((token) => {
            const url = `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.current_loc_lat}&lng=${this.state.current_loc_lon}&access_token=${token}`;
            axios({
              method: "get",
              url: url,
            }).then((response) => {
              console.log("reverse api response", response);
              let name = response.data.google_loc_data[0].address;
              this.setState({
                current_loc_name: name,
                end_loc_name: name,
              });
            });
          });
        }
      );
    };
    navigator.geolocation.getCurrentPosition(success);
    this.getOrderNumber();
    this.getUserSubOrg();
    this.getProductCategory();
    this.getShopList();
    this.getPaymentMethodType();
    this.getDiscountType();
  }

  getPaymentMethodType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_PAYMENT_METHOD_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            payment_method_choices: response.data.payment_method_choices,
          });
          console.log(
            "response from order payment method type choices",
            response
          );
        })
        .catch((error) => {
          console.log(
            "error from order payment method type choices api",
            error
          );
        });
    });
  };

  getDiscountType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_DISCOUNT_TYPE_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            discount_type_choices: response.data.order_discount_type_choices,
          });
          console.log("response from order disocunt type choices", response);
        })
        .catch((error) => {
          console.log("error from order disocunt type choices api", error);
        });
    });
  };

  getUserSubOrg = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?assigned_user_id=${
        GET_USER_LOCAL()._id.$oid
      }&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          if (response.data.data.length > 0) {
            this.setState(
              {
                assigned_user_sub_orgs: response.data.data,
                sub_org_id: response.data.data[0].id,
                sub_org_name: response.data.data[0].name,
              },
              () => {
                this.getInventoryList();
              }
            );
          } else {
            this.setState(
              { assigned_user_sub_orgs: response.data.data },
              () => {
                this.getInventoryList();
              }
            );
          }
          console.log("response from assigned user sub org list", response);
        })
        .catch((error) => {
          console.log("error from assigned user sub org list api", error);
        });
    });
  };

  getInventoryList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_INVENTORY}?sub_org=${this.state.sub_org_id}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from inventory list", response);
          this.setState(
            {
              inventory_data: response.data.data,
              inventory_id:
                response.data.data.length > 0
                  ? response.data.data[0]._id.$oid
                  : "",
              inventory_name:
                response.data.data.length > 0
                  ? response.data.data[0].entity_name
                  : "",
            },
            () => {
              this.getProductList();
            }
          );
        })
        .catch((error) => {
          console.log("error from inventory list api", error);
        });
    });
  };

  getShopList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?limit=100&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("shop list api", response);
          this.setState({ dataS: response.data.data });
        })
        .catch((error) => {
          console.log("error from shop list api", error);
        });
    });
  };

  searchPlace = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?contact_number=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from place list", response);
          this.setState({
            dataS: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from place list api", error);
        });
    });
  }, 500);

  getOrderNumber = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_NUMBER}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from order Number", response);
          this.setState({
            order_number: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from org list api", error);
        });
    });
  };

  getProductCategory = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT_CATEGORY}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ product_categories: response.data?.data ?? [] });
          console.log(
            "data from product category list using org api",
            response
          );
        })
        .catch((error) => {
          console.log("error from product category list using org api", error);
        });
    });
  };

  getProductList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_PRODUCT}?category_id=${this.state.category_name}&product_status=active&inventory_id=${this.state.inventory_id}&enable_order_for_availabl_stock_or_stock_quantity_zero=false&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product", response);
          let products = response.data.data.map((p) => ({
            id: p._id.$oid,
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id,
            product_img: p.product_img,
            product_img_thumb: p.product_img_thumb,
            related_promotion_count: p.related_promotion_count,
            parent_product_id: p.parent_product_id,
          }));

          let products1 = response.data.data.map((p) => ({
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id,
            product_img: p.product_img,
            product_img_thumb: p.product_img_thumb,
            related_promotion_count: p.related_promotion_count,
            parent_product_id: p.parent_product_id,
          }));

          console.log("..........................", products);
          this.setState(
            { data: [...this.state.data, ...products1], items: products },
            () => {
              console.log("data joined", this.state.data);
            }
          );
        })
        .catch((error) => {
          console.log("error from shop list api", error);
        });
    });
  };

  itemSearch = debounce((value, event) => {
    let limit = 100;
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT}?barcode_search=${
        this.state.barcode_search_text!==""?true:false
      }&sku=${this.state.barcode_search_text!=="" ? value : ""}&category_name=${
        this.state.category_name
      }&name=${
        this.state.barcode_search_text==="" ? value : ""
      }&product_status=active&inventory_id=${
        this.state.inventory_id
      }&enable_order_for_availabl_stock_or_stock_quantity_zero=false&limit=${limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product list", response);
          let products = response.data.data.map((p) => ({
            id: p._id.$oid,
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id,
            product_img: p.product_img,
            product_img_thumb: p.product_img_thumb,
            related_promotion_count: p.related_promotion_count,
            parent_product_id: p.parent_product_id,
          }));

          let products1 = response.data.data.map((p) => ({
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id,
            product_img: p.product_img,
            product_img_thumb: p.product_img_thumb,
            related_promotion_count: p.related_promotion_count,
            parent_product_id: p.parent_product_id,
          }));
          if (this.state.barcode_search_text!=="" && products.length === 1) {
            this.onSelectChange(products[0]);
            this.setState({ barcode_search_text: "" });
          }
          console.log("..........................", products);
          this.setState(
            { data: [...this.state.data, ...products1], items: products },
            () => {
              console.log("data joined", this.state.data);
            }
          );
        })
        .catch((error) => {
          console.log("error from product list api", error);
        });
    });
  }, 500);

  setEnd = (value) => {
    this.setState(
      { end_entity_name: value.label, end_entity_key: value.key },
      () => {
        this.getShopList();
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_ENTITY}${this.state.end_entity_key}?access_token=${token}`;
          axios({
            method: "get",
            url: url,
          }).then((response) => {
            console.log("response fro edit entity", response);
            if (response.data.data.hasOwnProperty("contact")) {
              this.setState({
                drop_contact_name: response.data?.data?.contact?.name ?? "",
                drop_contact_no:
                  response.data?.data?.contact?.mobile?.slice(4) ?? "",
                drop_contact_email: response.data?.data?.contact?.email ?? "",
              });
            }
            this.setState({
              endObj: {
                name: response.data.data.name,
                id: response.data.data._id.$oid,
                entity_type: response.data.data.entity_type,
                loc: response.data.data.loc,
                address: response.data.data.address,
                city: response.data.data.city,
                country: response.data.data.country,
                post_code: response.data.data.post_code,
                organization: response.data.data.organization.$oid,
              },
              end_loc: response.data.data.loc,
              end_loc_name: response.data.data.address,
            });
          });
        });
      }
    );
  };

  setSubOrg = (value) => {
    this.setState({ sub_org_id: value.key, sub_org_name: value.label }, () => {
      this.getInventoryList();
      // this.getProductList();
      console.log("sub org value", this.state.sub_org_id);
    });
  };

  setProductCategory = (value) => {
    this.setState({ category_name: value }, () => {
      this.getProductList();
      console.log(this.state.category_name);
    });
  };

  setInventory = (value) => {
    this.setState(
      { inventory_name: value.label, inventory_id: value.key },
      () => {
        console.log(this.state.inventory_name);
        this.getProductList();
      }
    );
  };

  setOtherDiscountType = (value) => {
    this.setState({ other_discount_type: value }, () => {
      console.log(this.state.other_discount_type);
    });
  };

  setOtherDiscount = (evt) => {
    evt.preventDefault();
    this.setState({ other_discount: Number(evt.target.value) }, () => {
      console.log(this.state.other_discount);
    });
  };

  setDeliverychargeincluded = (value) => {
    this.setState({ delivery_charge_included: CHECK_BOOL(value) }, () => {
      console.log(this.state.delivery_charge_included);
    });
  };

  setDelivery = (value) => {
    this.setState({ delivery: CHECK_BOOL(value) }, () => {
      console.log(this.state.delivery);
    });
  };

  setDelivered = (value) => {
    this.setState({ delivered: CHECK_BOOL(value) }, () => {
      console.log(this.state.delivered);
    });
  };

  setReceived = (value) => {
    this.setState({ received: CHECK_BOOL(value) }, () => {
      console.log(this.state.received);
    });
  };

  setDeliveryCharge = (evt) => {
    evt.preventDefault();
    this.setState({ delivery_charge: evt.target.value }, () => {
      this.setState({
        bill_amount:
          Number(this.state.total_price) +
          Number(this.state.delivery_charge) +
          Number(this.state.other_charges),
      });
      console.log(this.state.delivery_charge);
    });
  };
  setOtherCharge = (evt) => {
    evt.preventDefault();
    this.setState({ other_charges: evt.target.value }, () => {
      this.setState({
        bill_amount:
          Number(this.state.total_price) +
          Number(this.state.delivery_charge) +
          Number(this.state.other_charges),
      });
      console.log(this.state.other_charges);
    });
  };
  setReceivedMethod = (value) => {
    this.setState({ received_method: value }, () => {
      console.log(this.state.received_method);
    });
  };

  setBillAmount = (evt) => {
    evt.preventDefault();
    this.setState({ bill_amount: evt.target.value }, () => {
      console.log(this.state.bill_amount);
    });
  };
  setReceivedAmount = (evt) => {
    evt.preventDefault();
    this.setState({ received_amount: evt.target.value }, () => {
      console.log(this.state.received_amount);
    });
  };
  setDueAmount = (evt) => {
    evt.preventDefault();
    this.setState({ due_amount: evt.target.value }, () => {
      console.log(this.state.due_amount);
    });
  };

  calculateDiscount = () => {
    if (this.state.other_discount_type === "amount") {
      this.setState(
        {
          total_price:
            Number(this.state.total_price) - Number(this.state.other_discount),
        },
        () => {
          this.setState({ bill_amount: this.state.total_price });
        }
      );
    } else {
      let percentValue =
        (this.state.total_price * this.state.other_discount) / 100;
      this.setState(
        {
          total_price: Number(this.state.total_price) - Number(percentValue),
        },
        () => {
          this.setState({ bill_amount: this.state.total_price });
        }
      );
    }
  };

  calculateTotal = () => {
    this.setState(
      {
        total_price: 0,
        total_price_before_discount: 0,
        total_discount: 0,
        bill_amount: 0,
        other_discount: 0,
      },
      () => {
        let final = this.state.data.filter((i) => i.qty !== 0);
        if (final.length > 0) {
          final.map((i) => {
            let total = i.discounted_price;
            let total_before_discount = i.price;
            let total_discount = i.discount;
            this.setState(
              (prevState) => ({
                total_price: prevState.total_price + total,
                total_price_before_discount:
                  prevState.total_price_before_discount + total_before_discount,
                total_discount: prevState.total_discount + total_discount,
              }),
              () => {
                this.setState({
                  bill_amount:
                    Number(this.state.total_price) +
                    Number(this.state.delivery_charge) +
                    Number(this.state.other_charges),
                });
                console.log("total price", this.state.total_price);
              }
            );

            return null;
          });
        }
      }
    );

    // await new Promise((resolve) => resolve());
    // this.createOrder();
  };

  createOrder = () => {
    if (this.state.data.filter((i) => i.qty !== 0).length > 0) {
      let final = this.state.data.filter((i) => i.qty !== 0);
      let data = {
        current_loc: {
          coordinates: [this.state.current_loc_lon, this.state.current_loc_lat],
        },
        current_loc_name: this.state.current_loc_name,
        order_status: {
          name: "order_delivered",
          seq_no: 6,
        },
        order_number: this.state.order_number,
        order_type: "pos_order",
        organization: GET_USER_LOCAL().organization.$oid,
        name: `${this.state.order_number}-${this.state.end_loc_name}`,
        details: "",
        sub_org: this.state.sub_org_id,
        inventory_id: this.state.inventory_id,
        inventory_entity_name: this.state.inventory_name,
        items: final,
        drop_contact_name: this.state.drop_contact_name,
        drop_contact_no: this.state.drop_contact_no,
        drop_contact_email: this.state.drop_contact_email,
        start_entity: [],
        end_entity: this.state.endObj,
        end_loc: this.state.end_loc,
        end_loc_name: this.state.end_loc_name,
        parcel_dim: {
          pl: 0,
          pw: 0,
          ph: 0,
        },
        unit_dim: 0,
        unit_weight: 0,
        parcel_weight: 0,
        max_weight: 0,
        fragile: false,
        temperature: {
          sensitive: false,
          temperature: null,
          fast: false,
        },
        ucp: false,
        assigned_org: GET_USER_LOCAL().organization.$oid,
        assigned_org_name: GET_USER_LOCAL().organizations[0].name,
        parcel_wght: null,
        total_price: 0,
        delivery_charge: 0,
        delivery_charge_included: true,
        other_charges: 0,
        other_discount_type: "amount",
        other_discount: 0,
        bill_amount: this.state.bill_amount,
        received_amount: 0,
        received_method: "cash",
        received_date: Date.now(),
        due_amount: 0,
        received: true,
        delivery: false,
        delivered: false,
        delivery_time_flexible: false,
        delivery_date_time: Date.now(),
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_ORDER}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            this.getOrderNumber();
            console.log("response from create order", response);
          })
          .catch((error) => {
            this.setState({ loading: false });
            this.getOrderNumber();
            swal.fire("Error", error.message, "error");
            console.log("error from create order", error);
          });
      });
    }
  };

  onSelectChange = (value, e) => {
    let item = this.state.barcode_search_text!=="" ? value : JSON.parse(e.props.value);
    if (this.state.data.some((od) => od.item_id === item.id && od.qty > 0)) {
      let item2 = this.state.data.find((od) => od.item_id === item.id);
      if (
        this.state.allow_zero_stock === false &&
        this.state.stock_quantity_type === "available_stock" &&
        item2.qty + 1 > item2.available_stock
      ) {
        swal.fire("Info", "Quantity must be less than available stock", "info");
        return;
      }
      if (
        this.state.allow_zero_stock === false &&
        this.state.stock_quantity_type === "none" &&
        item2.qty + 1 > item2.available_stock
      ) {
        swal.fire("Info", "Quantity must be less than available stock", "info");
        return;
      }
      if (
        this.state.allow_zero_stock === false &&
        this.state.stock_quantity_type === "stock_quantity" &&
        item2.qty + 1 > item2.stock_quantity
      ) {
        swal.fire("Info", "Quantity must be less than stock quantity", "info");
        return;
      }
      let inc = {
        item: item2.item,
        parent_product_id: item2.parent_product_id,
        available_stock: item2.available_stock,
        stock_quantity: item2.stock_quantity,
        unit_selling_price: item2.unit_selling_price,
        price: item2.price,
        discounted_price: 0,
        discount: 0,
        qty: item2.qty + 1,
        item_discount_type: item2.item_discount_type,
        unit_item_discount: item2.unit_item_discount,
        item_id: item2.item_id,
        unit: item2.unit,
        sku: item2.sku,
        unit_purchase_price: item2.unit_purchase_price,
        mrp: item2.mrp,
        inventory_id: item2.inventory_id,
      };
      if (inc.item_discount_type === "amount") {
        let discountedPrice = inc.unit_selling_price - inc.unit_item_discount;
        inc.discounted_price = discountedPrice * inc.qty;
        inc.price = inc.unit_selling_price * inc.qty;
        inc.discount = inc.price - inc.discounted_price;
        let arr = [];
        arr.push(inc);

        let filteredData = this.state.data.filter((i) => i.item !== item2.item);

        this.setState({ data: [...arr, ...filteredData] }, () => {
          console.log("final state", this.state.data);
          this.calculateTotal();
        });
      } else {
        let percentDiscount =
          (inc.unit_selling_price * inc.unit_item_discount) / 100;
        let discountedPrice = inc.unit_selling_price - percentDiscount;
        inc.discounted_price = discountedPrice * inc.qty;
        inc.price = inc.unit_selling_price * inc.qty;
        inc.discount = inc.price - inc.discounted_price;
        let arr = [];
        arr.push(inc);

        let filteredData = this.state.data.filter((i) => i.item !== item2.item);

        this.setState({ data: [...arr, ...filteredData] }, () => {
          this.calculateTotal();
          console.log("final state", this.state.data);
        });
      }
    } else {
      let inc = {
        item: item.item,
        parent_product_id: item.parent_product_id,
        available_stock: item.available_stock,
        stock_quantity: item.stock_quantity,
        unit_selling_price: item.unit_selling_price,
        price: item.price,
        discounted_price: 0,
        discount: 0,
        qty: 1,
        item_discount_type: "amount",
        unit_item_discount: 0,
        item_id: item.id,
        unit: item.unit,
        sku: item.sku,
        unit_purchase_price: item.unit_purchase_price,
        mrp: item.mrp,
        inventory_id: item.inventory_id,
      };
      inc.price = inc.unit_selling_price * inc.qty;
      inc.discounted_price = inc.unit_selling_price * inc.qty;
      let temp = [];
      temp.push(inc);
      //real data
      let filteredData = this.state.data.filter((i) => i.item !== item.item);

      this.setState({ data: [...temp, ...filteredData] }, () => {
        this.setState(
          {
            data: this.state.data.filter(
              (value, index, self) =>
                self.findIndex((t) => t.item_id === value.item_id) === index
            ),
          },
          () => {
            this.calculateTotal();
            console.log("selection data state", this.state.data);
          }
        );
      });
      //selected data
      this.setState(
        {
          selected_items: [...temp, ...this.state.selected_items],
        },
        () => {
          this.setState({
            selected_items: this.state.selected_items.filter(
              (value, index, self) =>
                self.findIndex((t) => t.item_id === value.item_id) === index
            ),
          });
          console.log(this.state.selected_items);
        }
      );
    }
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title={
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <Text style={{ fontSize: 15 }}>Sub Org Name : </Text>
                  <Tag color="blue">{this.state.sub_org_name}</Tag>
                </div>
                <div>
                  <Text style={{ fontSize: 15 }}>Inventory Name : </Text>
                  <Tag color="blue">{this.state.inventory_name}</Tag>
                </div>
                {this.props.userProfileData.data_level.access_all_data ? (
                  <Button
                    type="link"
                    onClick={() =>
                      this.setState({
                        showDropDowns: !this.state.showDropDowns,
                      })
                    }
                  >
                    Change
                  </Button>
                ) : null}
              </div>
            </>
          }
        />

        <Form>
          {this.state.showDropDowns ? (
            <Form.Item>
              <Text>Select Sub Organization</Text>
              <Select
                style={{ width: "100%" }}
                labelInValue
                value={{
                  key: this.state.sub_org_id,
                  label: this.state.sub_org_name,
                }}
                disabled={
                  this.state.assigned_user_sub_orgs.length < 2 ? true : false
                }
                onChange={this.setSubOrg}
                placeholder="Select Sub Organization"
              >
                {this.state.assigned_user_sub_orgs.map((r) => {
                  return <Option key={r.id}>{`${r.name}`}</Option>;
                })}
              </Select>
            </Form.Item>
          ) : null}
          {this.state.showDropDowns ? (
            <Form.Item>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text>Select Inventory :</Text>
                <Select
                  labelInValue
                  disabled={this.state.inventory_data.length < 2 ? true : false}
                  style={{ width: "100%" }}
                  value={{
                    key: this.state.inventory_id,
                    label: this.state.inventory_name,
                  }}
                  onChange={this.setInventory}
                  placeholder="Select Inventory"
                >
                  {this.state.inventory_data.map((r) => {
                    return <Option key={r._id.$oid}>{r.entity_name}</Option>;
                  })}
                </Select>
              </div>
            </Form.Item>
          ) : null}

          {/* <Form.Item>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text>Select Product Category</Text>
              <Select
                value={this.state.category_name}
                onChange={this.setProductCategory}
              >
                {this.state.product_categories.map((r) => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </div>
          </Form.Item> */}
          <Form.Item>
            <div style={{ display: "flex", flexDirection: "column" }}>
            <Text>Search Product With Barcode </Text>
              <Input
                placeholder="Search Product With Barcode"
                type="text"
                value={this.state.barcode_search_text}
                onChange={(e) =>
                  this.setState({ barcode_search_text: e.target.value }, () => {
                    this.itemSearch(this.state.barcode_search_text);
                  })
                }
              />
              <Text>Search Product With Text </Text>
              <Select
                className="customSelect"
                value={null}
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue
                onSearch={this.itemSearch}
                onChange={(value, e) => this.onSelectChange(value, e)}
                placeholder="Search Item"
              >
                {this.state.items.map((r) => (
                  <Option key={r.id} value={JSON.stringify(r)}>
                    <div>
                      {r.product_img_thumb ? (
                        <img
                          src={`${process.env.REACT_APP_BASE_PATH}${r.product_img_thumb}`}
                          alt="avatar"
                          style={{ width: 100, height: 45 }}
                        />
                      ) : null}
                      {`Name: ${r.item} > Unit: ${r.unit} > Promotions :${r.related_promotion_count} ${
                        this.state.stock_quantity_type === "available_stock"
                          ? `> Available Stock: ${r.available_stock}`
                          : this.state.stock_quantity_type === "stock_quantity"
                          ? `> Stock Quantity: ${r.stock_quantity}`
                          : ""
                      }`}
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
          </Form.Item>

          {this.state.selected_items.length > 0 ? (
            <Card>
              <Table
                dataSource={this.state.selected_items}
                pagination={false}
                rowKey={(record) => record.item_id}
                size="small"
              >
                <Column title="Product" dataIndex="item" key="product" />

                <Column title="Unit" dataIndex="unit" key="unit" />
                <Column
                  title="Unit Selling Price"
                  key="unit_selling_price"
                  dataIndex="unit_selling_price"
                />

                <Column
                  title="Decrease"
                  key="Decrease"
                  render={(record) => (
                    <span>
                      <Button
                        style={{
                          color: "red",
                        }}
                        type="link"
                        onClick={() => {
                          let item = this.state.data.find(
                            (i) => i.item === record.item
                          );
                          if (item.qty - 1 <= 0) {
                            swal.fire(
                              "Info",
                              "Quantity can not be zero",
                              "info"
                            );
                            return;
                          }
                          let inc = {
                            item: item.item,
                            parent_product_id: item.parent_product_id,
                            available_stock: item.available_stock,
                            stock_quantity: item.stock_quantity,
                            unit_selling_price: item.unit_selling_price,
                            price: item.price,
                            discounted_price: 0,
                            discount: 0,
                            qty: item.qty - 1,
                            item_discount_type: item.item_discount_type,
                            unit_item_discount: item.unit_item_discount,
                            item_id: item.item_id,
                            unit: item.unit,
                            sku: item.sku,
                            unit_purchase_price: item.unit_purchase_price,
                            mrp: item.mrp,
                            inventory_id: item.inventory_id,
                          };
                          if (inc.item_discount_type === "amount") {
                            let discountedPrice =
                              inc.unit_selling_price - inc.unit_item_discount;
                            inc.discounted_price = discountedPrice * inc.qty;
                            inc.price = inc.unit_selling_price * inc.qty;
                            inc.discount = inc.price - inc.discounted_price;
                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                this.calculateTotal();
                                console.log("final state", this.state.data);
                              }
                            );
                          } else {
                            let percentDiscount =
                              (inc.unit_selling_price *
                                inc.unit_item_discount) /
                              100;
                            let discountedPrice =
                              inc.unit_selling_price - percentDiscount;
                            inc.discounted_price = discountedPrice * inc.qty;
                            inc.price = inc.unit_selling_price * inc.qty;
                            inc.discount = inc.price - inc.discounted_price;
                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                this.calculateTotal();
                                console.log("final state", this.state.data);
                              }
                            );
                          }
                        }}
                      >
                        <Icon type="minus" />
                      </Button>
                    </span>
                  )}
                />

                <Column
                  title="Quantity"
                  key="Quantity"
                  render={(record) => (
                    <span>
                      <Input
                        type="number"
                        value={
                          this.state.data.length > 0
                            ? this.state.data.find(
                                (i) => i.item === record.item
                              )?.qty
                            : 0
                        }
                        onChange={(evt) => {
                          let item = this.state.data.find(
                            (i) => i.item === record.item
                          );
                          if (Number(evt.target.value <= 0)) {
                            return;
                          }

                          if (
                            this.state.allow_zero_stock === false &&
                            this.state.stock_quantity_type ===
                              "available_stock" &&
                            Number(evt.target.value) > item.available_stock
                          ) {
                            swal.fire(
                              "Info",
                              "Quantity must be less than available stock",
                              "info"
                            );
                            return;
                          }
                          if (
                            this.state.allow_zero_stock === false &&
                            this.state.stock_quantity_type === "none" &&
                            Number(evt.target.value) > item.available_stock
                          ) {
                            swal.fire(
                              "Info",
                              "Quantity must be less than available stock",
                              "info"
                            );
                            return;
                          }
                          if (
                            this.state.allow_zero_stock === false &&
                            this.state.stock_quantity_type ===
                              "stock_quantity" &&
                            Number(evt.target.value) > item.stock_quantity
                          ) {
                            swal.fire(
                              "Info",
                              "Quantity must be less than stock quantity",
                              "info"
                            );
                            return;
                          }

                          let inc = {
                            item: item.item,
                            parent_product_id: item.parent_product_id,
                            available_stock: item.available_stock,
                            stock_quantity: item.stock_quantity,
                            unit_selling_price: item.unit_selling_price,
                            price: item.price,
                            discounted_price: 0,
                            discount: 0,
                            qty: Number(evt.target.value),
                            item_discount_type: item.item_discount_type,
                            unit_item_discount: item.unit_item_discount,
                            item_id: item.item_id,
                            unit: item.unit,
                            sku: item.sku,
                            unit_purchase_price: item.unit_purchase_price,
                            mrp: item.mrp,
                            inventory_id: item.inventory_id,
                          };
                          if (inc.item_discount_type === "amount") {
                            let discountedPrice =
                              inc.unit_selling_price - inc.unit_item_discount;
                            inc.discounted_price =
                              discountedPrice * Number(evt.target.value);
                            inc.price =
                              inc.unit_selling_price * Number(evt.target.value);
                            inc.discount = inc.price - inc.discounted_price;
                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                this.calculateTotal();
                                console.log("final state", this.state.data);
                              }
                            );
                          } else {
                            let percentDiscount =
                              (inc.unit_selling_price *
                                inc.unit_item_discount) /
                              100;
                            let discountedPrice =
                              inc.unit_selling_price - percentDiscount;
                            inc.discounted_price =
                              discountedPrice * Number(evt.target.value);
                            inc.price =
                              inc.unit_selling_price * Number(evt.target.value);
                            inc.discount = inc.price - inc.discounted_price;
                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                this.calculateTotal();
                                console.log("final state", this.state.data);
                              }
                            );
                          }
                        }}
                      />
                    </span>
                  )}
                />
                <Column
                  title="Increase"
                  key="Increase"
                  render={(record) => (
                    <span>
                      <Button
                        style={{
                          color: "green",
                        }}
                        type="link"
                        onClick={() => {
                          let item = this.state.data.find(
                            (i) => i.item === record.item
                          );
                          if (
                            this.state.allow_zero_stock === false &&
                            this.state.stock_quantity_type ===
                              "available_stock" &&
                            item.qty + 1 > item.available_stock
                          ) {
                            swal.fire(
                              "Info",
                              "Quantity must be less than available stock",
                              "info"
                            );
                            return;
                          }
                          if (
                            this.state.allow_zero_stock === false &&
                            this.state.stock_quantity_type === "none" &&
                            item.qty + 1 > item.available_stock
                          ) {
                            swal.fire(
                              "Info",
                              "Quantity must be less than available stock",
                              "info"
                            );
                            return;
                          }
                          if (
                            this.state.allow_zero_stock === false &&
                            this.state.stock_quantity_type ===
                              "stock_quantity" &&
                            item.qty + 1 > item.stock_quantity
                          ) {
                            swal.fire(
                              "Info",
                              "Quantity must be less than stock quantity",
                              "info"
                            );
                            return;
                          }
                          let inc = {
                            item: item.item,
                            parent_product_id: item.parent_product_id,
                            available_stock: item.available_stock,
                            stock_quantity: item.stock_quantity,
                            unit_selling_price: item.unit_selling_price,
                            price: item.price,
                            discounted_price: 0,
                            discount: 0,
                            qty: item.qty + 1,
                            item_discount_type: item.item_discount_type,
                            unit_item_discount: item.unit_item_discount,
                            item_id: item.item_id,
                            unit: item.unit,
                            sku: item.sku,
                            unit_purchase_price: item.unit_purchase_price,
                            mrp: item.mrp,
                            inventory_id: item.inventory_id,
                          };
                          if (inc.item_discount_type === "amount") {
                            let discountedPrice =
                              inc.unit_selling_price - inc.unit_item_discount;
                            inc.discounted_price = discountedPrice * inc.qty;
                            inc.price = inc.unit_selling_price * inc.qty;
                            inc.discount = inc.price - inc.discounted_price;
                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                this.calculateTotal();
                                console.log("final state", this.state.data);
                              }
                            );
                          } else {
                            let percentDiscount =
                              (inc.unit_selling_price *
                                inc.unit_item_discount) /
                              100;
                            let discountedPrice =
                              inc.unit_selling_price - percentDiscount;
                            inc.discounted_price = discountedPrice * inc.qty;
                            inc.price = inc.unit_selling_price * inc.qty;
                            inc.discount = inc.price - inc.discounted_price;
                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                this.calculateTotal();
                                console.log("final state", this.state.data);
                              }
                            );
                          }
                        }}
                      >
                        <Icon type="plus" />
                      </Button>
                    </span>
                  )}
                />

                <Column
                  title="Price"
                  key="price"
                  render={(record) => (
                    <span>
                      <Text>
                        {this.state.data.find(
                          (i) => i.item_id === record.item_id
                        )?.price ?? 0}
                      </Text>
                    </span>
                  )}
                />

                <Column
                  title="Remove"
                  key="Remove"
                  render={(record) => (
                    <span>
                      <Button
                        style={{
                          color: "red",
                        }}
                        type="link"
                        onClick={() => {
                          this.setState(
                            {
                              selected_items: this.state.selected_items.filter(
                                (item) => item.item !== record.item
                              ),
                            },
                            () => {
                              console.log(
                                "selected delete",
                                this.state.selected_items
                              );
                            }
                          );
                          let item = this.state.data.find(
                            (i) => i.item === record.item
                          );
                          let inc = {
                            item: item.item,
                            parent_product_id: item.parent_product_id,
                            available_stock: item.available_stock,
                            stock_quantity: item.stock_quantity,
                            unit_selling_price: item.unit_selling_price,
                            price: 0,
                            qty: 0,
                            item_discount_type: "amount",
                            unit_item_discount: 0,
                            item_id: item.item_id,
                            unit: item.unit,
                            sku: item.sku,
                            unit_purchase_price: item.unit_purchase_price,
                            mrp: item.mrp,
                            inventory_id: item.inventory_id,
                          };
                          let arr = [];
                          arr.push(inc);

                          let filteredData = this.state.data.filter(
                            (i) => i.item !== item.item
                          );

                          this.setState(
                            { data: [...arr, ...filteredData] },
                            () => {
                              this.calculateTotal();
                              console.log("final state", this.state.data);
                            }
                          );
                        }}
                      >
                        <Icon type="close" />
                      </Button>
                    </span>
                  )}
                />
              </Table>
              <div
                style={{ display: "flex", flexDirection: "column", margin: 5 }}
              >
                <Text
                  style={{ fontSize: 18 }}
                >{`Total Before Discount : ${this.state.total_price_before_discount}`}</Text>
                <Text
                  style={{ fontSize: 18 }}
                >{`Total Product Discount : ${this.state.total_discount}`}</Text>
                <Text
                  style={{ fontSize: 18 }}
                >{`Total Price : ${this.state.total_price}`}</Text>
                <Text
                  style={{ fontSize: 18 }}
                >{`Bill Amount : ${this.state.bill_amount}`}</Text>
              </div>
              {/* <Button onClick={()=>{
              let final = this.state.data.filter((i) => i.qty !== 0);
              final.map((i) => {
                let total = i.discounted_price;
                let total_before_discount = i.price;
                let total_discount = i.discount;
                this.setState(
                  (prevState) => ({
                    total_price: prevState.total_price + total,
                    total_price_before_discount:
                      prevState.total_price_before_discount +
                      total_before_discount,
                    total_discount: prevState.total_discount + total_discount,
                  }),
                  () => {
                    this.setState({
                      bill_amount:
                        Number(this.state.total_price) +
                        Number(this.state.delivery_charge) +
                        Number(this.state.other_charges),
                    });
                    console.log("total price", this.state.total_price);
                  }
                );

                return null;
              });
            }} type="primary" style={{marginTop:10}}>Calculate Total</Button>
            <Text style={{marginLeft:10,fontSize:15}}>Total Bill Amount : {this.state.bill_amount}</Text> */}
            </Card>
          ) : null}
          <div style={{ display: "flex" }}>
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginRight: 5,
              }}
            >
              <Form.Item>
                <Text>Search Customer Number </Text>
                <Select
                  labelInValue
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={true}
                  onSearch={this.searchPlace}
                  value={{
                    key: this.state.end_entity_key,
                    label: this.state.end_entity_name,
                  }}
                  onChange={this.setEnd}
                >
                  {this.state.dataS.map((r) => {
                    return (
                      <Option key={r._id.$oid}>{`Mobile : ${
                        r.contact?.mobile ?? ""
                      }, Name : ${r.contact?.name ?? ""}, Place : ${
                        r.name
                      }`}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {/* <Form.Item>
                <Text>Total Price *</Text>
                <Input
                  placeholder="Total Price"
                  type="text"
                  disabled={true}
                  value={this.state.total_price}
                  // onChange={this.setTotalPrice}
                />
              </Form.Item>
              <Form.Item>
                <Text>Total Before Discount *</Text>
                <Input
                  placeholder="Total Before Discount"
                  type="text"
                  disabled={true}
                  value={this.state.total_price_before_discount}
                  // onChange={this.setTotalPrice}
                />
              </Form.Item>
              <Form.Item>
                <Text>Bill Amount</Text>
                <Input
                  placeholder="Bill Amount"
                  type="text"
                  disabled={true}
                  value={this.state.bill_amount}
                  onChange={this.setBillAmount}
                />
              </Form.Item> */}
              <Card>
                <Form.Item>
                  <Text>Other Discount Type</Text>
                  <Select
                    value={this.state.other_discount_type}
                    onChange={this.setOtherDiscountType}
                  >
                    {this.state.discount_type_choices.map((r) => {
                      return <Option key={r}>{r}</Option>;
                    })}
                  </Select>
                </Form.Item>

                <Form.Item>
                  <Text>Other Discount (Will be applied on total price)</Text>
                  <Input
                    placeholder="Other Discount"
                    type="number"
                    value={this.state.other_discount}
                    onChange={this.setOtherDiscount}
                  />
                  <Button type="primary" onClick={this.calculateDiscount}>
                    Add
                  </Button>
                </Form.Item>
              </Card>

              {/* <Form.Item>
                <Text>Due Amount</Text>
                <Input
                  placeholder="Due Amount"
                  type="text"
                  value={this.state.due_amount}
                  onChange={this.setDueAmount}
                />
              </Form.Item> */}
            </Card>
            <Card style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              {/* <Form.Item>
                <Text>Total Product Discount *</Text>
                <Input
                  placeholder="Total Discount"
                  type="text"
                  disabled={true}
                  value={this.state.total_discount}
                  // onChange={this.setTotalPrice}
                />
              </Form.Item> */}

              <Form.Item>
                <Text>Received Amount Payment Type</Text>
                <Select
                  value={this.state.received_method}
                  onChange={this.setReceivedMethod}
                  placeholder="Received Amount Payment Type"
                >
                  {this.state.payment_method_choices.map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Received Amount</Text>
                <Input
                  placeholder="Received Amount"
                  type="text"
                  value={this.state.received_amount}
                  onChange={this.setReceivedAmount}
                />
              </Form.Item>
              <Form.Item>
                <Text>Delivery Charge Included</Text>
                <Select
                  value={this.state.delivery_charge_included.toString()}
                  onChange={this.setDeliverychargeincluded}
                >
                  <Option key={true}>True</Option>
                  <Option key={false}>False</Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Delivery Charge</Text>
                <Input
                  placeholder="Delivery Charge"
                  disabled={!this.state.delivery_charge_included}
                  type="text"
                  value={this.state.delivery_charge}
                  onChange={this.setDeliveryCharge}
                />
              </Form.Item>
              <Form.Item>
                <Text>Other Charges</Text>
                <Input
                  placeholder="Other Charges"
                  type="text"
                  value={this.state.other_charges}
                  onChange={this.setOtherCharge}
                />
              </Form.Item>
            </Card>
          </div>

          <Form.Item>
            <Button
              style={{ marginTop: 10 }}
              className="login-form-button"
              htmlType="submit"
              onClick={() => this.createOrder()}
            >
              Create POS Order
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(CreatePosOrder);
