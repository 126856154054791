import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Pagination,
  Typography,
  Select,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
// import swal from "sweetalert2";
// import moment from "moment";
// import FileSaver from "file-saver";

import { CREATE_BILL_SETTINGS } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
// import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;

class BillSettingsList extends Component {
  state = {
    data: [],
    page: 0,
    limit: 10,
    total: 0,
  };
  componentDidMount() {
    this.props.getUserData();
    this.getBillSettingsList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getBillSettingsList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_BILL_SETTINGS}?limit=${this.state.limit}&page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from bill seeting list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from bill seeting list api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getBillSettingsList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getBillSettingsList();
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title="Bill Settings"
        />
        <div style={{ margin: 10 }}>
          <Text>{`Displaying : `}</Text>
          <Select value={this.state.limit} onChange={this.setLimit}>
            <Option key={10}>10</Option>
            <Option key={25}>25</Option>
            <Option key={50}>50</Option>
            <Option key={100}>100</Option>
          </Select>
          <Text>{`  Entries `}</Text>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />

          <Column
            title="Rate"
            key="rate"
            render={(text, record) => (
              <span>{record.rate ?? ""}</span>
            )}
          />
          <Column
            title="Bill Calculation Type"
            key="bill_calculation_type"
            render={(text, record) => (
              <span>{record.bill_calculation_type ?? ""}</span>
            )}
          />
          <Column
            title="Bill For"
            key="bill_for"
            render={(text, record) => (
              <span>{record.bill_for?.name ?? ""}</span>
            )}
          />
          <Column
            title="Bill Type"
            key="bill_type"
            render={(text, record) => (
              <span>{record.bill_type?.name ?? ""}</span>
            )}
          />
          <Column
            title="Created By"
            key="created_by"
            render={(text, record) => (
              <span>{record.created_by?.name ?? ""}</span>
            )}
          />
          <Column
            title="Created On"
            key="created_on"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Link
                  to={{
                    pathname: "/editBillSettings",
                    state: { bill_settings_id: record._id.$oid },
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    Edit
                  </Button>
                </Link>
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(BillSettingsList);
