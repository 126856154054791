import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Result,
  Select,
  Layout
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { GET_INVITAION_DATA, JOIN_ORG_INVITATION } from "../../api/constants";
import { GET_ACCESS_TOKEN,timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";
import Logo from "../../assets/logo.png";

const { Text } = Typography;
const { Footer } = Layout;
const { Option } = Select;

export default class JoinOrgInvitation extends Component {
  state = {
    sender_name: "",
    org: "",
    org_name: "",
    sender_org_name: "",
    expires_on: "",
    invitation_link: "",
    loading: false,
    action: "accept",
    show_downlaod:false
  };
  componentDidMount() {
    this.setState({ invitation_id: this.props.match.params.invitation_id });
    this.getOrgInvitation();
  }

  getOrgInvitation = () => {
    GET_ACCESS_TOKEN().then(token => {
      this.setState({ loading: true });
      let url = `${GET_INVITAION_DATA}${this.props.match.params.invitation_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from get org email invitation", response);
          if (response.data.status === "error") {
            swal.fire("Error", response.data.message, "error");
          } else {
            this.setState({
              sender_name: response.data.data.sender_name,
              org: response.data.data.org.$oid,
              org_name: response.data.data.org_name,
              sender_org_name: response.data.data.org_name,
              expires_on: response.data.data.expires_on.$date,
              invitation_link: response.data.data.invitation_link
            });
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from get invite", error);
        });
    });
  };

  handleActionchange = value => {
    this.setState({ action: value }, () => {
      console.log(this.state.action);
    });
  };
  joinOrgInvite = () => {
    GET_ACCESS_TOKEN().then(token => {
      this.setState({ loading: true });
      const data = {
        invitation_id: this.state.invitation_link,
        invite_rsp: this.state.action
      };
      console.log(data);

      let url = `${JOIN_ORG_INVITATION}${this.state.invitation_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Info", response.data.message, "success");
          if(response.data.message==="You have successfully connected the organizations!"){
              this.setState({show_downlaod:true});
          }
          console.log("response from join org email inviatation", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from join inviatation", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            paddingBottom: 10,
            paddingTop: 15,
            backgroundColor: "#006479"
          }}
        >
          <img src={Logo} alt="" style={{ width: 300 }} />
        </div>
        
    
          <div>
            {this.state.show_downlaod === true ? (
              <Card style={{ margin: 10 }}>
                <Result
                  status="success"
                  title="Invitation Accepted Successfully"
                  extra={
                    <Button
                      //    className="login-form-button"
                      htmlType="submit"
                      onClick={() =>
                        (window.location.href = `https://web.conveyance.app`)
                      }
                    >
                      Home
                    </Button>
                  }
                />
              </Card>
            ) : (
              <Card style={{ margin: 10 }}>
                <PageHeader
                  style={{
                    border: "1px solid rgb(235, 237, 240)"
                  }}
                  title={`Confirm Your Action to Connect With "${this.state.sender_org_name}"`}
                />
                <Form
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    flexDirection: "column"
                  }}
                >
                  <Form.Item>
                    <Text>Sender Name</Text>
                    <Input
                      placeholder="Sender Name"
                      type="text"
                      value={this.state.sender_name}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Sender Organization Name</Text>
                    <Input
                      placeholder="Sender Organization Name"
                      type="text"
                      value={this.state.sender_org_name}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Your Organization Name</Text>
                    <Input
                      placeholder="Your Organization Name"
                      type="text"
                      value={this.state.org_name}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Invitation ID</Text>
                    <Input
                      placeholder="Invitation ID"
                      type="text"
                      value={this.state.invitation_link}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Invitation Expires on</Text>
                    <Input
                      placeholder="Expires on"
                      type="text"
                      value={timeConverter(this.state.expires_on)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Invitation Action</Text>
                    <Select
                      value={this.state.action}
                      onChange={this.handleActionchange}
                    >
                      <Option key="accept">Accept</Option>
                      <Option key="reject">Reject</Option>
                      <Option key="ban">Ban</Option>
                      <Option key="delete">Delete</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className="login-form-button"
                      htmlType="submit"
                      onClick={this.joinOrgInvite}
                    >
                      Connect Organization
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            )}
          </div>
        
      {this.state.show_downlaod?null:(
          <Footer
          style={{
            textAlign: "center",
            backgroundColor: "#006479",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              justifyItems: "center"
            }}
          >
            <a href="https://web.conveyance.app">
              <img
                src={Logo}
                alt=""
                style={{ width: 160, height: 41, alignSelf: "center" }}
              />
            </a>
            <Text style={{ color: "white", fontSize: 15 }}>
              Monico Technologies LTD
            </Text>
            <Text style={{ color: "white", fontSize: 15 }}>
              26-27, Ringroad Adabor, Dhaka
            </Text>
            <Text style={{ color: "#b0bec5", fontSize: 15 }}>
              © All rights reserved by Monico Technologies LTD, 2020
            </Text>
          </div>
        </Footer>
      )}
          
        
      </div>
    );
  }
}
