import React, { Component } from "react";
import {
  Card,
  Typography,
  PageHeader,
  // Modal,
//   Form,
  // Table,
//   Tooltip,
//   Button,
//   Input,
//   Select,
  Switch,
  Icon,
//   Tag,
//   Radio,
//   Checkbox,
//   Divider
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import axios from "axios";
import swal from "sweetalert2";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import moment from "moment";

import {
  EDIT_TASK,
  REVERSE_LOCATION_SEARCH_GOOGLE,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  LOCAL_USER_INFO,
  timeConverter
} from "../../utilities/utilities";
import ReactGA from "react-ga4";

const { Text } = Typography;


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40]
});
const red = new L.Icon({
  iconUrl: require("../.././assets/mymarkertwo.png"),
  iconSize: [30, 40]
});


class TaskDetailsLink extends Component {
  state = {
    start_location: "",
    end_location: "",
    data: [],
    fetch: false,
    center_lat: 23.685,
    center_lon: 90.3563,
    start_location_lat: 23.685,
    start_location_lon: 90.3563,
    end_location_lat: 23.685,
    end_location_lon: 90.3563,
    name: "",
    details: "",
    start_time: "",
    start_time_view: "",
    end_time: "",
    end_time_view: "",
    task_code: "",
    contact_id: "",
    contact_no: "",
    contact_name: "",
    contact_info: "",
    drop_contact_id: "",
    drop_contact_no: "",
    drop_contact_name: "",
    drop_contact_info: "",
    price: "",
    assigned_to: "",
    assigned_to_label: "",
    userData: [],
    assigned_asset: "",
    assigned_asset_label: "",
    delay_mins: null,
    trip_duration: 0,
    started: false,
    accepted: false,
    completed: false,
    cancelled: false,
    is_public: false,
    loading: false,
    isChainTask: false,
    delivery_time: "",
    delivery_time_view: moment(),
    delivery_time_by_customer: "",
    delivery_time_by_customer_view: moment(),
    delay_reason: "",

    prev_task_id: "",
    m_task_id: "",
    start_dist: null,
    end_dist: null,
    task_id: "",
    disable: false,
    disableState: false,
    currentUserid: "",
    checkAssign: "",
    checkPublic: false,
    checkStarted: false,
    checkAccepted: false,
    checkCompleted: false,
    polyData: [],
    assignedUserLat: "",
    assignedUserLon: "",
    userLocLon: 0,
    userLocLat: 0,
    userLocName: "",
    task_link: "",
    zoom: 10,
    task_status: "",
    created_by_name: "",
    created_by_mobile: "",
    created_by_email: "",
    created_on: "",
    transport_bill_modal: false,
    transport_type: "",
    transport_name: "",
    transport_fare: null,
    transport_bill_data: [],
    total_transport_bill: null,
    transport_options: [],
    transport_bill_edit: false,
    transport_bill_id: "",
    taskData: {},
    disableLoad: false,
    timezone_name: "",
    timezone_choice: "org_timezone",
    custom_fields: [],
    custom_field_data: {},
    customArrayHandle: [],
    customArrayText: "",
    getCustomFieldData: {},
    assigned_user_name: "",
    assigned_user_no: "",
    assigned_asset_name: "",
    task_type:"",
    sub_org_id: "",
    sub_org_name: "",
    sub_org_type:""
  };

  componentDidMount() {
    this.props.getUserData();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    window.scrollTo(0, 0);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ timezone_name: timezone });
    console.log("time zone", timezone);

    const success = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState({
        userLocLat: latitude,
        userLocLon: longitude
      },()=>{
        GET_ACCESS_TOKEN().then(token => {
          axios
            .get(
              `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.userLocLat}&lng=${this.state.userLocLon}&access_token=${token}`
            )
            .then(response => {
              console.log("response for google reverse geo", response);
              this.setState({
                userLocName: response.data.google_loc_data[0].address
              });
            });
        });
      });
    };
    navigator.geolocation.getCurrentPosition(success);

    this.setState(
      {
        loading: true,
        task_id: this.props.match.params.task_id,
        currentUserid: LOCAL_USER_INFO().user._id
      }

    );
    this.getTask();
  }



  getTask = () => {
    GET_ACCESS_TOKEN().then(token => {
      let task_id = this.props.match.params.task_id;
      let url = `${EDIT_TASK}${task_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response from edit task", response);
        this.setState({ loading: false });

        if (response.data.message === "Task not found") {
          swal.fire("Info", "Task Not Found", "info");
          this.props.history.goBack();
        } else {
          
          this.setState(
            {
              taskData: response.data.data,
              name: response.data.data.name,
              task_type: response?.data?.data?.task_type??"",
              details: response.data.data.details,
              delay_reason: response.data.data.delay_reason,
              start_location: response.data.data.start_loc_name || "",
              end_location: response.data.data.end_loc_name || "",
              start_location_lat: response.data.data.start_loc.coordinates
                ? response.data.data.start_loc.coordinates[1]
                : 23.685,
              start_location_lon: response.data.data.start_loc.coordinates
                ? response.data.data.start_loc.coordinates[0]
                : 90.3563,
              center_lat: response.data.data.start_loc.coordinates
                ? response.data.data.start_loc.coordinates[1]
                : 23.685,
              center_lon: response.data.data.start_loc.coordinates
                ? response.data.data.start_loc.coordinates[0]
                : 90.3563,
              end_location_lat: response.data.data.end_loc.coordinates
                ? response.data.data.end_loc.coordinates[1]
                : 23.685,
              end_location_lon: response.data.data.end_loc.coordinates
                ? response.data.data.end_loc.coordinates[0]
                : 90.3563,
              start_time: response.data.data.start_time.$date,
              start_time_view: response.data.data.start_time.$date,
              end_time_view: response.data.data.end_time.$date,
              end_time: response.data.data.end_time.$date,
              delivery_time_view: response.data.data.delivery_time
                ? response.data.data.delivery_time.$date
                : "",
              delivery_time: response.data.data.delivery_time
                ? response.data.data.delivery_time.$date
                : "",
              delivery_time_by_customer_view: response.data.data
                .delivery_time_by_customer
                ? response.data.data.delivery_time_by_customer.$date
                : "",
              delivery_time_by_customer: response.data.data
                .delivery_time_by_customer
                ? response.data.data.delivery_time_by_customer.$date
                : "",
              task_code: response.data.data.task_code,
              contact_id: response.data.data.contact_id,
              contact_no: response.data.data.contact_no,
              contact_name: response.data.data.contact_name,
              drop_contact_id: response.data.data.drop_contact_id,
              drop_contact_no: response.data.data.drop_contact_no,
              drop_contact_name: response.data.data.drop_contact_name,
              price: response.data.data.price,
              assigned_user_name: response.data.data.assigned_user_name,
              assigned_user_no: response.data.data.assigned_user_no,
              assigned_to: response.data.data.assigned_to,
              sub_org_id: response?.data?.data?.sub_org?.id ?? "",
              sub_org_name: response?.data?.data?.sub_org?.name ?? "",
              sub_org_type: response?.data?.data?.sub_org?.sub_org_type_data?.name ?? "",
              assigned_asset: response.data.data.assigned_asset,
              assigned_asset_name:
                response.data?.data?.assigned_asset_name ?? "",
              delay_mins: response.data.data.delay_mins,
              colors: response.data.data.colors,
              trip_duration: response.data.data.trip_duration,
              is_public: response.data.data.is_public,

              prev_task_id: response.data.data.prev_task_id,
              m_task_id: response.data.data.m_task_id,
              start_dist: response.data.data.start_dist,
              end_dist: response.data.data.end_dist,
              task_link: response.data.data.task_link,
              task_status: response.data.data.task_status,
              created_by_name: response.data.data.created_by_details.name,
              created_by_mobile: response.data.data.created_by_details.mobile,
              created_by_email: response.data.data.created_by_details.email,
              created_on: response.data.data.created_on.$date,
              accepted: response.data.data.accepted,
              started: response.data.data.started,
              completed: response.data.data.completed,
              cancelled: response.data.data.cancelled,
              getCustomFieldData: response.data?.data?.custom_fields ?? {}
            }
          );
        }
      });
    });
  };


  handleAchange = checked => {
    this.setState({ accepted: checked }, () => {
      console.log(this.state.accepted);
      if (this.state.accepted) {
        const data = {
          accepted: this.state.accepted,
          accepted_loc: {
            coordinates: [this.state.userLocLon, this.state.userLocLat]
          },
          accepted_loc_name: this.state.userLocName
        };
        console.log(data);
        GET_ACCESS_TOKEN().then(token => {
          let url = `${EDIT_TASK}${this.state.task_id}?access_token=${token}`;
          axios({
            method: "put",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded"
            }
          })
            .then(response => {
              swal.fire("Info", response.data.message, "info");
              if (response.data.message === "Data successfully updated!") {
                this.getTask();
              }
              this.getTask();
              console.log("response from accepted task", response);
            })
            .catch(error => {
              this.getTask();
              swal.fire("Error", error.message, "error");
              console.log("error from edit task", error);
            });
        });
      }
    });
  };
  handleSchange = checked => {
    this.setState({ started: checked }, () => {
      console.log(this.state.started);
      if (this.state.started) {
        const data = {
          started: this.state.started,
          started_loc: {
            coordinates: [this.state.userLocLon, this.state.userLocLat]
          },
          started_loc_name: this.state.userLocName
        };
        console.log(data);
        GET_ACCESS_TOKEN().then(token => {
          let url = `${EDIT_TASK}${this.state.task_id}?access_token=${token}`;
          axios({
            method: "put",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded"
            }
          })
            .then(response => {
              swal.fire("Info", response.data.message, "info");
              if (response.data.message === "Data successfully updated!") {
                this.getTask();
                this.setState({ checkStarted: true });
              }
              this.getTask();
              console.log("response from started task", response);
            })
            .catch(error => {
              this.getTask();
              swal.fire("Error", error.message, "error");
              console.log("error from edit task", error);
            });
        });
      }
    });
  };
  handleCochange = checked => {
    this.setState({ completed: checked }, () => {
      console.log(this.state.completed);
      if (this.state.completed) {
        const data = {
          completed: this.state.completed,
          completed_loc: {
            coordinates: [this.state.userLocLon, this.state.userLocLat]
          },
          completed_loc_name: this.state.userLocName
        };
        console.log(data);
        GET_ACCESS_TOKEN().then(token => {
          let url = `${EDIT_TASK}${this.state.task_id}?access_token=${token}`;
          axios({
            method: "put",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded"
            }
          })
            .then(response => {
              swal.fire("Info", response.data.message, "info");
              if (response.data.message === "Data successfully updated!") {
                this.getTask();
                this.setState({ checkCompleted: true });
              }
              this.getTask();
              console.log("response from completed task", response);
            })
            .catch(error => {
              this.getTask();
              swal.fire("Error", error.message, "error");
              console.log("error from edit task", error);
            });
        });
      }
    });
  };
  handleCachange = checked => {
    this.setState({ cancelled: checked }, () => {
      console.log(this.state.cancelled);
      if (this.state.cancelled) {
        const data = {
          cancelled: this.state.cancelled
        };
        console.log(data);
        GET_ACCESS_TOKEN().then(token => {
          let url = `${EDIT_TASK}${this.state.task_id}?access_token=${token}`;
          axios({
            method: "put",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded"
            }
          })
            .then(response => {
              swal.fire("Info", response.data.message, "info");
              if (response.data.message === "Data successfully updated!") {
                this.getTask();
              }
              this.getTask();
              console.log("response from cancelled task", response);
            })
            .catch(error => {
              this.getTask();
              swal.fire("Error", error.message, "error");
              console.log("error from edit task", error);
            });
        });
      }
    });
  };



  render() {
  

    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Task Details"
        />
        <Map
          center={[this.state.center_lat, this.state.center_lon]}
          zoom={this.state.zoom}
          style={{ height: 500 }}
          onZoomEnd={e => this.setState({ zoom: e.target._zoom })}
        >


          <ReactLeafletGoogleLayer
            googleMapsLoaderConf={{ KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",VERSION:"3.49.2"}}
            type={"roadmap"}
          />

          <Marker
            position={[
              this.state.start_location_lat,
              this.state.start_location_lon
            ]}
            icon={green}
          >
            <Popup>
              <span>From :{this.state.start_location}</span>
            </Popup>
          </Marker>


          {this.state.start_location !== "" ? (
            <Marker
              position={[
                this.state.end_location_lat,
                this.state.end_location_lon
              ]}
              icon={red}
            >
              <Popup>
                <span>To :{this.state.end_location}</span>
              </Popup>
            </Marker>
          ) : null}
        </Map>
       

        {this.state.assigned_to!==LOCAL_USER_INFO().user._id ? null : (
          <Card
            style={{
              marginTop: 10
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 10,
                textAlign: "left"
              }}
            >
              <div
                style={{
                  margin: 10
                }}
              >
                <Switch
                  checkedChildren="Accepted"
                  unCheckedChildren="Accepted"
                  checked={this.state.accepted}
                  onChange={this.handleAchange}
                  disabled={this.state.accepted}
                />
              </div>
              <div
                style={{
                  margin: 10
                }}
              >
                <Switch
                  checkedChildren="Started"
                  unCheckedChildren="Started"
                  checked={this.state.started}
                  onChange={this.handleSchange}
                  disabled={this.state.started}
                />
              </div>
              <div
                style={{
                  margin: 10
                }}
              >
                <Switch
                  checkedChildren="Completed"
                  unCheckedChildren="Completed"
                  checked={this.state.completed}
                  onChange={this.handleCochange}
                  disabled={this.state.completed}
                />
              </div>
              <div
                style={{
                  margin: 10
                }}
              >
                <Switch
                  checkedChildren="Cancelled"
                  unCheckedChildren="Cancelled"
                  checked={this.state.cancelled}
                  onChange={this.handleCachange}
                  disabled={this.state.cancelled}
                />
              </div>
            </div>
          </Card>
         )} 
      

          <Card
            style={{
              marginTop: 10
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text className="task-view-text">
                <Icon type="environment" style={{ margin: 5 }} />
                Start Location : {""}
                <Text>{this.state.start_location}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="environment" style={{ margin: 5 }} />
                End Location : {""}
                <Text>{this.state.end_location}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="clock-circle" style={{ margin: 5 }} />
                Start Time : {""}
                <Text>
                  {this.state.start_time
                    ? timeConverter(this.state.start_time)
                    : ""}
                </Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="clock-circle" style={{ margin: 5 }} />
                End Time : {""}
                <Text>
                  {this.state.end_time
                    ? timeConverter(this.state.end_time)
                    : ""}
                </Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="clock-circle" style={{ margin: 5 }} />
                Delivery Time : {""}
                <Text>
                  {this.state.delivery_time
                    ? timeConverter(this.state.delivery_time)
                    : ""}
                </Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="clock-circle" style={{ margin: 5 }} />
                Delivery Time by Customer : {""}
                <Text>
                  {this.state.delivery_time_by_customer
                    ? timeConverter(this.state.delivery_time_by_customer)
                    : ""}
                </Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="book" style={{ margin: 5 }} />
                Task Name: {""}
                <Text>{this.state.name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="book" style={{ margin: 5 }} />
                Task Type: {""}
                <Text>{this.state.task_type}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="book" style={{ margin: 5 }} />
                Task Code: {""}
                <Text>{this.state.task_code}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="book" style={{ margin: 5 }} />
                Task Status: {""}
                <Text>{this.state.task_status}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="book" style={{ margin: 5 }} />
                Task Details: {""}
                <Text>{this.state.details}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="book" style={{ margin: 5 }} />
                Delay Reason: {""}
                <Text>{this.state.delay_reason}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="contacts" style={{ margin: 5 }} />
                Pickup Contact Name: {""}
                <Text>{this.state.contact_name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="contacts" style={{ margin: 5 }} />
                Pickup Contact Number: {""}
                <Text>{this.state.contact_no}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="contacts" style={{ margin: 5 }} />
                Drop Contact Name: {""}
                <Text>{this.state.drop_contact_name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="contacts" style={{ margin: 5 }} />
                Drop Contact Number: {""}
                <Text>{this.state.drop_contact_no}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="file" style={{ margin: 5 }} />
                Public Task: {""}
                <Text>{this.state?.is_public?.toString() ?? false}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Assigned User Name: {""}
                <Text>{this.state.assigned_user_name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Assigned User Mobile: {""}
                <Text>{this.state.assigned_user_no}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Sub Organization Name: {""}
                <Text>{this.state.sub_org_name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Sub Organization Type: {""}
                <Text>{this.state.sub_org_type}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Assigned Asset: {""}
                <Text>{this.state.assigned_asset_name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Email: {""}
                <Text>{this.state.created_by_email}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="user" style={{ margin: 5 }} />
                Created By: {""}
                <Text>{this.state.created_by_name}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="dollar" style={{ margin: 5 }} />
                Price: {""}
                <Text>{this.state.price}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="clock-circle" style={{ margin: 5 }} />
                Trip Duration: {""}
                <Text>{this.state.trip_duration}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="clock-circle" style={{ margin: 5 }} />
                Created On: {""}
                <Text>{timeConverter(this.state.created_on)}</Text>
              </Text>
              <Text className="task-view-text">
                <Icon type="link" style={{ margin: 5 }} />
                Task Link: {""}
                <Text>
                  <a
                    href={`https://web.conveyance.app/task-link/${this.state.task_link}`}
                  >
                    {" "}
                    {`Open Link`}
                  </a>
                </Text>
              </Text>
            </div>
          </Card>
       

       
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(TaskDetailsLink);
