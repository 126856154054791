import React, { Component } from "react";
import { Card, Form, Input, Button, Typography, PageHeader, Table} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { EDIT_COUNTRY } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Column } = Table;

export default class CreateCountry extends Component {
  state = {
    name: "",
    tz: "",
    currency: "",
    pmt_name: "",
    cities: [],
    subdivisions: [],
    tax: null,
    vat: null,
    city_name: "",
    city_parent_code: "",
    city_code: "",
    city_country_code: "",
    city_type: "",
    sub_name: "",
    sub_parent_code: "",
    sub_code: "",
    sub_country_code: "",
    sub_type: "",
    ccountry: "",
    loading: false
  };

  componentDidMount() {
    this.setState({ ccountry_id: this.props.location.state.ccountry_id });
  
    GET_ACCESS_TOKEN().then(token => {
      let ccountry_id = this.props.location.state.ccountry_id;
      let url = `${EDIT_COUNTRY}${ccountry_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit country", response.data.data);
        this.setState({
          name: response.data.data.name,
          tz: response.data.data.tz,
          currency: response.data.data.currency,
          pmt_name: response.data.data.pmt_name,
          cities: response.data.data.cities,
          subdivisions: response.data.data.subdivisions,
          tax: response.data.data.tax,
          vat: response.data.data.vat
        });
      });
    });
  }

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setTz = evt => {
    evt.preventDefault();
    this.setState({ tz: evt.target.value }, () => {
      console.log(this.state.tz);
    });
  };
  setCurrency = evt => {
    evt.preventDefault();
    this.setState({ currency: evt.target.value }, () => {
      console.log(this.state.currency);
    });
  };
  setPmt = evt => {
    evt.preventDefault();
    this.setState({ pmt_name: evt.target.value }, () => {
      console.log(this.state.pmt_name);
    });
  };
  setCities = evt => {
    evt.preventDefault();
    let obj = {
      name: this.state.city_name,
      parent_code: this.state.city_parent_code,
      code: this.state.city_code,
      country_code: this.state.city_country_code,
      type: this.state.city_type
    };
    this.state.cities.push(obj);
    console.log("cities", this.state.cities);
    this.setState({
      city_name: "",
      city_parent_code: "",
      city_code: "",
      city_country_code: "",
      city_type: ""
    });
  };
  setSubdivisions = evt => {
    evt.preventDefault();
    let obj = {
      name: this.state.sub_name,
      parent_code: this.state.sub_parent_code,
      code: this.state.sub_code,
      country_code: this.state.sub_country_code,
      type: this.state.sub_type
    };
    this.state.subdivisions.push(obj);
    console.log("subdivisions", this.state.subdivisions);
    this.setState({
      sub_name: "",
      sub_parent_code: "",
      sub_code: "",
      sub_country_code: "",
      sub_type: ""
    });
  };
  setTax = evt => {
    evt.preventDefault();
    this.setState({ tax: evt.target.value }, () => {
      console.log(this.state.tax);
    });
  };
  setVat = evt => {
    evt.preventDefault();
    this.setState({ vat: evt.target.value }, () => {
      console.log(this.state.vat);
    });
  };
  setCname = evt => {
    evt.preventDefault();
    this.setState({ city_name: evt.target.value }, () => {
      console.log(this.state.city_name);
    });
  };
  setCpcode = evt => {
    evt.preventDefault();
    this.setState({ city_parent_code: evt.target.value }, () => {
      console.log(this.state.city_parent_code);
    });
  };
  setSname = evt => {
    evt.preventDefault();
    this.setState({ sub_name: evt.target.value }, () => {
      console.log(this.state.sub_name);
    });
  };
  setSpcode = evt => {
    evt.preventDefault();
    this.setState({ sub_parent_code: evt.target.value }, () => {
      console.log(this.state.sub_parent_code);
    });
  };
  setCcode = evt => {
    evt.preventDefault();
    this.setState({ city_code: evt.target.value }, () => {
      console.log(this.state.city_code);
    });
  };
  setScode = evt => {
    evt.preventDefault();
    this.setState({ sub_code: evt.target.value }, () => {
      console.log(this.state.sub_code);
    });
  };
  setCcountrycode = evt => {
    evt.preventDefault();
    this.setState({ city_country_code: evt.target.value }, () => {
      console.log(this.state.city_country_code);
    });
  };
  setScountrycode = evt => {
    evt.preventDefault();
    this.setState({ sub_country_code: evt.target.value }, () => {
      console.log(this.state.sub_country_code);
    });
  };
  setCtype = evt => {
    evt.preventDefault();
    this.setState({ city_type: evt.target.value }, () => {
      console.log(this.state.city_type);
    });
  };
  setStype = evt => {
    evt.preventDefault();
    this.setState({ sub_type: evt.target.value }, () => {
      console.log(this.state.sub_type);
    });
  };

  editCountry = () => {
    this.setState({ loading: true });
    let data = {
      name: this.state.name,
      tz: this.state.tz,
      currency: this.state.currency,
      pmt_name: this.state.pmt_name,
      cities: this.state.cities,
      subdivisions: this.state.subdivisions,
      tax: this.state.tax,
      vat: this.state.vat
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_COUNTRY}${this.state.ccountry_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: { 
          'content-type': 'application/x-www-form-urlencoded' 
        }
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("success", response.data.message, "success");
          console.log("response from edit country", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("error", error, "error");
          console.log("error from edit country", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Card style={{ margin: 10 }}>
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)"
            }}
            title="Update Country"
            onBack={() => this.props.history.goBack()}
          />
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column"
            }}
          >
            <Form.Item>
              <Text>Name</Text>
              <Input
                placeholder="Name"
                type="text"
                value={this.state.name}
                onChange={this.setName}
              />
            </Form.Item>
            <Form.Item>
              <Text>Time Zone</Text>
              <Input
                placeholder="Time Zone"
                type="text"
                value={this.state.tz}
                onChange={this.setTz}
              />
            </Form.Item>
            <Form.Item>
              <Text>Currency</Text>
              <Input
                placeholder="Currency"
                type="text"
                value={this.state.currency}
                onChange={this.setCurrency}
              />
            </Form.Item>
            <Form.Item>
              <Text>Payment API</Text>
              <Input
                placeholder="Payment API"
                type="text"
                value={this.state.pmt_name}
                onChange={this.setPmt}
              />
            </Form.Item>
            <Form.Item>
              <Text>Cities</Text>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Input
                  style={{ margin: 5 }}
                  placeholder="name"
                  type="text"
                  value={this.state.city_name}
                  onChange={this.setCname}
                />
                <Input
                  style={{ margin: 5 }}
                  placeholder="Parent Code"
                  type="text"
                  value={this.state.city_parent_code}
                  onChange={this.setCpcode}
                />
                <Input
                  style={{ margin: 5 }}
                  placeholder="code"
                  type="text"
                  value={this.state.city_code}
                  onChange={this.setCcode}
                />
                <Input
                  style={{ margin: 5 }}
                  placeholder="country code"
                  type="text"
                  value={this.state.city_country_code}
                  onChange={this.setCcountrycode}
                />
                <Input
                  style={{ margin: 5 }}
                  placeholder="type"
                  type="text"
                  value={this.state.city_type}
                  onChange={this.setCtype}
                />
                <Button
                  className="login-form-button"
                  onClick={this.setCities}
                  style={{ margin: 5 }}
                >
                  Add Data
                </Button>
              </div>
            </Form.Item>
            <Form.Item>
              <Text>Sub Divisions</Text>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Input
                  style={{ margin: 5 }}
                  placeholder="name"
                  type="text"
                  value={this.state.sub_name}
                  onChange={this.setSname}
                />
                <Input
                  style={{ margin: 5 }}
                  placeholder="Parent Code"
                  type="text"
                  value={this.state.sub_parent_code}
                  onChange={this.setSpcode}
                />
                <Input
                  style={{ margin: 5 }}
                  placeholder="code"
                  type="text"
                  value={this.state.sub_code}
                  onChange={this.setScode}
                />
                <Input
                  style={{ margin: 5 }}
                  placeholder="country code"
                  type="text"
                  value={this.state.sub_country_code}
                  onChange={this.setScountrycode}
                />
                <Input
                  style={{ margin: 5 }}
                  placeholder="type"
                  type="text"
                  value={this.state.sub_type}
                  onChange={this.setStype}
                />
                <Button
                  className="login-form-button"
                  onClick={this.setSubdivisions}
                  style={{ margin: 5 }}
                >
                  Add Data
                </Button>
              </div>
            </Form.Item>
            <Form.Item>
              <Text>Tax</Text>
              <Input
                placeholder="Tax"
                type="number"
                value={this.state.tax}
                onChange={this.setTax}
              />
            </Form.Item>
            <Form.Item>
              <Text>Vat</Text>
              <Input
                placeholder="Vat"
                type="number"
                value={this.state.vat}
                onChange={this.setVat}
              />
            </Form.Item>

            <Form.Item>
              <Button className="login-form-button" htmlType="submit" onClick={this.editCountry}>
                Update Country
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Card>
            <Text
              style={{
                fontStyle: "bold",
                fontSize: 25
              }}
            >
              Cities
            </Text>
            <Table dataSource={this.state.cities} pagination={false} rowKey={record => record.name}>
              <Column title="Name" dataIndex="name" key="name" />
              <Column title="Parent Code" dataIndex="parent_code" key="parent_code" />
              <Column title="Code" dataIndex="code" key="code" />
              <Column title="Country Code" dataIndex="country_code" key="country_code" />
              <Column title="Type" dataIndex="type" key="type" />
            </Table>
          </Card>
          <Card>
            <Text
              style={{
                fontStyle: "bold",
                fontSize: 25
              }}
            >
              Sub Divisions
            </Text>
            <Table dataSource={this.state.subdivisions} pagination={false} rowKey={record => record.name}>
              <Column title="Name" dataIndex="name" key="name" />
              <Column title="Parent Code" dataIndex="parent_code" key="parent_code" />
              <Column title="Code" dataIndex="code" key="code" />
              <Column title="Country Code" dataIndex="country_code" key="country_code" />
              <Column title="Type" dataIndex="type" key="type" />
            </Table>
          </Card>
        </div>
      </div>
    );
  }
}
