import React, { Component } from "react";
import {
  Card,
  Result
} from "antd";
import { Redirect } from "react-router-dom";
import swal from "sweetalert2";
import { PayPalButton } from "react-paypal-button-v2";

import { INITIATE_PAYMENT_INT,PROCESS_PAYMENT_INT } from "../../api/constants";
import axios from "axios";
import { Spinner } from "../loader";


import Logo from "../../assets/logo.png";

export default class PaymentPageInt extends Component {
  state = {
    balance: "",
    currency: "",
    tran_id: "",
    plan_id: "",
    show:false,
    loading: false,
    success: false,
    cancel: false,
    fail: false,
  };
  componentDidMount() {
    this.setState({loading:true});
    console.log(this.props.match.params.mb_id);
    const data = {
      mb_id: this.props.match.params.mb_id,
      user_id: this.props.match.params.user_id
    };
    console.log("rdataent", data);
  
      let url=`${INITIATE_PAYMENT_INT}`
      axios({
        method: "post",
        url:url ,
        data: data,
        headers: { 
          'content-type': 'application/x-www-form-urlencoded' 
        }
      })
        .then(response => {
          console.log("response from initiate payment International", response);
          if(response.data.status==="success"){
                 this.setState({
            show: true,
            loading: false,
            balance:response.data.data.mb.balance,
            currency:response.data.data.mb.currency,
            tran_id:response.data.data.tran_id,
            plan_id:response.data.data.mb.org.plans[0]._id,
          });
          }
        })
        .catch(error => {
          console.log("error from initiate payment", error);
        });
    
  }
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    } else if (this.state.success===true){
   
      return <Redirect to={{
        pathname: '/successInt',
        state: { mb_id: this.props.match.params.mb_id,tran_id:this.state.tran_id }
      }}/>
    } else if (this.state.cancel===true){
    
      return <Redirect to={{
        pathname: '/cancelInt',
        state: { tran_id:this.state.tran_id }
      }}/>
    } else if (this.state.fail===true){
    
      return <Redirect to={{
        pathname: '/failInt',
        state: { tran_id:this.state.tran_id }
      }}/>
    }
    return (
         <div>
      <div
       style={{
         textAlign: "center",
         paddingBottom: 10,
         paddingTop: 15,
         backgroundColor: "#006479"
       }}
     >
       <img src={Logo} alt="" style={{ width: 300 }} />
     </div>
     <Card style={{ margin: 10 }}>
       {this.state.show===true?(
         <div style={{textAlign:"center"}}>
          <PayPalButton
          amount={this.state.balance}
          options={{
            clientId:process.env.REACT_APP_PRODUCTION_KEY,
            currency:this.state.currency
            // disableFunding:"card"
          }}
          onSuccess={(details, data) => {
            console.log("Transaction completed ",details);
            let url=`${PROCESS_PAYMENT_INT}${this.state.tran_id}`
            axios({
              method: "post",
              url:url ,
              data:details,
              headers: { 
                'content-type': 'application/x-www-form-urlencoded' 
              }
            })
              .then(response => {
                console.log("response from validate payment International", response);
                if(response.data.status_code===200){
                       this.setState({success:true });
                }
              })
              .catch(error => {
                console.log("error from  validate payment International", error);
              });
            
          }}

          onCancel={(data)=>{
            console.log("Transaction cancelled ",data);
            this.setState({cancel:true});
          }}

          onError={(data)=>{
            console.log("Transaction failed ",data);
            this.setState({fail:true});
          }}

          catchError={(error)=>{
            swal.fire("Error", "Something Went Wrong ! Please Try Again", "error");
          }}

          
        />
        </div>
       ):(
        <Result
           title="Something Went Wrong ! Please try Again."
         />
       )}
         
       </Card>
   </div>
    );
  }
}
