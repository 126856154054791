import React from "react";
import {
  Card,
  PageHeader,
  Button,
  Tooltip,
  Typography,
  Icon,
  Input,
  Pagination,
  Select,
  Divider,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import axios from "axios";
import swal from "sweetalert2";
import "leaflet/dist/leaflet.css";
import { Map, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import CardMaterial from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import ButtonMaterial from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import ReactGA from "react-ga4";

import {
  CREATE_ORGANIZATION,
  EDIT_ORGANIZATION,
  USER_LOCATION_LIST,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  // timeConverter,
  GET_USER_LOCAL,
  timeConverter,
  CHECK_BOOL,
} from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

import icon from "../../assets/icon.png";
// import moment from "moment";

// const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const userIcon = new L.Icon({
  iconUrl: require("../../assets/user.png"),
  iconSize: [35, 40],
});

class OrganizationList extends React.Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    loading: false,
    get_connected_orgs: true,
    is_staff: false,
    userLocationLat: 23.685,
    userLocationLon: 90.3563,
    UserLocationList: [],
    searchData: [],
    name: "",
    has_plan: true,
  };
  componentDidMount() {
    this.props.getUserData();
    // if (LOCAL_USER_INFO().user.is_staff || GET_USER_LOCAL().org_admin) {
    //   this.setState({ get_connected_orgs: true });
    // }
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });

    this.getOrganizationList();
    if (GET_USER_LOCAL().org_admin || GET_USER_LOCAL().is_staff) {
      this.getUserLocationList();
    }

    this.setState({ is_staff: GET_USER_LOCAL().is_staff }, () => {
      console.log(this.state.is_staff);
    });
  }

  getUserLocationList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${USER_LOCATION_LIST}?limit=10000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log(
            "response from organization user locstion list",
            response
          );
          if (response.data.status !== "error") {
            this.setState({ UserLocationList: response.data.data });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from org user locstion list api", error);
        });
    });
  };

  getOrganizationList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ORGANIZATION}?name=${this.state.name}&page=${this.state.page}&limit=${this.state.limit}&has_plan=${this.state.has_plan}&access_token=${token}&get_connected_orgs=${this.state.get_connected_orgs}&sort_type=dsc&page=${this.state.page}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from organization list", response);
          this.setState({
            data: response?.data?.data ?? [],
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from org list api", error);
        });
    });
  }, 500);

  handlesearch = (evt) => {
    this.setState({ name: evt.target.value }, () => {
      this.getOrganizationList();
    });
  };

  refresh = () => {
    this.setState(
      {
        name: "",
        page: 0,
        limit: 10,
      },
      () => {
        this.getOrganizationList();
      }
    );
  };

  setHasPlan = (value) => {
    this.setState({ has_plan: CHECK_BOOL(value) }, () => {
      console.log(this.state.has_plan);
      this.getOrganizationList();
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getOrganizationList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getOrganizationList();
    });
  };

  deleteOrganization = (id) => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${EDIT_ORGANIZATION}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => this.getOrganizationList(), 1000);
          console.log("deleted", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <>
        <div
          style={{
            paddingTop: 20,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {GET_USER_LOCAL().org_admin ? (
            <Link to="/sendInvitation">
              <Button
                className="login-form-button"
                style={{
                  width: 200,
                  fontSize: "medium",
                  margin: 0,
                  marginRight: 5,
                  height: 40,
                }}
              >
                Invite User
              </Button>
            </Link>
          ) : null}

          {GET_USER_LOCAL().org_admin ? (
            <Link to="/sendOrgInvitation">
              {" "}
              <Button
                className="login-form-button"
                style={{
                  width: 250,
                  fontSize: "medium",
                  margin: 0,
                  marginRight: 5,
                  height: 40,
                }}
              >
                Add Partner Organization
              </Button>
            </Link>
          ) : null}
        </div>
        <Card style={{ margin: 10 }}>
          {this.props.userProfileData.organization.organization_profile
            .organization_create ? (
            <Link to="/createOrganization">
              <Tooltip placement="top" title="Create Organization">
                <Button shape="circle" icon="plus" className="tooltip-button" />
              </Tooltip>
            </Link>
          ) : null}

          {GET_USER_LOCAL().org_admin || GET_USER_LOCAL().is_staff ? (
            <div style={{ marginBottom: 10 }}>
              <PageHeader title="Organization User Locations" />

              <Map
                center={[
                  this.state.userLocationLat,
                  this.state.userLocationLon,
                ]}
                zoom={10}
              >
                {/* <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                  /> */}
                <ReactLeafletGoogleLayer
                  googleMapsLoaderConf={{
                    KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                    VERSION: "3.53",
                  }}
                  type={"roadmap"}
                />

                {this.state.UserLocationList.map((user) => {
                  return (
                    <Marker
                      key={user.locs.user_id.$oid}
                      position={[
                        user.locs.loc.coordinates[0],
                        user.locs.loc.coordinates[1],
                      ]}
                      icon={userIcon}
                    >
                      <Popup>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span>Name : {user.user}</span>
                          {user.mobile === "" ? (
                            <span>Mobile : {user.email}</span>
                          ) : (
                            <span>Mobile : {user.mobile}</span>
                          )}
                          <span>
                            Last Updated On :{" "}
                            {timeConverter(user.locs.timestamp.$date)}
                          </span>
                        </div>
                      </Popup>
                    </Marker>
                  );
                })}
              </Map>
            </div>
          ) : null}

          <PageHeader title="Organizations" />
          <Input
            placeholder="Search Organization Name"
            value={this.state.name}
            // onSearch={this.handlesearch}
            onChange={this.handlesearch}
            size="large"
            style={{
              marginTop: 15,
              marginBottom: 15,
              borderRadius: 25,
              border: "2px solid grey",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ margin: 10 }}>
              <Text>{`Displaying : `}</Text>
              <Select value={this.state.limit} onChange={this.setLimit}>
                <Option key={10}>10</Option>
                <Option key={25}>25</Option>
                <Option key={50}>50</Option>
                <Option key={100}>100</Option>
              </Select>
              <Text>{`  Entries `}</Text>
            </div>
            <div style={{ marginTop: 10, textAlign: "center" }}>
              <Divider type="vertical" />
            </div>
            {GET_USER_LOCAL().is_staff ? (
              <div style={{ margin: 10 }}>
                <Text> Has Plan : {""}</Text>
                <Select
                  style={{ width: 80 }}
                  value={this.state.has_plan.toString()}
                  onChange={this.setHasPlan}
                  placeholder="Custom Field"
                >
                  <Option key={true}>True</Option>
                  <Option key={false}>False</Option>
                </Select>
              </div>
            ) : null}

            <div style={{ margin: 10, textAlign: "center" }}>
              <Button
                style={{ backgroundColor: "#1A3337", color: "white" }}
                onClick={this.refresh}
              >
                Refresh
              </Button>
            </div>
          </div>
          <div className="cardscontainer">
            {this.state.data.map((org) => (
              <CardMaterial
                key={org._id.$oid}
                className="cardsingle"
                style={{
                  marginTop: 30,
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
              >
                <CardContent>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Avatar
                      src={org.org_image === null ? icon : org.org_image}
                      style={{ width: 80, height: 80, marginBottom: 10 }}
                    />
                    <h2
                      style={{
                        // marginLeft: 5,
                        letterSpacing: 1,
                        fontWeight: 800,
                      }}
                    >
                      {org.name}
                    </h2>
                    <p>
                      <Icon
                        type="phone"
                        style={{ color: "black", margin: 5 }}
                      />
                      <Text
                        style={{ fontWeight: 550 }}
                      >{`Phone-${org.telephone}`}</Text>
                    </p>
                    <p>
                      <Icon
                        type="environment"
                        style={{ color: "black", margin: 5 }}
                      />
                      <Text
                        style={{ fontWeight: 550 }}
                      >{`Address-${org.address}`}</Text>
                    </p>
                    <p>
                      <Icon type="user" style={{ color: "black", margin: 5 }} />
                      <Text
                        style={{ fontWeight: 550 }}
                      >{`Total Users-${org.user_count}`}</Text>
                    </p>
                    <p>
                      <Icon
                        type="border"
                        style={{ color: "black", margin: 5 }}
                      />
                      <Text style={{ fontWeight: 550 }}>{`Plan Name-${
                        org?.plans?.length > 0 ? org?.plans[0]?.name ?? "" : ""
                      }`}</Text>
                      {(GET_USER_LOCAL().role_name === "org_admin" ||
                        GET_USER_LOCAL().org_admin) &&
                      org.country !== "Bangladesh" ? (
                        <Link
                          to={{
                            pathname: "/subscribeOrganizationPlan",
                            state: { org_id: org._id.$oid },
                          }}
                        >
                          <ButtonMaterial
                            color="primary"
                            style={{ width: 10, height: 10, marginLeft: 15 }}
                          >
                            {org?.plans?.length > 0 ? "Change" : "Subscribe"}
                          </ButtonMaterial>
                        </Link>
                      ) : null}
                    </p>

                    <p>
                      <Icon
                        type="calendar"
                        style={{ color: "black", margin: 5 }}
                      />
                      <Text style={{ fontWeight: 550 }}>{`Valid Till-${
                        org?.plans?.length > 0
                          ? timeConverter(
                              org?.plans[0]?.valid_till?.$date ?? Date.now()
                            )
                          : ""
                      }`}</Text>
                    </p>
                  </div>
                </CardContent>
                <CardActions style={{ position: "", bottom: 0 }}>
                  {this.props.userProfileData.organization.organization_profile
                    .organization_detail ? (
                    <Link
                      to={{
                        pathname: "/viewOrganization",
                        state: { org_id: org._id.$oid },
                      }}
                    >
                      <ButtonMaterial color="primary">View</ButtonMaterial>
                    </Link>
                  ) : null}

                  {/* {GET_USER_LOCAL().role_name === "org_admin" ||
                  GET_USER_LOCAL().is_super ? (
                    // &&
                    // moment(org?.plans[0]?.valid_till?.$date)
                    //   .subtract(24, "hours")
                    //   .format("x") <= Date.now()
                    <Link
                      to={{
                        pathname: "/subscribeOrganizationPlan",
                        state: { org_id: org._id.$oid },
                      }}
                    >
                      <ButtonMaterial color="primary">Subscribe</ButtonMaterial>
                    </Link>
                  ) : null} */}
                  {this.state.org_admin ||
                  this.props.userProfileData.organization.organization_profile
                    .organization_update ? (
                    <Link
                      to={{
                        pathname: "/editOrganization",
                        state: { org_id: org._id.$oid },
                      }}
                    >
                      <ButtonMaterial color="primary">Edit</ButtonMaterial>
                    </Link>
                  ) : null}
                  {this.state.org_admin ||
                  this.props.userProfileData.organization.organization_profile
                    .organization_delete ? (
                    <ButtonMaterial
                      color="secondary"
                      onClick={() => {
                        if (window.confirm("Delete the item?")) {
                          this.deleteOrganization(org._id.$oid);
                        }
                      }}
                    >
                      Delete
                    </ButtonMaterial>
                  ) : null}
                </CardActions>
              </CardMaterial>
            ))}
          </div>

          {this.state.total > 10 ? (
            <Pagination
              pageSize={Number(this.state.limit)}
              current={this.state.page + 1}
              total={this.state.total}
              onChange={this.pageChange}
              style={{ marginTop: 10 }}
            />
          ) : null}
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(OrganizationList);
