import React, { Component } from "react";
import {
  Card,
  Typography,
  Button,
  Table,
  Pagination,
  Select,
  Skeleton,
  //   Tag,
  // Progress,
} from "antd";
import axios from "axios";
import CardMaterial from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
// import swal from "sweetalert2";
// import moment from "moment";
// import DateFnsUtils from "@date-io/date-fns";
// import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import {
  ANALYTICS_API,
  USER_PROFILE,
  CURRENT_USER_SUB_ORG_LIST,
} from "../../api/constants";
import {
  Chart as ChartJS,
  ArcElement,
  // CategoryScale,
  // LinearScale,
  // PointElement,
  // BarElement,
  // LineElement,
  Tooltip,
  Legend,
  // Filler,
} from "chart.js";
import { Pie, Line, Bar } from "react-chartjs-2";

import "../login.css";

const { Text, Title } = Typography;
const { Column } = Table;
const { Option } = Select;
// const { CheckableTag } = Tag;

ChartJS.register(ArcElement, Tooltip, Legend);

class Asset extends Component {
  state = {
    date_to_use: "created_on",
    user_org_id: "",
    loading: false,
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    sub_org_id_task_search: "",
    asset_status_count: [],
    total_expense: 0,
    total_trip_cost: 0,
    total_trip_count: 0,
    average_expense: 0,
    monthly_expenses: [],
    monthly_trip_history: [],
    tasks_schedule_table_data: [],
    task_schedule_page: 1,
    task_schedule_limit: 10,
    task_schedule_total: 0,
    expiry_reminders: [],
    expiry_reminders_page: 1,
    expiry_reminders_limit: 10,
    expiry_reminders_total: 0,
    expiring_in_next_7_days: 0,
    expiring_in_next_month: 0,
    asset_status_list: [],
    asset_status_list_page: 1,
    asset_status_list_limit: 10,
    asset_status_list_total: 0,
  };
  componentDidMount() {
    this.getCurrentUserSubOrgs();
  }

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org_id_task_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getUserdata();
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_task_search: value },
      () => {
        this.getUserdata();
      }
    );
  };

  getUserdata = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${USER_PROFILE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user profile", response);

          this.setState(
            {
              user_org_id: response.data.organization.$oid,
            },
            () => {
              this.getAssetCharts();
              this.getTaskSchedule();
              this.getOrganizationSummary();
              this.getExpiryReminders();
              this.getAssetStatusList();
            }
          );
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from user profile", error);
        });
    });
  };

  getAssetCharts = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}asset_status_result/${this.state.user_org_id}?date_to_use=${this.state.date_to_use}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("asset status", response);
          this.setState({
            loading: false,
            asset_status_count: response.data?.asset_status_counts ?? [],
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("asset status", error);
        });
    });
  };
  getOrganizationSummary = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}organization_summary/${this.state.user_org_id}?date_to_use=${this.state.date_to_use}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("org summary", response);
          this.setState({
            loading: false,
            total_expense: response.data?.total_expense ?? 0,
            total_trip_cost: response.data?.total_trip_cost ?? 0,
            total_trip_count: response.data?.total_trip_count ?? 0,
            average_expense: response.data?.average_expense ?? 0,
            monthly_expenses: response.data?.monthly_expenses ?? [],
            monthly_trip_history: response.data?.monthly_trip_history ?? [],
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("org summary", error);
        });
    });
  };
  getTaskSchedule = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}tasks_schedule_table/${this.state.user_org_id}?limit=${this.state.task_schedule_limit}&page=${this.state.task_schedule_page}&date_to_use=${this.state.date_to_use}&sub_organization=${this.state.sub_org_id_task_search}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("task schedule", response);
          this.setState({
            loading: false,
            tasks_schedule_table_data:
              response.data?.tasks_schedule_table_data ?? [],
            task_schedule_total: response.data?.total ?? 0,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("task schedule", error);
        });
    });
  };

  pageChangeTaskSchedule = (current, pageSize) => {
    this.setState({ task_schedule_page: current }, () => {
      console.log("page number", this.state.task_schedule_page);
      this.getTaskSchedule();
    });
  };

  setLimitTaskSchedule = (value) => {
    this.setState({ task_schedule_limit: value }, () => {
      console.log(this.state.task_schedule_limit);
      this.getTaskSchedule();
    });
  };

  getExpiryReminders = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}expiry_reminders/${this.state.user_org_id}?limit=${this.state.expiry_reminders_limit}&page=${this.state.expiry_reminders_page}&date_to_use=${this.state.date_to_use}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("expiry reminders", response);
          this.setState({
            loading: false,
            expiry_reminders: response.data?.expiry_reminders ?? [],
            expiry_reminders_total: response.data?.total ?? 0,
            expiring_in_next_7_days:
              response.data?.message_count?.expiring_in_next_7_days ?? 0,
            expiring_in_next_month:
              response.data?.message_count?.expiring_in_next_month ?? 0,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("expiry reminders", error);
        });
    });
  };

  pageChangeExpiryReminders = (current, pageSize) => {
    this.setState({ expiry_reminders_page: current }, () => {
      console.log("page number", this.state.expiry_reminders_page);
      this.getExpiryReminders();
    });
  };

  setLimitExpiryReminders = (value) => {
    this.setState({ expiry_reminders_limit: value }, () => {
      console.log(this.state.expiry_reminders_limit);
      this.getExpiryReminders();
    });
  };

  getAssetStatusList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}asset_status_list/${this.state.user_org_id}?limit=${this.state.expiry_reminders_limit}&page=${this.state.expiry_reminders_page}&date_to_use=${this.state.date_to_use}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("status list", response);
          this.setState({
            loading: false,
            asset_status_list: response.data?.asset_status_list ?? [],
            asset_status_list_total: response.data?.total ?? 0,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("status list", error);
        });
    });
  };

  pageChangeStatusList = (current, pageSize) => {
    this.setState({ asset_status_list_page: current }, () => {
      console.log("page number", this.state.asset_status_list_page);
      this.getAssetStatusList();
    });
  };

  setLimitStatusList = (value) => {
    this.setState({ asset_status_list_limit: value }, () => {
      console.log(this.state.asset_status_list_limit);
      this.getAssetStatusList();
    });
  };

  render() {
    if (this.state.loading === true) {
      return (
        <Skeleton
          paragraph={{
            rows: 20,
          }}
          active
        />
      );
    }
    return (
      <div style={{ marginTop: 10 }}>
        <Card>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 5,
                marginLeft: 4,
              }}
            >
              <Text>Filter Time By : </Text>
              <Select
                value={this.state.date_to_use}
                onChange={(value) => {
                  this.setState({ date_to_use: value }, () => {
                    this.getAssetCharts();
                    console.log(this.state.date_to_use);
                  });
                }}
                style={{ minWidth: 100 }}
              >
                <Option key="created_on">Created On</Option>
                <Option key="completed_on">Completed On</Option>
              </Select>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text> User Sub Organization : {""}</Text>
              <Select
                style={{ minWidth: 200 }}
                disabled={
                  this.state.current_user_sub_orgs.length < 1 ? true : false
                }
                value={this.state.current_user_sub_org_id}
                onChange={this.setCurrentUserSubOrg}
              >
                {this.state.current_user_sub_orgs.map((r) => {
                  return <Option key={r.id}>{`${r.name}`}</Option>;
                })}
              </Select>
            </div>
            {this.props.userProfileData.data_level.access_all_data ? (
              <Button
                type="link"
                style={{ marginTop: 20, color: "#1A3337" }}
                onClick={() => {
                  this.setState(
                    { current_user_sub_org_id: "", sub_org_id_task_search: "" },
                    () => {
                      this.getUserdata();
                    }
                  );
                }}
              >
                All Sub Orgs
              </Button>
            ) : null}
          </div>
        </Card>
        <Card style={{ marginTop: 5 }}>
          <div
            className="cardscontainer"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: "5px solid #36BA98",
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Trip Count
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.total_trip_count}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: "5px solid #6FDCE3",
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Trip Cost
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.total_trip_cost}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: "5px solid #F4A261",
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Expense
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.total_expense}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: "5px solid #E76F51",
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Average Expense
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.average_expense}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
          </div>
        </Card>
        <Card>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginBottom: 10,
            }}
          >
            <Card style={{ width: "33%" }}>
              <Pie
                style={{ marginBottom: 10 }}
                data={{
                  labels: this.state.asset_status_count.map(
                    (r) => r.asset_status
                  ),
                  datasets: [
                    {
                      label: "Asset Status Counts",
                      data: this.state.asset_status_count.map((r) => r.count),
                      backgroundColor: [
                        "#A8CD89",
                        "#BBE9FF",
                        "#FF6969",
                        "#EBB02D",
                        "#FFAACF",
                      ],
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  aspectRatio: 1.5,
                }}
              />
            </Card>

            <Card style={{ width: "33%", marginLeft: 10 }}>
              <Line
                style={{ marginBottom: 10 }}
                data={{
                  labels: this.state.monthly_expenses.map((r) => r.month),
                  datasets: [
                    {
                      label: "Monthly Expenses",
                      data: this.state.monthly_expenses.map(
                        (r, index) => r.expense
                      ),
                      borderColor: "#F4538A",
                      pointBackgroundColor: "#F4538A",
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  aspectRatio: 1.5,
                }}
              />
            </Card>

            <Card
              style={{
                display: "flex",
                flexGrow: 1,
                width: "33%",
                marginLeft: 10,
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <CardHeader
                style={{ color: "#D7D3BF" }}
                title="Expiring Documents"
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ margin: 10 }}>
                  <Title
                    style={{
                      marginTop: 5,
                      color: "#F94A29",
                      textAlign: "center",
                      fontSize: 50,
                    }}
                  >{`${this.state.expiring_in_next_7_days}`}</Title>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 25,
                      color: "#D7D3BF",
                    }}
                  >
                    In 7 Days
                  </Text>
                </div>
                <div style={{ margin: 10 }}>
                  <Title
                    style={{
                      marginTop: 5,
                      color: "#F7D060",
                      textAlign: "center",
                      fontSize: 50,
                    }}
                  >{`${this.state.expiring_in_next_month}`}</Title>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 25,
                      color: "#D7D3BF",
                    }}
                  >
                    In 30 Days
                  </Text>
                </div>
              </div>
            </Card>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginBottom: 20,
            }}
          >
            <Card style={{ width: "33%" }}>
              <Bar
                style={{ marginBottom: 20 }}
                data={{
                  labels: this.state.monthly_trip_history.map((r) => r.month),
                  datasets: [
                    {
                      data: this.state.monthly_trip_history.map(
                        (r) => r.trip_count
                      ),
                      backgroundColor: "#EF9C66",
                      label: "Trip Count",
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  aspectRatio: 1.5,
                }}
              />
            </Card>
            <Card style={{ width: "33%", marginLeft: 10 }}>
              <Bar
                style={{ marginBottom: 20 }}
                data={{
                  labels: this.state.monthly_trip_history.map((r) => r.month),
                  datasets: [
                    {
                      data: this.state.monthly_trip_history.map(
                        (r) => r.total_distance
                      ),
                      backgroundColor: "#FCDC94",
                      label: "Total Distance",
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  aspectRatio: 1.5,
                }}
              />
            </Card>
            <Card style={{ width: "33%", marginLeft: 10 }}>
              <Bar
                style={{ marginBottom: 20 }}
                data={{
                  labels: this.state.monthly_trip_history.map((r) => r.month),
                  datasets: [
                    {
                      data: this.state.monthly_trip_history.map(
                        (r) => r.total_price
                      ),
                      backgroundColor: "#78ABA8",
                      label: "Total Price",
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  aspectRatio: 1.5,
                }}
              />
            </Card>
          </div>
          <div style={{ marginBottom: 10 }}>
            <Title level={4}>Asset Status List</Title>
            <Table
              dataSource={this.state.asset_status_list}
              pagination={false}
              rowKey={(record) => record.asset_id}
              size="small"
            >
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Asset Name</Text>
                  </>
                }
                key="asset_name"
                render={(text, record) => (
                  <span>{`${record?.asset_name ?? ""}`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Reg. Number</Text>
                  </>
                }
                key="registration_number"
                render={(text, record) => (
                  <span>{`${record?.registration_number ?? ""}`}</span>
                )}
              />

              <Column
                title={
                  <>
                    <Text ellipsis={true}>Asset Type</Text>
                  </>
                }
                key="asset_type"
                render={(text, record) => (
                  <span>{`${record.asset_type ?? ""}`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Mileage</Text>
                  </>
                }
                key="mileage"
                render={(text, record) => (
                  <span>{`${record?.mileage ?? ""}`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Age</Text>
                  </>
                }
                key="age"
                render={(text, record) => <span>{`${record?.age ?? ""}`}</span>}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Mishap</Text>
                  </>
                }
                key="mishap"
                render={(text, record) => (
                  <span>{`${record?.mishap ?? ""}`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Fuel Used</Text>
                  </>
                }
                key="fuel_used"
                render={(text, record) => (
                  <span>{`${record?.fuel_used ?? ""}`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Total Bill</Text>
                  </>
                }
                key="total_bill"
                render={(text, record) => (
                  <span>{`${record?.total_bill?.length}`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Bill Sum</Text>
                  </>
                }
                key="total_bill_sum"
                render={(text, record) => (
                  <span>{`${record?.total_bill_sum}`}</span>
                )}
              />
            </Table>
            {this.state.asset_status_list_total > 10 ? (
              <Pagination
                pageSize={Number(this.state.asset_status_list_limit)}
                current={this.state.asset_status_list_page}
                total={this.state.asset_status_list_total}
                onChange={this.pageChangeStatusList}
                style={{ marginTop: 10 }}
              />
            ) : null}
          </div>
          <div style={{ marginBottom: 10 }}>
            <Title level={4}>Expiry Reminders</Title>
            <Table
              dataSource={this.state.expiry_reminders}
              pagination={false}
              rowKey={(record) => record.unique_number}
              size="small"
            >
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Asset Name</Text>
                  </>
                }
                key="name"
                render={(text, record) => (
                  <span>{`${record?.name ?? ""}`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Document Type</Text>
                  </>
                }
                key="field"
                render={(text, record) => (
                  <span>{`${record?.field ?? ""}`}</span>
                )}
              />

              <Column
                title={
                  <>
                    <Text ellipsis={true}>Expiry Date</Text>
                  </>
                }
                key="expiry_date"
                render={(text, record) => (
                  <span>{`${
                    record.expiry_date ? timeConverter(record?.expiry_date) : ""
                  }`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Message</Text>
                  </>
                }
                key="message"
                render={(text, record) => (
                  <span>{`${record?.message ?? ""}`}</span>
                )}
              />
            </Table>
            {this.state.expiry_reminders_total > 10 ? (
              <Pagination
                pageSize={Number(this.state.expiry_reminders_limit)}
                current={this.state.expiry_reminders_page}
                total={this.state.expiry_reminders_total}
                onChange={this.pageChangeExpiryReminders}
                style={{ marginTop: 10 }}
              />
            ) : null}
          </div>

          <div style={{ marginBottom: 20 }}>
            <Title level={4}>Task Schedule</Title>
            <Table
              dataSource={this.state.tasks_schedule_table_data}
              pagination={false}
              rowKey={(record) => record.task_id}
              size="small"
            >
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Asset Name</Text>
                  </>
                }
                key="assigned_asset_name"
                render={(text, record) => (
                  <span>{`${record?.assigned_asset_name ?? ""}`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Assigned To</Text>
                  </>
                }
                key="assigned_to_name"
                render={(text, record) => (
                  <span>{`${record?.assigned_to_name ?? ""}`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Distance Crossed</Text>
                  </>
                }
                key="distance_crossed"
                render={(text, record) => (
                  <span>{`${record?.distance_crossed ?? ""}`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Trip Duration</Text>
                  </>
                }
                key="trip_duration"
                render={(text, record) => (
                  <span>{`${record?.trip_duration ?? ""}`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Price</Text>
                  </>
                }
                key="price"
                render={(text, record) => (
                  <span>{`${record?.price ?? ""}`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Start Time</Text>
                  </>
                }
                key="start_time"
                render={(text, record) => (
                  <span>{`${
                    record.start_time ? timeConverter(record?.start_time) : ""
                  }`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>Start Location</Text>
                  </>
                }
                key="start_loc_name"
                render={(text, record) => (
                  <span>{`${record?.start_loc_name ?? ""}`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>End Time</Text>
                  </>
                }
                key="end_time"
                render={(text, record) => (
                  <span>{`${
                    record.end_time ? timeConverter(record?.end_time) : ""
                  }`}</span>
                )}
              />
              <Column
                title={
                  <>
                    <Text ellipsis={true}>End Location</Text>
                  </>
                }
                key="completed_loc_name"
                render={(text, record) => (
                  <span>{`${record?.completed_loc_name ?? ""}`}</span>
                )}
              />
            </Table>
            {this.state.task_schedule_total > 10 ? (
              <Pagination
                pageSize={Number(this.state.task_schedule_limit)}
                current={this.state.task_schedule_page}
                total={this.state.task_schedule_total}
                onChange={this.pageChangeTaskSchedule}
                style={{ marginTop: 10 }}
              />
            ) : null}
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {
  getUserData,
})(Asset);
