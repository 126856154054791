import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Divider,Tooltip } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";

import {
  CREATE_RATE,
  REVERSE_GEO_COUNTRY,
  EDIT_RATE
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL
} from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Column } = Table;

export default class RateList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false,
    country_code: "",
    filtered_country: []
  };
  componentDidMount() {
    this.getRateList();
  }

  getCountry = () => {
    const success = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      axios.get(REVERSE_GEO_COUNTRY(latitude, longitude)).then(response => {
        this.setState({ loading: false });
        console.log("response from reverse geo", response);
        this.setState(
          { country_code: response.data.address.country_code },
          () => {
            let filteredList = this.state.data.filter(
              plan => plan.ccountry.toLowerCase() === this.state.country_code
            );
            console.log("filtered list", filteredList);
            this.setState({ filtered_country: filteredList });
          }
        );
      });
    };
    navigator.geolocation.getCurrentPosition(success);
  };

  getRateList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_RATE}?page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from rate list", response);
          this.setState(
            {
              data:
                this.state.page === 0
                  ? response.data.data
                  : [...this.state.data, ...response.data.data],
              total: response.data.total
            },
            () => {
              this.getCountry();
            }
          );
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from rate list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getRateList();
      }
    );
  };

  deleteRate = id => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${EDIT_RATE}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {GET_USER_LOCAL().is_super?(
          <Link to="/createRate">
          <Tooltip placement="top" title="Create Rate">
          <Button shape="circle" icon="plus" className="tooltip-button" />
          </Tooltip>
        </Link>
        ):null}
        
        <PageHeader
          title="Rate List"
        />
        <Table
          dataSource={this.state.filtered_country}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Price" dataIndex="price" key="price" />
          <Column title="Service" dataIndex="service_name" key="service_name" />
          <Column title="Currency" dataIndex="currency" key="currency" />
          <Column title="Country" dataIndex="ccountry" key="ccountry" />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          {GET_USER_LOCAL().is_super ? (
            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <span>
                  <Link
                    to={{
                      pathname: "/editRate",
                      state: { rate_id: record._id.$oid }
                    }}
                  >
                    <button
                      className="borderless-button"
                      style={{ color: "green" }}
                    >
                      Edit
                    </button>
                  </Link>
                  <Divider type="vertical" />
                  <button
                    className="borderless-button"
                    style={{ color: "red" }}
                    onClick={() => {
                      if (window.confirm("Delete the item?")) {
                        this.deleteRate(record._id.$oid);
                      }
                    }}
                  >
                    Delete
                  </button>
                </span>
              )}
            />
          ) : null}
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}
