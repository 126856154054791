import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Pagination,
  Select,
  Typography,
  Button,
  Modal,
  Form,
  Input,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
// import { Link } from "react-router-dom";
import axios from "axios";
import { debounce } from "lodash";
import swal from "sweetalert2";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FileSaver from "file-saver";

import {
  WALLET_DUE_COLLECTION_LIST,
  CURRENT_USER_SUB_ORG_LIST,
  GET_FIRST_CHILD_SUB_ORG_LIST,
  CREATE_DRIVER,
  SUB_ORG_WISE_DUE_COLLECTION_REPORT,
  WALLET_PAYMENT_METHOD_CHOICES,
  CREATE_HANDOVER_REQUEST,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  //   CHECK_BOOL,
  //   GET_USER_LOCAL,
} from "../../utilities/utilities";
// import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Text, Title } = Typography;
const { Option } = Select;

class DueCollectionList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    first_child_sub_orgs: [],
    first_child_sub_org_id: "",
    sub_org_id_wallet_search: "",
    sub_org_name: "",
    sub_org_parent_name: "",
    start_time: moment().startOf('day').format('x'),
    start_time_view: moment().startOf('day'),
    end_time: Date.now(),
    end_time_view: moment(),
    collector_id: "",
    userData: [],
    wallet_payment_methods: [],
    show_req_handover_modal: false,
    handover_method: "cash",
    handovered_amount: 0,
    notes: "",
  };
  componentDidMount() {
    this.getCurrentUserSubOrgs();
    this.getWalletPaymentMethodChoices();
    this.getUserList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getWalletPaymentMethodChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${WALLET_PAYMENT_METHOD_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from wallet payment method choices", response);

          this.setState({
            wallet_payment_methods: response.data.payment_method_choices,
          });
        })
        .catch((error) => {
          console.log("error from wallet payment method choices", error);
        });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from user list", response);
        })
        .catch((error) => {
          console.log("error from user list api", error);
        });
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from search user", response);
          this.setState({
            userData: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from search user api", error);
        });
    });
  }, 500);

  setAssignedUser = (value) => {
    this.setState({ collector_id: value }, () => {
      console.log(this.state.collector_id);
      this.getDueCollectionList();
    });
  };

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org_id_wallet_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getDueCollectionList();
              if (this.state.current_user_sub_org_id !== "") {
                GET_ACCESS_TOKEN().then((token) => {
                  const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

                  axios({
                    method: "get",
                    url: url,
                  })
                    .then((response) => {
                      console.log(
                        "response from first child sub org list",
                        response
                      );

                      this.setState({
                        first_child_sub_orgs: response.data.data,
                      });
                    })
                    .catch((error) => {
                      console.log(
                        "error from first child sub org list api",
                        error
                      );
                    });
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  getDueCollectionList = debounce(() => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${WALLET_DUE_COLLECTION_LIST}?page=${this.state.page}&limit=${this.state.limit}&start_time=${this.state.start_time}&end_time=${this.state.end_time}&sub_org_id=${this.state.sub_org_id_wallet_search}&collector_id=${this.state.collector_id}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from due collection list list", response);

          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from due collection list list api", error);
        });
    });
  }, 500);

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_wallet_search: value },
      () => {
        this.getDueCollectionList();
        GET_ACCESS_TOKEN().then((token) => {
          const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

          axios({
            method: "get",
            url: url,
          })
            .then((response) => {
              console.log("response from first child sub org list", response);

              this.setState({
                first_child_sub_orgs: response.data.data,
              });
            })
            .catch((error) => {
              console.log("error from first child sub org list api", error);
            });
        });
      }
    );
  };
  setFirstChildSubOrg = (value) => {
    this.setState(
      { first_child_sub_org_id: value, sub_org_id_wallet_search: value },
      () => {
        this.getDueCollectionList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getDueCollectionList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getDueCollectionList();
    });
  };

  refresh = () => {
    this.setState(
      {
        data: [],
        page: 0,
        total: 0,
        limit: 10,
        current_user_sub_orgs: [],
        current_user_sub_org_id: "",
        first_child_sub_orgs: [],
        first_child_sub_org_id: "",
        sub_org_id_wallet_search: "",
        sub_org_name: "",
        sub_org_parent_name: "",
        start_time: Date.now(),
        start_time_view: moment(),
        end_time: Date.now(),
        end_time_view: moment(),
        collector_id: "",
        userData: [],
      },
      () => {
        this.getCurrentUserSubOrgs();
      }
    );
  };

  downloadDueReport = () => {
    if (this.state.sub_org_id_wallet_search !== "") {
      GET_ACCESS_TOKEN().then((token) => {
        const url = `${SUB_ORG_WISE_DUE_COLLECTION_REPORT}${this.state.sub_org_id_wallet_search}?fmt=xls&end_time=${this.state.end_time}&start_time=${this.state.start_time}&access_token=${token}`;
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
          .then((res) => res.blob())
          .then((blob) => {
            console.log(
              "response from wallet combined history excel report",
              blob
            );
            FileSaver.saveAs(
              blob,
              `wallet_combined_history_report_${moment().format(
                "DD-MMMM-YYYY_hh:mm A"
              )}.xlsx`
            );
          })
          .catch((error) => {
            console.log(
              "error from wallet combined history excel report",
              error
            );
          });
      });
    } else {
      swal.fire("Info", "Please Select a Sub Organization", "info");
    }
  };

  reqHandover = () => {
    if (
      this.state.current_user_sub_orgs.length > 0 &&
      this.state.sub_org_id_wallet_search === ""
    ) {
      swal.fire("Info", "Please select a suborg", "info");
    } else if (Number(this.state.handovered_amount < 1)) {
      swal.fire("Info", "Please enter a proper handover amount", "info");
    } else {
      this.setState({ loading: true });
      const data = {
        sub_org_id: this.state.sub_org_id_wallet_search,
        handover_method: this.state.handover_method,
        notes: this.state.notes,
        handovered_amount: Number(this.state.handovered_amount),
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_HANDOVER_REQUEST}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({ loading: false });
            swal.fire("Info", response.data.message, "info");
            console.log("response from create handover request", response);
          })
          .catch((error) => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from create handover request", error);
          });
      });
    }
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title="Due Collections"
        />
        {this.props.userProfileData.data_level.access_suborg_data ||
        this.props.userProfileData.data_level.access_all_data ? (
          <Card>
            <Title level={4}>
              Filter Due Collections With Sub Organization and User
            </Title>

            <div
              style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
            >
              <div
                style={{ display: "flex", flexDirection: "column", margin: 5 }}
              >
                <Text> User Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.current_user_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.current_user_sub_org_id}
                  onChange={this.setCurrentUserSubOrg}
                >
                  {this.state.current_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", margin: 5 }}
              >
                <Text> Child Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.first_child_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.first_child_sub_org_id}
                  onChange={this.setFirstChildSubOrg}
                >
                  {this.state.first_child_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
              <div
                style={{
                  margin: 5,
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "200px",
                }}
              >
                <Text>Search User:</Text>
                <Select
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearch}
                  style={{ width: "100%" }}
                  value={this.state.collector_id}
                  onChange={this.setAssignedUser}
                  placeholder="Select User"
                  required
                >
                  {this.state.userData.map((r) => {
                    return (
                      <Option
                        key={r._id.$oid}
                      >{`${r.name}>${r.mobile}(${r.organization_status})`}</Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </Card>
        ) : null}
        <Card style={{ margin: 3 }}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).format("x"),
                        start_time_view: value,
                      },
                      () => this.getDueCollectionList()
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        end_time: moment(value).add(59, "seconds").format("x"),
                        end_time_view: value,
                      },
                      () => this.getDueCollectionList()
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            {this.props.userProfileData.report.due_collection_report ? (
              <Button
                style={{
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 18,
                }}
                onClick={() => this.downloadDueReport()}
              >
                Download Due Collection Report
              </Button>
            ) : null}
          </div>
        </Card>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            {/* <Text>{`  Entries `}</Text> */}
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
          {this.props.userProfileData.due_collection
            .due_collection_request_handover ? (
            <div style={{ margin: 10, textAlign: "center" }}>
              <Button
                style={{ backgroundColor: "#1A3337", color: "white" }}
                onClick={() => this.setState({ show_req_handover_modal: true })}
              >
                Request Handover
              </Button>
            </div>
          ) : null}
        </div>

        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record.id}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Collector Name</Text>
                </div>
              </>
            }
            dataIndex="modified_by_name"
            key="modified_by_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Added Amount</Text>
                </div>
              </>
            }
            dataIndex="added_amount"
            key="added_amount"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Cumulative Collection Amount</Text>
                </div>
              </>
            }
            dataIndex="cumulative_collection"
            key="cumulative_collection"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Current Collected Amount</Text>
                </div>
              </>
            }
            dataIndex="current_collected_amount"
            key="current_collected_amount"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Previous Collected Amount</Text>
                </div>
              </>
            }
            dataIndex="previous_collected_amount"
            key="previous_collected_amount"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Transaction Type</Text>
                </div>
              </>
            }
            dataIndex="trx_type"
            key="trx_type"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Method</Text>
                </div>
              </>
            }
            dataIndex="method"
            key="method"
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Parent Sub Org</Text>
                </div>
              </>
            }
            dataIndex="parent_sub_org"
            key="parent_sub_org"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Org</Text>
                </div>
              </>
            }
            dataIndex="sub_org"
            key="sub_org"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Place Name</Text>
                </div>
              </>
            }
            dataIndex="entity_name"
            key="entity_name"
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Modified On</Text>
                </div>
              </>
            }
            key="modified_on"
            render={(text, record) => (
              <span>{timeConverter(record.modified_on.$date)}</span>
            )}
          />
          {/* <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Link
                  to={{
                    pathname: "/walletHistory",
                    state: {
                      wallet_id: record._id.$oid,
                    },
                  }}
                >
                  <Button style={{ color: "green" }} type="link">
                    View History
                  </Button>
                </Link>
                <Button
                  type="link"
                  style={{ color: "green" }}
                  onClick={() => {
                    this.setState({
                      show_add_balance_modal: true,
                      wallet_id: record._id.$oid,
                    });
                  }}
                >
                  Add Balance
                </Button>

                {record.total_due_amount > 0 && record.wallet_balance > 0 ? (
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() => {
                      this.setState(
                        {
                          show_adjust_order_modal: true,
                          entity_id: record.entity_id,
                        },
                        () => this.getOrdersByEntity()
                      );
                    }}
                  >
                    Adjust
                  </Button>
                ) : null}
                {record.total_due_amount > 0 && record.wallet_balance > 0 ? (
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() => {
                      this.adjustWalletWithDues(record._id.$oid);
                    }}
                  >
                    Adjust With Balance
                  </Button>
                ) : null}
                {record.refundable_amount > 0 ? (
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() => {
                      this.refundMoneyToWallet(record._id.$oid);
                    }}
                  >
                    Add Refund To Wallet
                  </Button>
                ) : null}
              </span>
            )}
          /> */}
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
        <Modal
          title="Request Handover"
          visible={this.state.show_req_handover_modal}
          // onOk={() => {
          //   this.setState({ showModal: false });
          // }}
          onCancel={() => {
            this.setState({
              show_req_handover_modal: false,
              handover_method: "cash",
              handovered_amount: 0,
              notes: "",
            });
          }}
          // width={window.innerWidth - 200}
          closable={true}
          destroyOnClose={true}
          footer={null}
        >
          <Form>
            <Form.Item>
              <Text>Handover Method</Text>
              <Select
                style={{ minWidth: 150 }}
                value={this.state.handover_method}
                onChange={(value) => this.setState({ handover_method: value })}
              >
                {this.state.wallet_payment_methods.map((r) => (
                  <Option key={r}>{r}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Text>Handovered Amount</Text>
              <Input
                placeholder="Handovered Amount"
                type="number"
                value={this.state.handovered_amount}
                onChange={(evt) =>
                  this.setState({ handovered_amount: evt.target.value })
                }
              />
            </Form.Item>
            <Form.Item>
              <Text>Notes</Text>
              <Input
                placeholder="Notes"
                type="text"
                value={this.state.notes}
                onChange={(evt) => this.setState({ notes: evt.target.value })}
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.reqHandover}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {
  getUserData,
})(DueCollectionList);
