import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Tooltip,
  Typography,
  Select,
  Pagination,
  Tag,
} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";

import { TARGET_LIST } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  //   GET_USER_LOCAL
  //   CHECK_BOOL
} from "../../utilities/utilities";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;

class TargetList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
  };
  componentDidMount() {
    this.props.getUserData();
    this.getTargetList();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  getTargetList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${TARGET_LIST}?page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from target list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from target list api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getTargetList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getTargetList();
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.data_level.access_all_data ||
        this.props.userProfileData.data_level.access_suborg_data ? (
          <Link to="/createTarget">
            <Tooltip placement="top" title="Create Target">
              <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}

         <PageHeader
          title="Targets"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            {/* <Text>{`  Entries `}</Text> */}
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Target Name" dataIndex="name" key="name" />
          <Column
            title="Target Type"
            key="target_type"
            render={(text, record) => <span>{record.target_type}</span>}
          />
          <Column
            title="Target For"
            key="target_for"
            render={(text, record) => <span>{record.target_for}</span>}
          />
          <Column
            title="Target Value"
            dataIndex="target_value"
            key="target_value"
          />
          <Column
            title="Duration Type"
            key="duration_type"
            render={(text, record) => <span>{record.duration_type}</span>}
          />
          <Column
            title="Assigned To"
            key="assigned_to"
            render={(text, record) => (
              <span>
                {Object.keys(record.assigned_to).length !== 0
                  ? record.assigned_to.user.map((u) => (
                      <Tag key={u.user_id.$oid}>{u.assigned_user}</Tag>
                    ))
                  : null}
              </span>
            )}
          />
          <Column
            title="Start Time"
            key="start_time"
            render={(text, record) => (
              <span>
                {timeConverter(record.start_time?.$date ?? Date.now())}
              </span>
            )}
          />
          <Column
            title="End Time"
            key="end_time"
            render={(text, record) => (
              <span>{timeConverter(record.end_time?.$date ?? Date.now())}</span>
            )}
          />
          <Column
            title="Created On"
            key="created_on"
            render={(text, record) => (
              <span>
                {timeConverter(record.created_on?.$date ?? Date.now())}
              </span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Link
                  to={{
                    pathname: "/createAchievement",
                    state: {
                      target_id: record._id.$oid,
                      target_for: record.target_for,
                    },
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    Create Achievement
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: "/targetDetails",
                    state: { target_id: record._id.$oid },
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    View
                  </Button>
                </Link>
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(TargetList);
