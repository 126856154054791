import { SAVE_DEFAULT_OPEN_SUBMENU } from "../actions/types";

const initialState = {
  defaultOpenSubmenuData: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SAVE_DEFAULT_OPEN_SUBMENU:
      return {
        ...state,
        defaultOpenSubmenuData: action.payload
      };
    default:
      return state;
  }
}
