import React, { Component } from "react";
import {
  Descriptions,
  Card,
  PageHeader,
  Button,
  Typography,
  Select,
  Form
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import FileSaver from "file-saver";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { FINDER_FUEL_HISTORY, CREATE_TASK } from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class FuelData extends Component {
  state = {
    access_token: "",
    asset_id: "",
    time_from: "",
    time_from_view: moment(),
    time_to: "",
    time_to_view: moment(),
    name: "",
    starting_fuel: "",
    ending_fuel: "",
    fuel_usage: "",
    fuel_intake: "",
    displayFuelData: false,
    timezone_name: "",
    timezone_choice: "org_timezone",
    history_by: "timestamp",
    taskData: [],
    task_name: "",
    task_id: "",
    chained_task: false
  };

  componentDidMount() {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ timezone_name: timezone });
    console.log("time zone", timezone);
    this.setState({
      access_token: this.props.location.state.access_token,
      asset_id: this.props.location.state.asset_id
    });
    this.getTaskData();
  }

  getTaskData = () => {
    let date = new Date();
    let time = date.getTime();
    console.log(moment(time));
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_TASK}?limit=1000&ts=${time}&assigned=true&accepted=true&started=true&completed=true&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from Task list completed", response);
          this.setState({
            taskData: response.data.data
          });
        })
        .catch(error => {
          console.log("error from Task list api completed", error);
        });
    });
  };

  getFueldata = () => {
    if (
      this.state.history_by === "timestamp" &&
      (this.state.time_from === "" || this.state.time_to === "")
    ) {
      swal.fire("Warning", "Please Enter Start and End Time", "warning");
    } else if (this.state.history_by === "task" && this.state.task_id === "") {
      swal.fire("Warning", "Please Select a Task", "warning");
    } else {
      GET_ACCESS_TOKEN().then(token => {
        const url =
          this.state.history_by === "timestamp"
            ? `${FINDER_FUEL_HISTORY}?timezone_choice=${this.state.timezone_choice}&timezone_name=${this.state.timezone_name}&asset_id=${this.state.asset_id}&time_from=${this.state.time_from}&time_to=${this.state.time_to}&access_token=${token}`
            : `${FINDER_FUEL_HISTORY}?task_id=${this.state.task_id}&chained_task=${this.state.chained_task}&asset_id=${this.state.asset_id}&access_token=${token}`;

        axios({
          method: "get",
          url: url
        })
          .then(response => {
            console.log("response from fuelHistory", response);
            this.setState({
              name: response.data?.data?.data?.name ?? "",
              starting_fuel: response.data?.data?.data?.starting_fuel ?? "",
              ending_fuel: response.data?.data?.data?.ending_fuel ?? "",
              fuel_usage: response.data?.data?.data?.fuel_usage ?? "",
              fuel_intake: response.data?.data?.data?.fuel_intake ?? "",
              // asset_id: response.data?.data?.data?.asset_id ?? "",
              displayFuelData: true
            });
          })
          .catch(error => {
            console.log("error from fuelHistory", error);
          });
      });
    }
  };

  handleSaveFile = checked => {
    GET_ACCESS_TOKEN().then(token => {
      const url =
        this.state.history_by === "timestamp"
          ? `${FINDER_FUEL_HISTORY}?fmt=xls&timezone_choice=${this.state.timezone_choice}&timezone_name=${this.state.timezone_name}&asset_id=${this.state.asset_id}&time_from=${this.state.time_from}&time_to=${this.state.time_to}&access_token=${token}`
          : `${FINDER_FUEL_HISTORY}?fmt=xls&task_id=${this.state.task_id}&chained_task=${this.state.chained_task}&asset_id=${this.state.asset_id}&access_token=${token}`;
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          console.log("response from Attendance list fmt", blob);

          FileSaver.saveAs(
            blob,
            `fuel_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch(error => {
          console.log("error from Attendance list api", error);
        });
    });
  };

  setTimezonetype = value => {
    this.setState({ timezone_choice: value }, () => {
      console.log(this.state.timezone_choice);
    });
  };

  setHistorytype = value => {
    this.setState({ history_by: value }, () => {
      console.log(this.state.history_by);
    });
  };

  setTask = value => {
    this.setState({ task_name: value.label, task_id: value.key }, () => {
      console.log(this.state.task_id);
    });
  };

  setChainTask = value => {
    this.setState({ chained_task: CHECK_BOOL(value) }, () => {
      console.log(this.state.chained_task);
    });
  };

  setStartTime = (value, dateString) => {
    this.setState(
      { time_from: moment(value).format("x"), time_from_view: value },
      () => {
        console.log("Selected Time: ", this.state.time_from);
      }
    );
  };
  setEndTime = (value, dateString) => {
    this.setState(
      { time_to: moment(value).format("x"), time_to_view: value },
      () => {
        console.log("Selected Time: ", this.state.time_to);
      }
    );
  };
  render() {
    return (
      <div>
        <Card style={{ margin: 10 }}>
          <PageHeader
           
            title="Fuel data"
            onBack={() => this.props.history.goBack()}
          />
          <Card style={{ marginTop: 10 }}>
            <div style={{ textAlign: "center" }}>
              <Text> Fuel History By : {""}</Text>
              <Select
                value={this.state.history_by}
                onChange={this.setHistorytype}
                placeholder="Fuel History By"
              >
                <Option key="timestamp">Time</Option>
                <Option key="task">Task</Option>
              </Select>
            </div>
          </Card>
          {this.state.history_by === "timestamp" ? (
            <Card style={{ marginTop: 10 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: 5
                  }}
                >
                  <Text style={{ marginTop: 15, marginRight: 5 }}>From</Text>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      style={{ width: 300 }}
                      inputVariant="outlined"
                      value={this.state.time_from_view}
                      onChange={this.setStartTime}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: 5
                  }}
                >
                  <Text style={{ marginTop: 15, marginRight: 5 }}>To</Text>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      style={{ width: 300 }}
                      inputVariant="outlined"
                      value={this.state.time_to_view}
                      onChange={this.setEndTime}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{ margin: 10 }}>
                  <Text> Timezone : {""}</Text>
                  <Select
                    value={this.state.timezone_choice}
                    onChange={this.setTimezonetype}
                    placeholder="View as"
                  >
                    <Option key="user_timezone">User</Option>
                    <Option key="org_timezone">Organization</Option>
                  </Select>
                </div>
                <Button
                  className="login-form-button"
                  onClick={() => this.getFueldata()}
                  style={{ marginTop: 10, width: 250 }}
                >
                  {" "}
                  Search Fuel Data
                </Button>
              </div>
            </Card>
          ) : (
            <Card style={{ marginTop: 10 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    margin: 10
                  }}
                >
                  <Form.Item>
                    <Text>Select Task : </Text>
                    <Select
                      labelInValue
                      value={{
                        key: this.state.task_id,
                        label: this.state.task_name
                      }}
                      onChange={this.setTask}
                      placeholder="Select Task"
                      style={{ minWidth: 200 }}
                    >
                      {this.state.taskData.map(r => {
                        return <Option key={r._id.$oid}>{r.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div
                  style={{
                    margin: 10
                  }}
                >
                  <Form.Item>
                    <Text>Chain Task : </Text>
                    <Select
                      defaultValue="False"
                      onChange={this.setChainTask}
                      style={{ minWidth: 100 }}
                    >
                      <Option key={true}>True</Option>
                      <Option key={false}>False</Option>
                    </Select>
                  </Form.Item>
                </div>
                <Button
                  className="login-form-button"
                  onClick={() => this.getFueldata()}
                  style={{ marginTop: 10, width: 250 }}
                >
                  {" "}
                  Seacrh Fuel Data
                </Button>
              </div>
            </Card>
          )}

          <Card style={{ marginTop: 10 }}>
            <div style={{ margin: 10, textAlign: "center" }}>
              <Button
                onClick={this.handleSaveFile}
                style={{
                  width: 200,
                  backgroundColor: "#1A3337",
                  color: "white"
                }}
              >
                Download Report
              </Button>
            </div>
          </Card>
          {this.state.displayFuelData ? (
            <Card style={{ marginTop: 10 }}>
              <Descriptions
                bordered
                column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                layout="vertical"
              >
                <Descriptions.Item label="Name">
                  {this.state.name}
                </Descriptions.Item>
                <Descriptions.Item label="Asset ID">
                  {this.state.asset_id}
                </Descriptions.Item>
                <Descriptions.Item label="Starting Fuel">
                  {this.state.starting_fuel}
                </Descriptions.Item>
                <Descriptions.Item label="Ending Fuel">
                  {this.state.ending_fuel}
                </Descriptions.Item>
                <Descriptions.Item label="Fuel Usage">
                  {this.state.fuel_usage}
                </Descriptions.Item>
                <Descriptions.Item label="Fuel Intake">
                  {this.state.fuel_intake}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          ) : null}
        </Card>
      </div>
    );
  }
}
