import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Input,
  Select,
  Typography,
  Pagination,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { debounce } from "lodash";
import axios from "axios";

import { ALLOCATED_DRIVER_LOG } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";
// import { Divider } from "@material-ui/core";

const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;

class AllocatedDriverLog extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
  };
  componentDidMount() {
    this.getAllocatedDriverLog();

     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getAllocatedDriverLog = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ALLOCATED_DRIVER_LOG}?page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from allocated driver list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
            datarange: response.data.show_data,
          });
        })
        .catch((error) => {
          console.log("error from allocated driver list api", error);
        });
    });
  }, 500);

  pageChange = (current, pageSize) => {
    this.setState({ page: current-1 }, () => {
      console.log("page number", this.state.page);
      this.getAllocatedDriverLog();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getAllocatedDriverLog();
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
        
          title="Allocated Driver Log"
        />
        {/* <Card style={{ margin: 3 }}>
          <Title level={4}>
            Select date range within which you would like to find your Assets
          </Title>

          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).format("x"),
                        start_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getAssetList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        end_time: moment(value).format("x"),
                        end_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getAssetList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
           
          </div>
        </Card> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Asset Name</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="asset_name"
            render={(text, record) => <span>{record.asset_name}</span>}
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Asset Type</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="asset_type"
            render={(text, record) => <span>{record.asset_type}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Asset Registration Number</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="asset_reg_no"
            render={(text, record) => <span>{record.asset_reg_no}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Driver Name</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="driver_name"
            render={(text, record) => <span>{record.driver_name}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Driver Mobile</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="driver_mobile"
            render={(text, record) => <span>{record.driver_mobile}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Driver Email</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="driver_email"
            render={(text, record) => <span>{record.driver_email}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Created on</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="created_on"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
        </Table>

        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page+1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(AllocatedDriverLog);
