import React, { Component } from "react";
import {
    Card,PageHeader,Layout,Typography,Descriptions
} from "antd";

import { CANCEL_PAYMENT_INT } from "../../api/constants";
import axios from "axios";
// import swal from "sweetalert2";
import { Spinner } from "../loader";

import Logo from "../../assets/logo.png";
const { Footer } = Layout;
const { Text } = Typography;
export default class CancelInt extends Component {
    state={
        name: "",
        address: "",
        city: "",
        country: "",
        connect_key: "",
        post_code: "",
        telephone: "",
        website: "",
        sub_orgs: "",
        credit_days: null,
        tax_amount: null,
        plans: [],
        created_on: "",
        discount_amount: null,
        active: false,
        sum_total: null,
        has_paid: false,
        balance: null,
        dues: null,
        payments: null,
        billed_amount: null,
        year: null,
        month: null,
        remarks: "",
        loading:false
    }
  componentDidMount(){
    console.log("tran id", this.props.history.location.state.tran_id);
    this.setState({ loading: true });
    let url = `${CANCEL_PAYMENT_INT}${this.props.history.location.state.tran_id}`;
    axios({
        method: "get",
        url: url
      })
      .then(response => {
        this.setState({ loading: false });
        console.log("response from monthly bill cancel paypal", response);
        this.setState({
            name: response.data.data.mb.org.name,
            address: response.data.data.mb.org.address,
            city: response.data.data.mb.org.city,
            country: response.data.data.mb.org.country,
            connect_key: response.data.data.mb.org.connect_key,
            post_code: response.data.data.mb.org.post_code,
            telephone: response.data.data.mb.org.telephone,
            website: response.data.data.mb.org.website,
            sub_orgs: response.data.data.mb.org.sub_orgs,
            credit_days: response.data.data.mb.org.credit_days,
            tax_amount: response.data.data.mb.org.tax_amount,
            plans: response.data.data.mb.org.plans,
            created_on: response.data.data.mb.generated_on.$date,
            discount_amount: response.data.data.mb.discount_amount,
            active: response.data.data.mb.org.active,
            sum_total: response.data.data.mb.sum_total,
            has_paid: response.data.data.mb.has_paid,
            balance: response.data.data.mb.balance,
            dues: response.data.data.mb.dues,
            payments: response.data.data.mb.payments,
            billed_amount: response.data.data.mb.billed_amount,
            year: response.data.data.mb.year,
            month: response.data.data.mb.month,
            remarks: response.data.data.mb.remarks
          });
      
      })
      .catch(error => {
        this.setState({ loading: false });
        console.log("error from cancel payment", error);
      });
  }
  render() {
    if (this.state.loading === true) {
        return <Spinner />;
      }
    return (
        <div
       
        >
            <div
            style={{
              textAlign: "center",
              paddingBottom: 10,
              paddingTop: 15,
              backgroundColor: "#006479"
            }}
          >
            <img src={Logo} alt="" style={{ width: 300 }} />
          </div>
          <Card
            style={{ margin: 10, backgroundColor: "#F5F5F5" }}
          >
            <PageHeader
                style={{
                  border: "1px solid rgb(235, 237, 240)"
                }}
                title="Payment Cancellled"
              />
          <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
          layout="vertical"
        >
          <Descriptions.Item label="Name">{this.state.name}</Descriptions.Item>
          <Descriptions.Item label="Address">{this.state.address}</Descriptions.Item>
          <Descriptions.Item label="City">{this.state.city}</Descriptions.Item>
          <Descriptions.Item label="Country"> {this.state.country}</Descriptions.Item>
          <Descriptions.Item label="Post Code">{this.state.post_code}</Descriptions.Item>
          <Descriptions.Item label="Telephone">{this.state.telephone}</Descriptions.Item>
          <Descriptions.Item label="Remarks">{this.state.remarks}</Descriptions.Item>
          <Descriptions.Item label="Credit Days">{this.state.credit_days}</Descriptions.Item>
          <Descriptions.Item label="Tax Amount">{this.state.tax_amount}</Descriptions.Item>
          <Descriptions.Item label=" Discount Amount">{this.state.discount_amount}</Descriptions.Item>
          <Descriptions.Item label=" Total Amount">{this.state.sum_total}</Descriptions.Item>
          <Descriptions.Item label=" Billed Amount">{this.state.billed_amount}</Descriptions.Item>
          <Descriptions.Item label=" Bill Date"> {this.state.month}/{this.state.year}</Descriptions.Item>
          <Descriptions.Item label="  Balance"> {this.state.balance}</Descriptions.Item>
          <Descriptions.Item label="  Dues"> {this.state.dues}</Descriptions.Item>
          <Descriptions.Item label="  Payments"> {this.state.payments}</Descriptions.Item>
          <Descriptions.Item label="  Paid"> {this.state.has_paid.toString()}</Descriptions.Item>
          <Descriptions.Item label="  Active"> {this.state.active.toString()}</Descriptions.Item>
        </Descriptions>
        </Card>
          
          <Footer
              style={{
                textAlign: "center",
                backgroundColor: "#006479",
                width:"100%",
                position: "fixed",
                bottom:0
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "center",
                  justifyItems: "center"
                }}
              >
                <a href="https://web.conveyance.app">
                  <img
                    src={Logo}
                    alt=""
                    style={{ width: 160, height: 41, alignSelf: "center" }}
                  />
                </a>
                <Text style={{ color: "white", fontSize: 15 }}>
                  Monico Technologies LTD
                </Text>
                <Text style={{ color: "white", fontSize: 15 }}>
                  26-27, Ringroad Adabor, Dhaka
                </Text>
                <Text style={{ color: "#b0bec5", fontSize: 15 }}>
                  © All rights reserved by Monico Technologies LTD, 2020
                </Text>
              </div>
              </Footer>
        </div>
    );
  }
}
