import React, { Component } from "react";
import { Typography } from "antd";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
import axios from "axios";
import swal from "sweetalert2";
import { CREATE_TASK } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL
} from "../../utilities/utilities";
import "../login.css";
import Logo from "../../assets/logo.png";
import { Spinner } from "../loader";

const { Text } = Typography;
// const { Header } = Layout;

export default class LiveDashboard extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false
  };

  componentDidMount() {
    this.getTaskList();
  }

  getTaskList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_TASK}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from Task list", response);
          swal.fire("Info", "Press Enter to Start or Pause Scrolling", "info");
          this.setState(
            {
              data: response.data.data,
              total: response.data.total
            },
            () => {
              // setInterval(function() {
              //   window.scrollBy(0, 2);
              //   if (
              //     window.innerHeight + window.pageYOffset >=
              //     document.body.scrollHeight
              //   ) {
              //     // Reached end of page
              //     window.scrollTo(0, 0)
              //   }
              // }, 10);
              let scrollerID;
              let paused = true;

              function startScroll() {
                let id = setInterval(function() {
                  window.scrollBy(0, 2);
                  if (
                    window.innerHeight + window.pageYOffset >=
                    document.body.scrollHeight
                  ) {
                    // console.log(
                    //   window.innerHeight + window.pageYOffset >=
                    //     document.body.scrollHeight
                    // );
                    // Reached end of page
                    window.scrollTo(0, 0);
                  }
                }, 30);
                return id;
              }

              function stopScroll() {
                clearInterval(scrollerID);
              }

              document.body.addEventListener(
                "keypress",
                function(event) {
                  if (event.which === 13 || event.keyCode === 13) {
                    // It's the 'Enter' key
                    if (paused === true) {
                      scrollerID = startScroll();
                      paused = false;
                    } else {
                      stopScroll();
                      paused = true;
                    }
                  }
                },
                true
              );
            }
          );
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from Task list api", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    const primary = GET_USER_LOCAL().organizations.filter(
      org => org._id.$oid === GET_USER_LOCAL().organization.$oid
    );
    return (
      <div>
        <div
          style={{
            position: "sticky",
            top: 0,
            maxHeight: 80,
            paddingBottom: 10,
            backgroundColor: "#0D98AD",
            zIndex: 1
          }}
        >
          <div
            style={{
              textAlign: "left",
              paddingLeft: 10,
              position: "relative",
              top: 5
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: 30,
                fontWeight: "bold"
              }}
            >
              {primary[0].name.toUpperCase()}
            </Text>
          </div>

          <div
            style={{ textAlign: "center", position: "relative", bottom: 40 }}
          >
            <Text
              style={{
                color: "white",
                fontSize: 30,
                fontWeight: "bold"
              }}
            >
              Live Tasks
            </Text>
          </div>
          <div
            style={{
              textAlign: "right",
              paddingRight: 10,
              position: "relative",
              bottom: 95
            }}
          >
            <img
              src={Logo}
              alt=""
              style={{ width: 200, paddingTop: 10, paddingLeft: 10 }}
            />
          </div>
        </div>

        <TableContainer style={{ overflowX: "initial" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                >
                  Task ID
                </TableCell>
                <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  Status
                </TableCell>
                <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  Asset No.
                </TableCell>
                <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  Assigned To
                </TableCell>
                <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  Contact Number
                </TableCell>
                <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  Start Location
                </TableCell>
                <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  Start Time
                </TableCell>
                {/* <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  Started On
                </TableCell> */}
                <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  End Location
                </TableCell>
                <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  End Time
                </TableCell>
                <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  Completed On
                </TableCell>
                <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  Duration (Minutes)
                </TableCell>
                <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  Start Time Delay (Minutes)
                </TableCell>
                <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  Completed Time Delay (Minutes)
                </TableCell>
                {/* <TableCell
                  style={{ color: "#FAFAFA", backgroundColor: "#212121" }}
                  align="right"
                >
                  Delay (Minutes)
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map((row, index) => (
                <TableRow
                  style={{
                    backgroundColor: index % 2 === 0 ? "#263238" : "#212121"
                  }}
                  key={row._id.$oid}
                >
                  <TableCell
                    style={{ color: "#FAFAFA" }}
                    component="th"
                    scope="row"
                  >
                    {row.task_code}
                  </TableCell>
                  <TableCell
                    style={{
                      color:
                        row.task_status === "COMPLETED"
                          ? "#34FF3C"
                          : row.task_status === "STARTED"
                          ? "#1DFFFF"
                          : row.task_status === "ACCEPTED"
                          ? "#AB9BFF"
                          : row.task_status === "PENDING"
                          ? "#FFCC67"
                          : row.task_status === "CANCELLED"
                          ? "#FF4848"
                          : "#FAFAFA"
                    }}
                    component="th"
                    scope="row"
                    align="right"
                  >
                    {row.task_status}
                  </TableCell>
                  <TableCell style={{ color: "#FAFAFA" }} align="right">
                    {row.assigned_asset_name ? row.assigned_asset_name : ""}
                  </TableCell>
                  <TableCell
                    style={{ color: "#FAFAFA" }}
                    align="right"
                    scope="row"
                  >
                    {row?.assigned_to?.name ?? ""}
                  </TableCell>
                  <TableCell
                    style={{ color: "#FAFAFA" }}
                    align="right"
                    scope="row"
                  >
                    {row?.assigned_to?.name ?? ""}
                  </TableCell>
                  <TableCell
                    style={{ color: "#FAFAFA" }}
                    align="right"
                    scope="row"
                  >
                    {row.start_loc_name}
                  </TableCell>
                  <TableCell
                    style={{
                      color:
                        row.task_status === "COMPLETED"
                          ? "#34FF3C"
                          : row.task_status === "STARTED"
                          ? "#1DFFFF"
                          : row.task_status === "ACCEPTED"
                          ? "#AB9BFF"
                          : row.task_status === "PENDING"
                          ? "#FFCC67"
                          : row.task_status === "CANCELLED"
                          ? "#FF4848"
                          : "#FAFAFA"
                    }}
                    align="right"
                    scope="row"
                  >
                    {row.start_time.$date
                      ? timeConverter(row.start_time.$date)
                      : ""}
                  </TableCell>
                  {/* <TableCell
                    style={{ color: "#FAFAFA", width: 30 }}
                    align="right"
                    scope="row"
                  >
                    {row.started_on.$date?timeConverter(row?.started_on?.$date):""}
                  </TableCell> */}
                  <TableCell
                    style={{ color: "#FAFAFA" }}
                    align="right"
                    scope="row"
                  >
                    {row.end_loc_name}
                  </TableCell>
                  <TableCell
                    style={{
                      color:
                        row.task_status === "COMPLETED"
                          ? "#34FF3C"
                          : row.task_status === "STARTED"
                          ? "#1DFFFF"
                          : row.task_status === "ACCEPTED"
                          ? "#AB9BFF"
                          : row.task_status === "PENDING"
                          ? "#FFCC67"
                          : row.task_status === "CANCELLED"
                          ? "#FF4848"
                          : "#FAFAFA"
                    }}
                    align="right"
                    scope="row"
                  >
                    {row.end_time.$date
                      ? timeConverter(row.end_time.$date)
                      : ""}
                  </TableCell>
                  <TableCell
                    style={{ color: "#FAFAFA", width: 30 }}
                    align="right"
                    scope="row"
                  >
                    {row.completed_on.$date?timeConverter(row?.completed_on?.$date):""}
                  </TableCell>
                  <TableCell
                    style={{ color: "#FAFAFA" }}
                    align="right"
                    scope="row"
                  >
                    {row?.duration_min ?? 0}
                  </TableCell>
                  <TableCell
                    style={{ color: "#FAFAFA" }}
                    align="right"
                    scope="row"
                  >
                    {row?.delayed_by_started_min ?? 0}
                  </TableCell>
                  <TableCell
                    style={{ color: "#FAFAFA" }}
                    align="right"
                    scope="row"
                  >
                    {row?.delayed_by_completed_min ?? 0}
                  </TableCell>
                  {/* <TableCell
                    style={{ color: "#FAFAFA" }}
                    align="right"
                    scope="row"
                  >
                    {row?.delay_mins ?? 0}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
