import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Modal
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_MTYPE,
  CREATE_MATERIAL,
  CREATE_UNIT,
  CREATE_SUPPLIER
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";
import CreateMaterialType from "../mtype/createMtype";
import CreateSupplier from "../supplier/createSupplier";
import CreateUnit from "../unit/createUnit";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class CreateMaterial extends Component {
  state = {
    name: "",
    mtype: "",
    mtypekey: "",
    data: [],
    filtered_mtype: [],
    dataU: [],
    filtered_unit: [],
    unit: "",
    unit_key: "",
    price: null,
    unit_price: 1,
    supplier_name: "",
    supplier_name_key: "",
    loading: false,
    dataS: [],
    filtered_supplier: [],
    showSupplier: false,
    showMtype: false,
    showUnit: false
  };

  componentDidMount() {
    this.getUnitList();
    this.getMtype();
    this.getSupplierList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getSupplierList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_SUPPLIER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ dataS: response.data.data });
        })
        .catch(error => {
          console.log("error from shop list api", error);
        });
    });
  };

  getMtype = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_MTYPE}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ data: response.data.data });
        })
        .catch(error => {
          console.log("error from mtype list api", error);
        });
    });
  };

  getUnitList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_UNIT}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ dataU: response.data.data });
          console.log("data from unit list api", response);
        })
        .catch(error => {
          console.log("error from unit list api", error);
        });
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  supplierSearch = value => {
    const result = this.state.dataS.filter(supplier => {
      return supplier.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered_supplier: result });
  };
  setSupplier = value => {
    this.setState(
      { supplier_name: value.label, supplier_name_key: value.key },
      () => {
        console.log(this.state.supplier_name);
      }
    );
  };
  mtypeSearch = value => {
    const result = this.state.data.filter(mtype => {
      return mtype.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered_mtype: result });
  };
  setMtype = value => {
    this.setState({ mtype: value.label, mtypekey: value.key }, () => {
      console.log(this.state.mtype);
    });
  };

  setPrice = evt => {
    evt.preventDefault();
    this.setState({ price: evt.target.value }, () => {
      console.log(this.state.price);
    });
  };

  setUnitPrice = evt => {
    evt.preventDefault();
    this.setState({ unit_price: evt.target.value }, () => {
      console.log(this.state.unit_price);
    });
  };
  unitSearch = value => {
    const result = this.state.dataU.filter(unit => {
      return unit.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered_unit: result });
  };
  setUnit = value => {
    this.setState({ unit: value.label, unit_key: value.key }, () => {
      console.log(this.state.unit);
    });
  };

  createMaterial = () => {
    if (
      this.state.name === "" ||
      this.state.mtype === "" ||
      this.state.unit === ""
    ) {
      swal.fire("info", "Please enter Name, Material Type and Unit", "info");
    } else {
      const data = {
        name: this.state.name,
        price: Number(this.state.price),
        unit_price: Number(this.state.unit_price),
        supplier_id: this.state.supplier_name_key,
        mtype: {
          id: this.state.mtypekey,
          name: this.state.mtype
        },
        unit: this.state.unit
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_MATERIAL}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Success", response.data.message, "success");
            console.log("response from create material", response);
          })
          .catch(error => {
            swal.fire("Error", error.message, "error");
            console.log("error from create material", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    const mtypeList = this.state.data.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });

    const FilteredMtype = this.state.filtered_mtype.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });
    const supplierList = this.state.dataS.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });

    const FilteredSupplier = this.state.filtered_supplier.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });
    const UnitList = this.state.dataU.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });

    const FilteredUnit = this.state.filtered_unit.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Create Item"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Unit Price</Text>
            <Input
              placeholder="Unit Price"
              type="number"
              value={this.state.unit_price}
              onChange={this.setUnitPrice}
            />
          </Form.Item>
          <Form.Item>
            <Text>
             Search Supplier Name{" "}
              <Button
                type="link"
                onClick={() => this.setState({ showSupplier: true })}
              >
                Create Supplier
              </Button>
            </Text>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.supplierSearch}
              value={{
                key: this.state.supplier_name_key,
                label: this.state.supplier_name
              }}
              onChange={this.setSupplier}
              placeholder="Search Supplier"
            >
              {this.state.filtered_supplier.length === 0
                ? supplierList
                : FilteredSupplier}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>
            Search Item Type{" "}
              <Button
                type="link"
                onClick={() => this.setState({ showMtype: true })}
              >
                Create Item Type
              </Button>
            </Text>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.mtypeSearch}
              value={{ key: this.state.mtypekey, label: this.state.mtype }}
              onChange={this.setMtype}
              placeholder="Search Item Type"
            >
               {this.state.filtered_mtype.length === 0
                ? mtypeList
                : FilteredMtype}
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>
            Search Unit{" "}
              <Button
                type="link"
                onClick={() => this.setState({ showUnit: true })}
              >
                Create Unit
              </Button>
            </Text>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.unitSearch}
              value={{ key: this.state.unit_key, label: this.state.unit }}
              onChange={this.setUnit}
              placeholder="Search Unit"
            >
               {this.state.filtered_unit.length === 0
                ? UnitList
                : FilteredUnit}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createMaterial}
            >
              Create Item
            </Button>
          </Form.Item>
        </Form>
        <Modal
          title="Create New Item Type"
          visible={this.state.showMtype}
          onOk={() => {
            this.setState({ showMtype: false });
            this.getMtype();
          }}
          onCancel={() => {
            this.setState({ showMtype: false });
            this.getMtype();
          }}
          width={window.innerWidth - 200}
          closable={true}
          destroyOnClose={true}
        >
          <CreateMaterialType />
        </Modal>
        <Modal
          title="Create New Supplier"
          visible={this.state.showSupplier}
          onOk={() => {
            this.setState({ showSupplier: false });
            this.getSupplierList();
          }}
          onCancel={() => {
            this.setState({ showSupplier: false });
            this.getSupplierList();
          }}
          width={window.innerWidth - 200}
          closable={true}
          destroyOnClose={true}
        >
          <CreateSupplier />
        </Modal>
        <Modal
          title="Create New Unit"
          visible={this.state.showUnit}
          onOk={() => {
            this.setState({ showUnit: false });
            this.getUnitList();
          }}
          onCancel={() => {
            this.setState({ showUnit: false });
            this.getUnitList();
          }}
          width={window.innerWidth - 200}
          closable={true}
          destroyOnClose={true}
        >
          <CreateUnit />
        </Modal>
      </Card>
    );
  }
}
