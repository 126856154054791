import React from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
import { REFRESH_TOKEN, USER_PROFILE } from "../api/constants";
import { persistor } from "../redux/store/store";
import moment from "moment";


export const timeConverter = UNIX_timestamp => {
  var time = moment(UNIX_timestamp).format("DD MMM YYYY hh:mm a")
  return time;
};

export const GET_ACCESS_TOKEN = async () => {
  let user = await localStorage.getItem("conveyanceUser");
  let TOKEN = JSON.parse(user);

  let intial_time = TOKEN.Generated_time;
  let current_time = Date.now();

  let difference = (current_time - intial_time) / 1000;

  let access_token;

  if (difference > 3400) {
    let user = await localStorage.getItem("conveyanceUser");
    let token = JSON.parse(user);

    // console.log("Refresh token from api", token.refresh_token);
    await axios({
      method: "post",
      url: REFRESH_TOKEN,
      data: {
        access_token: token.access_token,
        refresh_token: token.refresh_token
      },
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      }
    })
      .then(response => {
        // console.log("Response in token", response);
        if (response.data.status === "fail") {
          persistor.pause();
          persistor.flush().then(() => {
            return persistor.purge();
          });
          localStorage.removeItem("conveyanceUser");
          localStorage.removeItem("activeKeyInsights");
          localStorage.removeItem("userProfile");
          localStorage.removeItem("activeKeyParent");
          localStorage.removeItem("activeKey");
          localStorage.removeItem("activeKeyOrder");
          localStorage.removeItem("viewasvalue");
          localStorage.removeItem("registerKey");
          localStorage.removeItem("selectedKeys");
        }
        token.Generated_time = Date.now();
        let data = token.access_token;
        access_token = data;
        // console.log("server token", access_token);
      })
      .catch(error => {
        console.log("Error in token", error);
        if (error) {
          persistor.pause();
          persistor.flush().then(() => {
            return persistor.purge();
          });
          localStorage.removeItem("conveyanceUser");
          localStorage.removeItem("userProfile");
          localStorage.removeItem("activeKeyParent");
          localStorage.removeItem("activeKeyInsights");
          localStorage.removeItem("activeKey");
          localStorage.removeItem("activeKeyOrder");
          localStorage.removeItem("viewasvalue");
          localStorage.removeItem("registerKey");
          localStorage.removeItem("selectedKeys");
        } else if (error.response.data.status === "fail") {
          persistor.pause();
          persistor.flush().then(() => {
            return persistor.purge();
          });
          localStorage.removeItem("conveyanceUser");
          localStorage.removeItem("userProfile");
          localStorage.removeItem("activeKeyParent");
          localStorage.removeItem("activeKeyInsights");
          localStorage.removeItem("activeKey");
          localStorage.removeItem("activeKeyOrder");
          localStorage.removeItem("viewasvalue");
          localStorage.removeItem("registerKey");
          localStorage.removeItem("selectedKeys");
        }
      });

    // let data = await response.data.access_token;
  } else {
    access_token = TOKEN.access_token;
    // console.log("local token", access_token);
  }

  return access_token;
};

export const CHECK_BOOL = value => {
  if (value === "true") {
    return true;
  } else {
    return false;
  }
};

export const NUMBER_VALIDATION = number => {
  if (number.length !== 10) {
    return false;
  } else {
    return true;
  }
};

export const LOCAL_USER_INFO = () => {
  let userInfo = localStorage.getItem("conveyanceUser");
  let token = JSON.parse(userInfo);

  return token;
};

export const checkUser = () => {
  let token = LOCAL_USER_INFO();
  if (token) {
    let intial_time = token.Generated_time;
    let current_time = Date.now();
    let difference = (current_time - intial_time) / 1000;

    if (difference >= 54000) {
      localStorage.removeItem("conveyanceUser");
      return false;
    } else if (!token.user.email_verified && !token.user.mobile_verified) {
      localStorage.removeItem("conveyanceUser");
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      checkUser() === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: window.location.pathname }
          }}
        />
      )
    }
  />
);

export function validateEmail(email) {
  var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const GET_USER = () => {
  let user;
  GET_ACCESS_TOKEN().then(token => {
    const url = `${USER_PROFILE}?access_token=${token}`;
    axios({
      method: "get",
      url: url
    })
      .then(response => {
        console.log("response from user profile", response);

        user = response.data;
        localStorage.setItem("userProfile", JSON.stringify(response.data));
      })
      .catch(error => {
        console.log("error from user profile", error);
      });
  });

  return user;
};

export const GET_USER_LOCAL = () => {
  let userInfo = localStorage.getItem("userProfile");
  let user = JSON.parse(userInfo);

  return user;
};

export const TIME_DIFF = (curent, user) => {
  let valueOne = Math.floor(curent / 60000);
  let valueTwo = Math.floor(user / 60000);

  let diff = valueOne - valueTwo;

  return diff;
};

export const randomId = length => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const isValidURL = string => {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
  );
  return res !== null;
};
