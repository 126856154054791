import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  Table,
  Input,
  Modal
} from "antd";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_ORDER,
  CONTAINER_FOR_ORDER,
  BOOK_CONTAINER,
  REVERSE_GEO
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  CHECK_BOOL,
  timeConverter,
  randomId
} from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { Column } = Table;

export default class ContainerforOrder extends Component {
  state = {
    name: "",
    details: "",
    price: "",
    order: "",
    order_id: [],
    orderView: [],
    radius: 0,
    running: false,
    inside_org: false,
    loading: false,
    data: [],
    filtered: [],
    showList: false,
    suggestedContainers: [],
    start_loc: [],
    start_loc_name: "",
    end_loc: [],
    end_loc_name: "",
    assigned_to: "",
    start_time: "",
    start_time_view: moment(),
    end_time: "",
    end_time_view: moment(),
    parcels: [],
    bookingData: [],
    showtaskModal: false,
    containerInfo: {}
  };

  componentDidMount() {
    this.getOrderList();
  }

  getOrderList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ORDER}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ data: response.data.data });
          console.log("data from order list api send org invite api", response);
        })
        .catch(error => {
          console.log("error from order list api", error);
        });
    });
  };

  setName = evt => {
    this.setState({ name: evt.target.value });
    console.log(this.state.name);
  };

  setDetails = evt => {
    this.setState({ details: evt.target.value });
    console.log(this.state.details);
  };

  setPrice = evt => {
    this.setState({ price: evt.target.value });
    console.log(this.state.price);
  };

  setStartTime = (value, dateString) => {
    this.setState(
      { start_time: moment(value).format("x"), start_time_view: value },
      () => {
        console.log("Selected Time: ", this.state.start_time);
        console.log("Selected value: ", this.state.start_time_view);
      }
    );
  };

  setEndTime = (value, dateString) => {
    this.setState(
      { end_time: moment(value).format("x"), end_time_view: value },
      () => {
        console.log("Selected Time: ", this.state.end_time);
        console.log("Selected Time: ", this.state.end_time_view);
      }
    );
  };

  setOrder = value => {
    this.setState({ orderView: value });
    value.forEach(item => {
      let orderData = {
        order_id: item
      };
      let array = [];
      array.push(orderData);
      this.setState({ order_id: [...this.state.order_id, ...array] }, () => {
        console.log(this.state.order_id);
      });
    });
  };
  //   handleSearch=(value)=>{
  //     const result = this.state.data.filter(org=> {
  //       return org.name.toLowerCase() === value.toLowerCase()
  //     })
  //     console.log(result)
  //     this.setState({filtered:result})

  //   }

  setRunning = value => {
    this.setState({ running: CHECK_BOOL(value) }, () => {
      console.log(this.state.running);
    });
  };
  setInsideOrg = value => {
    this.setState({ inside_org: CHECK_BOOL(value) }, () => {
      console.log(this.state.inside_org);
    });
  };

  setRadius = evt => {
    evt.preventDefault();
    this.setState({ radius: evt.target.value }, () => {
      console.log(this.state.radius);
    });
  };
  searchContainer = () => {
    if (this.state.order_id.length === 0) {
      swal.fire("info", "Please Select Order", "info");
    } else {
      this.setState({ loading: true });
      let data = {
        orders: this.state.order_id,
        radius: Number(this.state.radius),
        is_running: this.state.running,
        inside_org: this.state.inside_org
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CONTAINER_FOR_ORDER}?access_token=${token}`;

        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });

            swal.fire(
              "Success",
              `${response.data.message}#${response.data.containers_needed}`,
              "success"
            );
            this.setState(
              {
                showList: true,
                suggestedContainers: response.data.containers,
                start_loc: response.data.start_loc,
                end_loc: response.data.end_loc,
                parcels: response.data.packages
              },
              () => {
                axios
                  .get(
                    REVERSE_GEO(this.state.end_loc[1], this.state.end_loc[0])
                  )
                  .then(response => {
                    this.setState({
                      end_loc_name:
                        response.data.features[0].properties.display_name
                    });
                  });
                axios
                  .get(
                    REVERSE_GEO(
                      this.state.start_loc[1],
                      this.state.start_loc[0]
                    )
                  )
                  .then(response => {
                    this.setState({
                      start_loc_name:
                        response.data.features[0].properties.display_name
                    });
                  });
              }
            );

            console.log(
              "response from container for order connect list send",
              response
            );
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log(
              "error from container for order connect list send",
              error
            );
          });
      });
    }
  };

  bookContainer = container => {
    let containerObj = {
      container_id:container._id.$oid,
      container: container,
      start_loc: {
        coordinates: [this.state.start_loc[0], this.state.start_loc[1]]
      },
      end_loc: {
        coordinates: [this.state.end_loc[0], this.state.end_loc[1]]
      },
      start_time: this.state.start_time,
      end_time: this.state.end_time,
      task_gen_data: {
        name: this.state.name,
        details: this.state.details,
        end_loc: {
          coordinates: [this.state.end_loc[0], this.state.end_loc[1]]
        },
        end_loc_name: this.state.end_loc_name,
        task_code: randomId(10),
        price: Number(this.state.price),
        start_loc: {
          coordinates: [this.state.start_loc[0], this.state.start_loc[1]]
        },
        start_loc_name: this.state.start_loc_name,
        end_time: this.state.start_time,
        start_time: this.state.end_time,
        assigned_to: container.asset.user_id,
        start_dist: 3000,
        end_dist: 3000,
        trip_duration: 0,
        delay_mins: 0,
        assigned_asset: container.asset._id.$oid,
        prev_task_id: "",
        m_task_id: "",
        contact_no: "",
        contact_name: "",
        colors: []
      },
      parcels: this.state.parcels.map(p => {
        return {
          order_id: p.order_id,
          parcel_id: p._id.$oid
        };
      })
    };
    let array = [];
    array.push(containerObj);
    let data = {
      containers: array
    };
    console.log("booking info", data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${BOOK_CONTAINER}?access_token=${token}`;

      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Success", response.data.message, "success");

          console.log("response from book container", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from from book container", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    // const UserList=this.state.data.map(r => {
    //   return <Option key={r._id.$oid}>{r.name}</Option>;
    // })

    // const FliteredList = this.state.filtered.map(r => {
    //   return <Option key={r._id.$oid}>{r.name}</Option>;
    // })
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          //   onBack={() => this.props.history.goBack()}
          title="Container Suggestion for Orders"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Select Order</Text>
            <Select
              mode="multiple"
              //   showSearch={true}
              //   labelInValue
              //   notFoundContent={fetch ? <Spin size="small" /> : null}
              //   filterOption={false}
              //   showArrow={false}
              //   autoClearSearchValue={false}
              //   onSearch={this.handleSearch}
              value={this.state.orderView}
              onChange={this.setOrder}
              placeholder="Select Order"
            >
              {this.state.data.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
            <Button
              onClick={() => this.setState({ order_id: [], orderView: [] })}
            >
              Clear Selected
            </Button>
          </Form.Item>

          <Form.Item>
            <Text>Search Radius (In Meters)</Text>
            <Input
              placeholder="Search Radius (In Meters)"
              type="number"
              value={this.state.radius}
              onChange={this.setRadius}
            />
          </Form.Item>

          <Form.Item>
            <Text>Running</Text>
            <Select
              value={this.state.running.toString()}
              onChange={this.setRunning}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Search Inside Organization</Text>
            <Select
              value={this.state.inside_org.toString()}
              onChange={this.setInsideOrg}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.searchContainer}
            >
              Search Container
            </Button>
          </Form.Item>
        </Form>
        {this.state.showList ? (
          <Card>
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              title="Suggested Containers"
            />
            <Table
              dataSource={this.state.suggestedContainers}
              pagination={false}
              rowKey={record => record._id.$oid}
              size="small"
              bordered
            >
              <Column title="Name" dataIndex="name" key="name" />
              <Column title="Length" dataIndex="cl" key="cl" />
              <Column title="Height" dataIndex="ch" key="ch" />
              <Column title="Width" dataIndex="cw" key="cw" />
              <Column title="Dimension" dataIndex="unit_dim" key="unit_dim" />
              <Column
                title="Max Weight"
                dataIndex="max_weight"
                key="max_weight"
              />
              <Column
                title="Gross Weight"
                dataIndex="gross_weight"
                key="gross_weight"
              />
              <Column
                title="Unit Weight"
                dataIndex="unit_weight"
                key="unit_weight"
              />
              <Column
                title="Asset"
                key="asset.asset"
                render={(text, record) => <span>{record.asset.asset}</span>}
              />
              <Column
                title="User Name"
                key="asset.name"
                render={(text, record) => <span>{record.asset.name}</span>}
              />
              <Column
                title="Organization Name"
                key="org.name"
                render={(text, record) => <span>{record.org.name}</span>}
              />

              <Column
                title="Created On"
                key="created_on"
                render={(text, record) => (
                  <span>{timeConverter(record.created_on.$date)}</span>
                )}
              />
              <Column
                title="Modified On"
                key="modified_on"
                render={(text, record) => (
                  <span>{timeConverter(record.modified_on.$date)}</span>
                )}
              />

              <Column
                title="Action"
                key="action"
                render={(text, record) => (
                  <span>
                    <button
                      
                      style={{ backgroundColor: "teal",color:"white" }}
                      onClick={() =>
                        this.setState({
                          showtaskModal: true,
                          containerInfo: record
                        })
                      }
                    >
                      Book
                    </button>
                  </span>
                )}
              />
            </Table>
            <Modal
              title="Add Booking Information"
              visible={this.state.showtaskModal}
              onOk={() => {
                this.setState({ showtaskModal: false });
                this.bookContainer(this.state.containerInfo);
              }}
              onCancel={() =>
                this.setState({ showtaskModal: false, containerInfo: {} })
              }
            >
              <Form>
                <Form.Item>
                  <Text>Task Name</Text>

                  <Input
                    type="text"
                    placeholder="Task Name"
                    value={this.state.name}
                    onChange={this.setName}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Task Details</Text>

                  <TextArea
                    rows={4}
                    placeholder="Task Details"
                    value={this.state.details}
                    onChange={this.setDetails}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Price</Text>

                  <Input
                    type="number"
                    placeholder="Price"
                    value={this.state.price}
                    onChange={this.setPrice}
                  />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <Text>Start Date & Time</Text>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.start_time_view}
                        onChange={this.setStartTime}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <Text>End Date & Time</Text>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.end_time_view}
                        onChange={this.setEndTime}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
              </Form>
            </Modal>
          </Card>
        ) : null}
      </Card>
    );
  }
}
