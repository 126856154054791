import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Icon,
  Tooltip,
  Spin,
} from "antd";
import { debounce } from "lodash";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_ENTITY,
  ENTITY_TYPE,
  CREATE_COUNTRY,
  EDIT_COUNTRY,
  LOCATION_SEARCH_GOOGLE,
  REVERSE_LOCATION_SEARCH_GOOGLE,
  SUB_ORGS_WITH_USER_ROLE_PERMISSION,
  // CURRENT_USER_SUB_ORG_LIST
} from "../../api/constants";
import "leaflet/dist/leaflet.css";
import { Map, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";

import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";
import ReactGA from "react-ga4";

import { Spinner } from "../loader";

import "../login.css";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40],
});

class CreateShop extends Component {
  state = {
    name: "",
    shop: "",
    loc: { coordinates: [90.3563, 23.685] },
    address: "",
    city: "",
    country: "",
    post_code: "",
    cities: [],
    countries: [],
    city_id: "",
    country_id: "",
    loading: false,
    query: "",
    start_location: "",
    value: [],
    data: [],
    fetch: false,
    center_lat: 23.685,
    center_lon: 90.3563,
    start_location_lat: 23.685,
    start_location_lon: 90.3563,
    editStart: false,
    zoom: 15,
    entity_choices: [],
    redirectOrder: false,
    filtered: [],
    contact_name: "",
    contact_no: "",
    contact_email: "",
    state: "",
    is_inventory: false,
    sub_orgs: [],
    sub_org: "",
    enable_purchase_order: false,
  };
  componentDidMount() {
    this.props.getUserData();
    // this.countryList();
    this.getCurrentPosition();
    this.getEntityChoices();
    this.getSubOrgs();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getCurrentPosition = () => {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState({
        center_lat: latitude,
        start_location_lat: latitude,
        center_lon: longitude,
        start_location_lon: longitude,
      });
    };
    navigator.geolocation.getCurrentPosition(success);
  };

  getSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${SUB_ORGS_WITH_USER_ROLE_PERMISSION}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("sub org list according to role", response);
          this.setState({
            sub_orgs: response.data?.data ?? [],
            sub_org:
              response.data?.data?.length > 0
                ? response.data.data[0]._id.$oid
                : "",
          });
        })
        .catch((error) => {
          console.log("error from sub org list according to role", error);
        });
    });
  };
  getEntityChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ENTITY_TYPE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("dropdown entity", response);
          this.setState({ entity_choices: response.data.entity_choices });
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  };

  countryList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 200;
      const url = `${CREATE_COUNTRY}?limit=${limit}&d=${false}&sort_by="name"&show="no"&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ countries: response.data.data }, () => {
            console.log("dropdown", this.state.countries);
          });
        })
        .catch((error) => {
          console.log("error from country list api", error);
        });
    });
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setContactName = (evt) => {
    evt.preventDefault();
    this.setState({ contact_name: evt.target.value }, () => {
      console.log(this.state.contact_name);
    });
  };
  setContactNumber = (evt) => {
    evt.preventDefault();
    this.setState({ contact_no: evt.target.value }, () => {
      console.log(this.state.contact_no);
    });
  };
  setContactEmail = (evt) => {
    evt.preventDefault();
    this.setState({ contact_email: evt.target.value }, () => {
      console.log(this.state.contact_email);
    });
  };
  setSubOrg = (value) => {
    this.setState({ sub_org: value }, () => {
      console.log(this.state.sub_org);
    });
  };
  setShop = (value) => {
    this.setState({ shop: value }, () => {
      console.log(this.state.shop);
    });
  };
  setAddress = (evt) => {
    evt.preventDefault();
    this.setState({ start_location: evt.target.value }, () => {
      console.log(this.state.start_location);
    });
  };
  setCity = (value) => {
    this.setState(
      { city: value.label, city_id: value.key, filtered: [] },
      () => {
        console.log(this.state.city);
      }
    );
  };

  setInventory = (value) => {
    this.setState({ is_inventory: CHECK_BOOL(value) }, () => {
      console.log(this.state.is_inventory);
    });
  };

  setEnablePurchaseOrder = (value) => {
    this.setState({ enable_purchase_order: CHECK_BOOL(value) }, () => {
      console.log(this.state.enable_purchase_order);
    });
  };

  handleSearch = (value) => {
    const result = this.state.cities.filter((city) => {
      return city.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered: result });
  };
  setCountry = (value) => {
    this.setState({ country: value.label, country_id: value.key }, () => {
      console.log(this.state.country);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_COUNTRY}${this.state.country_id}?access_token=${token}`;

        axios({
          method: "get",
          url: url,
        }).then((response) => {
          console.log("response fro edit country", response.data.data);
          this.setState({
            cities: response.data.data.cities,
          });
        });
      });
    });
  };
  setPostcode = (evt) => {
    evt.preventDefault();
    this.setState({ post_code: evt.target.value }, () => {
      console.log(this.state.post_code);
    });
  };

  handleStart = debounce((value) => {
    this.setState({ query: value, data: [], fetching: true }, () => {
      console.log(this.state.query);
      if (this.state.query !== "") {
        GET_ACCESS_TOKEN().then((token) => {
          axios
            .get(
              `${LOCATION_SEARCH_GOOGLE}?textsearch=${this.state.query}&access_token=${token}`
            )
            .then((response) => {
              console.log("response from google search", response);
              const data = response.data.google_loc_datas.map((res) => ({
                id: `${res._id.$oid}>${res.loc.coordinates[0]}>${res.loc.coordinates[1]}`,
                value: `${res.name}>${res.address}`,
              }));
              this.setState({ data: data, fetch: false });
            });
        });
      }
    });
  }, 500);

  handleStartChange = (value) => {
    this.setState({ value, data: [], fetch: false });
    return this.setState(
      {
        start_location: value.label.split(">")[0],
        start_location_lon: value.key.split(">")[1],
        center_lon: value.key.split(">")[1],
        start_location_lat: value.key.split(">")[2],
        center_lat: value.key.split(">")[2],
      },
      () => {
        console.log("selected name", this.state.start_location_lat);
        GET_ACCESS_TOKEN().then((token) => {
          axios
            .get(
              `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.start_location_lat}&lng=${this.state.start_location_lon}&access_token=${token}`
            )
            .then((response) => {
              console.log("reverse geo response after selection", response);
              this.setState({
                city: response.data?.google_loc_data[0]?.city ?? "",
                country: response.data?.google_loc_data[0]?.country ?? "",
                state: response.data?.google_loc_data[0]?.state ?? "",
                post_code: response.data?.google_loc_data[0]?.postcode ?? "",
              });
            });
        });
      }
    );
  };

  updatePositionStart = (evt) => {
    console.log(evt.target.getLatLng());

    this.setState(
      {
        start_location_lat: evt.target.getLatLng().lat,
        start_location_lon: evt.target.getLatLng().lng,
        center_lon: evt.target.getLatLng().lng,
        center_lat: evt.target.getLatLng().lat,
      },
      () => {
        GET_ACCESS_TOKEN().then((token) => {
          axios
            .get(
              `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.start_location_lat}&lng=${this.state.start_location_lon}&access_token=${token}`
            )
            .then((response) => {
              console.log("marker movement reponse", response);
              let name = response.data.google_loc_data[0].address;
              let id = response.data.google_loc_data[0]._id.$oid;
              this.setState({
                start_location: name,
                value: { key: id, label: name },
                city: response.data?.google_loc_data[0]?.city ?? "",
                country: response.data?.google_loc_data[0]?.country ?? "",
                state: response.data?.google_loc_data[0]?.state ?? "",
                post_code: response.data?.google_loc_data[0]?.postcode ?? "",
              });
            });
        });
      }
    );
  };

  createShop = () => {
    if (
      this.state.name === "" ||
      this.state.country === "" ||
      this.state.start_location === "" ||
      this.state.shop === ""
    ) {
      swal.fire("info", "Please enter all informations", "info");
    } else if (
      this.state.contact_no !== "" &&
      !this.state.contact_no.includes("+")
    ) {
      swal.fire(
        "info",
        "Please enter number with country code.Example +8801711123456",
        "info"
      );
    } else {
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        is_inventory: this.state.is_inventory,
        enable_purchase_order: this.state.enable_purchase_order,
        entity_type: this.state.shop,
        loc: {
          coordinates: [
            this.state.start_location_lon,
            this.state.start_location_lat,
          ],
        },
        address: this.state.start_location,
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        post_code: this.state.post_code,
        sub_org: this.state.sub_org,
        contact: {
          name: this.state.contact_name,
          mobile: this.state.contact_no,
          email: this.state.contact_email,
        },
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_ENTITY}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({ loading: false });
            swal.fire("Info", response.data.message, "info");
            // if(this.props.location.state.fromOrder){
            //   this.setState({redirectOrder:true});
            // }
            console.log("response from create shop", response);
          })
          .catch((error) => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from create shop", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    const citiesList = this.state.cities.map((r) => {
      return <Option key={r.code}>{r.name}</Option>;
    });

    const FliteredList = this.state.filtered.map((r) => {
      return <Option key={r.code}>{r.name}</Option>;
    });
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title="Create Place (* Marked Fields Are Mandatory)"
        />
        <div>
          <Map
            center={[this.state.center_lat, this.state.center_lon]}
            zoom={this.state.zoom}
            style={{ height: 500 }}
            onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
          >
            {/* <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            /> */}

            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{
                KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                VERSION: "3.49.2",
              }}
              type={"roadmap"}
            />

            <Marker
              position={[
                this.state.start_location_lat,
                this.state.start_location_lon,
              ]}
              draggable
              onDragend={this.updatePositionStart}
              icon={green}
            >
              <Popup>
                <span>From :{this.state.start_location}</span>
              </Popup>
            </Marker>
            <Button
              size="large"
              shape="circle"
              style={{
                position: "absolute",
                right: 20,
                bottom: 40,
                zIndex: 400,
                paddingTop: 7,
                paddingRight: 1,
              }}
              onClick={this.getCurrentPosition}
            >
              <MyLocationIcon />
            </Button>
          </Map>
        </div>
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Search Place Location *</Text>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {this.state.editStart === true ? (
                <Input
                  type="text"
                  placeholder="Start Location"
                  value={this.state.start_location}
                  onChange={(evt) =>
                    this.setState({ start_location: evt.target.value })
                  }
                />
              ) : (
                <Select
                  showSearch={true}
                  labelInValue
                  value={this.state.value}
                  notFoundContent={fetch ? <Spin size="small" /> : null}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  placeholder="Search Start Location"
                  onSearch={this.handleStart}
                  onChange={this.handleStartChange}
                  optionLabelProp="label"
                >
                  {this.state.data.map((r) => {
                    return (
                      <Option key={r.id} label={r.value}>
                        {
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Text>{r.value.split(">")[0]}</Text>
                            <Text style={{ fontSize: 10, color: "#9CB4CC" }}>
                              {r.value.split(">")[1]}
                            </Text>
                          </div>
                        }
                      </Option>
                    );
                  })}
                </Select>
              )}
              {this.state.start_location === "" ? null : (
                <Tooltip
                  title={
                    this.state.editStart
                      ? "Search Start Location"
                      : "Edit Start Location"
                  }
                >
                  <Button
                    type="primary"
                    shape="circle"
                    style={{ marginLeft: 10 }}
                    onClick={() =>
                      this.setState({ editStart: !this.state.editStart })
                    }
                  >
                    <Icon type={this.state.editStart ? "search" : "edit"} />
                  </Button>
                </Tooltip>
              )}
            </div>
          </Form.Item>
          <Form.Item>
            <Text>Place Name *</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Place Type *</Text>
            <Select value={this.state.shop.toString()} onChange={this.setShop}>
              {this.state.entity_choices.map((entity) => {
                return <Option key={entity}>{entity}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Address *</Text>

            <TextArea
              rows={4}
              placeholder="Address"
              type="text"
              value={this.state.start_location}
              onChange={this.setAddress}
            />
          </Form.Item>
          <Form.Item>
            <Text>Contact Name</Text>
            <Input
              placeholder="Conatct Name"
              type="text"
              value={this.state.contact_name}
              onChange={this.setContactName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Contact Number</Text>
            <Input
              placeholder="Example: +8801711123456"
              type="text"
              value={this.state.contact_no}
              onChange={this.setContactNumber}
            />
          </Form.Item>
          <Form.Item>
            <Text>Contact Email</Text>
            <Input
              placeholder="Contact Email"
              type="text"
              value={this.state.contact_email}
              onChange={this.setContactEmail}
            />
          </Form.Item>
          <Form.Item>
            <Text>Select Sub Org</Text>
            <Select
              value={this.state.sub_org}
              onChange={this.setSubOrg}
              placeholder="Select Sub Org"
            >
              {this.state.sub_orgs.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Inventory</Text>
            <Select
              disabled={
                this.props.userProfileData.place.update_is_inventory_in_place
                  ? false
                  : true
              }
              value={this.state.is_inventory.toString()}
              onChange={this.setInventory}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          {this.props.userProfileData.data_level.access_suborg_data ||
          this.props.userProfileData.data_level.access_all_data ? (
            <Form.Item>
              <Text>Purchase Order</Text>
              <Select
                value={this.state.enable_purchase_order.toString()}
                onChange={this.setEnablePurchaseOrder}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
          ) : null}

          <Form.Item>
            <Text>Country *</Text>
            <Select
              disabled
              labelInValue
              value={{ key: this.state.country_id, label: this.state.country }}
              onChange={this.setCountry}
              placeholder="Select Country"
            >
              {this.state.countries.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>City *</Text>

            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.handleSearch}
              // disabled={this.state.country === "" ? true : false}
              disabled
              value={{ key: this.state.city_id, label: this.state.city }}
              onChange={this.setCity}
              placeholder="City"
            >
              {this.state.filtered.length === 0 ? citiesList : FliteredList}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>State</Text>

            <Input
              placeholder="State"
              disabled
              type="text"
              value={this.state.state}
              onChange={this.setPostcode}
            />
          </Form.Item>
          <Form.Item>
            <Text>Post Code</Text>

            <Input
              placeholder="Post Code"
              type="text"
              value={this.state.post_code}
              onChange={this.setPostcode}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createShop}
            >
              Create Place
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(CreateShop);
