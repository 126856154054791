import {SAVE_REQUISITION_LIST_PARAMS} from "../actions/types";

const initialState={
    requisitionListParamsData:{}
}


export default function(state=initialState,action){
    switch(action.type){
        case SAVE_REQUISITION_LIST_PARAMS:
            return {
                ...state,
                requisitionListParamsData:action.payload
            }
        default:
            return state;
    }
}