import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CONNECT_DRIVER,
  CREATE_DRIVER,
  CONNECT_ADMIN,
  EDIT_DRIVER
} from "../../api/constants";
import { GET_ACCESS_TOKEN, LOCAL_USER_INFO } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class ConnectToOrganization extends Component {
  state = {
    driver_id: "",
    pin: "",
    loading: false,
    userData: [],
    name: "",
    isStaff: false
  };

  componentDidMount() {
    this.setState({ isStaff: LOCAL_USER_INFO().user.is_staff });
    this.getUserList();
  }

  setValue = value => {
    this.setState({ driver_id: value.key }, () => {
      console.log(this.state.driver_id);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${EDIT_DRIVER}${this.state.driver_id}?access_token=${token}`;
        axios({
          method: "get",
          url: url
        }).then(response => {
          console.log("response fro edit driver", response);
          this.setState(
            {
              pin: response.data.data.pin
            },
            () => {
              console.log(this.state.pin);
            }
          );
        });
      });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch(error => {
          console.log("error from driver list api", error);
        });
    });
  };

  connectByDriver = () => {
    if (this.state.isStaff === true) {
      if (this.state.pin===""){
        swal.fire("info","Please select user","info");
      }else{
        this.setState({ loading: true });
      const data = {
        pin: this.state.pin
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CONNECT_ADMIN}?access_token=${token}`;

        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("success", response.data.message, "success");
            console.log("response from connect to org admin", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("error", error, "error");
            console.log("error from connect to org admin", error);
          });
      });
      }
      
    } else {
      if (this.state.pin===""||this.state.driver_id===""){
        swal.fire("info","Please select user","info");
      }else{
        this.setState({ loading: true });
      const data = {
        driver_id: this.state.driver_id,
        pin: this.state.pin
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CONNECT_DRIVER}${this.state.driver_id}?access_token=${token}`;

        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("success", response.data.message, "success");
            console.log("response from connect to org driver", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("error", error, "error");
            console.log("error from connect to org driver", error);
          });
      });
      }
      
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Connect User to Organization"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Select User</Text>
            <Select
              labelInValue
              value={{ key: this.state.driver_id, Label: this.state.name }}
              onChange={this.setValue}
              placeholder="Select User"
            >
              {this.state.userData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.connectByDriver}
            >
              Connect User
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
