import React, { Component } from "react";
import { Card } from "antd";
import qb from "../../assets/quickbooks.png";
import xero from "../../assets/xero.svg";
// import axios from "axios";
// import swal from "sweetalert2";
// import { ORGANIZATION_SETTINGS_LIST,GET_EMAIL_SETTINGS } from "../../api/constants";
// import { 
//     GET_ACCESS_TOKEN,
//     //  CHECK_BOOL
//      } from "../../utilities/utilities";

import "../login.css";

// const { Title } = Typography;
// const { Option } = Select;

export default class ConnectThirdParty extends Component {
  state = {
    org_setting_id: "",
    order_delivery_via_task: false,
  };

  componentDidMount() {
    // this.getOrganizationSettingsList();
  }

//   getOrganizationSettingsList = () => {
//     GET_ACCESS_TOKEN().then(token => {
//       const url = `${ORGANIZATION_SETTINGS_LIST}?access_token=${token}`;
//       axios({
//         method: "get",
//         url: url
//       })
//         .then(response => {
//           this.setState(
//             {
//               org_setting_id: response.data?.data[0]?._id.$oid ?? ""
//             },
//             () => {
//               this.getEmailSettings();
//             }
//           );
//           console.log("response from organization settings list", response);
//         })
//         .catch(error => {
//           console.log("error from organization settings list api", error);
//         });
//     });
//   };

//   getEmailSettings = () => {
//     GET_ACCESS_TOKEN().then(token => {
//       const url = `${GET_EMAIL_SETTINGS}${this.state.org_setting_id}?access_token=${token}`;
//       axios({
//         method: "get",
//         url: url
//       })
//         .then(response => {
//           this.setState({
//             order_delivery_via_task: response.data?.data?.order_delivery_via_task??false
//           });
//           console.log("response from email settings", response);
//         })
//         .catch(error => {
//           console.log("error from email settings api", error);
//         });
//     });
//   };


//   setOrderDeliveryViaTask = value => {
//     this.setState({ order_delivery_via_task: CHECK_BOOL(value) }, () => {
//       console.log(this.state.order_delivery_via_task);
//     });
//   };



//   updateEmailSettings = () => {
//     const data = {
//         order_delivery_via_task:this.state.order_delivery_via_task
//     };
//     console.log(data);
//     GET_ACCESS_TOKEN().then(token => {
//       let url = `${GET_EMAIL_SETTINGS}${this.state.org_setting_id}?access_token=${token}`;

//       axios({
//         method: "put",
//         url: url,
//         data: data,
//         headers: {
//           "content-type": "application/x-www-form-urlencoded"
//         }
//       })
//         .then(response => {
//           swal.fire("Info", response.data.message, "info");
//           this.getOrganizationSettingsList();
//           console.log("response from update email Settings", response);
//         })
//         .catch(error => {
//           swal.fire("Error", error.message, "error");
//           console.log("error from update email Settings", error);
//         });
//     });
//   };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <div style={{display:"flex",flexDirection:"row"}}>
        <a href="https://appcenter.intuit.com/connect/oauth2?client_id=ABVKOqwzRweKupnjiqseVx6zvDchIwE7j6cBHpLoQFXTpftUcC&redirect_uri=https://web.conveyance.app/home&response_type=code&scope=com.intuit.quickbooks.accounting openid profile email phone address&state=quickbooks">
            <img
              src={qb}
              alt=""
              style={{
                width: 100,
                position: "relative",
              }}
            />
          </a>
        <a style={{marginLeft:15}} href="https://login.xero.com/identity/connect/authorize?response_type=code&client_id=139C8E79475141F7B2803F193CA9CE78&redirect_uri=https://web.conveyance.app/home&scope=openid profile email offline_access accounting.transactions accounting.settings accounting.contacts&state=xero">
            <img
              src={xero}
              alt=""
              style={{
                width: 100,
                position: "relative",
              }}
            />
          </a>

            {/* <div style={{display:"flex"}}>
            <Title level={4}>Quickbooks : </Title>
              <Button
                style={{marginLeft:10,width:80,backgroundColor: "#1A3337", color: "white"}}
              
                onClick={()=>
                    window.open("https://appcenter.intuit.com/connect/oauth2?client_id=ABVKOqwzRweKupnjiqseVx6zvDchIwE7j6cBHpLoQFXTpftUcC&redirect_uri=https://web.conveyance.app/home&response_type=code&scope=com.intuit.quickbooks.accounting openid profile email phone address&state=quickbooks")
                }
              >
                Connect
              </Button>
            </div>
            <div style={{display:"flex",marginTop:10}}>
            <Title level={4}>Xero : </Title>
              <Button
                style={{marginLeft:10,width:80,backgroundColor: "#1A3337", color: "white"}}
               
                
                onClick={()=>
                    window.open("https://login.xero.com/identity/connect/authorize?response_type=code&client_id=139C8E79475141F7B2803F193CA9CE78&redirect_uri=https://web.conveyance.app/home&scope=openid profile email offline_access accounting.transactions accounting.settings accounting.contacts&state=xero")
                }
              >
                Connect
              </Button>
            </div> */}
       
        </div>
      </Card>
    );
  }
}
