import React, { Component } from "react";
import {
  Card,
  Button,
  Result
} from "antd";
import { FAIL_PAYMENT_INT } from "../../api/constants";
import axios from "axios";
// import swal from "sweetalert2";
import { Spinner } from "../loader";
import Logo from "../../assets/logo.png";

export default class FailInt extends Component {
    state={
        loading:false
    }
  componentDidMount() {
    console.log("bill id", this.props.history.location.state.tran_id);
    this.setState({ loading: true });
    let url = `${FAIL_PAYMENT_INT}${this.props.history.location.state.tran_id}`;
    axios({
        method: "get",
        url: url
      })
      .then(response => {
        this.setState({ loading: false });
        console.log("response from monthly bill fail paypal", response);
      
      })
      .catch(error => {
        this.setState({ loading: false });
        console.log("error from fail payment", error);
      });
   
  }
  render() {
    if (this.state.loading === true) {
        return <Spinner />;
      }
    return (
      <div>
      <div
       style={{
         textAlign: "center",
         paddingBottom: 10,
         paddingTop: 15,
         backgroundColor: "#006479"
       }}
     >
       <img src={Logo} alt="" style={{ width: 300 }} />
     </div>
     <Card style={{ margin: 10 }}>
         <Result
         status="error"
           title="Payment failed ! Please try Again."
           extra={
             <Button
               className="login-form-button"
               htmlType="submit"
               onClick={() =>
                 (window.location.href = `https://web.conveyance.app`)
               }
             >
               Home
             </Button>
           }
         />
       </Card>
   </div>
    );
  }
}
