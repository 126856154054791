import {SAVE_BILL_LIST_PARAMS} from "../actions/types";

const initialState={
    billListParamsData:{}
}


export default function(state=initialState,action){
    switch(action.type){
        case SAVE_BILL_LIST_PARAMS:
            return {
                ...state,
                billListParamsData:action.payload
            }
        default:
            return state;
    }
}