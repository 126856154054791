import { SAVE_USER_PROFILE, API_ERROR } from "./types";
import axios from "axios";
import { USER_PROFILE, EDIT_ROLE } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";

export const saveUserProfile = saveUserProfile => dispatch => {
  dispatch({
    type: SAVE_USER_PROFILE,
    payload: saveUserProfile
  });
};

export const apiError = apiError => dispatch => {
  dispatch({
    type: API_ERROR,
    payload: {}
  });
};

export const getUserData = () => {
  return dispatch => {
    return GET_ACCESS_TOKEN().then(token => {
      const url = `${USER_PROFILE}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          let user = response.data.role_id;
          console.log("response from user profile redux", response);
          GET_ACCESS_TOKEN().then(token => {
            let url = `${EDIT_ROLE}${user}?access_token=${token}`;
            axios({
              method: "get",
              url: url
            }).then(response => {
              if (response.data.status_code === 200) {
                let permissions = response.data.data.permissions;
                dispatch(saveUserProfile(permissions));
                console.log("response from user permission redux", permissions);
              } else {
                dispatch(apiError());
              }
            });
          });
        })
        .catch(error => {
          console.log("error from user permission redux", error);
        });
    });
  };
};
