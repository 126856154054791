import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  EDIT_SUPPLIER,
  CREATE_COUNTRY,
  EDIT_COUNTRY
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

export default class EditSupplier extends Component {
  state = {
    name: "",
    supplier: "",
    loc: { coordinates: [90.3563, 23.685] },
    address: "",
    city: "",
    country: "",
    post_code: "",
    supplier_id: "",
    cities: [],
    filtered_cities: [],
    countries: [],
    city_id: "",
    country_id: "",
    loading: false
  };
  componentDidMount() {
    this.countryList();
    this.getSupplier();
    this.setState({ supplier_id: this.props.location.state.supplier_id });
  }

  getSupplier = () => {
    GET_ACCESS_TOKEN().then(token => {
      let supplier_id = this.props.location.state.supplier_id;
      let url = `${EDIT_SUPPLIER}${supplier_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit supplier", response);
        this.setState({
          name: response.data.data.name,
          supplier: response.data.data.supplier,
          address: response.data.data.address,
          city: response.data.data.city,
          country: response.data.data.country,
          post_code: response.data.data.post_code
        });
      });
    });
  };

  countryList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 200;
      const url = `${CREATE_COUNTRY}?limit=${limit}&d=${false}&sort_by="name"&show="no"&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ countries: response.data.data }, () => {
            console.log("dropdown", this.state.countries);
          });
        })
        .catch(error => {
          console.log("error from country list api", error);
        });
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setSupplier = evt => {
    evt.preventDefault();
    this.setState({ supplier: evt.target.value }, () => {
      console.log(this.state.supplier);
    });
  };
  setAddress = evt => {
    evt.preventDefault();
    this.setState({ address: evt.target.value }, () => {
      console.log(this.state.address);
    });
  };
  handleSearch = value => {
    const result = this.state.cities.filter(city => {
      return city.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered_cities: result });
  };
  setCity = value => {
    this.setState({ city: value.label, city_id: value.key }, () => {
      console.log(this.state.city);
    });
  };
  setCountry = value => {
    this.setState({ country: value.label, country_id: value.key }, () => {
      console.log(this.state.country);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${EDIT_COUNTRY}${this.state.country_id}?access_token=${token}`;

        axios({
          method: "get",
          url: url
        }).then(response => {
          console.log("response fro edit country", response.data.data);
          this.setState({
            cities: response.data.data.cities
          });
        });
      });
    });
  };
  setPostcode = evt => {
    evt.preventDefault();
    this.setState({ post_code: evt.target.value }, () => {
      console.log(this.state.post_code);
    });
  };

  editSupplier = () => {
    this.setState({ loading: true });
    const data = {
      name: this.state.name,
      supplier: this.state.supplier,
      loc: this.state.loc,
      address: this.state.address,
      city: this.state.city,
      country: this.state.country,
      post_code: this.state.post_code
    };
    console.log(data);

    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_SUPPLIER}${this.state.supplier_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          console.log("response from edit supplier", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from edit supplier", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    const CityList = this.state.cities.map(r => {
      return <Option key={r.code}>{r.name}</Option>;
    });

    const FliteredList = this.state.filtered_cities.map(r => {
      return <Option key={r.code}>{r.name}</Option>;
    });
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Edit Supplier"
          onBack={() => this.props.history.goBack()}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Supplier</Text>
            <Input
              placeholder="Supplier"
              type="text"
              value={this.state.supplier}
              onChange={this.setSupplier}
            />
          </Form.Item>

          <Form.Item>
            <Text>Address</Text>

            <TextArea
              rows={4}
              placeholder="Address"
              type="text"
              value={this.state.address}
              onChange={this.setAddress}
            />
          </Form.Item>
          <Form.Item>
            <Text>Country</Text>
            <Select
              labelInValue
              value={{ key: this.state.country_id, label: this.state.country }}
              onChange={this.setCountry}
              placeholder="Select Country"
            >
              {this.state.countries.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Search City</Text>

            <Select
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.handleSearch}
              labelInValue
              disabled={this.state.country === "" ? true : false}
              value={{ key: this.state.city_id, label: this.state.city }}
              onChange={this.setCity}
              placeholder="Search City"
            >
              {this.state.filtered_cities.length === 0
                ? CityList
                : FliteredList}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Post Code</Text>

            <Input
              placeholder="Post Code"
              type="text"
              value={this.state.post_code}
              onChange={this.setPostcode}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editSupplier}
            >
              Update Supplier
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
