import React, { Component } from 'react';
import { Card, Form, Input, Button, Typography, PageHeader } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { EDIT_UNIT } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;

export default class EditUnit extends Component {
    state = {
        name: "",
        unit: "",
        unit_id:"",
        loading: false
      };
      componentDidMount() {
        this.setState({ unit_id: this.props.location.state.unit_id });
      
        GET_ACCESS_TOKEN().then(token => {
          let unit_id = this.props.location.state.unit_id;
          let url = `${EDIT_UNIT}${unit_id}?access_token=${token}`;
          axios({
            method: "get",
            url: url
          }).then(response => {
            console.log("response fro edit unit", response);
            this.setState({
                name: response.data.data.name,
                unit: response.data.data.unit
            });
          });
        });
      }
      
      setName = evt => {
        evt.preventDefault();
        this.setState({ name: evt.target.value }, () => {
          console.log(this.state.name);
        });
      };
      // setUnit = evt => {
      //   evt.preventDefault();
      //   this.setState({ unit: evt.target.value }, () => {
      //     console.log(this.state.unit);
      //   });
      // };
      
    
      editUnit = () => {
        this.setState({ loading: true });
        const data = {
          name: this.state.name,
          unit: this.state.name
        };
        console.log(data);
        GET_ACCESS_TOKEN().then(token => {
          let url = `${EDIT_UNIT}${this.state.unit_id}?access_token=${token}`;

          axios({
            method: "put",
            url: url,
            data: data,
            headers: { 
              'content-type': 'application/x-www-form-urlencoded' 
            }
          })
            .then(response => {
              this.setState({ loading: false });
              swal.fire("Info", response.data.message, "info");
              console.log("response from edit unit", response);
            })
            .catch(error => {
              this.setState({ loading: false });
              swal.fire("Error", error.message, "error");
              console.log("error from edit unit", error);
            });
        });
      };
      render() {
        if (this.state.loading === true) {
          return <Spinner />;
        }
        return (
          <Card style={{ margin: 10 }}>
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              title="Edit Unit"
              onBack={() => this.props.history.goBack()}
            />
            <Form
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column"
              }}
            >
              <Form.Item>
                <Text>Name</Text>
                <Input
                  placeholder="Name"
                  type="text"
                  value={this.state.name}
                  onChange={this.setName}
                />
              </Form.Item>
              {/* <Form.Item>
                <Text>Unit</Text>
                <Input
                  placeholder="Unit"
                  type="text"
                  value={this.state.unit}
                  onChange={this.setUnit}
                />
              </Form.Item> */}
    
              
    
              <Form.Item>
                <Button className="login-form-button" htmlType="submit" onClick={this.editUnit}>
                  Update Unit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        );
      }
}
