import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Tooltip, Tag } from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";

import { CREATE_ROASTER } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";


import "../login.css";

const { Column } = Table;

class RoasterList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false
  };
  componentDidMount() {
    this.props.getUserData();
    this.getRoasterList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

  }

  getRoasterList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ROASTER}?page=${this.state.page}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from roaster list", response);
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from roaster list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getRoasterList();
      }
    );
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.attendance.roaster.roaster_create?(
           <Link to="/createRoaster">
           <Tooltip placement="top" title="Create Roster">
           <Button shape="circle" icon="plus" className="tooltip-button" />
           </Tooltip>
         </Link>
        ):null}
       
        <PageHeader
          title="Rosters"
        />

        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Roster Name" dataIndex="name" key="name" />
          <Column title="Roster Start Date" dataIndex="from" key="from" />
          <Column title="Roster End Date" dataIndex="to" key="to" />
          <Column
            title="Assigned Users"
            dataIndex="assigned_users"
            key="assigned_users"
            render={assigned_users => (
              <span>
                {assigned_users.map(item => (
                  <Tag
                    color="purple"
                    key={item.id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2
                    }}
                  >
                    {`${item.name}>${item.mobile}`}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title="Roster Plan Names"
            render={(text, record) => (
              <span>
                <Tag color="blue" style={{ margin: 3 }}>
                  {record.roaster_plan.name}
                </Tag>
              </span>
            )}
          />
          <Column
            title="Shift Start Time"
            render={(text, record) => (
              <span>
                <Tag color="blue" style={{ margin: 3 }}>
                  {record.roaster_plan.start_time}
                </Tag>
              </span>
            )}
          />
          <Column
            title="Shift End Time"
            render={(text, record) => (
              <span>
                <Tag color="blue" style={{ margin: 3 }}>
                  {record.roaster_plan.end_time}
                </Tag>
              </span>
            )}
          />
          <Column
            title="Plan Timezone"
            render={(text, record) => (
              <span>
                <Tag color="blue" style={{ margin: 3 }}>
                  {record.roaster_plan.timezone_name}
                </Tag>
              </span>
            )}
          />

          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          {GET_USER_LOCAL().org_admin||this.props.userProfileData.attendance.roaster.roaster_update ? (
            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <span>
                  <Link
                    to={{
                      pathname: "/editRoaster",
                      state: { roaster_id: record._id.$oid }
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                </span>
              )}
            />
          ) : null}
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(RoasterList);
