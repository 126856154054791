import React from "react";
import { Redirect } from "react-router-dom";

import { Card, Typography, PageHeader } from "antd";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
// import DateFnsUtils from "@date-io/date-fns";
// import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { EDIT_DRIVER } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";


const { Text } = Typography;


export default class EditDriver extends React.Component {
  state = {
    name: "",
    email: "",
    mobile: "",
    email_verified: false,
    mobile_verified: false,
    password: "",
    address: "",
    city: "",
    country: "",
    security_id: "",
    organization: "",
    org_id: "",
    contact_no: "",
    emr_contact_name: "",
    blood_grp: "",
    lic_no: "",
    exp_date: moment(),
    exp_date_view: moment(),
    player_id: "",
    driver_id: "",
    data: [],
    cities: [],
    countries: [],
    city_id: "",
    country_id: "",
    loading: false,
    org_admin: false,
    is_operator: false,
    redirect: false,
    is_admin: false,
    is_staff: false,
    own_account: false,
    designation: "",
    created_on:"",
    is_active:true
  };

  componentDidMount() {
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    this.getUser();
    console.log(this.props.history);
    this.setState({
      driver_id: this.props.location.state.driver_id
    });
  }

  getUser = () => {
    GET_ACCESS_TOKEN().then(token => {
      let driver_id = this.props.location.state.driver_id;
      let url = `${EDIT_DRIVER}${driver_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit driver", response);
        if (response.data.message === "Driver not found") {
          swal.fire("Error", "User Not Found !", "error");
          this.setState({ redirect: true });
        } else {
          this.setState({
            name: response?.data?.data?.name,
            designation: response?.data?.data?.designation ?? "",
            email: response?.data?.data?.email,
            mobile: response?.data?.data?.mobile,
            email_verified: response?.data?.data?.email_verified??false,
            mobile_verified: response?.data?.data?.mobile_verified??false,
            // password: response.data.data.password,
            address: response?.data?.data?.address,
            // city: response.data.data.city,
            // country: response.data.data.country,
            security_id: response?.data?.data?.security_id,
            // organization: response.data.data.organizations[0].name,
            // org_id: response.data.data.organization.$oid,
            contact_no: response?.data?.data?.contact_no,
            emr_contact_name: response?.data?.data?.emr_contact_name,
            blood_grp: response?.data?.data?.blood_grp,
            lic_no: response?.data?.data?.lic_no,
            created_on: response?.data?.data?.created_on?.$date??"",
            // exp_date: response?.data?.data?.exp_date?.$date??"",
            // exp_date_view: response?.data?.data?.exp_date?.$date,
            player_id: response?.data?.data?.player_id ?? "",
            is_operator: response?.data?.data?.is_operator??false,
            org_admin: response?.data?.data?.org_admin ?? false,
            is_active: response?.data?.data?.is_active ?? false,
            is_staff: response?.data?.data?.is_staff ?? false
          });
        }
      });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    if (this.state.redirect === true) {
      return <Redirect to="/userList" />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title="View User"
        />
        <Card
          style={{
            marginTop: 5,
            width: "100%",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}
        >
        <p >
          <Text style={{ fontWeight: "bold" }}>User Name</Text>:{" "}
          {this.state.name}
        </p>
        <p >
          <Text style={{ fontWeight: "bold" }}>Designation</Text>:{" "}
          {this.state.designation}
        </p>
        <p >
          <Text style={{ fontWeight: "bold" }}>Mobile</Text>:{" "}
          {this.state.mobile}
        </p>
        <p >
          <Text style={{ fontWeight: "bold" }}>Mobile Verified</Text>:{" "}
          {this.state.mobile_verified.toString()}
        </p>
        <p >
          <Text style={{ fontWeight: "bold" }}>E-mail</Text>:{" "}
          {this.state.email}
        </p>
        <p >
          <Text style={{ fontWeight: "bold" }}>E-mail Verified</Text>:{" "}
          {this.state.email_verified.toString()}
        </p>
        <p >
          <Text style={{ fontWeight: "bold" }}>Address</Text>:{" "}
          {this.state.address}
        </p>
        <p >
          <Text style={{ fontWeight: "bold" }}>Contact Number</Text>:{" "}
          {this.state.contact_no}
        </p>
        <p >
          <Text style={{ fontWeight: "bold" }}>Active</Text>:{" "}
          {this.state.is_active.toString()}
        </p>
        <p >
          <Text style={{ fontWeight: "bold" }}>Staff</Text>:{" "}
          {this.state.is_staff.toString()}
        </p>
        <p >
          <Text style={{ fontWeight: "bold" }}>Admin</Text>:{" "}
          {this.state.org_admin.toString()}
        </p>
        <p >
          <Text style={{ fontWeight: "bold" }}>Operator</Text>:{" "}
          {this.state.is_operator.toString()}
        </p>
        <p >
          <Text style={{ fontWeight: "bold" }}>Member Since</Text>:{" "}
          {timeConverter(this.state.created_on)}
        </p>
        </Card>
      </Card>
    );
  }
}
