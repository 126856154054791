import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { EDIT_MTYPE,CREATE_BILL_TYPE } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class editMtype extends Component {
  state = {
    name: "",
    // mtype: "",
    loading: false,
    mtype_id: "",
    data: [],
    total: "",
    bill_type_id: "",
    bill_type_name: ""
  };
  componentDidMount() {
    this.getMtype();
    this.getBillTypeList();
    this.setState({ mtype_id: this.props.location.state.mtype_id });
  }

  getBillTypeList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_BILL_TYPE}?limit=50&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from mtype list", response);

          this.setState({
            data: response.data.data,
            total: response.data.total
          });
        })
        .catch(error => {
          console.log("error from mtype list api", error);
        });
    });
  };

  getMtype = () => {
    GET_ACCESS_TOKEN().then(token => {
      let mtype_id = this.props.location.state.mtype_id;
      let url = `${EDIT_MTYPE}${mtype_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit mtype", response);
        this.setState({
          name: response.data.data.name,
          // mtype: response.data.data.mtype,
          bill_type_id: response.data.data.bill_type.id,
          bill_type_name: response.data.data.bill_type.name
        });
      });
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  // setMtype = evt => {
  //   evt.preventDefault();
  //   this.setState({ mtype: evt.target.value }, () => {
  //     console.log(this.state.mtype);
  //   });
  // };

  setBillType = value => {
    this.setState(
      { bill_type_name: value.label, bill_type_id: value.key },
      () => {
        console.log(this.state.bill_type_name);
      }
    );
  };

  editMtype = () => {
    this.setState({ loading: true });
    const data = {
      name: this.state.name,
      // mtype: this.state.mtype,
      bill_type:{
        id:this.state.bill_type_id,
        name:this.state.bill_type_name
      }
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_MTYPE}${this.state.mtype_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          console.log("response from edit mtype", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from edit mtype", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Edit Item type"
          onBack={() => this.props.history.goBack()}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          {/* <Form.Item>
            <Text>Material Type</Text>
            <Input
              placeholder="Material Type"
              type="text"
              value={this.state.mtype}
              onChange={this.setMtype}
            />
          </Form.Item> */}
          <Form.Item>
            <Text>Select Bill Type</Text>
            <Select
              labelInValue
              value={{
                key: this.state.bill_type_id,
                label: this.state.bill_type_name
              }}
              onChange={this.setBillType}
              placeholder="Select Bill Type"
            >
              {this.state.data.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editMtype}
            >
              Update Item Type
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
