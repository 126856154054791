import React from "react";
import Logo from "../assets/icon.png";

export const Spinner = () => (
  <div
    style={{
      position: "absolute",
      top: window.innerHeight / 2.3,
      bottom: 0,
      left: window.innerWidth / 2,
      right: 0,
      margin: "auto"
    }}
  >
    <img src={Logo} alt="" style={{ width: 100, borderRadius: 100 }} />
    <p></p>
  </div>
);
