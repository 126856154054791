import React, { Component } from "react";
import { Card, PageHeader, Table, Typography, Select, Button } from "antd";
// import { Link } from "react-router-dom";
import axios from "axios";
// import swal from "sweetalert2";
import moment from "moment";
import FileSaver from "file-saver";
import {
  INVENTORY_PRODUCT_CLOSING_TIME_LIST,
  INVENTORY_PRODUCT_CLOSING_LIST,
  INVENTORY_CLOSING_HISTORY_REPORT,
} from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";

import "../login.css";

const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;

class ViewClosingHistory extends Component {
  state = {
    inventory_closing_times: [],
    inventory_closing_time: "",
    inventory_closing_time_label: "",
    inventory_id: "",
    inventory_closing_list: [],
    inventory_entity_name: "",
    inventory_previous_last_closing_value: null,
    inventory_total_added_stock_after_adjustment_value: null,
    inventory_total_wasted_value: null,
    inventory_total_delivered_value: null,
    inventory_total_subtracted_without_adjustment_value: null,
    inventory_last_closing_value: null,
    inventory_last_closing_time: "",
    loading: false,
  };

  componentDidMount() {
    this.setState({ inventory_id: this.props.location.state.inventory_id });
    this.getInventoryClosingTimes();
  }

  getInventoryClosingTimes = () => {
    this.setState({ loading: true });
    let inventory_id = this.props.location.state.inventory_id;
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${INVENTORY_PRODUCT_CLOSING_TIME_LIST}${inventory_id}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from inventory closing time list", response);
          this.setState(
            {
              inventory_closing_times:
                response?.data?.distinct_inventory_closing_history_date_data ??
                [],
              inventory_closing_time:
                response?.data?.distinct_inventory_closing_history_date_data[0]
                  ?.$date ?? "",
              inventory_closing_time_label: response?.data
                ?.distinct_inventory_closing_history_date_data[0]?.$date
                ? timeConverter(
                    response.data
                      .distinct_inventory_closing_history_date_data[0].$date
                  )
                : "",
            },
            () => {
              this.getInventoryClosingList();
            }
          );
        })
        .catch((error) => {
          console.log("error from inventory closing time list", error);
        });
    });
  };
  getInventoryClosingList = () => {
    this.setState({ loading: true });
    let inventory_id = this.props.location.state.inventory_id;
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${INVENTORY_PRODUCT_CLOSING_LIST}${inventory_id}?inventory_last_closing_time=${this.state.inventory_closing_time}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from inventory closing list", response);
          this.setState({
            inventory_closing_list:
              response?.data?.inv_prod_closing_hist_data ?? [],
            inventory_entity_name:
              response?.data?.inventory_closing_history_data
                ?.inventory_entity_name ?? "",
            inventory_previous_last_closing_value:
              response?.data?.inventory_closing_history_data
                ?.inv_prev_last_closing_val ?? null,
            inventory_total_added_stock_after_adjustment_value:
              response?.data?.inventory_closing_history_data
                ?.inv_sum_adjusted_added_val ?? null,
            inventory_total_wasted_value:
              response?.data?.inventory_closing_history_data
                ?.inv_sum_wasted_val ?? null,
            inventory_total_delivered_value:
              response?.data?.inventory_closing_history_data
                ?.inv_sum_delivered_val ?? null,
            inventory_total_subtracted_without_adjustment_value:
              response?.data?.inventory_closing_history_data
                ?.inv_sum_adjusted_subtracted_val ?? null,
            inventory_last_closing_value:
              response?.data?.inventory_closing_history_data
                ?.inv_last_closing_val ?? null,
            inventory_last_closing_time:
              response?.data?.inventory_closing_history_data
                ?.inv_last_closing_time?.$date ?? "",
          });
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log("error from inventory closing list", error);
        });
    });
  };

  downloadExcelreport = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${INVENTORY_CLOSING_HISTORY_REPORT}${this.state.inventory_id}?inventory_last_closing_time=${this.state.inventory_last_closing_time}&timezone_name=Asia/Dhaka&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log(
            "response from inventory last closing history report fmt",
            blob
          );
          FileSaver.saveAs(
            blob,
            `inventory_closing_history_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          console.log(
            "error from inventory last closing history report api",
            error
          );
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          onBack={() => this.props.history.goBack()}
          title="Inventory Closing History Data"
        />
        <Card
          style={{
            width: "100%",
          }}
        >
          <p >
            <Text style={{ fontWeight: "bold" }}>Inventory Name</Text>:{" "}
            {this.state.inventory_entity_name}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}> Initial Value</Text>:{" "}
            {this.state.inventory_previous_last_closing_value}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}> Total Added Stock Value</Text>
            : {this.state.inventory_total_added_stock_after_adjustment_value}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}> Wasted Stock Value</Text>:{" "}
            {this.state.inventory_total_wasted_value}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}> Delivered Stock Value</Text>:{" "}
            {this.state.inventory_total_delivered_value}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>
              {" "}
              Total Subtracted Stock Value
            </Text>
            : {this.state.inventory_total_subtracted_without_adjustment_value}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}> Inventory Closing Value</Text>
            : {this.state.inventory_last_closing_value}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}> Inventory Closing Date</Text>:{" "}
            {timeConverter(this.state.inventory_last_closing_time)}
          </p>
        </Card>
        <Card>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginTop: 5, marginBottom: 5, width: 200 }}>
              <Text>Select Closing Time to Filter</Text>
              <Select
                style={{ width: "100%" }}
                labelInValue
                value={{
                  key: this.state.inventory_closing_time,
                  label: this.state.inventory_closing_time_label,
                }}
                onChange={(value) =>
                  this.setState(
                    {
                      inventory_closing_time: value.key,
                      inventory_closing_time_label: value.label,
                    },
                    () => this.getInventoryClosingList()
                  )
                }
                placeholder="Remarks"
              >
                {this.state.inventory_closing_times.map((r) => {
                  return (
                    <Option key={r.$date}>{timeConverter(r.$date)}</Option>
                  );
                })}
              </Select>
            </div>
            {this.props.userProfileData.report.inventory_report.inventory_closing_history_report?(
               <Button
               style={{
                 backgroundColor: "#1A3337",
                 color: "white",
                 margin: 15,
                 marginTop: 25,
               }}
               onClick={() => {
                 this.downloadExcelreport();
               }}
             >
               Download Report
             </Button>
            ):null}
           
          </div>

          <Table
            dataSource={this.state.inventory_closing_list}
            pagination={false}
            rowKey={(record) => record._id.$oid}
            size="small"
            bordered
          >
            <Column
              title="Product Name"
              dataIndex="product_name"
              key="product_name"
            />
            <Column
              title="Initial Quantity"
              dataIndex="prev_last_closing_qty"
              key="prev_last_closing_qty"
            />
            <Column
              title="Initial Value"
              dataIndex="prev_last_closing_val"
              key="prev_last_closing_val"
            />
            <Column
              title="Added Stock quantity"
              dataIndex="sum_adjusted_added_qty"
              key="sum_adjusted_added_qty"
            />
            <sum_wasted_val
              title="Added Stock Value"
              dataIndex="sum_adjusted_added_val"
              key="sum_adjusted_added_val"
            />
            <Column
              title="Wasted Stock Quantity"
              dataIndex="sum_wasted_qty"
              key="sum_wasted_qty"
            />
            <Column
              title="Wasted Stock Value"
              dataIndex="sum_wasted_val"
              key="sum_wasted_val"
            />
            <Column
              title="Delivered Stock Quantity"
              dataIndex="sum_delivered_qty"
              key="sum_delivered_qty"
            />
            <Column
              title="Delivered Stock Value"
              dataIndex="sum_delivered_val"
              key="sum_delivered_val"
            />

            <Column
              title="Subtracted Stock Quantity"
              dataIndex="sum_adjusted_subtracted_qty"
              key="sum_adjusted_subtracted_qty"
            />
            <Column
              title="Subtracted Stock Value"
              dataIndex="sum_adjusted_subtracted_val"
              key="sum_adjusted_subtracted_val"
            />
            <Column
              title="Closing Quantity"
              dataIndex="last_closing_qty"
              key="last_closing_qty"
            />
            <Column
              title="Closing Value"
              dataIndex="last_closing_val"
              key="last_closing_val"
            />

            <Column
              title="Created On"
              key="created_by"
              render={(text, record) => (
                <span>{timeConverter(record.created_on.$date)}</span>
              )}
            />
          </Table>
        </Card>

        {/* {this.state.stock_update_type === "add" ? (
              <Form.Item>
                <Text>Remarks</Text>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.remarks}
                  onChange={value => this.setState({ remarks: value })}
                  placeholder="Remarks"
                >
                  {this.state.add_reason_options.map(r => {
                    return <Option key={r.name}>{r.label}</Option>;
                  })}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item>
                <Text>Remarks</Text>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.remarks}
                  onChange={value => this.setState({ remarks: value })}
                  placeholder="Remarks"
                >
                  {this.state.remove_reason_options.map(r => {
                    return <Option key={r.name}>{r.label}</Option>;
                  })}
                </Select>
              </Form.Item>
            )}

            <Form.Item>
              <Button
                disabled={this.state.disbale_update_stock_button}
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateStock}
              >
                Update
              </Button>
            </Form.Item>
          </Form> */}
      </Card>
    );
  }
}
const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {  getUserData })(
  ViewClosingHistory
);