import React, { Component } from "react";
import { Card, Typography } from "antd";

import icon from "../assets/icon.png";

const { Text } = Typography;

export default class About extends Component {
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <div style={{ textAlign: "center", paddingBottom: 10 }}>
          <img src={icon} alt="" style={{hight:100,width:100}} />
        </div>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Text style={{ fontSize: 50 }}>Contact us</Text>
          <Text style={{ fontSize: 18 }}>Monico Bhaban, House No. 26-27</Text>
          <Text style={{ fontSize: 18 }}>Adarsha Chaya Neer</Text>
          <Text style={{ fontSize: 18 }}>Ring Road Adabor, </Text>
          <Text style={{ fontSize: 18 }}>Dhaka - 1207,Bangladesh</Text>
          <Text style={{ fontSize: 18 }}>Phone: +880-2-58157212, +880-2-9131073</Text>
          <Text style={{ fontSize: 18 }}>OR drop us a line at info@monicotechnologies.com </Text>
        </div>
      </Card>
    );
  }
}
