import React, { Component } from "react";
import { Card, Tabs,Typography } from "antd";

import MyOrders from "./myOrders";
import ReactGA from "react-ga4";


import "../login.css";

const { TabPane } = Tabs;
const { Title } = Typography;

export default class OrderList extends Component {
  state = {
    activeKeyOrder: "1"
  };

  componentDidMount() {
    let keyOrder = JSON.parse(localStorage.getItem("activeKeyOrder"));
    if (keyOrder) {
      this.setState({ activeKeyOrder: keyOrder });
    }
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  onChange = activeKeyOrder => {
    console.log(`onChange ${activeKeyOrder}`);
    this.setState(
      {
        activeKeyOrder
      },
      () => {
        localStorage.setItem(
          "activeKeyOrder",
          JSON.stringify(this.state.activeKeyOrder)
        );
      }
    );
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
         <Title style={{marginLeft:15}} level={3}>Order List</Title>
        <Tabs
          destroyInactiveTabPane={true}
          activeKey={this.state.activeKeyOrder}
          onChange={this.onChange}
        >
          <TabPane tab="My Orders" key="1">
            <MyOrders param="get_created_by"/>
          </TabPane>
          {/* <TabPane tab="Assigned Orders" key="2">
            <MyOrders param="get_assigned_org"/>
          </TabPane> */}
          <TabPane tab="Organization Orders" key="3">
            <MyOrders param="get_organization"/>
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}
