import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Result,
  Layout,
  Select
} from "antd";
import Countdown from "react-countdown";
import axios from "axios";
import swal from "sweetalert2";
import { GET_INVITE, JOIN_INVITE } from "../../api/constants";
// import { timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import { countryCode } from "../countryCode";

import "../login.css";
import Logo from "../../assets/logo.png";
import Play from "../../assets/play.png";

// const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
const { Footer } = Layout;

const Completionist = () => <span>Invitation Expired!</span>;

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        <Text>Invitation Expires In :</Text> {hours}:{minutes}:{seconds}
      </span>
    );
  }
};

export default class JoinInvitation extends Component {
  state = {
    name: "",
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
    invitation_id: "",
    org: "",
    org_name: "",
    is_operator: false,
    tz: "",
    expires_on: "",
    show_downlaod: false,
    data: [],
    loading: false,
    countryCode: [],
    code: "+880"
  };
  componentDidMount() {
    this.setState({
      invitation_id: this.props.match.params.invitation_id,
      countryCode: countryCode
    });
    let date = new Date();
    let time = date.getTimezoneOffset();
    this.setState({ tz: time });
    this.getInvitation();
  }

  getInvitation = () => {
    this.setState({ loading: true });
    let url = `${GET_INVITE}${this.props.match.params.invitation_id}`;
    axios({
      method: "get",
      url: url
    })
      .then(response => {
        this.setState({ loading: false });
        console.log("response from get invitation", response);
        if (response.data.status === "error") {
          swal.fire("Error", response.data.message, "error");
        } else {
          this.setState({
            name: response.data.data.name,
            email: response.data.data.email,
            mobile: response.data.data.mobile,
            is_operator: response.data.data.is_operator,
            org: response.data.data.org.$oid,
            org_name: response.data.data.org_name,
            expires_on: response.data.data.expires_on.$date
          });
        }
      })
      .catch(error => {
        this.setState({ loading: false });
        console.log("error from get invite", error);
      });
  };

  setEmail = evt => {
    evt.preventDefault();
    this.setState({ email: evt.target.value }, () => {
      console.log(this.state.email);
    });
  };
  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setCode = value => {
    this.setState({ code: value }, () => {
      console.log(this.state.code);
    });
  };
  setMobile = evt => {
    evt.preventDefault();
    this.setState({ mobile: evt.target.value }, () => {
      console.log(this.state.mobile);
    });
  };
  setPassword = evt => {
    evt.preventDefault();
    this.setState({ password: evt.target.value }, () => {
      console.log(this.state.password);
    });
  };
  setcPassword = evt => {
    evt.preventDefault();
    this.setState({ confirmPassword: evt.target.value }, () => {
      console.log(this.state.confirmPassword);
    });
  };

  joinInvite = () => {
    if (this.state.password === "") {
      swal.fire("info", "Please enter all informations", "info");
    } else if (this.state.password.length < 6) {
      swal.fire("Error", "Password must be atleast 6 letters", "error");
    } else{
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        email: this.state.email,
        mobile: this.state.mobile!==""? this.state.code + this.state.mobile:"",
        org: this.state.org,
        is_operator: this.state.is_operator,
        password: this.state.password,
        tz: this.state.tz
      };
      console.log(data);

      let url = `${JOIN_INVITE}${this.state.invitation_id}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });
          if (response.data.status === "success") {
            this.setState({ show_downlaod: true });
          }
          swal.fire("Info", response.data.message, "info");
          console.log("response from join inviatation", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from join inviatation", error);
        });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            paddingBottom: 10,
            paddingTop: 15,
            backgroundColor: "#006479"
          }}
        >
          <img src={Logo} alt="" style={{ width: 300 }} />
        </div>
        <div style={{ textAlign: "center", fontSize: 50 }}>
          {this.state.expires_on !== "" ? (
            <Countdown
              date={Date.now() + this.state.expires_on}
              renderer={renderer}
            />
          ) : null}
        </div>
        {this.state.expires_on === "" ? (
          <Card style={{ margin: 10 }}>
            <Result
              title="Your Invitation Has Expired"
              extra={
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={() =>
                    (window.location.href = `https://web.conveyance.app`)
                  }
                >
                  Home
                </Button>
              }
            />
          </Card>
        ) : (
          <div>
            {this.state.show_downlaod === true ? (
              <Card style={{ margin: 10 }}>
                <Result
                  status="success"
                  title="Invitation Accepted Successfully"
                  //  extra={
                  //    <Button
                  //      className="login-form-button"
                  //      htmlType="submit"
                  //      onClick={() =>
                  //        (window.location.href = `https://web.conveyance.app`)
                  //      }
                  //    >
                  //      Home
                  //    </Button>
                  //  }
                />
              </Card>
            ) : (
              <Card style={{ margin: 10 }}>
                <PageHeader
                  style={{
                    border: "1px solid rgb(235, 237, 240)"
                  }}
                  title={`Just one more step and you are good to go.Please type in your password for your account to join "${this.state.org_name}"`}
                />
                <Form
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    flexDirection: "column"
                  }}
                >
                  <Form.Item>
                    <Text>Name</Text>
                    <Input
                      placeholder="Name"
                      type="text"
                      value={this.state.name}
                      onChange={this.setName}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Email</Text>
                    <Input
                      placeholder="Email"
                      type="text"
                      value={this.state.email}
                      onChange={this.setEmail}
                    />
                  </Form.Item>
                  
                  <Form.Item>
                    <Text>Mobile</Text>
                    <Select
                      value={this.state.code}
                      onChange={this.setCode}
                      placeholder="Select Coode"
                    >
                      {this.state.countryCode.map(r => {
                        return (
                          <Option key={r.dial_code}>
                            {r.name} {r.dial_code}
                          </Option>
                        );
                      })}
                    </Select>
                    <Input
                      placeholder="Example-1711123456"
                      type="number"
                      value={this.state.mobile}
                      onChange={this.setMobile}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>
                      Password ( If you are already registered, enter your old
                      password )
                    </Text>
                    <Input
                      placeholder="Password"
                      type="password"
                      value={this.state.password}
                      onChange={this.setPassword}
                    />
                  </Form.Item>
                  {/* <Form.Item>
              <Text>Confirm Password</Text>
              <Input
                placeholder="Confirm Password"
                type="password"
                value={this.state.confirmPassword}
                onChange={this.setcPassword}
              />
            </Form.Item> */}

                  <Form.Item>
                    <Button
                      className="login-form-button"
                      htmlType="submit"
                      onClick={this.joinInvite}
                    >
                      Join Now
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            )}
          </div>
        )}
        {this.state.expires_on === "" ? null : (
          <Footer
            style={{
              textAlign: "center",
              backgroundColor: "#006479",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            {this.state.show_downlaod === true ? (
              <div>
                {this.state.is_operator === false ? (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      flexDirection: "column"
                    }}
                  >
                    <Text style={{ color: "white", fontSize: 15 }}>
                      Conveyance Admin App
                    </Text>
                    <a href="https://play.google.com/store/apps/details?id=com.mtl.conveyance&hl=en">
                      <img
                        src={Play}
                        alt=""
                        style={{ width: 200, height: 70, alignSelf: "center" }}
                      />
                    </a>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      flexDirection: "column"
                    }}
                  >
                    <Text style={{ color: "white", fontSize: 15 }}>
                      Conveyance Operator App
                    </Text>
                    <a href="https://play.google.com/store/apps/details?id=com.mtl.conveyance.operator&hl=en_US">
                      <img
                        src={Play}
                        alt=""
                        style={{ width: 200, height: 70, alignSelf: "center" }}
                      />
                    </a>
                  </div>
                )}
              </div>
            ) : null}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                justifyItems: "center"
              }}
            >
              <a href="https://web.conveyance.app">
                <img
                  src={Logo}
                  alt=""
                  style={{ width: 160, height: 41, alignSelf: "center" }}
                />
              </a>
              <Text style={{ color: "white", fontSize: 15 }}>
                Monico Technologies LTD
              </Text>
              <Text style={{ color: "white", fontSize: 15 }}>
                26-27, Ringroad Adabor, Dhaka
              </Text>
              <Text style={{ color: "#b0bec5", fontSize: 15 }}>
                © All rights reserved by Monico Technologies LTD, 2020
              </Text>
            </div>
          </Footer>
        )}
      </div>
    );
  }
}
