import React, { Component } from "react";
import {
  Table,
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Modal,
  Icon,
  Tag,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import { debounce } from "lodash";
import {
  CREATE_ENTITY,
  ORDER_ITEM_UPDATE,
  REVERSE_LOCATION_SEARCH_GOOGLE,
  PROMOTIONS_FOR_PRODUCT,
  CREATE_PRE_ORDER_PROMOTIONS,
  DEDUCT_APPLIED_PROMOTION,
  PRE_ORDER_APPLIED_PROMOTIONS,
  IS_PROMOTION_APPLICABLE,
  GET_FREE_PROMOTION_PRODUCT,
  MULTIPLE_ORDER_DETAILS_WITH_CHILD_PRODUCT,
  EDIT_PRODUCT,
} from "../../api/constants";

import {
  GET_ACCESS_TOKEN,
  CHECK_BOOL,
  randomId,
  timeConverter,
  // GET_USER_LOCAL
} from "../../utilities/utilities";

import "../login.css";
import "../order.css";

const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

class EditMultipleOrder extends Component {
  state = {
    order_number: "",
    name: "",
    details: "",
    items: [],
    itemObj: {},
    item: "",
    price: null,
    qty: 0,
    unit: "",
    drop_contact_name: "",
    drop_contact_no: "",
    drop_contact_email: "",
    start_entity_name: "",
    start_entity_key: "",
    end_entity_name: "",
    end_entity_key: "",
    end_loc: "",
    end_loc_name: "",
    delivery: true,
    delivered: false,
    rescheduled: false,
    startObj: {},
    endObj: {},
    pl: null,
    pw: null,
    ph: null,
    assigned_org_name: "",
    assigned_org_id: "",
    parcel_wght: null,
    total_price: null,
    delivery_charge: null,
    delivery_charge_included: true,
    other_charges: null,
    bill_amount: null,
    received_amount: null,
    due_amount: null,
    // received: false,
    delivery_date_time: moment(),
    delivery_date_time_view: moment(),
    modify_on: "",
    data: [],
    dataS: [],
    orgList: [],
    loading: false,
    visible: false,
    showMap: false,
    showEntity: false,
    showProduct: false,
    delivery_time_flexible: false,
    other_discount_type: "amount",
    other_discount: 0,
    other_discount_category: "manual",
    total_price_before_discount: null,
    total_discount: null,

    query: "",
    start_location: "",
    value: [],
    dataMap: [],
    fetch: false,
    center_lat: 23.685,
    center_lon: 90.3563,
    end_location_lat: 23.685,
    end_location_lon: 90.3563,
    editEnd: false,
    zoom: 15,
    get_connected_orgs: true,
    products: [],
    edit: false,

    unit_dim: null,
    unit_weight: null,
    max_weight: null,
    fragile: false,
    sensitive: false,
    fast: false,
    temperature: null,
    ucp: false,
    filtered: [],
    filtered_items: [],
    selected_items: [],
    order_item_edit_modal: false,
    order_id: "",
    parent_product_id: "",

    item_price: 0,
    item_unit_selling_price: 0,
    item_purchase_qty: 0,
    item_free_qty: 0,
    item_name: "",
    item_unit: "",
    item_id: "",
    item_parent_id: "",
    item_sku: "",
    item_unit_purchase_price: 0,
    item_mrp: 0,
    item_inventory_id: "",
    item_discount_type: "amount",
    item_discount: 0,
    item_raw_discount: 0,
    item_returned: false,
    return_item_qty: 0,

    promotional_item_price: 0,
    promotional_item_unit_selling_price: 0,
    promotional_item_purchase_qty: 0,
    promotional_item_free_qty: 0,
    promotional_item_name: "",
    promotional_item_unit: "",
    promotional_item_id: "",
    promotional_item_parent_id: "",
    promotional_item_sku: "",
    promotional_item_unit_purchase_price: 0,
    promotional_item_mrp: 0,
    promotional_item_inventory_id: "",
    promotional_item_discount_type: "amount",
    promotional_item_discount: 0,
    promotional_item_raw_discount: 0,
    promotional_item_returned: false,
    promotional_return_item_qty: 0,

    free_item_price: 0,
    free_item_unit_selling_price: 0,
    free_item_purchase_qty: 0,
    free_item_free_qty: 0,
    free_item_name: "",
    free_item_unit: "",
    free_item_id: "",
    free_item_parent_id: "",
    free_item_sku: "",
    free_item_unit_purchase_price: 0,
    free_item_mrp: 0,
    free_item_inventory_id: "",
    free_item_discount_type: "amount",
    free_item_discount: 0,
    free_item_raw_discount: 0,
    free_item_returned: false,
    free_return_item_qty: 0,

    total_refund: 0,
    payment_method_choices: [],
    discount_type_choices: [],
    received_method: "cash",
    received_date: Date.now(),
    received_date_view: moment(),
    total_received_amount: null,
    refunded_amount: null,
    refunded_date: Date.now(),
    refunded_date_view: moment(),
    refunded_method: "cash",
    total_refunded_amount: null,
    refundable_amount: null,
    show_received_modal: false,
    show_refund_modal: false,
    inventory_data: [],
    inventory_id: "",
    inventory_name: "",
    current_loc_lat: 23.685,
    current_loc_lon: 90.3563,
    current_loc_name: "",
    active_order_statuses: [],
    seq_no: "",
    status_name: "",
    org_setting_id: "",
    assigned_user_sub_orgs: [],
    sub_org_id: "",
    sub_org_name: "",
    allow_zero_stock: false,
    stock_quantity_type: "none",
    product_categories: [],
    category_name: "",
    barcode_search: false,
    promotions_for_product: [],
    applied_promotions: [],
    promotional_qty: 0,
    showProductPromotions: false,
    applied_promotion: {},
    new_apply_promotion: {},
    promotional_items: [],
    non_promotional_items: [],
    free_items: [],
    promotional_free_items_data_from_api: [],
    multiple_order_details: [],
    stock_quantity: 0,
    ordered_quantity: 0,
  };

  componentDidMount() {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState(
        {
          current_loc_lat: latitude,
          current_loc_lon: longitude,
        },
        () => {
          GET_ACCESS_TOKEN().then((token) => {
            const url = `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.current_loc_lat}&lng=${this.state.current_loc_lon}&access_token=${token}`;
            axios({
              method: "get",
              url: url,
            }).then((response) => {
              console.log("reverse api response", response);
              let name = response.data.google_loc_data[0].address;
              this.setState({
                current_loc_name: name,
              });
            });
          });
        }
      );
    };
    navigator.geolocation.getCurrentPosition(success);
    this.props.getUserData();
    this.getChildProductOrderDetails();
    this.getProductDetails();
  }

  getProductDetails = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let product_id = this.props.location.state.item_id;
      let url = `${EDIT_PRODUCT}${product_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro view product", response);
        this.setState({
          stock_quantity: response.data?.data?.stock_quantity ?? 0,
          ordered_quantity: response.data?.data?.ordered_quantity ?? 0,
        });
      });
    });
  };

  getChildProductOrderDetails = () => {
    let data = {
      order_ids: this.props.location.state.order_ids,
    };
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${MULTIPLE_ORDER_DETAILS_WITH_CHILD_PRODUCT}${this.props.location.state.item_id}?access_token=${token}`;
      axios({
        method: "put",
        data: data,
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            multiple_order_details: response.data?.data ?? [],
          });
          console.log("response from child order details", response);
        })
        .catch((error) => {
          console.log("error from child order details", error);
        });
    });
  };

  getShopList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?selected_sub_org_for_drop_off_address=${this.state.sub_org_id}&limit=100&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("shop list api", response);
          this.setState({ dataS: response.data.data });
        })
        .catch((error) => {
          console.log("error from shop list api", error);
        });
    });
  };

  searchPlace = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from place list", response);
          this.setState({
            dataS: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from place list api", error);
        });
    });
  }, 500);

  calculateDiscount = () => {
    if (this.state.other_discount_type === "amount") {
      this.setState({
        total_price:
          Number(this.state.total_price) - Number(this.state.other_discount),
      });
    } else {
      let percentValue =
        (this.state.total_price * this.state.other_discount) / 100;
      this.setState({
        total_price: Number(this.state.total_price) - Number(percentValue),
      });
    }
  };

  createPreOrderPromotion = () => {
    let data = {
      order_number: this.state.order_number,
    };

    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_PRE_ORDER_PROMOTIONS}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          console.log("response from create pre order promotion ", response);
          this.getPreOrderAppliedPromotion();
        })
        .catch((error) => {
          console.log("error from create pre order promotion ", error);
        });
    });
  };

  getPreOrderAppliedPromotion = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PRE_ORDER_APPLIED_PROMOTIONS}?order_number=${this.state.order_number}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            applied_promotions: response.data.data[0].applied_promotions,
          });
          console.log("response from pre order applied promotions", response);
        })
        .catch((error) => {
          console.log("error from pre order applied promotions", error);
        });
    });
  };

  getPromotionsForProducts = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PROMOTIONS_FOR_PRODUCT}${this.state.item_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState(
            {
              promotions_for_product:
                response.data?.data?.related_promotions ?? [],
            },
            () => {
              if (this.state.item_discount_type === "free_product") {
                let filtered = this.state.applied_promotions.filter((ap) =>
                  this.state.promotions_for_product.some(
                    (pp) =>
                      pp.product_qty_discount_tier.id === ap.applied_tier_row.id
                  )
                );
                this.getFreeProductDetails(
                  filtered[0].applied_tier_row.free_product
                );
              }
            }
          );
          console.log("response from promotions for product", response);
        })
        .catch((error) => {
          console.log("error from promotions for product", error);
        });
    });
  };

  getFreeProductDetails = (free_products) => {
    let data = {
      inventory_id: this.state.inventory_id,
      free_products: free_products,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${GET_FREE_PROMOTION_PRODUCT}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            promotional_free_items_data_from_api:
              response.data.free_product_list,
          });
          console.log("response from free product details", response);
        })
        .catch((error) => {
          console.log("error from free product details", error);
        });
    });
  };

  deductPromotion = () => {
    let data = {
      order_number: this.state.order_number,
      client_entity_id: this.state.end_entity_key,
      applied_promotion: this.state.applied_promotion,
    };

    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${DEDUCT_APPLIED_PROMOTION}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          console.log("response from deduct promotion ", response);
          this.isPromotionApplicable();
          this.getPreOrderAppliedPromotion();
        })
        .catch((error) => {
          console.log("error from deduct promotion ", error);
        });
    });
  };
  isPromotionApplicable = () => {
    let data = {
      order_number: this.state.order_number,
      client_entity_id: this.state.end_entity_key,
      applied_promotion: this.state.new_apply_promotion,
    };

    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${IS_PROMOTION_APPLICABLE}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          console.log("response from new apply promotion promotion ", response);
          this.setState({ new_apply_promotion: {} });
          this.getPreOrderAppliedPromotion();
        })
        .catch((error) => {
          console.log("error from new apply promotion promotion ", error);
        });
    });
  };

  calculateTotalValues = () => {
    let final = this.state.data.filter((i) => i.qty !== 0);
    final.map((i) => {
      let total = i.discounted_price;
      let total_before_discount = i.price;
      let total_discount = i.discount;
      this.setState(
        (prevState) => ({
          total_price: prevState.total_price + total,
          total_price_before_discount:
            prevState.total_price_before_discount + total_before_discount,
          total_discount: prevState.total_discount + total_discount,
        }),
        () => {
          this.setState({
            bill_amount:
              Number(this.state.total_price) +
              Number(this.state.delivery_charge) +
              Number(this.state.other_charges),
          });
          console.log("total price", this.state.total_price);
        }
      );

      return null;
    });
  };

  updateItem = () => {
    this.setState({ order_item_edit_modal: false });
    const data = {
      items: {
        promotional_items: this.state.promotional_items,

        non_promotional_items: this.state.non_promotional_items,

        free_items: this.state.free_items,
      },
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${ORDER_ITEM_UPDATE}${this.state.order_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from update order item", response);
          this.setState({ order_id: "" });

          this.getChildProductOrderDetails();
          this.getProductDetails();
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          this.setState({ order_id: "" });
          console.log("error from edit order", error);
        });
    });
  };

  setnonPromotionalItems = () => {
    let obj = {
      item_id: this.state.item_id,
      item: this.state.item_name,
      purchase_qty: this.state.item_purchase_qty,
      free_qty: 0,
      sku: this.state.item_sku,
      inventory_id: this.state.item_inventory_id,
      unit_selling_price: this.state.item_unit_selling_price,
      unit_purchase_price: this.state.item_unit_purchase_price,
      mrp: this.state.item_mrp,
      unit: this.state.item_unit,
      parent_product_id: this.state.item_parent_id,
      item_discount_type: this.state.item_discount_type,
      discount: 0,
      item_discount: 0,
      returned: this.state.item_returned,
      return_item_qty: this.state.return_item_qty,
    };
    let arr = [];
    arr.push(obj);
    this.setState({ non_promotional_items: arr }, () => {
      console.log("non promo items", this.state.non_promotional_items);
    });
  };

  setPromotionalItems = () => {
    let obj = {
      item_id: this.state.promotional_item_id,
      item: this.state.promotional_item_name,
      purchase_qty: this.state.promotional_item_purchase_qty,
      free_qty: 0,
      sku: this.state.promotional_item_sku,
      inventory_id: this.state.promotional_item_inventory_id,
      unit_selling_price: this.state.promotional_item_unit_selling_price,
      unit_purchase_price: this.state.promotional_item_unit_purchase_price,
      mrp: this.state.promotional_item_mrp,
      unit: this.state.promotional_item_unit,
      parent_product_id: this.state.promotional_item_parent_id,
      item_discount_type: this.state.promotional_item_discount_type,
      discount: this.state.promotional_item_raw_discount,
      item_discount: this.state.promotional_item_discount,
      returned: this.state.promotional_item_returned,
      return_item_qty: this.state.promotional_return_item_qty,
    };

    let arr = [];
    arr.push(obj);
    this.setState({ promotional_items: arr }, () => {
      console.log("promo items", this.state.promotional_items);
    });
  };

  setFreeItems = () => {
    const item = this.state.promotional_free_items_data_from_api[0];
    let obj = {
      item_id: item.item_id,
      item: item.item,
      purchase_qty: 0,
      free_qty: this.state.free_item_free_qty,
      sku: item.sku,
      inventory_id: this.state.inventory_id,
      unit_selling_price: item.unit_selling_price,
      unit_purchase_price: item.unit_purchase_price,
      mrp: item.mrp,
      unit: item.unit,
      parent_product_id: item.parent_product_id,
      item_discount_type: "free_product",
      discount: 0,
      item_discount: 0,
      returned: false,
      return_item_qty: 0,
    };
    let arr = [];
    arr.push(obj);
    this.setState({ free_items: arr }, () => {
      console.log("free items", this.state.free_items);
    });
  };

  deleteOrderItem = (id) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = ` ${ORDER_ITEM_UPDATE}${this.state.order_id}?item_id=${id}&access_token=${token}`;
      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          swal.fire("Success", response.data.message, "success");
          this.getOrderDetails();
          console.log("deleted", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from del", error);
        });
    });
  };

  getItemDiscount = (record) => {
    const item_discount =
      record.product_qty_discount_tier.discount_type === "percentage"
        ? (this.state.promotional_item_unit_selling_price *
            this.state.promotional_item_purchase_qty *
            record.product_qty_discount_tier.discount) /
          100
        : record.product_qty_discount_tier.discount *
          (this.state.promotional_item_purchase_qty /
            record.product_qty_discount_tier.buy_product[0].product_qty);
    return item_discount;
  };

  render() {
    const applied_promotions = this.state.applied_promotions;
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          onBack={() => this.props.history.goBack()}
          title="Update Multiple Order"
        />
        {this.state.multiple_order_details.map((o) => (
          <Card
            key={o._id.$oid}
            style={{
              display: "flex",
              flexBasis: "100%",
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <p >
              <Text style={{ fontWeight: "bold" }}>Order Number</Text>:{" "}
              {o.order_number}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Order Status</Text>:{" "}
              {o.order_status.name}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>
                {" "}
                Delivery Place Address
              </Text>
              : {o.end_loc_name}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Delivery Date</Text>:{" "}
              {timeConverter(o.delivery_date_time?.$date ?? Date.now())}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Deliver To</Text>:{" "}
              {`${o.drop_contact_name}, ${o.drop_contact_no}`}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Product Stock Quantity</Text>:{" "}
              {this.state.stock_quantity}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Product Ordered Quantity</Text>:{" "}
              {this.state.ordered_quantity}
            </p>
            <Card style={{ flex: 1 }}>
              <Table
                dataSource={[o.items]}
                pagination={false}
                rowKey={() => randomId(10)}
                size="small"
              >
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Product</Text>
                      </div>
                    </>
                  }
                  dataIndex="item"
                  key="product"
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Unit</Text>
                      </div>
                    </>
                  }
                  dataIndex="unit"
                  key="unit"
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Unit Price</Text>
                      </div>
                    </>
                  }
                  dataIndex="unit_selling_price"
                  key="unit_selling_price"
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Discount Type</Text>
                      </div>
                    </>
                  }
                  dataIndex="item_discount_type"
                  key="item_discount_type"
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Discount</Text>
                      </div>
                    </>
                  }
                  dataIndex="discount"
                  key="discount"
                />
                {/* <Column
                        title={
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text ellipsis={true}>Per Item Discount</Text>
                            </div>
                          </>
                        }
                        // dataIndex="unit_item_discount"
                        key="unit_item_discount"
                        render={(record) => (
                          <Text>
                            {record.item_discount_type === "amount"
                              ? record.unit_item_discount
                              : record.item_discount_type === "percentage"
                              ? `${record.unit_item_discount}%`
                              : 0}
                          </Text>
                        )}
                      /> */}
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Purchase Quantity</Text>
                      </div>
                    </>
                  }
                  key="purchase_qty"
                  dataIndex="purchase_qty"
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Free Quantity</Text>
                      </div>
                    </>
                  }
                  key="free_qty"
                  dataIndex="free_qty"
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Promotional Quantity</Text>
                      </div>
                    </>
                  }
                  key="qty"
                  dataIndex="promotional_qty"
                />

                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Price</Text>
                      </div>
                    </>
                  }
                  dataIndex="price"
                  key="price"
                />

                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Discounted Price</Text>
                      </div>
                    </>
                  }
                  dataIndex="item_discounted_price"
                  key="item_discounted_price"
                />

                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Action</Text>
                      </div>
                    </>
                  }
                  key="action"
                  render={(text, record) => (
                    <span>
                      {this.props.userProfileData.order_and_delivery.order
                        .order_detail.order_product_status_update ? (
                        <Button
                          type="link"
                          style={{ color: "green" }}
                          onClick={() => {
                            window.open(
                              `https://web.conveyance.app/OrderItemDetails/${this.state.order_id}/${record.item_id}`
                            );
                          }}
                        >
                          Update Status
                        </Button>
                      ) : null}
                      {this.props.userProfileData.order_and_delivery.order
                        .order_item_update ? (
                        <Button
                          type="link"
                          style={{ color: "green" }}
                          onClick={() =>
                            this.setState(
                              {
                                item_price: record.price ?? 0,
                                parent_product_id:
                                  record.parent_product_id ?? "",
                                item_name: record.item ?? "",
                                item_unit: record.unit ?? "",
                                item_unit_selling_price:
                                  record.unit_selling_price ?? 0,
                                item_sku: record.sku ?? "",
                                item_unit_purchase_price:
                                  record.unit_purchase_price ?? 0,
                                item_mrp: record.mrp ?? 0,
                                item_inventory_id: record.inventory_id ?? "",
                                item_purchase_qty:
                                  record.purchase_qty -
                                    record.promotional_qty ?? 0,
                                item_free_qty: record.free_qty ?? 0,
                                item_id: record.item_id ?? "",
                                item_parent_id: record.parent_product_id ?? "",
                                item_discount_type:
                                  record.item_discount_type ?? "amount",
                                item_discount: record.item_discount ?? 0,
                                item_raw_discount: record.discount ?? 0,
                                item_returned: record.returned ?? false,
                                return_item_qty: record.return_item_qty ?? 0,
                                promotional_qty: record.promotional_qty ?? 0,
                                order_id: o._id.$oid,
                              },
                              () => {
                                if (record.promotional_qty > 0) {
                                  this.setState(
                                    {
                                      promotional_item_price:
                                        this.state.item_price,
                                      promotional_item_name:
                                        this.state.item_name,
                                      promotional_item_unit:
                                        this.state.item_unit,
                                      promotional_item_unit_selling_price:
                                        this.state.item_unit_selling_price,
                                      promotional_item_sku: this.state.item_sku,
                                      promotional_item_unit_purchase_price:
                                        this.state.item_unit_purchase_price,
                                      promotional_item_mrp: this.state.item_mrp,
                                      promotional_item_inventory_id:
                                        this.state.item_inventory_id,
                                      promotional_item_purchase_qty:
                                        this.state.promotional_qty,
                                      promotional_item_free_qty:
                                        this.state.item_free_qty,
                                      promotional_item_id: this.state.item_id,
                                      promotional_item_parent_id:
                                        this.state.item_parent_id,
                                      promotional_item_discount_type:
                                        this.state.item_discount_type,
                                      promotional_item_discount:
                                        this.state.item_discount,
                                      promotional_item_raw_discount:
                                        this.state.item_raw_discount,
                                      promotional_item_returned:
                                        this.state.item_returned,
                                      promotional_return_item_qty:
                                        this.state.return_item_qty,
                                      free_item_free_qty:
                                        this.state.item_free_qty,
                                    },
                                    () => {
                                      this.setPromotionalItems();
                                    }
                                  );
                                }

                                this.setnonPromotionalItems();
                                this.getPromotionsForProducts();
                                this.setState({
                                  order_item_edit_modal: true,
                                });
                              }
                            )
                          }
                        >
                          Edit
                        </Button>
                      ) : null}
                      {this.props.userProfileData.order_and_delivery.order
                        .order_item_update ? (
                        <Button
                          type="link"
                          style={{ color: "red" }}
                          onClick={() => this.deleteOrderItem(record.item_id)}
                        >
                          Delete
                        </Button>
                      ) : null}
                    </span>
                  )}
                />
              </Table>
              <Text
                style={{ margin: 5 }}
              >{`Total Price : ${o.total_price}`}</Text>
            </Card>
          </Card>
        ))}

        <Modal
          title={this.state.item_name}
          visible={this.state.order_item_edit_modal}
          onOk={() =>
            this.setState(
              {
                item_price: Number(
                  this.state.item_unit_selling_price *
                    this.state.item_purchase_qty
                ),
              },
              () => {
                this.setnonPromotionalItems();
                this.updateItem();
              }
            )
          }
          onCancel={() =>
            this.setState({
              order_item_edit_modal: false,
              item_price: 0,
              item_unit_selling_price: 0,
              item_purchase_qty: 0,
              item_free_qty: 0,
              item_name: "",
              item_unit: "",
              item_id: "",
              item_parent_id: "",
              item_sku: "",
              item_unit_purchase_price: 0,
              item_mrp: 0,
              item_inventory_id: "",
              item_discount_type: "amount",
              item_discount: 0,
              item_returned: false,
              return_item_qty: 0,
              promotions_for_product: [],
              order_id: "",
            })
          }
        >
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column",
            }}
          >
            {this.state.promotional_qty > 0 ? (
              <Text
                style={{ color: "#1976D2" }}
              >{`Promotional Quantity : ${this.state.promotional_qty}`}</Text>
            ) : null}
            {this.state.promotions_for_product.length > 0 ? (
              <Tag
                color="#42A5F5"
                style={{ width: 150 }}
                onClick={() =>
                  this.setState(
                    {
                      showProductPromotions: true,
                    },
                    () => {
                      this.createPreOrderPromotion();
                    }
                  )
                }
              >{`${this.state.promotions_for_product.length} Promotions Available`}</Tag>
            ) : null}
            {/* <Form.Item>
                  <Text>Item Discount Type</Text>
                  <Select
                    value={this.state.item_discount_type}
                    onChange={(value) => {
                      this.setState({ item_discount_type: value }, () => {
                        console.log(this.state.item_discount_type);
                      });
                    }}
                  >
                    {this.state.discount_type_choices.map((r) => {
                      return <Option key={r}>{r}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Text>Item Discount</Text>
                  <Input
                    placeholder="Item Discount"
                    type="number"
                    value={this.state.unit_item_discount}
                    onChange={(evt) => {
                      evt.preventDefault();
                      this.setState(
                        { unit_item_discount: Number(evt.target.value) },
                        () => {
                          console.log(this.state.unit_item_discount);
                        }
                      );
                    }}
                  />
                </Form.Item> */}
            <Form.Item>
              <Text>Updated Quantity</Text>
              <Input
                disabled={
                  this.state.status_name === "order_delivered" ||
                  this.state.status_name === "order_completed" ||
                  this.state.status_name === "order_cancelled" ||
                  this.state.status_name === "order_cancelled_by_organization"
                    ? true
                    : false
                }
                placeholder="Quantity"
                type="number"
                value={this.state.item_purchase_qty}
                onChange={(evt) => {
                  if (Number(evt.target.value) < 1) {
                    swal.fire(
                      "info",
                      "Use delete button to remove item",
                      "info"
                    );
                  } else {
                    this.setState(
                      {
                        item_purchase_qty: Number(evt.target.value),
                      },
                      () => this.setnonPromotionalItems()
                    );
                  }
                }}
              />
            </Form.Item>
            <Form.Item>
              <Text>Item Returned</Text>
              <Select
                value={this.state.item_returned.toString()}
                disabled={
                  this.state.status_name === "order_delivered" ||
                  this.state.status_name === "order_completed" ||
                  this.state.status_name === "order_cancelled" ||
                  this.state.status_name === "order_cancelled_by_organization"
                    ? false
                    : true
                }
                onChange={(value) => {
                  this.setState({ item_returned: CHECK_BOOL(value) }, () => {
                    this.setnonPromotionalItems();
                    console.log(this.state.item_returned);
                  });
                }}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Text>Returned Item Quantity</Text>
              <Input
                disabled={
                  this.state.status_name === "order_delivered" ||
                  this.state.status_name === "order_completed" ||
                  this.state.status_name === "order_cancelled" ||
                  this.state.status_name === "order_cancelled_by_organization"
                    ? false
                    : true
                }
                placeholder="Returned Item Quantity"
                type="number"
                value={this.state.return_item_qty}
                onChange={(evt) =>
                  this.setState(
                    {
                      return_item_qty: Number(evt.target.value),
                    },
                    () => this.setnonPromotionalItems()
                  )
                }
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Product Promotions"
          visible={this.state.showProductPromotions}
          footer={null}
          onCancel={() => {
            this.setState({ showProductPromotions: false });
          }}
          width={window.innerWidth - 200}
          closable={true}
        >
          <Table
            dataSource={this.state.promotions_for_product}
            pagination={false}
            rowKey={(record) => record.product_qty_discount_tier.id}
            size="small"
          >
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text ellipsis={true}>Name</Text>
                  </div>
                </>
              }
              key="item_name"
              render={(record) => (
                <Text>
                  {record.product_qty_discount_tier.buy_product[0].product_name}
                </Text>
              )}
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text ellipsis={true}>Price</Text>
                  </div>
                </>
              }
              key="item_price"
              render={(record) => (
                <Text>{this.state.item_unit_selling_price}</Text>
              )}
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text ellipsis={true}>Discount</Text>
                  </div>
                </>
              }
              key="discount"
              render={(record) => (
                <Text>{record.product_qty_discount_tier.discount}</Text>
              )}
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text ellipsis={true}>Discount Type</Text>
                  </div>
                </>
              }
              key="discount_type"
              render={(record) => (
                <Text>{record.product_qty_discount_tier.discount_type}</Text>
              )}
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text ellipsis={true}>Ordered Price</Text>
                  </div>
                </>
              }
              key="ordered_price"
              render={(record) => (
                <Text>
                  {applied_promotions.some(
                    (ap) =>
                      ap.applied_tier_row.id ===
                      record.product_qty_discount_tier.id
                  ) ? (
                    <Text>
                      {this.state.promotional_item_unit_selling_price *
                        this.state.promotional_item_purchase_qty}
                    </Text>
                  ) : (
                    0
                  )}
                </Text>
              )}
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text ellipsis={true}>Discounted Price</Text>
                  </div>
                </>
              }
              key="discounted_price"
              render={(record) => (
                <Text>
                  {applied_promotions.some(
                    (ap) =>
                      ap.applied_tier_row.id ===
                      record.product_qty_discount_tier.id
                  ) ? (
                    <Text>
                      {record.product_qty_discount_tier.discount_type ===
                      "fixed_amount"
                        ? this.state.promotional_item_unit_selling_price *
                            this.state.promotional_item_purchase_qty -
                          record.product_qty_discount_tier.discount *
                            (this.state.promotional_item_purchase_qty /
                              record.product_qty_discount_tier.buy_product[0]
                                .product_qty)
                        : this.state.promotional_item_unit_selling_price *
                            this.state.promotional_item_purchase_qty -
                          (record.product_qty_discount_tier.discount *
                            (this.state.promotional_item_purchase_qty /
                              record.product_qty_discount_tier.buy_product[0]
                                .product_qty)) /
                            100}
                    </Text>
                  ) : (
                    0
                  )}
                </Text>
              )}
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text ellipsis={true}>Minimum Quantity</Text>
                  </div>
                </>
              }
              key="minimum_qty"
              render={(record) => (
                <Text>
                  {record.product_qty_discount_tier.buy_product[0].product_qty}
                </Text>
              )}
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text ellipsis={true}>Free Quantity</Text>
                  </div>
                </>
              }
              key="free_qty"
              render={(record) => (
                <Text>
                  {applied_promotions.some(
                    (ap) =>
                      ap.applied_tier_row.id ===
                      record.product_qty_discount_tier.id
                  )
                    ? this.state.free_item_free_qty
                    : 0}
                </Text>
              )}
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text ellipsis={true}>Promotion Applied</Text>
                  </div>
                </>
              }
              key="promotion_applied"
              render={(record) => (
                <Text>
                  {applied_promotions.some(
                    (ap) =>
                      ap.applied_tier_row.id ===
                      record.product_qty_discount_tier.id
                  ) ? (
                    <Text style={{ color: "green" }}>Applied</Text>
                  ) : (
                    <Text style={{ color: "red" }}>Not Applied</Text>
                  )}
                </Text>
              )}
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text ellipsis={true}>Promotional quantity</Text>
                  </div>
                </>
              }
              key="promotional_quantity"
              render={(record) => (
                <Text>
                  {applied_promotions.some(
                    (ap) =>
                      ap.applied_tier_row.id ===
                      record.product_qty_discount_tier.id
                  ) ? (
                    <div>
                      <Button
                        style={{
                          color: "red",
                        }}
                        type="link"
                        onClick={() => {
                          if (
                            this.state.promotional_item_purchase_qty -
                              record.product_qty_discount_tier.buy_product[0]
                                .product_qty ===
                            0
                          ) {
                            this.setState(
                              {
                                promotional_item_purchase_qty:
                                  this.state.promotional_item_purchase_qty -
                                  record.product_qty_discount_tier
                                    .buy_product[0].product_qty,
                              },
                              () => {
                                let filtered = applied_promotions.filter(
                                  (ap) =>
                                    ap.applied_tier_row.id ===
                                    record.product_qty_discount_tier.id
                                );
                                console.log("filtered value", filtered);
                                this.setState(
                                  {
                                    applied_promotion: filtered[0],
                                    promotioal_items: [],
                                  },
                                  () => this.deductPromotion()
                                );
                              }
                            );
                          } else if (
                            this.state.promotional_item_purchase_qty -
                              record.product_qty_discount_tier.buy_product[0]
                                .product_qty >
                            0
                          ) {
                            this.setState(
                              {
                                promotional_item_purchase_qty:
                                  this.state.promotional_item_purchase_qty -
                                  record.product_qty_discount_tier
                                    .buy_product[0].product_qty,
                                promotional_item_raw_discount:
                                  record.product_qty_discount_tier.discount,
                                promotional_item_discount_type:
                                  record.product_qty_discount_tier
                                    .discount_type,
                              },
                              () => {
                                if (
                                  record.product_qty_discount_tier
                                    .discount_type === "free_product"
                                ) {
                                  this.setState(
                                    {
                                      free_item_free_qty:
                                        this.state.free_item_free_qty -
                                        record.product_qty_discount_tier
                                          .free_product[0].product_qty,
                                    },
                                    () => {
                                      this.setFreeItems();
                                    }
                                  );
                                }
                                this.setState(
                                  {
                                    promotional_item_discount:
                                      this.getItemDiscount(record),
                                  },
                                  () => this.setPromotionalItems()
                                );
                              }
                            );
                          }
                        }}
                      >
                        <Icon type="minus" />
                      </Button>
                      <Text>{this.state.promotional_item_purchase_qty}</Text>
                      <Button
                        style={{
                          color: "red",
                        }}
                        type="link"
                        onClick={() => {
                          this.setState(
                            {
                              promotional_item_purchase_qty:
                                this.state.promotional_item_purchase_qty +
                                record.product_qty_discount_tier.buy_product[0]
                                  .product_qty,
                              promotional_item_raw_discount:
                                record.product_qty_discount_tier.discount,
                              promotional_item_discount_type:
                                record.product_qty_discount_tier.discount_type,
                            },
                            () => {
                              if (
                                record.product_qty_discount_tier
                                  .discount_type === "free_product"
                              ) {
                                this.setState(
                                  {
                                    free_item_free_qty:
                                      this.state.free_item_free_qty +
                                      record.product_qty_discount_tier
                                        .free_product[0].product_qty,
                                  },
                                  () => {
                                    this.setFreeItems();
                                  }
                                );
                              }
                              this.setState(
                                {
                                  promotional_item_discount:
                                    this.getItemDiscount(record),
                                },
                                () => this.setPromotionalItems()
                              );
                            }
                          );
                        }}
                      >
                        <Icon type="plus" />
                      </Button>
                    </div>
                  ) : null}
                </Text>
              )}
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text ellipsis={true}>Apply Promotion</Text>
                  </div>
                </>
              }
              key="apply_promotion"
              render={(record) => (
                <Text>
                  {applied_promotions.some(
                    (ap) =>
                      ap.applied_tier_row.id ===
                      record.product_qty_discount_tier.id
                  ) ? null : (
                    <Button
                      type="link"
                      style={{ color: "green" }}
                      onClick={() => {
                        swal
                          .fire({
                            title:
                              "Are you sure you want to apply this promotion?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes",
                          })
                          .then((result) => {
                            if (result.value) {
                              let filtered = applied_promotions.filter((ap) =>
                                this.state.promotions_for_product.some(
                                  (pp) =>
                                    pp.product_qty_discount_tier.id ===
                                    ap.applied_tier_row.id
                                )
                              );
                              console.log("filtered", filtered);
                              let obj = {
                                promotion_id: record._id.$oid,
                                promotion_type: record.promotion_type,
                                usage_qty: 1,
                                applied_tier_row:
                                  record.product_qty_discount_tier,
                              };
                              this.setState(
                                {
                                  new_apply_promotion: obj,
                                  applied_promotion: filtered[0],
                                  promotional_item_purchase_qty:
                                    record.product_qty_discount_tier
                                      .buy_product[0].product_qty,
                                  promotional_item_raw_discount:
                                    record.product_qty_discount_tier.discount,
                                  promotional_item_discount_type:
                                    record.product_qty_discount_tier
                                      .discount_type,
                                },
                                () => {
                                  this.deductPromotion();
                                  if (
                                    record.product_qty_discount_tier
                                      .discount_type === "free_product"
                                  ) {
                                    this.setState(
                                      {
                                        free_item_free_qty:
                                          record.product_qty_discount_tier
                                            .free_product[0].product_qty,
                                      },
                                      () => {
                                        this.setFreeItems();
                                      }
                                    );
                                  }
                                  this.setState(
                                    {
                                      promotional_item_discount:
                                        this.getItemDiscount(record),
                                    },
                                    () => {
                                      this.setPromotionalItems();
                                    }
                                  );
                                }
                              );
                            }
                          });
                      }}
                    >
                      Apply
                    </Button>
                  )}
                </Text>
              )}
            />
          </Table>
        </Modal>
      </Card>
    );
  }
}
const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(EditMultipleOrder);
