import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Tooltip } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
// import swal from "sweetalert2";

import { CREATE_REMINDER } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";


import "../login.css";

const { Column } = Table;

export default class ReminderList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false
  };
  componentDidMount() {
    this.getReminderList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

  }

  getReminderList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_REMINDER}?page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from reminder list", response);
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          console.log("error from reminder list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getReminderList();
      }
    );
  };

//   deleteProduct = id => {
//     this.setState({ loading: true });
//     GET_ACCESS_TOKEN().then(token => {
//       const url = `${EDIT_PRODUCT}${id}?access_token=${token}`;

//       axios({
//         method: "delete",
//         url: url
//       })
//         .then(response => {
//           this.setState({ loading: false });
//           swal.fire("Success", response.data.message, "success");
//           setTimeout(() => window.location.reload(), 1000);
//           console.log("deleted", response);
//         })
//         .catch(error => {
//           this.setState({ loading: false });
//           swal.fire("Error", error, "error");
//           console.log("error from del", error);
//         });
//     });
//   };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <Link to="/createReminder">
          <Tooltip placement="top" title="Create Reminder">
          <Button shape="circle" icon="plus" className="tooltip-button" />
          </Tooltip>
        </Link>
        <PageHeader
          title="Reminders"
        />
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Reason" dataIndex="reason" key="reason" />
          <Column title="Reminder Type" dataIndex="reminder_type" key="reminder_type" />
          <Column title="Start Date" dataIndex="start_date" key="start_date" />
          <Column title="End Date" dataIndex="end_date" key="end_date" />
          <Column title="Reminder Time" dataIndex="reminder_time" key="reminder_time" />
          <Column
            title="Created By/For"
            key="created_for"
            render={(text, record) => (
              <span>{record?.created_for?.name??""}</span>
            )}
          />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          {/* <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Link
                  to={{
                    pathname: "/editReminder",
                    state: { reminder_id: record._id.$oid }
                  }}
                >
                  <Button
                    type="link"
                    style={{ color: "green" }}
                  >
                    Edit
                  </Button>
                </Link>
              </span>
            )}
          /> */}
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}
