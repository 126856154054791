import {SAVE_USER_LIST_PARAMS} from "../actions/types";

const initialState={
    userListParamsData:{}
}


export default function(state=initialState,action){
    switch(action.type){
        case SAVE_USER_LIST_PARAMS:
            return {
                ...state,
                userListParamsData:action.payload
            }
        default:
            return state;
    }
}