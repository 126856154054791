import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { EDIT_RATE, CREATE_SERVICE, CREATE_COUNTRY } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class EditRate extends Component {
  state = {
    name: "",
    service: "",
    country: "",
    countries: [],
    country_id: "",
    price: null,
    currency: "BDT",
    data: [],
    rate_id: ""
  };

  componentDidMount() {
    this.countryList();
    this.getServices();
    this.getRate();
  }

  getRate = () => {
    this.setState({ rate_id: this.props.location.state.rate_id });

    GET_ACCESS_TOKEN().then(token => {
      let rate_id = this.props.location.state.rate_id;
      let url = `${EDIT_RATE}${rate_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit rate", response);
        this.setState({
          name: response.data.data.name,
          service: response.data.data.service,
          country: response.data.data.ccountry,
          currency: response.data.data.currency,
          price: response.data.data.price
        });
      });
    });
  };

  getServices = () => {
    GET_ACCESS_TOKEN().then(token => {
      let url = `${CREATE_SERVICE}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from service list", response);
          this.setState({
            data: response.data.data
          });
        })
        .catch(error => {
          console.log("error from service list api", error);
        });
    });
  };

  countryList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 200;
      const url = `${CREATE_COUNTRY}?limit=${limit}&d=${false}&sort_by="name"&show="no"&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ countries: response.data.data }, () => {
            console.log("dropdown", this.state.countries);
          });
        })
        .catch(error => {
          console.log("error from country list api", error);
        });
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  setService = value => {
    this.setState({ service: value }, () => {
      console.log(this.state.service);
    });
  };
  setCountry = value => {
    this.setState({ country: value.label, country_id: value.key }, () => {
      console.log(this.state.country);
    });
  };
  setCurrency = value => {
    this.setState({ currency: value }, () => {
      console.log(this.state.currency);
    });
  };
  setPrice = evt => {
    evt.preventDefault();
    this.setState({ price: evt.target.value }, () => {
      console.log(this.state.price);
    });
  };

  updateRate = () => {
    this.setState({ loading: true });
    const data = {
      name: this.state.name,
      service: this.state.service,
      ccountry: this.state.country,
      currency: this.state.currency,
      price: Number(this.state.price)
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_RATE}${this.state.rate_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Success", response.data.message, "success");
          console.log("response from update rate", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from update rate", error);
        });
    });
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title="Update Rate"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>

          <Form.Item>
            <Text>Service Type</Text>
            <Select
              value={this.state.service}
              onChange={this.setService}
              placeholder="Select Service"
            >
              {this.state.data.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Country</Text>
            <Select
              labelInValue
              value={{ key: this.state.country_id, label: this.state.country }}
              onChange={this.setCountry}
              placeholder="Select Country"
            >
              {this.state.countries.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Currency</Text>
            <Select defaultValue="BDT" onChange={this.setCurrency}>
              <Option value="BDT">BDT</Option>
              <Option value="NPR">NPR</Option>
              <Option value="AUD">AUD</Option>
              <Option value="USD">USD</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Price</Text>
            <Input
              placeholder="Price"
              type="number"
              value={this.state.price}
              onChange={this.setPrice}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.updateRate}
            >
              Update Rate
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
