import React, { Component } from "react";
import { Card, PageHeader, Button, Row, Col, Icon,Tooltip } from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
// import swal from "sweetalert2";

import CardMaterial from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import ButtonMaterial from "@material-ui/core/Button";

import { CREATE_VISIT_PLAN } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

class VisitPlans extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false
  };
  componentDidMount() {
    this.props.getUserData();
    this.getVisitPlans();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

  }

  getVisitPlans = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_VISIT_PLAN}?page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from visit plan list", response);
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from visit plan list api", error.message);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getVisitPlans();
      }
    );
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
         {this.props.userProfileData.visit_plan.visit_plan_create ? (
          <Link to="/createVisitPlan">
            <Tooltip placement="top" title="Create Visit Plan">
            <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}
        <PageHeader
          title="Visit Plans"
        />

        <Row
          gutter={[16, 24]}
          style={{ display: "flex", flexWrap: "wrap", marginTop: 5 }}
        >
          {this.state.data.map(item => {
            return (
              <Col
                key={item._id.$oid}
                className="gutter-row"
                span={6}
                style={{ flexBasis: "50%" }}
              >
                <CardMaterial
                  style={{
                    borderRadius: 5
                  }}
                >
              
                    <CardContent>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h2
                          style={{
                            fontFamily: "AirbnbCerealApp-ExtraBold",
                            marginLeft: 5,
                            letterSpacing: 1
                          }}
                        >
                          Name : {item.plan_name}
                        </h2>
                        <p >
                          <Icon
                            type="book"
                            style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                          />
                          Note : {item.note}
                        </p>
                        <p >
                          {" "}
                          <Icon
                            type="clock-circle"
                            style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                          />
                          Visiting Time :{" "}
                          {timeConverter(item.visiting_time.$date)}
                        </p>
                        <p >
                          {" "}
                          <Icon
                            type="clock-circle"
                            style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                          />
                          Created On : {timeConverter(item.created_on.$date)}
                        </p>
                      </div>
                    </CardContent>
               
                  {this.props.userProfileData.visit_plan.visit_plan_detail?(
                    <CardActions>
                    <Link
                      to={{
                        pathname: "/viewPlan",
                        state: {
                          plan_id: item._id.$oid
                        }
                      }}
                    >
                      <ButtonMaterial color="primary">View</ButtonMaterial>{" "}
                    </Link>
                  </CardActions>
                  ):null}
                  
                </CardMaterial>
              </Col>
            );
          })}
        </Row>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}
const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(VisitPlans);