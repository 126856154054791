import React, { Component } from "react";
import {
  Table,
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Modal,
  Icon,
} from "antd";
// import { Redirect } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import {
  CREATE_BILL,
  CREATE_MATERIAL,
  CREATE_MTYPE,
  CREATE_SUPPLIER,
  CREATE_PROCEDURE,
  ASSET_SEARCH,
  CREATE_BILL_TYPE,
  // CREATE_TASK,
  // CREATE_ATTENDANCE,
  ALL_MATERIAL,
  BILL_ID_GENERATOR,
  ADVANCED_TASK_SEARCH,
  CURRENT_USER_SUB_ORG_LIST,
  CREATE_DRIVER,
  CREATE_INCIDENT,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  CHECK_BOOL,
  // GET_USER_LOCAL,
} from "../../utilities/utilities";
import CreateMaterial from "../material/createMaterial";
// import CreateMaterialType from "../mtype/createMtype";
import CreateSupplier from "../supplier/createSupplier";
// import CreateUnit from "../unit/createUnit";
import ReactGA from "react-ga4";
import { debounce } from "lodash";

import "../login.css";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

class CreateNormalBill extends Component {
  state = {
    name: "",
    details: "",
    items: [],
    itemObj: {},
    item: "",
    price: null,
    qty: 0,
    unit: "",
    contact_no: "",
    supplier_name: "",
    supplier_name_key: "",
    rcmnd_price: null,
    appr_price: null,
    approved: "pending",
    recommended: "pending",
    authorised: "pending",
    data: [],
    dataS: [],
    procedureData: [],
    recommender_id: "",
    authorized_price: "",
    approved_price: "",
    asset_id: "",
    asset_name: "",
    assetData: [],
    miscellaneous_bill_type_choices: [],
    misc_bill_type: "",
    misc_bill_type_id: "",
    taskData: [],
    started: [],
    completed: [],
    task_name: "",
    task_id: "",
    attendanceData: [],
    attendance_name: "",
    attendance_id: "",
    showSupplier: false,
    showEntity: false,
    showProduct: false,
    showUnit: false,
    mtypeData: [],
    mtype: "",
    mtype_id: "",
    materialData: [],
    material: "",
    material_id: "",
    amount: 0,
    bill_no: "",
    filtered_items: [],
    selected_items: [],
    redirect: false,
    task_search_text: "",
    asset_text_search: "",
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    generated_for: "",
    users: [],
    incidents: [],
    incident_id: "",
    incident_name: "",
  };

  componentDidMount() {
    this.setState({
      task_id: this.props?.values?.task_id ?? "",
      incident_id: this.props?.values?.incident_id ?? "",
      incident_name: this.props?.values?.incident_name ?? "",
    });
    console.log("props", this.props);
    this.getProcedureList();
    this.getCurrentUserSubOrgs();
    // this.getProductList();
    this.getSupplierList();
    this.getAssetList();
    this.getBillTypeChoices();
    this.started();
    // this.getAttendanceList();
    this.getBillId();
    this.getUserList();
    this.getIncident();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getIncident = debounce((value = "") => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 10;
      const url = `${CREATE_INCIDENT}?name=${value}&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("incident", response);
          this.setState({ incidents: response.data.data });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, 500);

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user list", response);
          this.setState({
            users: response.data?.data ?? [],
          });
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  };

  handleSearchUser = debounce((value) => {
    console.log("searc text", value);
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from search user", response);
          this.setState({
            users: response.data?.data ?? [],
          });
        })
        .catch((error) => {
          console.log("error from search user api", error);
        });
    });
  }, 500);

  setUser = (value) => {
    this.setState({ generated_for: value }, () => {
      console.log(this.state.generated_for);
    });
  };

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState({
            current_user_sub_orgs: response.data.data,
            current_user_sub_org_id:
              response.data.data.length > 0 ? response?.data?.data[0].id : "",
          });
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  getBillId = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${BILL_ID_GENERATOR}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from bill id generator", response);
          this.setState({
            bill_no: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from bill id generator", error);
        });
    });
  };
  getMaterial = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_MATERIAL}?limit=100&search=true&search_text=${this.state.mtype}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from material list", response);
          this.setState({
            materialData: response.data?.data ?? [],
          });
        })
        .catch((error) => {
          console.log("error from material list api", error);
        });
    });
  };

  getMtype = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_MTYPE}?limit=100&search=true&search_text=${this.state.misc_bill_type}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from mtype list", response);
          this.setState({
            mtypeData: response.data?.data ?? [],
          });
        })
        .catch((error) => {
          console.log("error from mtype list api", error);
        });
    });
  };
  // getAttendanceList = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     const url = `${CREATE_ATTENDANCE}?limit=1000&access_token=${token}`;

  //     axios({
  //       method: "get",
  //       url: url
  //     })
  //       .then(response => {
  //         console.log("response from Attendance list", response);
  //         this.setState({
  //           attendanceData: response.data.data
  //         });
  //       })
  //       .catch(error => {
  //         console.log("error from attendance list api", error);
  //       });
  //   });
  // };

  searchIncident = (value) => {
    this.getIncident(value);
  };
  setIncident = (value) => {
    this.setState(
      { incident_id: value.key, incident_name: value.label },
      () => {
        console.log(this.state.incident_name);
      }
    );
  };

  onTaskSearch = (value) => {
    this.setState({ task_search_text: value }, () => {
      this.started();
    });
  };
  onAssetSearch = (value) => {
    this.setState({ asset_text_search: value }, () => {
      this.getAssetList();
    });
  };

  setCurrentUserSubOrg = (value) => {
    this.setState({ current_user_sub_org_id: value });
  };

  completed = () => {
    let date = new Date();
    let time = date.getTime();
    console.log(moment(time));
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ADVANCED_TASK_SEARCH}?name=${this.state.task_search_text}&limit=10&ts=${time}&task_status=completed&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from Task list completed", response);
          this.setState(
            {
              completed: response.data.data,
            },
            () => {
              this.setState(
                { taskData: this.state.started.concat(this.state.completed) },
                () => {
                  console.log("taskdata for admin", this.state.taskData);
                }
              );
            }
          );
        })
        .catch((error) => {
          console.log("error from Task list api completed", error);
        });
    });
  };

  started = () => {
    let date = new Date();
    let time = date.getTime();
    console.log(moment(time));
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ADVANCED_TASK_SEARCH}?name=${this.state.task_search_text}&limit=10&ts=${time}&task_status=started&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from Task list started", response);
          this.setState(
            {
              started: response.data.data,
            },
            () => {
              this.completed();
            }
          );
        })
        .catch((error) => {
          console.log("error from Task list api", error);
        });
    });
  };

  getBillTypeChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_BILL_TYPE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            miscellaneous_bill_type_choices: response.data?.data ?? [],
            misc_bill_type: response.data?.data[0]?.name ?? "",
            misc_bill_type_id: response.data?.data[0]?._id?.$oid ?? "",
          },()=>this.getMtype());
          console.log("response from bill type choices", response);
        })
        .catch((error) => {
          console.log("error from bill type choices api", error);
        });
    });
  };

  getAssetList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 10;
      const url = `${ASSET_SEARCH}?name=${this.state.asset_text_search}&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ assetData: response.data?.data ?? [] });
          console.log("response from asset list", response);
        })
        .catch((error) => {
          console.log("error from asset list api", error);
        });
    });
  };

  getProcedureList = () => {
    let limit = 1000;
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PROCEDURE}?limit=${limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from procedure list", response);
          let bill = response.data.data.filter(
            (proce) => proce.name.toLowerCase() === "bill"
          );
          let recommender_id = bill[0].recommender;
          let authoriser_id = bill[0].authorizer;
          let approver_id = bill[0].approver;
          this.setState(
            {
              recommender_id: recommender_id,
              authoriser_id: authoriser_id,
              approver_id: approver_id,
            },
            () => {
              console.log("recommender", this.state.recommender_id);
              console.log("auhtorizer", this.state.authoriser_id);
              console.log("approver", this.state.approver_id);
            }
          );
        })
        .catch((error) => {
          console.log("error from procedure list api", error);
        });
    });
  };

  getSupplierList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 1000;
      const url = `${CREATE_SUPPLIER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ dataS: response.data?.data ?? [] });
        })
        .catch((error) => {
          console.log("error from shop list api", error);
        });
    });
  };

  getProductList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ALL_MATERIAL}?search=true&search_text=${this.state.misc_bill_type}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          let products = response.data.data.map((p) => ({
            id: p._id.$oid,
            item: p.name,
            unit_price: p.unit_price,
            price: p.price,
            unit: p.unit,
            mtype: p.mtype,
            qty: 0,
            recommended_price: p.recommended_price,
            authorized_price: p.authorized_price,
            approved_price: p.approved_price,
          }));

          let products1 = response.data.data.map((p) => ({
            item: p.name,
            unit_price: p.unit_price,
            price: p.price,
            unit: p.unit,
            mtype: p.mtype,
            qty: 0,
            recommended_price: p.recommended_price,
            authorized_price: p.authorized_price,
            approved_price: p.approved_price,
          }));

          console.log("..........................", products);
          this.setState({ data: products1, items: products });
        })
        .catch((error) => {
          console.log("error from shop list api", error);
        });
    });
  };

  itemSearch = (value) => {
    const result = this.state.items.filter((item) => {
      return item.item.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered_items: result });
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  setAmount = (evt) => {
    evt.preventDefault();
    this.setState({ amount: evt.target.value }, () => {
      console.log(this.state.amount);
    });
  };

  setDetails = (evt) => {
    evt.preventDefault();
    this.setState({ details: evt.target.value }, () => {
      console.log(this.state.details);
    });
  };

  setContact = (evt) => {
    evt.preventDefault();
    this.setState({ contact_no: evt.target.value }, () => {
      console.log(this.state.contact_no);
    });
  };
  setAssignedAsset = (value) => {
    this.setState({ asset_name: value.label, asset_id: value.key }, () => {
      console.log(this.state.asset_id);
    });
  };
  setSupplier = (value) => {
    this.setState(
      { supplier_name: value.label, supplier_name_key: value.key },
      () => {
        console.log(this.state.supplier_name);
      }
    );
  };
  setTask = (value) => {
    this.setState({ task_name: value.label, task_id: value.key }, () => {
      console.log(this.state.task_id);
    });
  };
  setAttendance = (value) => {
    this.setState(
      { attendance_name: value.label, attendance_id: value.key },
      () => {
        console.log(this.state.attendance_id);
      }
    );
  };
  setRcprice = (evt) => {
    evt.preventDefault();
    this.setState({ rcmnd_price: evt.target.value }, () => {
      console.log(this.state.rcmnd_price);
    });
  };
  setBillType = (value) => {
    this.setState(
      { misc_bill_type: value.label, misc_bill_type_id: value.key },
      () => {
        this.getMtype();
        this.getProductList();
        console.log(this.state.misc_bill_type);
      }
    );
  };
  setMaterialType = (value) => {
    this.setState({ mtype: value.label, mtype_id: value.key }, () => {
      this.getMaterial();
      this.getProductList();
      console.log(this.state.mtype);
    });
  };
  setMaterial = (value) => {
    this.setState({ material: value.label, material_id: value.key }, () => {
      console.log(this.state.material);
    });
  };
  setApprice = (evt) => {
    evt.preventDefault();
    this.setState({ appr_price: evt.target.value }, () => {
      console.log(this.state.appr_price);
    });
  };

  setApprroved = (value) => {
    this.setState({ approved: CHECK_BOOL(value) }, () => {
      console.log(this.state.approved);
    });
  };
  setRecommended = (value) => {
    this.setState({ recommended: CHECK_BOOL(value) }, () => {
      console.log(this.state.recommended);
    });
  };
  setAuthorized = (value) => {
    this.setState({ authorised: CHECK_BOOL(value) }, () => {
      console.log(this.state.authorised);
    });
  };

  createBill = () => {
    if (
      this.state.name === "" ||
      this.state.details === "" ||
      this.state.contact_no === ""
    ) {
      swal.fire("Info", "Please enter all informations", "info");
    } else {
      let final = this.state.data.filter((i) => i.qty !== 0);
      let data = {
        bill_no: this.state.bill_no,
        name: this.state.name,
        details: this.state.details,
        generated_for: this.state.generated_for,
        asset_id: this.state.asset_id,
        asset_name: this.state.asset_name,
        items: final,
        contact_no: this.state.contact_no,
        supplier: {
          name: this.state.supplier_name,
          id: this.state.supplier_name_key,
        },
        approved: this.state.approved,
        recommended: this.state.recommended,
        authorized: this.state.authorised,
        misc_bill_type_id: this.state.misc_bill_type_id,
        task_id: this.state.task_id,
        incident_id: this.state.incident_id,
        task_name: this.state.task_name,
        attendance_id: this.state.attendance_id,
        attendance_name: this.state.attendance_name,
        sub_org: this.state.current_user_sub_org_id,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_BILL}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            if (response.data.message === "Data successfully inserted!") {
              this.setState({ redirect: true });
            }
            console.log("response from create Bill", response);
          })
          .catch((error) => {
            swal.fire("Error", error.message, "error");
            console.log("error from create Bill", error);
          });
      });
    }
  };
  createConveyanceBill = () => {
    if (
      this.state.misc_bill_type === "" ||
      this.state.mtype === "" ||
      this.state.amount === 0
    ) {
      swal.fire(
        "Info",
        "Please enter bill type,material type and amount",
        "info"
      );
    } else {
      let data = {
        items: [
          {
            item: this.state.material,
            id: this.state.material_id,
            mtype: { name: this.state.mtype, id: this.state.mtype_id },
            approved_price: 0,
            authorized_price: 0,
            price: Number(this.state.amount),
            qty: 1,
            recommended_price: 0,
            unit: "",
            unit_price: 0,
          },
        ],
        bill_no: this.state.bill_no,
        details: this.state.details,
        generated_for: this.state.generated_for,
        misc_bill_type_id: this.state.misc_bill_type_id,
        task_id: this.state.task_id,
        incident_id: this.state.incident_id,
        task_name: this.state.task_name,
        attendance_id: this.state.attendance_id,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_BILL}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            if (response.data.message === "Data successfully inserted!") {
              this.setState({ redirect: true });
            }
            console.log("response from create Bill conveyance", response);
          })
          .catch((error) => {
            swal.fire("Error", error.message, "error");
            console.log("error from create Bill conveyance", error);
          });
      });
    }
  };

  render() {
    // if (this.state.redirect === true) {
    //   return <Redirect to="/billList" />;
    // }
    const ItemList = this.state.items.map((r) => {
      return (
        <Option key={r.id} value={JSON.stringify(r)}>
          {r.item}
        </Option>
      );
    });

    const FilteredItem = this.state.filtered_items.map((r) => {
      return (
        <Option key={r.id} value={JSON.stringify(r)}>
          {r.item}
        </Option>
      );
    });
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title="Create Bill"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              {this.state.misc_bill_type === "Purchase General" ||
              this.state.misc_bill_type === "Purchase Assets" ||
              this.state.misc_bill_type === "Entertainment" ? (
                <Form.Item>
                  <Text>Name</Text>
                  <Input
                    placeholder="Name"
                    type="text"
                    value={this.state.name}
                    onChange={this.setName}
                  />
                </Form.Item>
              ) : null}

              {this.state.misc_bill_type === "Purchase General" ||
              this.state.misc_bill_type === "Purchase Assets" ||
              this.state.misc_bill_type === "Entertainment" ? (
                <Form.Item>
                  <Text>Contact Number</Text>
                  <Input
                    placeholder="+8801711..."
                    type="text"
                    value={this.state.contact_no}
                    onChange={this.setContact}
                  />
                </Form.Item>
              ) : null}

              <Form.Item>
                <Text>Bill Type</Text>
                <Select
                  labelInValue
                  value={{
                    key: this.state.misc_bill_type_id,
                    label: this.state.misc_bill_type,
                  }}
                  onChange={this.setBillType}
                  placeholder="Bill Type"
                >
                  {this.state.miscellaneous_bill_type_choices.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Sub Org</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.current_user_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.current_user_sub_org_id}
                  onChange={this.setCurrentUserSubOrg}
                >
                  {this.state.current_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </Form.Item>
              {this.props.userProfileData.data_level.access_suborg_data ||
              this.props.userProfileData.data_level.access_all_data ? (
                <Form.Item>
                  <Text>Bill Created For</Text>
                  <Select
                    value={this.state.generated_for}
                    showSearch={true}
                    filterOption={false}
                    showArrow={false}
                    autoClearSearchValue={false}
                    onSearch={this.handleSearchUser}
                    onChange={this.setUser}
                  >
                    {this.state.users.map((r) => {
                      return (
                        <Option
                          key={r._id.$oid}
                        >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : null}

              {this.state.misc_bill_type === "Purchase General" ||
              this.state.misc_bill_type === "Purchase Assets" ||
              this.state.misc_bill_type === "Entertainment" ? null : (
                <Form.Item>
                  <Text>Item Type</Text>
                  <Select
                    labelInValue
                    disabled={this.state.mtypeData.length > 0 ? false : true}
                    value={{
                      key: this.state.mtype_id,
                      label: this.state.mtype,
                    }}
                    onChange={this.setMaterialType}
                    placeholder="Item Type"
                  >
                    {this.state.mtypeData.map((r) => {
                      return <Option key={r._id.$oid}>{r.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
              )}

              {this.state.misc_bill_type === "Purchase General" ||
              this.state.misc_bill_type === "Purchase Assets" ||
              this.state.misc_bill_type === "Entertainment" ? null : (
                <Form.Item>
                  <Text>Item (Optional)</Text>
                  <Select
                    labelInValue
                    disabled={this.state.materialData.length > 0 ? false : true}
                    value={{
                      key: this.state.material_id,
                      label: this.state.material,
                    }}
                    onChange={this.setMaterial}
                    placeholder="Item"
                  >
                    {this.state.materialData.map((r) => {
                      return <Option key={r._id.$oid}>{r.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
              )}
              {this.state.misc_bill_type === "Purchase General" ||
              this.state.misc_bill_type === "Purchase Assets" ||
              this.state.misc_bill_type === "Entertainment" ? null : (
                <Form.Item>
                  <Text>Amount</Text>
                  <Input
                    placeholder="Amount"
                    type="number"
                    value={this.state.amount}
                    onChange={this.setAmount}
                  />
                </Form.Item>
              )}

              {/* {GET_USER_LOCAL().org_admin ? ( */}
              <Form.Item>
                <Text>Search Incident for Bill</Text>
                <Select
                  labelInValue
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue
                  value={{
                    key: this.state.incident_id,
                    label: this.state.incident_name,
                  }}
                  onSearch={this.searchIncident}
                  onChange={this.setIncident}
                >
                  {this.state.incidents.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Search Task for Bill</Text>
                <Select
                  labelInValue
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue
                  value={{
                    key: this.state.task_id,
                    label: this.state.task_name,
                  }}
                  onSearch={this.onTaskSearch}
                  onChange={this.setTask}
                >
                  {this.state.taskData.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              {/* ) : null} */}

              {/* {GET_USER_LOCAL().org_admin ? ( */}
              {/* <Form.Item>
                <Text>Select Attendance for Bill</Text>
                <Select
                  labelInValue
                  value={{
                    key: this.state.attendance_id,
                    label: this.state.attendance_name
                  }}
                  onChange={this.setAttendance}
                  placeholder="Select Attendance for Bill"
                >
                  {this.state.attendanceData.map(r => {
                    return (
                      <Option key={r._id.$oid}>{`${
                        r.created_by_name
                      }-${timeConverter(r.created_on.$date)}`}</Option>
                    );
                  })}
                </Select>
              </Form.Item> */}
              {/* ) : null} */}
              {this.state.misc_bill_type === "Purchase General" ||
              this.state.misc_bill_type === "Purchase Assets" ||
              this.state.misc_bill_type === "Entertainment" ? (
                <Form.Item>
                  <Text>
                    Supplier Name{" "}
                    <Button
                      type="link"
                      onClick={() => this.setState({ showSupplier: true })}
                    >
                      Create Supplier
                    </Button>
                  </Text>
                  <Select
                    labelInValue
                    value={{
                      key: this.state.supplier_name_key,
                      label: this.state.supplier_name,
                    }}
                    onChange={this.setSupplier}
                    placeholder="Select Supplier"
                  >
                    {this.state.dataS.map((r) => {
                      return <Option key={r._id.$oid}>{r.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
              ) : null}
              {this.state.misc_bill_type === "Purchase General" ||
              this.state.misc_bill_type === "Purchase Assets" ||
              this.state.misc_bill_type === "Entertainment" ||
              this.state.misc_bill_type === "Servicing and Maintenance Bill" ? (
                <Form.Item>
                  <Text>Assign to (Asset)</Text>
                  <Select
                    labelInValue
                    showSearch={true}
                    filterOption={false}
                    showArrow={false}
                    autoClearSearchValue
                    style={{ width: "100%" }}
                    value={{
                      key: this.state.asset_id,
                      label: this.state.asset_name,
                    }}
                    onSearch={this.onAssetSearch}
                    onChange={this.setAssignedAsset}
                    placeholder="Select Asset"
                  >
                    {this.state.assetData.map((r) => {
                      return <Option key={r._id.$oid}>{r.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
              ) : null}

              {this.state.misc_bill_type === "Purchase General" ||
              this.state.misc_bill_type === "Purchase Assets" ||
              this.state.misc_bill_type === "Conveyance" ||
              this.state.misc_bill_type === "Entertainment" ? (
                <Form.Item>
                  <Text>Details</Text>
                  <TextArea
                    rows={4}
                    placeholder="Details"
                    type="text"
                    value={this.state.details}
                    onChange={this.setDetails}
                  />
                </Form.Item>
              ) : null}

              {this.state.misc_bill_type === "Purchase General" ||
              this.state.misc_bill_type === "Purchase Assets" ||
              this.state.misc_bill_type === "Entertainment" ? (
                <Form.Item>
                  <Text>Select Items (Optional)</Text>
                  <Card>
                    <Form.Item>
                      <Text>
                        Search Item{" "}
                        <Button
                          type="link"
                          onClick={() => this.setState({ showEntity: true })}
                        >
                          Create Item
                        </Button>
                      </Text>
                      <Select
                        labelInValue
                        showSearch={true}
                        filterOption={false}
                        showArrow={false}
                        autoClearSearchValue
                        onSearch={this.itemSearch}
                        onChange={(value, e) => {
                          let item = JSON.parse(e.props.value);
                          let inc = {
                            item: item.item,
                            unit_price: item.unit_price,
                            price: item.price,
                            mtype: item.mtype,
                            qty: 1,
                            unit: item.unit,
                            recommended_price: item.recommended_price,
                            authorized_price: item.authorized_price,
                            approved_price: item.approved_price,
                            id: item.id,
                            unit_price_disable: false,
                          };
                          inc.price = inc.unit_price * inc.qty;
                          let temp = [];
                          temp.push(inc);
                          //real data
                          let filteredData = this.state.data.filter(
                            (i) => i.item !== item.item
                          );

                          this.setState(
                            { data: [...temp, ...filteredData] },
                            () => {
                              this.setState(
                                {
                                  data: this.state.data.filter(
                                    (value, index, self) =>
                                      self.findIndex(
                                        (t) => t.id === value.id
                                      ) === index
                                  ),
                                },
                                () => {
                                  console.log(
                                    "selection data state",
                                    this.state.data
                                  );
                                }
                              );
                            }
                          );
                          //selected data
                          this.setState(
                            {
                              selected_items: [
                                ...this.state.selected_items,
                                ...temp,
                              ],
                            },
                            () => {
                              this.setState({
                                selected_items:
                                  this.state.selected_items.filter(
                                    (value, index, self) =>
                                      self.findIndex(
                                        (t) => t.id === value.id
                                      ) === index
                                  ),
                              });
                              console.log(this.state.selected_items);
                            }
                          );
                        }}
                        placeholder="Search Item"
                      >
                        {this.state.filtered_items.length === 0
                          ? ItemList
                          : FilteredItem}
                      </Select>
                    </Form.Item>
                    {this.state.selected_items.length > 0 ? (
                      <Table
                        dataSource={this.state.selected_items}
                        pagination={false}
                        rowKey={(record) => record.id}
                        size="small"
                      >
                        <Column title="Item" dataIndex="item" key="product" />
                        <Column
                          title="Unit"
                          dataIndex="unit"
                          key="unit"
                          style={{ paddingLeft: 15 }}
                        />
                        <Column
                          title="Unit Price"
                          key="unit_price"
                          render={(record) => (
                            <span
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Input
                                type="number"
                                disabled={
                                  this.state.data.find(
                                    (i) => i.item === record.item
                                  ).unit_price_disable
                                }
                                value={
                                  this.state.data.find(
                                    (i) => i.item === record.item
                                  ).unit_price
                                }
                                onChange={(evt) => {
                                  let item = this.state.data.find(
                                    (i) => i.item === record.item
                                  );
                                  let inc = {
                                    item: item.item,
                                    unit_price: Number(evt.target.value),
                                    price: item.price,
                                    mtype: item.mtype,
                                    qty: item.qty,
                                    unit: item.unit,
                                    recommended_price: item.recommended_price,
                                    authorized_price: item.authorized_price,
                                    approved_price: item.approved_price,
                                    unit_price_disable: item.unit_price_disable,
                                    id: record.id,
                                  };
                                  inc.price =
                                    Number(evt.target.value) * inc.qty;
                                  let arr = [];
                                  arr.push(inc);

                                  let filteredData = this.state.data.filter(
                                    (i) => i.item !== item.item
                                  );

                                  this.setState(
                                    { data: [...arr, ...filteredData] },
                                    () => {
                                      console.log(
                                        "final state",
                                        this.state.data
                                      );
                                    }
                                  );
                                }}
                              />
                              <Button
                                type="link"
                                onClick={() => {
                                  let item = this.state.data.find(
                                    (i) => i.item === record.item
                                  );
                                  let inc = {
                                    item: item.item,
                                    unit_price: item.unit_price,
                                    price: item.price,
                                    mtype: item.mtype,
                                    qty: item.qty,
                                    unit: item.unit,
                                    recommended_price: item.recommended_price,
                                    authorized_price: item.authorized_price,
                                    approved_price: item.approved_price,
                                    unit_price_disable: item.unit_price_disable,
                                    id: record.id,
                                  };
                                  inc.unit_price_disable =
                                    !inc.unit_price_disable;
                                  let arr = [];
                                  arr.push(inc);

                                  let filteredData = this.state.data.filter(
                                    (i) => i.item !== item.item
                                  );

                                  this.setState(
                                    { data: [...arr, ...filteredData] },
                                    () => {
                                      console.log(
                                        "final state",
                                        this.state.data
                                      );
                                    }
                                  );
                                }}
                              >
                                Edit Unit Price
                              </Button>
                            </span>
                          )}
                        />
                        <Column
                          title="Decrease"
                          key="Decrease"
                          render={(record) => (
                            <span>
                              <Button
                                style={{
                                  backgroundColor: "#F5F5F5",
                                  color: "red",
                                }}
                                shape="circle"
                                onClick={() => {
                                  if (
                                    this.state.data.find(
                                      (i) => i.item === record.item
                                    ).qty <= 0
                                  ) {
                                    return;
                                  } else {
                                    let item = this.state.data.find(
                                      (i) => i.item === record.item
                                    );
                                    let inc = {
                                      item: item.item,
                                      unit_price: item.unit_price,
                                      price: item.price,
                                      mtype: item.mtype,
                                      qty: item.qty - 1,
                                      unit: item.unit,
                                      recommended_price: item.recommended_price,
                                      authorized_price: item.authorized_price,
                                      approved_price: item.approved_price,
                                      unit_price_disable:
                                        item.unit_price_disable,
                                      id: record.id,
                                    };
                                    inc.price = inc.unit_price * inc.qty;
                                    let arr = [];
                                    arr.push(inc);

                                    let filteredData = this.state.data.filter(
                                      (i) => i.item !== item.item
                                    );

                                    this.setState(
                                      { data: [...arr, ...filteredData] },
                                      () => {
                                        console.log(
                                          "final state",
                                          this.state.data
                                        );
                                      }
                                    );
                                  }
                                }}
                              >
                                <Icon type="minus" />
                              </Button>
                            </span>
                          )}
                        />

                        <Column
                          title="Quantity"
                          key="quantity"
                          render={(record) => (
                            <span>
                              <Input
                                type="number"
                                value={
                                  this.state.data.find(
                                    (i) => i.item === record.item
                                  ).qty
                                }
                                onChange={(evt) => {
                                  if (Number(evt.target.value <= 0)) {
                                    return;
                                  } else {
                                    let item = this.state.data.find(
                                      (i) => i.item === record.item
                                    );
                                    let inc = {
                                      item: item.item,
                                      unit_price: item.unit_price,
                                      price: item.price,
                                      mtype: item.mtype,
                                      qty: Number(evt.target.value),
                                      unit: item.unit,
                                      recommended_price: item.recommended_price,
                                      authorized_price: item.authorized_price,
                                      approved_price: item.approved_price,
                                      unit_price_disable:
                                        item.unit_price_disable,
                                      id: record.id,
                                    };
                                    inc.price =
                                      inc.unit_price * Number(evt.target.value);
                                    let arr = [];
                                    arr.push(inc);

                                    let filteredData = this.state.data.filter(
                                      (i) => i.item !== item.item
                                    );

                                    this.setState(
                                      { data: [...arr, ...filteredData] },
                                      () => {
                                        console.log(
                                          "final state",
                                          this.state.data
                                        );
                                      }
                                    );
                                  }
                                }}
                              />
                            </span>
                          )}
                        />
                        <Column
                          title="Increase"
                          key="Increase"
                          render={(record) => (
                            <span>
                              <Button
                                style={{
                                  backgroundColor: "#F5F5F5",
                                  color: "green",
                                }}
                                shape="circle"
                                onClick={() => {
                                  let item = this.state.data.find(
                                    (i) => i.item === record.item
                                  );
                                  let inc = {
                                    item: item.item,
                                    unit_price: item.unit_price,
                                    price: item.price,
                                    mtype: item.mtype,
                                    qty: item.qty + 1,
                                    unit: item.unit,
                                    recommended_price: item.recommended_price,
                                    authorized_price: item.authorized_price,
                                    approved_price: item.approved_price,
                                    unit_price_disable: item.unit_price_disable,
                                    id: record.id,
                                  };
                                  inc.price = inc.unit_price * inc.qty;
                                  let arr = [];
                                  arr.push(inc);

                                  let filteredData = this.state.data.filter(
                                    (i) => i.item !== item.item
                                  );

                                  this.setState(
                                    { data: [...arr, ...filteredData] },
                                    () => {
                                      console.log(
                                        "final state",
                                        this.state.data
                                      );
                                    }
                                  );
                                }}
                              >
                                <Icon type="plus" />
                              </Button>
                            </span>
                          )}
                        />
                        <Column
                          title="Price"
                          key="price"
                          render={(record) => (
                            <span
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Input
                                type="number"
                                disabled={
                                  !this.state.data.find(
                                    (i) => i.item === record.item
                                  ).unit_price_disable
                                }
                                value={
                                  this.state.data.find(
                                    (i) => i.item === record.item
                                  ).price
                                }
                                onChange={(evt) => {
                                  let item = this.state.data.find(
                                    (i) => i.item === record.item
                                  );
                                  let inc = {
                                    item: item.item,
                                    unit_price: item.unit_price,
                                    price: Number(evt.target.value),
                                    mtype: item.mtype,
                                    qty: item.qty,
                                    unit: item.unit,
                                    recommended_price: item.recommended_price,
                                    authorized_price: item.authorized_price,
                                    approved_price: item.approved_price,
                                    unit_price_disable: item.unit_price_disable,
                                    id: record.id,
                                  };
                                  inc.unit_price =
                                    Number(evt.target.value) / inc.qty;
                                  let arr = [];
                                  arr.push(inc);

                                  let filteredData = this.state.data.filter(
                                    (i) => i.item !== item.item
                                  );

                                  this.setState(
                                    { data: [...arr, ...filteredData] },
                                    () => {
                                      console.log(
                                        "final state",
                                        this.state.data
                                      );
                                    }
                                  );
                                }}
                              />
                              <Button
                                type="link"
                                onClick={() => {
                                  let item = this.state.data.find(
                                    (i) => i.item === record.item
                                  );
                                  let inc = {
                                    item: item.item,
                                    unit_price: item.unit_price,
                                    price: item.price,
                                    mtype: item.mtype,
                                    qty: item.qty,
                                    unit: item.unit,
                                    recommended_price: item.recommended_price,
                                    authorized_price: item.authorized_price,
                                    approved_price: item.approved_price,
                                    unit_price_disable: item.unit_price_disable,
                                    id: record.id,
                                  };
                                  inc.unit_price_disable =
                                    !inc.unit_price_disable;
                                  let arr = [];
                                  arr.push(inc);

                                  let filteredData = this.state.data.filter(
                                    (i) => i.item !== item.item
                                  );

                                  this.setState(
                                    { data: [...arr, ...filteredData] },
                                    () => {
                                      console.log(
                                        "final state",
                                        this.state.data
                                      );
                                    }
                                  );
                                }}
                              >
                                Edit Price
                              </Button>
                            </span>
                          )}
                        />
                        <Column
                          title="Remove"
                          key="Remove"
                          render={(record) => (
                            <span>
                              <Button
                                style={{
                                  backgroundColor: "#F5F5F5",
                                  color: "red",
                                }}
                                shape="circle"
                                onClick={() => {
                                  this.setState({
                                    selected_items:
                                      this.state.selected_items.filter(
                                        (item) => item.item !== record.item
                                      ),
                                  });
                                  let item = this.state.data.find(
                                    (i) => i.item === record.item
                                  );
                                  let inc = {
                                    item: item.item,
                                    unit_price: item.unit_price,
                                    price: 0,
                                    mtype: item.mtype,
                                    qty: 0,
                                    unit: item.unit,
                                    recommended_price: item.recommended_price,
                                    authorized_price: item.authorized_price,
                                    approved_price: item.approved_price,
                                    id: record.id,
                                  };
                                  let arr = [];
                                  arr.push(inc);

                                  let filteredData = this.state.data.filter(
                                    (i) => i.item !== item.item
                                  );

                                  this.setState(
                                    { data: [...arr, ...filteredData] },
                                    () => {
                                      console.log(
                                        "final state",
                                        this.state.data
                                      );
                                    }
                                  );
                                }}
                              >
                                <Icon type="close" />
                              </Button>
                            </span>
                          )}
                        />
                      </Table>
                    ) : null}
                  </Card>
                </Form.Item>
              ) : null}
            </div>

            <Modal
              title="Create New Item"
              visible={this.state.showEntity}
              onOk={() => {
                this.setState({ showEntity: false });
                this.getProductList();
              }}
              onCancel={() => {
                this.setState({ showEntity: false });
                this.getProductList();
              }}
              width={window.innerWidth - 200}
              closable={true}
              destroyOnClose={true}
            >
              <CreateMaterial />
            </Modal>

            <Modal
              title="Create New Supplier"
              visible={this.state.showSupplier}
              onOk={() => {
                this.setState({ showSupplier: false });
                this.getSupplierList();
              }}
              onCancel={() => {
                this.setState({ showSupplier: false });
                this.getSupplierList();
              }}
              width={window.innerWidth - 200}
              closable={true}
            >
              <CreateSupplier />
            </Modal>
          </div>
          {/* <Form.Item>
              <Button style={{margin:5}} type="primary" shape="circle">
                    <Icon type="plus" />
                  </Button>
          </Form.Item> */}

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={() => {
                if (
                  this.state.misc_bill_type === "Purchase General" ||
                  this.state.misc_bill_type === "Purchase Assets" ||
                  this.state.misc_bill_type === "Entertainment"
                ) {
                  this.createBill();
                } else {
                  this.createConveyanceBill();
                }
              }}
            >
              Create Bill
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(CreateNormalBill);
