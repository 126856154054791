import React, { Component } from "react";
import { Card, Button, Typography } from "antd";
import axios from "axios";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import FileSaver from "file-saver";

import { ORDER_FINANCE_REPORT } from "../../api/constants";
import { GET_ACCESS_TOKEN, GET_USER_LOCAL } from "../../utilities/utilities";

// import "../table.css";

const { Title } = Typography;

export default class FinanceReport extends Component {
  state = {
    order_finance_report_from: moment()
      .startOf("month")
      .startOf("day")
      .format("x"),
    order_finance_report_from_view: moment().startOf("month").startOf("day"),
    order_finance_report_to: moment().endOf("day").format("x"),
    order_finance_report_to_view: moment().endOf("day"),
    finance_report_json: [],
    finance_report_json_rearranged: {},
  };

  downloadOrderFinanceReport = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_FINANCE_REPORT}?org_id=${
        GET_USER_LOCAL().organization.$oid
      }&from=${this.state.order_finance_report_from}&to=${
        this.state.order_finance_report_to
      }&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from order finacne excel report", blob);
          FileSaver.saveAs(
            blob,
            `order_finance_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from order finacne excel report", error);
        });
    });
  };
  viewOrderFinanceReport = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_FINANCE_REPORT}?org_id=${
        GET_USER_LOCAL().organization.$oid
      }&from=${this.state.order_finance_report_from}&to=${
        this.state.order_finance_report_to
      }&fmt=json&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from order finance report list", response);
          this.setState({ finance_report_json: response.data.data }, () =>
            this.transposeData()
          );
        })
        .catch((error) => {
          console.log("error from order finance report list", error);
        });
    });
  };
  transposeData = () => {
    const existingData = [...this.state.finance_report_json];
    const newStructure = {};
    existingData.forEach((entry) => {
      Object.keys(entry).forEach((category) => {
        if (category === "total_expense_earnings") {
          if (!newStructure[category]) {
            newStructure[category] = {};
          }
          Object.keys(entry[category]).forEach((month) => {
            const value = entry[category][month];
            if (!newStructure[category][month]) {
              newStructure[category][month] = 0;
            }
            newStructure[category][month] += value;
          });
        } else {
          Object.keys(entry[category]).forEach((type) => {
            if (!newStructure[type]) {
              newStructure[type] = {};
            }
            Object.keys(entry[category][type]).forEach((month) => {
              const value = entry[category][type][month];
              if (!newStructure[type][month]) {
                newStructure[type][month] = 0;
              }
              newStructure[type][month] += value;
            });
          });
        }
      });
    });
    this.setState({ finance_report_json_rearranged: newStructure });
    console.log("new structure", newStructure);
  };
  render() {
    let tableData = { ...this.state.finance_report_json_rearranged };
    return (
      <Card style={{ margin: 10 }}>
        <Card style={{ margin: 10 }}>
          <Title level={4}>Finance Report</Title>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  views={["year", "month"]}
                  disableFuture={true}
                  inputVariant="outlined"
                  value={this.state.order_finance_report_from_view}
                  onChange={(value, dateString) => {
                    this.setState({
                      order_finance_report_from: moment(value)
                        .startOf("month")
                        .startOf("day")
                        .format("x"),
                      order_finance_report_from_view: value,
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  views={["year", "month"]}
                  disableFuture={true}
                  inputVariant="outlined"
                  value={this.state.order_finance_report_to_view}
                  onChange={(value, dateString) => {
                    this.setState({
                      order_finance_report_to: moment(value)
                        .endOf("month")
                        .endOf("day")
                        .format("x"),
                      order_finance_report_to_view: value,
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 15,
              }}
            >
              <Button
                style={{
                  backgroundColor: "#1A3337",
                  color: "white",
                }}
                onClick={() => {
                  this.downloadOrderFinanceReport();
                }}
              >
                Download Report
              </Button>
              <Button
                style={{
                  backgroundColor: "#1A3337",
                  color: "white",
                }}
                onClick={() => {
                  this.viewOrderFinanceReport();
                }}
              >
                View Data
              </Button>
            </div>
          </div>
        </Card>
        {Object.keys(tableData).length > 0 ? (
          <Card style={{ margin: 10 }}>
            <table
              style={{
                border: "1px solid grey",
                borderCollapse: "collapse",
                paddingTop: 15,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <tr>
                <th
                  style={{
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                    paddinTop:15,
                    paddingLeft:15,
                    paddingRight:15
                  }}
                >
                  Label
                </th>
                {Object.keys(tableData.Revenue).map((date) => (
                  <th key={date}>{date}</th>
                ))}
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                    paddinTop:15,
                    paddingLeft:15,
                    paddingRight:15
                  }}
                >
                  Revenue
                </th>
                {Object.keys(tableData.Revenue).map((revenue, index) => (
                  <td
                    style={{
                      border: "1px solid grey",
                      borderCollapse: "collapse",
                      paddinTop:15,
                      paddingLeft:15,
                      paddingRight:15
                    }}
                    key={index}
                  >
                    {tableData.Revenue[revenue]}
                  </td>
                ))}
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                    paddinTop:15,
                    paddingLeft:15,
                    paddingRight:15
                  }}
                >
                  Cost of goods sold(CGS)
                </th>
                {Object.keys(tableData["Cost of goods sold(CGS)"]).map(
                  (revenue, index) => (
                    <td
                      style={{
                        border: "1px solid grey",
                        borderCollapse: "collapse",
                        paddinTop:15,
                        paddingLeft:15,
                        paddingRight:15
                      }}
                      key={index}
                    >
                      {tableData["Cost of goods sold(CGS)"][revenue]}
                    </td>
                  )
                )}
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                    paddinTop:15,
                    paddingLeft:15,
                    paddingRight:15
                  }}
                >
                  Gross Profit(Revenue - CGS)
                </th>
                {Object.keys(tableData["Gross Profit(Revenue - CGS)"]).map(
                  (revenue, index) => (
                    <td
                      style={{
                        border: "1px solid grey",
                        borderCollapse: "collapse",
                        paddinTop:15,
                        paddingLeft:15,
                        paddingRight:15
                      }}
                      key={index}
                    >
                      {tableData["Gross Profit(Revenue - CGS)"][revenue]}
                    </td>
                  )
                )}
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                    paddinTop:15,
                    paddingLeft:15,
                    paddingRight:15
                  }}
                >
                  Purchase General
                </th>
                {Object.keys(tableData["Purchase General"]).map(
                  (purchase, index) => (
                    <td
                      style={{
                        border: "1px solid grey",
                        borderCollapse: "collapse",
                        paddinTop:15,
                        paddingLeft:15,
                        paddingRight:15
                      }}
                      key={index}
                    >
                      {tableData["Purchase General"][purchase]}
                    </td>
                  )
                )}
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                    paddinTop:15,
                    paddingLeft:15,
                    paddingRight:15
                  }}
                >
                  Expenses(Conveyance)
                </th>
                {Object.keys(tableData["Conveyance"]).map((revenue, index) => (
                  <td
                    style={{
                      border: "1px solid grey",
                      borderCollapse: "collapse",
                      paddinTop:15,
                      paddingLeft:15,
                      paddingRight:15
                    }}
                    key={index}
                  >
                    {tableData["Conveyance"][revenue]}
                  </td>
                ))}
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                    paddinTop:15,
                    paddingLeft:15,
                    paddingRight:15
                  }}
                >
                  Expenses(Entertainment)
                </th>
                {Object.keys(tableData["Entertainment"]).map(
                  (revenue, index) => (
                    <td
                      style={{
                        border: "1px solid grey",
                        borderCollapse: "collapse",
                        paddinTop:15,
                        paddingLeft:15,
                        paddingRight:15
                      }}
                      key={index}
                    >
                      {tableData["Entertainment"][revenue]}
                    </td>
                  )
                )}
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                    paddinTop:15,
                    paddingLeft:15,
                    paddingRight:15
                  }}
                >
                  Total Expense
                </th>
                {Object.keys(tableData["total_expense_earnings"]).map(
                  (revenue, index) => (
                    <td
                      style={{
                        border: "1px solid grey",
                        borderCollapse: "collapse",
                        paddinTop:15,
                        paddingLeft:15,
                        paddingRight:15
                      }}
                      key={index}
                    >
                      {
                        tableData["total_expense_earnings"][revenue].split(
                          ","
                        )[0]
                      }
                    </td>
                  )
                )}
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                    paddinTop:15,
                    paddingLeft:15,
                    paddingRight:15
                  }}
                >
                  Earning(Gross Profit - Total Expenses)
                </th>
                {Object.keys(tableData["total_expense_earnings"]).map(
                  (revenue, index) => (
                    <td
                      style={{
                        border: "1px solid grey",
                        borderCollapse: "collapse",
                        paddinTop:15,
                        paddingLeft:15,
                        paddingRight:15
                      }}
                      key={index}
                    >
                      {
                        tableData["total_expense_earnings"][revenue].split(
                          ","
                        )[1]
                      }
                    </td>
                  )
                )}
              </tr>
            </table>
          </Card>
        ) : null}
      </Card>
    );
  }
}
