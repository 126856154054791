import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  Input,
  Icon,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  ORGANIZATION_SETTINGS_LIST,
  GET_SMS_SETTINGS,
  SMS_SERVICE_PROVIDER_CHOICES,
} from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class SmsSettings extends Component {
  state = {
    org_setting_id: "",
    order_create: false,
    order_delivery_via_task: false,
    order_pick_up_via_task: false,
    use_own_sms_service: false,
    sms_service_provider: "",
    api_key: "",
    api_secret: "",
    sender_id: "",
    sms_providers: [],
    show_api_key: false,
    show_api_secret: false,
    api_mobile: "",
  };

  componentDidMount() {
    this.getOrganizationSettingsList();
    this.getSmsProviderList();
  }

  getSmsProviderList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${SMS_SERVICE_PROVIDER_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            sms_providers: response.data?.sms_provider_choices ?? [],
          });
          console.log("response from sms service provider list", response);
        })
        .catch((error) => {
          console.log("error from sms service provider list", error);
        });
    });
  };
  getOrganizationSettingsList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORGANIZATION_SETTINGS_LIST}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState(
            {
              org_setting_id: response.data?.data[0]?._id.$oid ?? "",
            },
            () => {
              this.getSmsSettings();
            }
          );
          console.log("response from organization settings list", response);
        })
        .catch((error) => {
          console.log("error from organization settings list api", error);
        });
    });
  };

  getSmsSettings = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${GET_SMS_SETTINGS}${this.state.org_setting_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            order_create: response.data?.data?.order_create ?? false,
            order_delivery_via_task:
              response.data?.data?.order_delivery_via_task ?? false,
            order_pick_up_via_task:
              response.data?.data?.order_pick_up_via_task ?? false,
            use_own_sms_service:
              response.data?.data?.use_own_sms_service ?? false,
            sms_service_provider:
              response.data?.data?.sms_service_credentials
                .sms_service_provider ?? "",
            api_key:
              response.data?.data?.sms_service_credentials.encrypted_api_key ??
              "",
            api_secret:
              response.data?.data?.sms_service_credentials
                .encrypted_api_secret ?? "",
            api_mobile:
              response.data?.data?.sms_service_credentials
                .encrypted_api_mobile ?? "",
            sender_id:
              response.data?.data?.sms_service_credentials.sender_id ?? "",
          });
          console.log("response from sms settings", response);
        })
        .catch((error) => {
          console.log("error from sms settings api", error);
        });
    });
  };

  setOrderCreate = (value) => {
    this.setState({ order_create: CHECK_BOOL(value) }, () => {
      console.log(this.state.order_create);
    });
  };
  setOrderDeliveryViaTask = (value) => {
    this.setState({ order_delivery_via_task: CHECK_BOOL(value) }, () => {
      console.log(this.state.order_delivery_via_task);
    });
  };
  setOrderPickupViaTask = (value) => {
    this.setState({ order_pick_up_via_task: CHECK_BOOL(value) }, () => {
      console.log(this.state.order_pick_up_via_task);
    });
  };
  setOwnSmsService = (value) => {
    this.setState({ use_own_sms_service: CHECK_BOOL(value) }, () => {
      console.log(this.state.use_own_sms_service);
    });
  };
  setSmsServiceProvider = (value) => {
    this.setState({ sms_service_provider: value }, () => {
      console.log(this.state.sms_service_provider);
    });
  };
  setApiKey = (evt) => {
    evt.preventDefault();
    this.setState({ api_key: evt.target.value }, () => {
      console.log(this.state.api_key);
    });
  };
  setApiMobile = (evt) => {
    evt.preventDefault();
    this.setState({ api_mobile: evt.target.value }, () => {
      console.log(this.state.api_mobile);
    });
  };
  setApiSecret = (evt) => {
    evt.preventDefault();
    this.setState({ api_secret: evt.target.value }, () => {
      console.log(this.state.api_secret);
    });
  };
  setSenderId = (evt) => {
    evt.preventDefault();
    this.setState({ sender_id: evt.target.value }, () => {
      console.log(this.state.sender_id);
    });
  };

  updateSmsSettings = () => {
    if (
      this.state.sms_service_provider === "twilio" &&
      this.state.api_mobile === ""
    ) {
      swal.fire("Info", "Please enter Twilio number", "info");
    } else {
      const data = {
        order_create: this.state.order_create,
        order_delivery_via_task: this.state.order_delivery_via_task,
        order_pick_up_via_task: this.state.order_pick_up_via_task,
        use_own_sms_service: this.state.use_own_sms_service,
        sms_service_credentials: {
          sms_service_provider: this.state.sms_service_provider,
          api_key: this.state.api_key,
          api_secret: this.state.api_secret,
          api_mobile: this.state.api_mobile,
          sender_id: this.state.sender_id,
        },
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${GET_SMS_SETTINGS}${this.state.org_setting_id}?access_token=${token}`;

        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            this.getOrganizationSettingsList();
            console.log("response from update sms Settings", response);
          })
          .catch((error) => {
            swal.fire("Error", error.message, "error");
            console.log("error from update sms Settings", error);
          });
      });
    }
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <div>
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)",
            }}
            title="SMS Settings"
          />
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column",
            }}
          >
            <Form.Item>
              <Text>Order Create</Text>
              <Select
                value={this.state.order_create.toString()}
                onChange={this.setOrderCreate}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Text>Order Delivery Via Task</Text>
              <Select
                value={this.state.order_delivery_via_task.toString()}
                onChange={this.setOrderDeliveryViaTask}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Text>Order Pickup Via Task</Text>
              <Select
                value={this.state.order_pick_up_via_task.toString()}
                onChange={this.setOrderPickupViaTask}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Text>Use Own SMS Service</Text>
              <Select
                value={this.state.use_own_sms_service.toString()}
                onChange={this.setOwnSmsService}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
            {this.state.use_own_sms_service ? (
              <>
                <Form.Item>
                  <Text>SMS Service Provider</Text>
                  <Select
                    value={this.state.sms_service_provider}
                    onChange={this.setSmsServiceProvider}
                  >
                    {this.state.sms_providers.map((s) => (
                      <Option key={s}>{s}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item>
                  <Text>API Key</Text>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Input
                      placeholder="API Key"
                      type={this.state.show_api_key ? "text" : "password"}
                      value={this.state.api_key}
                      onChange={this.setApiKey}
                    />
                    <Button
                      onClick={() =>
                        this.setState({
                          show_api_key: !this.state.show_api_key,
                        })
                      }
                    >
                      <Icon
                        type={this.state.show_api_key ? "eye" : "eye-invisible"}
                        style={{
                          marginTop: 3,
                          fontSize: 18,
                          color: "#7B8FA1",
                        }}
                      />
                    </Button>
                  </div>
                </Form.Item>
                {this.state.sms_service_provider === "adn" ? null : (
                  <Form.Item>
                    <Text>Twilio Number</Text>
                    <Input
                      placeholder="Twilio Number"
                      type="text"
                      value={this.state.api_mobile}
                      onChange={this.setApiMobile}
                    />
                  </Form.Item>
                )}

                <Form.Item>
                  <Text>API Secret</Text>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Input
                      placeholder="API Secret"
                      type={this.state.show_api_secret ? "text" : "password"}
                      value={this.state.api_secret}
                      onChange={this.setApiSecret}
                    />
                    <Button
                      onClick={() =>
                        this.setState({
                          show_api_secret: !this.state.show_api_secret,
                        })
                      }
                    >
                      <Icon
                        type={
                          this.state.show_api_secret ? "eye" : "eye-invisible"
                        }
                        style={{
                          marginTop: 3,
                          fontSize: 18,
                          color: "#7B8FA1",
                        }}
                      />
                    </Button>
                  </div>
                </Form.Item>
                <Form.Item>
                  <Text>Sender ID</Text>
                  <Input
                    placeholder="Sender ID"
                    type="text"
                    value={this.state.sender_id}
                    onChange={this.setSenderId}
                  />
                </Form.Item>
              </>
            ) : null}

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateSmsSettings}
              >
                Update SMS Settings
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    );
  }
}
