import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Typography,
  Select,
  Pagination
} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import FileSaver from "file-saver";
import moment from "moment";

import { ACHIEVEMENT_LIST,GENERATE_ACHIEVEMENT } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
    timeConverter,
} from "../../utilities/utilities";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;

export default class AchievementList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10
  };
  componentDidMount() {
    this.getAchievementList();

     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getAchievementList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${ACHIEVEMENT_LIST}?page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from Achievement list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total
          });
        })
        .catch(error => {
          console.log("error from Achievement list api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getAchievementList();
    });
  };

  setLimit = value => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getAchievementList();
    });
  };

  downloadReport = (p_id,status,target_id,fmt) => {
    GET_ACCESS_TOKEN().then(token => {
      const url =`${GENERATE_ACHIEVEMENT}?fmt=${fmt}&p_id=${p_id}&status=${status}&target_id=${target_id}&access_token=${token}`
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          console.log("response from achievement list fmt", blob);

          FileSaver.saveAs(
            blob,
            `achievement_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.${fmt==="xl"?"xlsx":"pdf"}`
          );
        })
        .catch(error => {
          console.log("error from achievement list fmt api", error);
        });
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>

        <PageHeader
          title="Achievements"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Target Name" dataIndex="target" key="target" />
          <Column title="Achievement Query" dataIndex="label" key="label" />
          <Column title="Status" dataIndex="status" key="status" />
          <Column
            title="Duration Type"
            key="duration_type"
            render={(text, record) => (
              <span>{record.duration_type}</span>
            )}
          />     
          <Column
            title="Start Date"
            key="start_date"
            render={(text, record) => (
              <span>{timeConverter(record.start_date.$date)}</span>
            )}
          />
          <Column
            title="End Date"
            key="end_date"
            render={(text, record) => (
              <span>{timeConverter(record.end_date.$date)}</span>
            )}
          />
          {/* <Column
            title="Created On"
            key="created_on"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          /> */}
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Link
                  to={{
                    pathname: "/achievementDetails",
                    state: { api: record.report_api }
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    View
                  </Button>
                </Link>
              
                  <Button type="link" style={{ color: "green" }} onClick={()=>this.downloadReport(record.p_id,record.status,record.target_id.$oid,"pdf")}>
                    Download PDF
                  </Button>
                  <Button type="link" style={{ color: "green" }} onClick={()=>this.downloadReport(record.p_id,record.status,record.target_id.$oid,"xl")}>
                    Download Excel
                  </Button>
                
              </span>
            )}
          />
        </Table>
         {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}
