import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Tag,
  Tooltip,
  Typography,
  Select,
  Pagination,
  Divider,
  Input,
  Icon
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { saveRequisitionListParams } from "../../redux/actions/requisitionListParamsAction";
import { getUserData } from "../../redux/actions/userProfileAction";
import { debounce } from "lodash";
import axios from "axios";
import swal from "sweetalert2";
import FileSaver from "file-saver";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ReactGA from "react-ga4";

import {
  EDIT_REQUISITION,
  REQUISITION_SEARCH,
  CREATE_REQUISITION,
  GENERATE_BILL_FROM_REQUISITION,
  REQUISITION_REPORT_PDF
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL
} from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Column } = Table;
const { Text, Title } = Typography;
const { Option } = Select;

class ViewRequisition extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    loading: false,
    param: "upcoming",
    timezone_name: "",
    timezone_choice: "org_timezone",
    from: "",
    from_view: moment(),
    to: "",
    to_view: moment(),
    report_type: "conveyance",
    custom_field: false,
    bill_type_name: "",
    name: "",
    approved: "",
    recommended: "",
    authorized: "",
    approved_price: "",
    recommended_price: "",
    authorized_price: "",
    approver_name: "",
    recommender_name: "",
    authorizer_name: "",
    task_name: "",
    asset_name: "",
    supplier_name: "",
    created_by_name: "",
    modified_by_name: "",
    start_time: "",
    start_time_view: moment(),
    end_time: "",
    end_time_view: moment(),
    search_date_time_type: "created_on",
    datarange: "",
    sort_by: "created_on",
    sort_type: "dsc"
  };
  componentDidMount() {
    this.props.getUserData();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ timezone_name: timezone });
    console.log("time zone", timezone);

    console.log("redux", this.props.requisitionListParams);
    if (Object.keys(this.props.requisitionListParams).length !== 0) {
      console.log("inside if");
      this.setState(
        {
          bill_type_name:
            this.props?.requisitionListParams?.bill_type_name ?? "",
          name: this.props?.requisitionListParams?.name ?? "",
          approved: this.props?.requisitionListParams?.approved ?? "",
          recommended: this.props?.requisitionListParams?.recommended ?? "",
          authorized: this.props?.requisitionListParams?.authorized ?? "",
          approved_price:
            this.props?.requisitionListParams?.approved_price ?? "",
          recommended_price:
            this.props?.requisitionListParams?.recommended_price ?? "",
          authorized_price:
            this.props?.requisitionListParams?.authorized_price ?? "",
          approver_name: this.props?.requisitionListParams?.approver_name ?? "",
          recommender_name:
            this.props?.requisitionListParams?.recommender_name ?? "",
          authorizer_name:
            this.props?.requisitionListParams?.authorizer_name ?? "",
          task_name: this.props?.requisitionListParams?.task_name ?? "",
          start_time: this.props?.requisitionListParams?.start_time ?? "",
          start_time_view:
            this.props?.requisitionListParams?.start_time_view ?? moment(),
          end_time: this.props?.requisitionListParams?.end_time ?? "",
          end_time_view:
            this.props?.requisitionListParams?.end_time_view ?? moment(),
          asset_name: this.props?.requisitionListParams?.asset_name ?? "",
          supplier_name: this.props?.requisitionListParams?.supplier_name ?? "",
          created_by_name:
            this.props?.requisitionListParams?.created_by_name ?? "",
          modified_by_name:
            this.props?.requisitionListParams?.modified_by_name ?? "",
          search_date_time_type:
            this.props?.requisitionListParams?.search_date_time_type ??
            "created_on",
          limit: this.props?.requisitionListParams?.limit ?? 10,
          page: this.props?.requisitionListParams?.page ?? 0,
          sort_by: this.props?.requisitionListParams?.sort_by ?? "created_by",
          sort_type: this.props?.requisitionListParams?.sort_type ?? "dsc"
        },
        () => {
          this.getRequisitionList();
        }
      );
    } else {
      this.getRequisitionList();
    }
  }
  setTimezonetype = value => {
    this.setState({ timezone_choice: value }, () => {
      console.log(this.state.timezone_choice);
    });
  };
  handleApproved = value => {
    this.setState({ approved: value }, () => {
      console.log(this.state.approved);
      this.getRequisitionList();
    });
  };
  handleRecomended = value => {
    this.setState({ recommended: value }, () => {
      console.log(this.state.recommended);
      this.getRequisitionList();
    });
  };
  handleAuthorised = value => {
    this.setState({ authorised: value }, () => {
      console.log(this.state.authorised);
      this.getRequisitionList();
    });
  };
  setTo = (value, dateString) => {
    this.setState(
      { to: moment(value).format("x") / 1000, to_view: value },
      () => {
        console.log("Selected Time: ", this.state.to);
      }
    );
  };
  setFrom = (value, dateString) => {
    this.setState(
      { from: moment(value).format("x") / 1000, from_view: value },
      () => {
        console.log("Selected Time: ", this.state.from);
      }
    );
  };

  getRequisitionList = debounce(() => {
    let reduxdata = {
      bill_type_name: this.state.bill_type_name,
      name: this.state.name,
      approved: this.state.approved,
      recommended: this.state.recommended,
      authorized: this.state.authorized,
      approved_price: this.state.approved_price,
      recommended_price: this.state.recommended_price,
      authorized_price: this.state.authorized_price,
      approver_name: this.state.approver_name,
      recommender_name: this.state.recommender_name,
      authorizer_name: this.state.authorizer_name,
      task_name: this.state.task_name,
      start_time: this.state.start_time,
      start_time_view: this.state.start_time_view,
      end_time: this.state.end_time,
      end_time_view: this.state.end_time_view,
      asset_name: this.state.asset_name,
      supplier_name: this.state.supplier_name,
      created_by_name: this.state.created_by_name,
      modified_by_name: this.state.modified_by_name,
      search_date_time_type: this.state.search_date_time_type,
      sort_by: this.state.sort_by,
      sort_type: this.state.sort_type,
      limit: this.state.limit,
      page: this.state.page
    };
    this.props.saveRequisitionListParams(reduxdata);
    console.log("reduxdata going in", reduxdata);
    GET_ACCESS_TOKEN().then(token => {
      const url = `${REQUISITION_SEARCH}?page=${this.state.page}&limit=${this.state.limit}&sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&modified_by_name=${this.state.modified_by_name}&created_by_name=${this.state.created_by_name}&supplier_name=${this.state.supplier_name}&asset_name=${this.state.asset_name}&task_name=${this.state.task_name}&recommender_name=${this.state.recommender_name}&authorizer_name=${this.state.authorizer_name}&approver_name=${this.state.approver_name}&authorized_price=${this.state.authorized_price}&recommended_price=${this.state.recommended_price}&approved_price=${this.state.approved_price}&authorized=${this.state.authorized}&recommended=${this.state.recommended}&approved=${this.state.approved}approved&end_time=${this.state.end_time}&start_time=${this.state.start_time}&name=${this.state.name}&search_date_time_type=${this.state.search_date_time_type}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from requisition list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
            datarange: response.data.show_data
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from Requisition list api", error);
        });
    });
  },500);

  refresh = () => {
    this.setState(
      {
        bill_type_name: "",
        name: "",
        approved: "",
        recommended: "",
        authorized: "",
        approved_price: "",
        recommended_price: "",
        authorized_price: "",
        approver_name: "",
        recommender_name: "",
        authorizer_name: "",
        task_name: "",
        asset_name: "",
        supplier_name: "",
        created_by_name: "",
        modified_by_name: "",
        sort_by: "created_by",
        sort_type: "dsc",
        start_time: "",
        start_time_view: moment(),
        end_time: "",
        end_time_view: moment(),
        search_date_time_type: "created_on",
        page: 0,
        limit: 10
      },
      () => {
        this.getRequisitionList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getRequisitionList();
    });
  };

  setLimit = value => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getRequisitionList();
    });
  };

  deleteOrder = id => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${EDIT_REQUISITION}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error, "error");
          console.log("error from del", error);
        });
    });
  };

  handleSaveFile = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url =
        this.state.timezone_choice === "org_timezone"
          ? `${CREATE_REQUISITION}?fmt=xls&timezone_choice=${this.state.timezone_choice}&access_token=${token}`
          : `${CREATE_REQUISITION}?fmt=xls&timezone_choice=${this.state.timezone_choice}&timezone_name=${this.state.timezone_name}&access_token=${token}`;
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          console.log("response from requisition list fmt", blob);

          FileSaver.saveAs(
            blob,
            `requisition_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch(error => {
          console.log("error from requisition list api", error);
        });
    });
  };

  downloadPdfReport = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${REQUISITION_REPORT_PDF}?access_token=${token}`;
      const data = {
        from: Number(this.state.from),
        to: Number(this.state.to)
      };
      console.log(data);
      fetch(url, {
        method: "POST",
        body: JSON.stringify(data)
      })
        .then(res => res.blob())
        .then(blob => {
          console.log("response from requisition pdf report", blob);

          FileSaver.saveAs(
            blob,
            `requisition_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.pdf`
          );
        })
        .catch(error => {
          console.log("error from requisition pdf report", error);
        });
    });
  };

  generateBill = requisition => {
    requisition.items.forEach(item => {
      item.recommended_price = 0;
      item.authorized_price = 0;
      item.approved_price = 0;
    });

    console.log(requisition.items);
    let data = {
      requisition_id: requisition._id.$oid,
      name: requisition.name,
      details: requisition.details,
      contact_no: requisition.contact_no,
      supplier: requisition.supplier,
      items: requisition.items,
      approved: requisition.approved,
      recommended: requisition.recommended,
      authorized: requisition.authorized,
      organization: requisition.organization.$oid,
      sub_org: requisition.sub_org
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${GENERATE_BILL_FROM_REQUISITION}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          // this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          console.log("response from generate bill from requisition", response);
        })
        .catch(error => {
          // this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from generate bill from requisition", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.requisition.requisition_create ? (
          <Link to="/createRequisition">
            <Tooltip placement="top" title="Create Requisition">
            <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}

        <PageHeader
          title="Requisitions"
        />
        {/* <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
         
          <div style={{ margin: 5 }}>
            <Text style={{ margin: 5 }}>Recommended : </Text>
            <Select
              style={{ width: 100 }}
              value={this.state.recommended.toString()}
              onChange={this.handleRecomended}
            >
              <Option key={true}>true</Option>
              <Option key={false}>false</Option>
            </Select>
          </div>
          <div style={{ margin: 5 }}>
            <Text style={{ margin: 5 }}>Authorized : </Text>
            <Select
              style={{ width: 100 }}
              value={this.state.authorised.toString()}
              onChange={this.handleAuthorised}
            >
              <Option key={true}>true</Option>
              <Option key={false}>false</Option>
            </Select>
          </div>
          <div style={{ margin: 5 }}>
            <Text style={{ margin: 5 }}>Approved : </Text>
            <Select
              style={{ width: 100 }}
              value={this.state.approved.toString()}
              onChange={this.handleApproved}
            >
              <Option key={true}>true</Option>
              <Option key={false}>false</Option>
            </Select>
          </div>
        </div> */}
        <Card style={{ marginTop: 5, marginBottom: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left"
            }}
          >
            <div style={{ margin: 10 }}>
              <Text> Timezone : {""}</Text>
              <Select
                value={this.state.timezone_choice}
                onChange={this.setTimezonetype}
                placeholder="View as"
              >
                <Option key="user_timezone">User</Option>
                <Option key="org_timezone">Organization</Option>
              </Select>
            </div>
            <div style={{ margin: 10 }}>
              <Button
                onClick={this.handleSaveFile}
                style={{
                  width: 200,
                  backgroundColor: "#1A3337",
                  color: "white"
                }}
              >
                Download Report
              </Button>
            </div>
          </div>
        </Card>
        <Card style={{ marginTop: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              marginBottom: 10
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.from_view}
                  onChange={this.setFrom}
                />
              </MuiPickersUtilsProvider>
            </div>
            <Text style={{ paddingTop: 20 }}>---</Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.to_view}
                  onChange={this.setTo}
                />
              </MuiPickersUtilsProvider>
            </div>
            <Button
              onClick={this.downloadPdfReport}
              style={{
                width: 200,
                backgroundColor: "#1A3337",
                color: "white",
                margin: 10,
                marginTop: 15
              }}
            >
              Download PDF
            </Button>
          </div>
        </Card>
        <Card style={{ margin: 3 }}>
          <Title level={4}>
            Select daterange within which you would like to find your
            requisitions
          </Title>

          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).format("x"),
                        start_time_view: value,
                        page: 0
                      },
                      () => {
                        this.getRequisitionList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        end_time: moment(value).format("x"),
                        end_time_view: value,
                        page: 0
                      },
                      () => {
                        this.getRequisitionList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 15
              }}
            >
              <Text style={{ margin: 5 }}>Filter Time By : </Text>
              <Select
                value={this.state.search_date_time_type}
                onChange={value => {
                  this.setState({ search_date_time_type: value }, () => {
                    this.getRequisitionList();
                    console.log(this.state.search_date_time_type);
                  });
                }}
                style={{ minWidth: 130 }}
              >
                <Option key="created_on">Created on</Option>
                <Option key="modified_on">Modified on</Option>
                <Option key="recommended_on">Recommended on</Option>
                <Option key="authorized_on">Authorized on</Option>
                <Option key="approved_on">Approved on</Option>
              </Select>
            </div>
          </div>
        </Card>

        <div
          style={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
        </div>

        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Name</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "asc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "dsc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.name}
                  onChange={evt => {
                    this.setState({ name: evt.target.value, page: 0 }, () => {
                      this.getRequisitionList()
                    });
                  }}
                />
              </>
            }
            dataIndex="name"
            key="name"
          />
          <Column title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Details</Text>
                  <Input disabled />
                </div>
              </>
            } dataIndex="details" key="details" />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                  
                  <Text ellipsis={true}>Recommended Price</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_recommended_price",
                            sort_type: "asc"
                          },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_recommended_price",
                            sort_type: "dsc"
                          },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.recommended_price}
                  onChange={evt => {
                    this.setState(
                      { recommended_price: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="total_recommended_price"
            key="total_recommended_price"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Recommended</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "recommended", sort_type: "asc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "recommended", sort_type: "dsc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.recommended}
                  onChange={evt => {
                    this.setState(
                      { recommended: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
              </>
            }
            key="Recommended"
            render={(text, record) => (
              <span>{record.recommended.toString()}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Recommender</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "recommender_name", sort_type: "asc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "recommender_name", sort_type: "dsc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.recommender_name}
                  onChange={evt => {
                    this.setState(
                      { recommender_name: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
              </>
            }
            key="Recommender"
            render={(text, record) => (
              <span>{record.recommender_name.toString()}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Authorized Price</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_authorized_price",
                            sort_type: "asc"
                          },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_authorized_price",
                            sort_type: "dsc"
                          },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.authorized_price}
                  onChange={evt => {
                    this.setState(
                      { authorized_price: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="total_authorized_price"
            key="total_authorized_price"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Authorized</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "authorized", sort_type: "asc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "authorized", sort_type: "dsc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.authorized}
                  onChange={evt => {
                    this.setState(
                      { authorized: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
              </>
            }
            key="Authorized"
            render={(text, record) => (
              <span>{record.authorized.toString()}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Authorizer</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "authorizer_name", sort_type: "asc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "authorizer_name", sort_type: "dsc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.authorizer_name}
                  onChange={evt => {
                    this.setState(
                      { authorizer_name: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
              </>
            }
            key="Authorizer"
            render={(text, record) => (
              <span>{record.authorizer_name.toString()}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Approved Price</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "total_approved_price", sort_type: "asc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "total_approved_price", sort_type: "dsc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.approved_price}
                  onChange={evt => {
                    this.setState(
                      { approved_price: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="total_approved_price"
            key="total_approved_price"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Approved</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "approved", sort_type: "asc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "approved", sort_type: "dsc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.approved}
                  onChange={evt => {
                    this.setState(
                      { approved: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
              </>
            }
            key="Approved"
            render={(text, record) => <span>{record.approved.toString()}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Approver</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "approver_name", sort_type: "asc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "approver_name", sort_type: "dsc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.approver_name}
                  onChange={evt => {
                    this.setState(
                      { approver_name: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
              </>
            }
            key="Approver"
            render={(text, record) => (
              <span>{record.approver_name.toString()}</span>
            )}
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Price</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="total_price"
            key="total_price"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Task Name</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_name", sort_type: "asc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_name", sort_type: "dsc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.task_name}
                  onChange={evt => {
                    this.setState(
                      { task_name: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
              </>
            }
            key="Task Name"
            render={(text, record) => <span>{record?.task_name ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Asset Name</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "asset_name", sort_type: "asc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "asset_name", sort_type: "dsc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.asset_name}
                  onChange={evt => {
                    this.setState(
                      { asset_name: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
              </>
            }
            key="Asset Name"
            render={(text, record) => <span>{record?.asset_name ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Supplier Name</Text>
                </div>

                <Input
                  value={this.state.supplier_name}
                  onChange={evt => {
                    this.setState(
                      { supplier_name: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
                {/* <Icon
                  type="caret-up"
                  onClick={() => {
                    this.setState(
                      { sort_by: "name", sort_type: "asc" },
                      () => {
                        this.getRequisitionList();
                      }
                    );
                  }}
                />
                <Icon
                  type="caret-down"
                  onClick={() => {
                    this.setState(
                      { sort_by: "name", sort_type: "dsc" },
                      () => {
                        this.getRequisitionList();
                      }
                    );
                  }}
                /> */}
              </>
            }
            key="Supplier Name"
            render={(text, record) => (
              <span>{record?.supplier?.name ?? ""}</span>
            )}
          />

          {/* <Column
            title="Supplier Name"
            dataIndex="supplier.name"
            key="supplier.name"
          /> */}
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Item Name</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="items"
            key="items"
            render={items => (
              <span>
                {items.map(item => (
                  <Tag
                    color="blue"
                    key={item.item}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2
                    }}
                  >
                    {item.item}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Item Type</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="items"
            key="items.mtype"
            render={items => (
              <span>
                {items.map(item => (
                  <Tag
                    color="blue"
                    key={item.item}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2
                    }}
                  >
                    {item?.mtype?.name ?? ""}
                  </Tag>
                ))}
              </span>
            )}
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Item Quantity</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="items"
            key="items2"
            render={items => (
              <span>
                {items.map(item => (
                  <Tag
                    color="green"
                    key={item.item}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2
                    }}
                  >
                    {item.qty} {item.unit}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Item Price</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="items"
            key="items3"
            render={items => (
              <span>
                {items.map(item => (
                  <Tag
                    color="red"
                    key={item.item}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2
                    }}
                  >
                    {item.price}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Created On</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Created By</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_by_name", sort_type: "asc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_by_name", sort_type: "dsc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.created_by_name}
                  onChange={evt => {
                    this.setState(
                      { created_by_name: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
              </>
            }
            key="Created By"
            render={(text, record) => (
              <span>{record?.created_by_name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Modified By</Text>
                  <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "modified_by_name", sort_type: "asc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "modified_by_name", sort_type: "dsc" },
                          () => {
                            this.getRequisitionList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.modified_by_name}
                  onChange={evt => {
                    this.setState(
                      { modified_by_name: evt.target.value, page: 0 },
                      () => {
                        this.getRequisitionList()
                      }
                    );
                  }}
                />
              </>
            }
            key="Modified By"
            render={(text, record) => (
              <span>{record?.modified_by_name ?? ""}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Link
                  to={{
                    pathname: "/createReminder",
                    state: {
                      requisition_id: record._id.$oid,
                      requisition_name: record.name
                    }
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    Add Reminder
                  </Button>
                </Link>
                {this.props.userProfileData.requisition.requisition_detail ? (
                  <Link
                    to={{
                      pathname: "/requisitionDetails",
                      state: { req_id: record._id.$oid }
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.requisition.requisition_update ? (
                  <Link
                    to={{
                      pathname: "/editRequisition",
                      state: { req_id: record._id.$oid }
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}

                {GET_USER_LOCAL().org_admin ||
                this.props.userProfileData.requisition.requisition_delete ? (
                  <div>
                    <Button
                      type="link"
                      style={{ color: "red" }}
                      onClick={() => {
                        if (window.confirm("Delete the item?")) {
                          this.deleteOrder(record._id.$oid);
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                ) : null}
              </span>
            )}
          />
          {/* <Column
            title="Generate Bill"
            key="Generate Bill"
            render={(text, record) => (
              <span>
                <button
                  className="borderless-button"
                  style={{ color: "green" }}
                  onClick={() => this.generateBill(record)}
                >
                  Generate
                </button>
              </span>
            )}
          /> */}
        </Table>
        <div style={{ marginRight: 10, marginTop: 15 }}>
          <Text>{`Displaying ${this.state.datarange} of ${this.state.total}`}</Text>
        </div>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  requisitionListParams: state.requisitionListParams.requisitionListParamsData,
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, {
  saveRequisitionListParams,
  getUserData
})(ViewRequisition);
