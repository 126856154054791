import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { PARCEL_FROM_ORDER, EDIT_ORDER } from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class ParcelfromOrder extends Component {
  state = {
    order_id: "",
    pl: null,
    pw: null,
    ph: null,
    unit_dim: null,
    unit_weight: null,
    parcel_weight: null,
    gross_weight: null,
    max_weight: null,
    fragile: false,
    sensitive: false,
    fast: false,
    temperature: null,
    loading: false,
    order_number: "",
    name: "",
    items:[],
    parcelData: [],
    showGenerateparcelButton: false,
    parceldataLength: 0
  };

  componentDidMount() {
    this.getOrderDetails();
  }

  getOrderDetails = () => {
    this.setState({ order_id: this.props.location.state.order_id });

    GET_ACCESS_TOKEN().then(token => {
      let order_id = this.props.location.state.order_id;
      let url = `${EDIT_ORDER}${order_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro view order", response.data.data);
        this.setState({
          name: response.data.data.name,
          order_number: response.data.data.order_number,
          items: response.data.data.items
        });
      });
    });
  };

  setpl = evt => {
    evt.preventDefault();
    this.setState({ pl: evt.target.value }, () => {
      console.log(this.state.pl);
    });
  };
  setpw = evt => {
    evt.preventDefault();
    this.setState({ pw: evt.target.value }, () => {
      console.log(this.state.pw);
    });
  };
  setph = evt => {
    evt.preventDefault();
    this.setState({ ph: evt.target.value }, () => {
      console.log(this.state.ph);
    });
  };
  setDim = evt => {
    evt.preventDefault();
    this.setState({ unit_dim: evt.target.value }, () => {
      console.log(this.state.unit_dim);
    });
  };
  setWeight = evt => {
    evt.preventDefault();
    this.setState({ unit_weight: evt.target.value }, () => {
      console.log(this.state.unit_weight);
    });
  };
  setPweight = evt => {
    evt.preventDefault();
    this.setState({ parcel_weight: evt.target.value }, () => {
      console.log(this.state.parcel_weight);
    });
  };
  setMaxweight = evt => {
    evt.preventDefault();
    this.setState({ max_weight: evt.target.value }, () => {
      console.log(this.state.max_weight);
    });
  };
  setGrossweight = evt => {
    evt.preventDefault();
    this.setState({ gross_weight: evt.target.value }, () => {
      console.log(this.state.gross_weight);
    });
  };

  setFragile = value => {
    this.setState({ fragile: CHECK_BOOL(value) }, () => {
      console.log(this.state.fragile);
    });
  };
  setSensitive = value => {
    this.setState({ sensitive: CHECK_BOOL(value) }, () => {
      console.log(this.state.sensitive);
    });
  };
  setFast = value => {
    this.setState({ fast: CHECK_BOOL(value) }, () => {
      console.log(this.state.fast);
    });
  };
  setTemperature = evt => {
    this.setState({ temperature: evt.target.value }, () => {
      console.log(this.state.temperature);
    });
  };

  addParcelData = () => {
    let ParcelDataObject = {
      order_id: this.state.order_id,
      parcel_dim: {
        pl: Number(this.state.pl),
        pw: Number(this.state.pw),
        ph: Number(this.state.ph)
      },
      items:this.state.items,
      parcel_weight: Number(this.state.parcel_weight),
      gross_weight: Number(this.state.gross_weight),
      unit_dim: Number(this.state.unit_dim),
      unit_weight: Number(this.state.unit_weight),
      max_weight: Number(this.state.max_weight),
      fragile: this.state.fragile,
      temperature: {
        sensitive: this.state.sensitive,
        temperature: Number(this.state.temperature),
        fast: this.state.fast
      }
    };
    let tempParcelData = [];
    tempParcelData.push(ParcelDataObject);
    this.setState(
      { parcelData: [...this.state.parcelData, ...tempParcelData] },
      () => {
        console.log("temporary data", this.state.parcelData);
        this.setState({ parceldataLength: this.state.parcelData.length });
      }
    );
  };

  createParcel = () => {
    if (
      this.state.pl === null ||
      this.state.ph === null ||
      this.state.pw === null ||
      this.state.unit_dim === null ||
      this.state.unit_weight === null ||
      this.state.parcel_weight === null ||
      this.state.max_weight === null
    ) {
      swal.fire(
        "warning",
        "Please fill up dimension,parcel weight,maximum weight,length,width and height",
        "warning"
      );
    } else {
      this.setState({ loading: true });
      const data = {
        parcels: this.state.parcelData
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${PARCEL_FROM_ORDER}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("Success", response.data.message, "success");

            console.log("response from order to parcels", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from order to parcels", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title={`Generate Multiple Parcels from Order # ${this.state.order_number}(${this.state.name})`}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Form.Item style={{ padding: 5 }}>
              <Text>Parcel length (In Feet)</Text>
              <Input
                placeholder="Parcel length"
                type="number"
                value={this.state.pl}
                onChange={this.setpl}
              />
            </Form.Item>
            <Form.Item style={{ padding: 5 }}>
              <Text>Parcel Width (In Feet)</Text>
              <Input
                placeholder="Parcel Width"
                type="number"
                value={this.state.pw}
                onChange={this.setpw}
              />
            </Form.Item>
            <Form.Item style={{ padding: 5 }}>
              <Text>Parcel Height (In Feet)</Text>
              <Input
                placeholder="Parcel Height"
                type="number"
                value={this.state.ph}
                onChange={this.setph}
              />
            </Form.Item>
            <Form.Item style={{ padding: 5 }}>
              <Text>Parcel Weight (In KG)</Text>
              <Input
                placeholder="Parcel Weight"
                type="number"
                value={this.state.parcel_weight}
                onChange={this.setPweight}
              />
            </Form.Item>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Form.Item style={{ padding: 5 }}>
              <Text>Unit Dimension</Text>
              <Input
                placeholder="Unit Dimension"
                type="number"
                value={this.state.unit_dim}
                onChange={this.setDim}
              />
            </Form.Item>

            <Form.Item style={{ padding: 5 }}>
              <Text>Unit Weight (In KG)</Text>
              <Input
                placeholder="Unit Weight"
                type="number"
                value={this.state.unit_weight}
                onChange={this.setWeight}
              />
            </Form.Item>
            <Form.Item style={{ padding: 5 }}>
              <Text>Unit Max Weight (In KG)</Text>
              <Input
                placeholder="Unit Maximum Weight"
                type="number"
                value={this.state.max_weight}
                onChange={this.setMaxweight}
              />
            </Form.Item>
            <Form.Item style={{ padding: 5 }}>
              <Text>Gross Weight (In KG)</Text>
              <Input
                placeholder="Gross Weight"
                type="number"
                value={this.state.gross_weight}
                onChange={this.setGrossweight}
              />
            </Form.Item>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Form.Item style={{ padding: 5 }}>
              <Text>Fragile</Text>
              <Select
                value={this.state.fragile.toString()}
                onChange={this.setFragile}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
            <Form.Item style={{ padding: 5 }}>
              <Text>Temperature</Text>
              <Input
                placeholder="Temperature"
                type="number"
                value={this.state.temperature}
                onChange={this.setTemperature}
              />
            </Form.Item>
            <Form.Item style={{ padding: 5 }}>
              <Text>Sensitive</Text>
              <Select
                value={this.state.sensitive.toString()}
                onChange={this.setSensitive}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
            <Form.Item style={{ padding: 5 }}>
              <Text>Fast</Text>
              <Select
                value={this.state.fast.toString()}
                onChange={this.setFast}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
          <Form.Item>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.addParcelData}
                >
                  {`Add Parcel Data (${this.state.parceldataLength} Selected)`}
                </Button>
              </Form.Item>
            {this.state.parceldataLength > 0 ? (
              <Form.Item>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.createParcel}
                >
                  {this.state.parceldataLength > 1
                    ? `Generate ${this.state.parceldataLength} Parcels`
                    : "Generate Parcel"}
                </Button>
              </Form.Item>
            ) :null }
            {this.state.parceldataLength > 0 ? (
              <Form.Item>
              <Button
                className="login-form-button"
                style={{ backgroundColor: "#1A3337", border: "none" }}
                htmlType="submit"
                onClick={() =>
                  this.setState({
                    parcelData:[],parceldataLength:0
                  })
                }
              >
                Clear Data
              </Button>
            </Form.Item>
            ):null}
            
          </div>
        </Form>
      </Card>
    );
  }
}
