import React from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Divider,
} from "antd";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
// import DateFnsUtils from "@date-io/date-fns";
// import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  CREATE_DRIVER,
  CREATE_ORGANIZATION,
  CREATE_COUNTRY,
  EDIT_COUNTRY,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  CHECK_BOOL,
  GET_USER_LOCAL,
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import { countryCode } from "../countryCode";
import ReactGA from "react-ga4";

import "../login.css";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

export default class CreateDriver extends React.Component {
  state = {
    name: "",
    email: "",
    mobile: "",
    email_verified: false,
    mobile_verified: false,
    password: "",
    address: "",
    city: "",
    country: "",
    security_id: "",
    organization: "",
    org_id: "",
    contact_no: "",
    emr_contact_name: "",
    blood_grp: "",
    lic_no: "",
    exp_date: moment(),
    exp_date_view: moment(),
    player_id: "",
    data: [],
    cities: [],
    filtered: [],
    countries: [],
    city_id: "",
    country_id: "",
    loading: false,
    org_admin: false,
    is_operator: false,
    is_admin: false,
    timezone_name: "",
    countryCode: [],
    code: "+880",
    designation: "",
    is_inventory: false,
  };

  componentDidMount() {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ timezone_name: timezone, countryCode: countryCode });
    console.log("time zone", timezone);
    this.countryList();
    this.getOrganizationList();
    this.setState({ is_admin: GET_USER_LOCAL().org_admin });
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  countryList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 200;
      const url = `${CREATE_COUNTRY}?limit=${limit}&d=${false}&sort_by="name"&show="no"&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ countries: response.data.data }, () => {
            console.log("dropdown", this.state.countries);
          });
        })
        .catch((error) => {
          console.log("error from country list api", error);
        });
    });
  };

  getOrganizationList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 1000;
      const url = `${CREATE_ORGANIZATION}?limit=${limit}&get_connected_orgs=true&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from organization list create user", response);
          this.setState({ data: response.data.data });
        })
        .catch((error) => {
          console.log("error from org list create user api", error);
        });
    });
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setDesignation = (evt) => {
    evt.preventDefault();
    this.setState({ designation: evt.target.value }, () => {
      console.log(this.state.designation);
    });
  };
  setEmail = (evt) => {
    evt.preventDefault();
    this.setState({ email: evt.target.value }, () => {
      console.log(this.state.email);
    });
  };
  setCode = (value) => {
    this.setState({ code: value }, () => {
      console.log(this.state.code);
    });
  };
  setMobile = (evt) => {
    evt.preventDefault();
    this.setState({ mobile: evt.target.value }, () => {
      console.log(this.state.mobile);
    });
  };
  handleEmailVchange = (value) => {
    this.setState({ email_verified: CHECK_BOOL(value) }, () => {
      console.log(this.state.email_verified);
    });
  };
  handleMobileVchange = (value) => {
    this.setState({ mobile_verified: CHECK_BOOL(value) }, () => {
      console.log(this.state.mobile_verified);
    });
  };
  handleOperatorChange = (value) => {
    this.setState({ is_operator: CHECK_BOOL(value) }, () => {
      console.log(this.state.is_operator);
    });
  };
  handleAdminChange = (value) => {
    this.setState({ org_admin: CHECK_BOOL(value) }, () => {
      console.log(this.state.org_admin);
    });
  };
  setPassword = (evt) => {
    evt.preventDefault();
    this.setState({ password: evt.target.value }, () => {
      console.log(this.state.password);
    });
  };
  setAddress = (evt) => {
    evt.preventDefault();
    this.setState({ address: evt.target.value }, () => {
      console.log(this.state.address);
    });
  };
  handleSearch = (value) => {
    const result = this.state.cities.filter((city) => {
      return city.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered: result });
  };
  setCity = (value) => {
    this.setState({ city: value.label, city_id: value.key }, () => {
      console.log(this.state.city);
    });
  };
  setCountry = (value) => {
    this.setState({ country: value.label, country_id: value.key }, () => {
      console.log(this.state.country);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_COUNTRY}${this.state.country_id}?access_token=${token}`;

        axios({
          method: "get",
          url: url,
        }).then((response) => {
          console.log("response fro edit country", response.data.data);
          this.setState({
            cities: response.data.data.cities,
          });
        });
      });
    });
  };
  setSecurity = (evt) => {
    evt.preventDefault();
    this.setState({ security_id: evt.target.value }, () => {
      console.log(this.state.security_id);
    });
  };
  setOrganization = (value) => {
    this.setState({ organization: value.label, org_id: value.key }, () => {
      console.log(this.state.organization);
    });
  };
  setContact = (evt) => {
    evt.preventDefault();
    this.setState({ contact_no: evt.target.value }, () => {
      console.log(this.state.contact_no);
    });
  };
  setEmergencyContact = (evt) => {
    evt.preventDefault();
    this.setState({ emr_contact_name: evt.target.value }, () => {
      console.log(this.state.emr_contact_name);
    });
  };
  setBloodgroup = (evt) => {
    evt.preventDefault();
    this.setState({ blood_grp: evt.target.value }, () => {
      console.log(this.state.blood_grp);
    });
  };
  setLicense = (evt) => {
    evt.preventDefault();
    this.setState({ lic_no: evt.target.value }, () => {
      console.log(this.state.lic_no);
    });
  };
  setExpiryDate = (value, dateString) => {
    this.setState(
      { exp_date: moment(value).format("x"), exp_date_view: value },
      () => {
        console.log("Selected Time: ", this.state.exp_date);
        console.log("Selected value: ", this.state.exp_date_view);
      }
    );
  };

  createDriver = () => {
    if (
      this.state.name === "" ||
      this.state.city === "" ||
      this.state.country === "" ||
      this.state.address === "" ||
      this.state.password === "" ||
      this.state.organization === ""
    ) {
      swal.fire("Info", "Please enter all informations", "info");
    } else if (this.state.mobile === "" && this.state.email === "") {
      swal.fire("Info", "Please enter mobile or email", "info");
    } else {
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        designation: this.state.designation,
        email: this.state.email,
        mobile:
          this.state.mobile !== "" ? this.state.code + this.state.mobile : "",
        email_verified: this.state.email_verified,
        mobile_verified: this.state.mobile_verified,
        password: this.state.password,
        address: this.state.address,
        city: this.state.city,
        country: this.state.country,
        security: this.state.security_id,
        organization: this.state.org_id,
        contact_no: this.state.contact_no,
        emr_contact_name: this.state.emr_contact_name,
        blood_grp: this.state.blood_grp,
        lic_no: this.state.lic_no,
        // exp_date: this.state.exp_date,
        player_id: this.state.player_id,
        is_operator: this.state.is_operator,
        org_admin: this.state.org_admin,
        tz: this.state.timezone_name,
        is_inventory: this.state.is_inventory,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_DRIVER}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({ loading: false });
            swal.fire("Info", response.data.message, "info");
            console.log("response from create driver", response);
          })
          .catch((error) => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from create driver", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    const citiesList = this.state.cities.map((r) => {
      return <Option key={r.code}>{r.name}</Option>;
    });

    const FliteredList = this.state.filtered.map((r) => {
      return <Option key={r.code}>{r.name}</Option>;
    });
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title="Create User (* Marked fields are mandatory)"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Name *</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Designation </Text>
            <Input
              placeholder="Designation"
              type="text"
              value={this.state.designation}
              onChange={this.setDesignation}
            />
          </Form.Item>
          {/* <Form.Item>
            <Text>Father's Name </Text>
            <Input
              placeholder="Father's Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Mother's Name </Text>
            <Input
              placeholder="Mother's Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item> */}
          {/* temp code starts here */}
          {/* <Form.Item>
            <Text>Date of Joining</Text>
            <Input
              placeholder="Date of Joining"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
           <Form.Item>
            <Text>Posting Area</Text>
            <Input
              placeholder="Posting Area"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
           <Form.Item>
            <Text>Date of Birth</Text>
            <Input
              placeholder="Date of Birth"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Registration Number</Text>
            <Input
              placeholder="Registration Number"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item> */}
          {/* temp code ends here */}
          <Divider type="horizontal">
            Please enter a valid email address or mobile number
          </Divider>

          <Form.Item>
            <Text>Email</Text>
            <Input
              placeholder="Email"
              type="text"
              value={this.state.email}
              onChange={this.setEmail}
            />
          </Form.Item>
          <Form.Item>
            <Text>Mobile</Text>
            <Select
              value={this.state.code}
              onChange={this.setCode}
              placeholder="Select Code"
            >
              {this.state.countryCode.map((r) => {
                return (
                  <Option key={r.dial_code}>
                    {r.name} {r.dial_code}
                  </Option>
                );
              })}
            </Select>
            <Input
              placeholder="Example-1711123456"
              type="number"
              value={this.state.mobile}
              onChange={this.setMobile}
            />
          </Form.Item>
          <Divider type="horizontal"></Divider>

          {/* <Form.Item>
            <Text>Email Verified</Text>
            <Select value={this.state.email_verified.toString()} onChange={this.handleEmailVchange}>
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Mobile Verified</Text>
            <Select value={this.state.mobile_verified.toString()} onChange={this.handleMobileVchange}>
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item> */}
          {/* <Form.Item>
            <Text>Operator</Text>
            <Select
              disabled={this.state.is_admin ? false : true}
              value={this.state.is_operator.toString()}
              onChange={this.handleOperatorChange}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item> */}
          <Form.Item>
            <Text>Org Admin</Text>
            <Select
              disabled={this.state.is_admin ? false : true}
              value={this.state.org_admin.toString()}
              onChange={this.handleAdminChange}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Inventory</Text>
            <Select
              value={this.state.is_inventory.toString()}
              onChange={(value) => {
                this.setState({ is_inventory: CHECK_BOOL(value) }, () => {
                  console.log(this.state.is_inventory);
                });
              }}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Password *</Text>
            <Input
              placeholder="Password"
              type="text"
              value={this.state.password}
              onChange={this.setPassword}
            />
          </Form.Item>

          <Form.Item>
            <Text>Address *</Text>

            <TextArea
              rows={4}
              placeholder="Address"
              type="text"
              value={this.state.address}
              onChange={this.setAddress}
            />
          </Form.Item>
          {/* <Form.Item>
            <Text>Permanent Address</Text>

            <TextArea
              rows={4}
              placeholder="Permanent Address"
              type="text"
              value={this.state.address}
              onChange={this.setAddress}
            />
          </Form.Item> */}
          <Form.Item>
            <Text>Country *</Text>
            <Select
              labelInValue
              value={{ key: this.state.country_id, label: this.state.country }}
              onChange={this.setCountry}
              placeholder="Select Country"
            >
              {this.state.countries.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Search City *</Text>

            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.handleSearch}
              disabled={this.state.country === "" ? true : false}
              value={{ key: this.state.city_id, label: this.state.city }}
              onChange={this.setCity}
              placeholder="Select City"
            >
              {this.state.filtered.length === 0 ? citiesList : FliteredList}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Security</Text>

            <Input
              placeholder="Security"
              type="text"
              value={this.state.security_id}
              onChange={this.setSecurity}
            />
          </Form.Item>
          <Form.Item>
            <Text>Organization *</Text>
            <Select
              labelInValue
              value={{ key: this.state.org_id, label: this.state.organization }}
              onChange={this.setOrganization}
              placeholder="Select Organization"
            >
              {this.state.data.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Contact Number</Text>

            <Input
              placeholder="Example:+8801711123456"
              type="text"
              value={this.state.contact_no}
              onChange={this.setContact}
            />
          </Form.Item>
          <Form.Item>
            <Text>Emergency Contact</Text>

            <Input
              placeholder="Emergency Contact"
              type="text"
              value={this.state.emr_contact_name}
              onChange={this.setEmergencyContact}
            />
          </Form.Item>
          <Form.Item>
            <Text>Blood Group</Text>

            <Input
              placeholder="Blood Group"
              type="text"
              value={this.state.blood_grp}
              onChange={this.setBloodgroup}
            />
          </Form.Item>
          <Form.Item>
            <Text>License Number</Text>

            <Input
              placeholder="License Number"
              type="text"
              value={this.state.lic_no}
              onChange={this.setLicense}
            />
          </Form.Item>
          {/* <Form.Item>
            <Text>License First Issue Date</Text>

            <Input
              placeholder="License First Issue Date"
              type="text"
              value={this.state.lic_no}
              onChange={this.setLicense}
            />
          </Form.Item>
          <Form.Item>
            <Text>Total Experience of Driving</Text>

            <Input
              placeholder="Total Experience of Driving"
              type="text"
              value={this.state.lic_no}
              onChange={this.setLicense}
            />
          </Form.Item> */}
          {/* <Form.Item>
            <Text>Remarks</Text>

            <Input
              placeholder="Remarks"
              type="text"
              value={this.state.lic_no}
              onChange={this.setLicense}
            />
          </Form.Item> */}
          {/* <Form.Item>
          <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>Expiry Date</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.exp_date_view}
                  onChange={this.setExpiryDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item> */}

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createDriver}
            >
              Create User
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
