import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Tooltip } from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";

import { EDIT_ROUTE, CREATE_ROUTE } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Column } = Table;

class RouteList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false
  };
  componentDidMount() {
    this.props.getUserData();
    this.getRouteList();
  }

  getRouteList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ROUTE}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from route list", response);
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from route list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getRouteList();
      }
    );
  };

  deleteTask = id => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${EDIT_ROUTE}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.task.route.route_create?(
          <Link to="/createRoute">
          <Tooltip placement="top" title="Create Route">
          <Button shape="circle" icon="plus" className="tooltip-button" />
          </Tooltip>
        </Link>
        ):null}
        
        <PageHeader
          title="Routes"
        />

        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column
            title="Start"
            dataIndex="start_loc_name"
            key="start_loc_name"
          />
          <Column title="End" dataIndex="end_loc_name" key="end_loc_name" />
          <Column
            title="Trip Duration"
            dataIndex="trip_duration"
            key="trip_duration"
          />

          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                
                {this.props.userProfileData.task.route.route_update?(
                  <Link
                  to={{
                    pathname: "/editRoute",
                    state: { route_id: record._id.$oid }
                  }}
                >
                  <Button
                    type="link"
                    style={{ color: "green" }}
                  >
                    Edit
                  </Button>
                </Link>
                ):null}
                {this.props.userProfileData.task.route.route_delete?(
                    <Button
                    type="link"
                    style={{ color: "red" }}
                    onClick={() => {
                      if (window.confirm("Delete the item?")) {
                        this.deleteTask(record._id.$oid);
                      }
                    }}
                  >
                    Delete
                  </Button>
                ):null}
                
              
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(RouteList);
