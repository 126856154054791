import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Tooltip } from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";

import { CREATE_TASK_TYPE } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Column } = Table;

class TaskTypeList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false
  };
  componentDidMount() {
    this.props.getUserData();
    this.getTaskType();
  }

  getTaskType = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_TASK_TYPE}?page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from task type list", response);
          // if (response.data.data.length === 0) {
          //   swal.fire("Info", "No Data Available", "info");
          // }
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from task type list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getTaskType();
      }
    );
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.task.task_type.task_type_create ? (
          <Link to="/createTaskType">
            <Tooltip placement="top" title="Create Task Type">
            <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}

        <PageHeader
          title="Task Types"
        />
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.task.task_type.task_type_update ? (
                  <Link
                    to={{
                      pathname: "/editTaskType",
                      state: { taskType_id: record._id.$oid }
                    }}
                  >
                    <button
                      className="borderless-button"
                      style={{ color: "green" }}
                    >
                      Edit
                    </button>
                  </Link>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(TaskTypeList);
