import {createStore,applyMiddleware} from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from "../reducers/index";

const persistConfig = {
    key: 'root',
    storage,
  }

const initialState={};

const middleWare=[thunk];

const persistedReducer=persistReducer(persistConfig,rootReducer)
const store=createStore(persistedReducer,initialState,applyMiddleware(...middleWare));
const persistor=persistStore(store)

export default store
export {persistor}